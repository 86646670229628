import { useLocation } from 'react-router-dom';
import { PATHS } from 'AppPaths';

const footerBlockedUrls = [
  PATHS._CHECKOUT,
  PATHS._MOBILE_SEARCH,
  PATHS._RESERVATION,
  PATHS._VERIFICATION_SUCCESS,
];

const carouselBlockedUrls = [
  PATHS._CHECKOUT,
  PATHS._TRAINING,
  PATHS._PROFILE,
  PATHS._COOKIE_POLICY,
  PATHS._ACCOUNT_ACTIVATION,
  PATHS._GENERAL_TERMS,
  PATHS._TERMS,
  PATHS._POLICY,
  PATHS._THANK_YOU,
  PATHS._RESET,
  PATHS._AUTH,
  PATHS.MOBILE_SEARCH,
  PATHS._RESERVATION,
  PATHS._JOB_TYPES,
  PATHS._VERIFICATION,
  PATHS._VERIFICATION_SUCCESS,
];

const recentlyViewedProductsBlockedUrls = [
  PATHS._CHECKOUT,
  PATHS._SUCCESS,
  PATHS._PROFILE,
  PATHS._COOKIE_POLICY,
  PATHS._ACCOUNT_ACTIVATION,
  PATHS._GENERAL_TERMS,
  PATHS._TERMS,
  PATHS._POLICY,
  PATHS._THANK_YOU,
  PATHS._RESET,
  PATHS._AUTH,
  PATHS.MOBILE_SEARCH,
  PATHS._RESERVATION,
  PATHS._VERIFICATION,
  PATHS._VERIFICATION_SUCCESS,
];

const headerBlockedUrls = [PATHS._VERIFICATION_SUCCESS];

export const useGetLayoutConfiguration = () => {
  const { pathname } = useLocation();

  return {
    isHeader: !headerBlockedUrls.some((e) => pathname.includes(e)),
    isCarousel:
      !carouselBlockedUrls.some((element) => pathname.includes(element)) &&
      pathname !== PATHS.ROOT,
    isRecentlyViewedProducts: !recentlyViewedProductsBlockedUrls.some(
      (element) => pathname.includes(element),
    ),
    isFooter: !footerBlockedUrls.some((e) => pathname.includes(e)),
    isProfile: pathname.includes('profile'),
  };
};
