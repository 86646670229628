import React, { useCallback, useContext, useRef } from 'react';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import { useTotalPriceCalculating } from 'hooks';
import { Lang } from 'lang';
import {
  cartAllItemsLength$,
  cartFormattedData$,
  latestTotalPriceInfo$,
  setIsCartPopupVisible,
} from 'models/cart';
import { $isCheckoutNotAvailable } from 'models/common';

import CheckoutBtn from 'features/common/CheckoutBtn';

import { history } from 'libs/history';

import ProductList from './ProductsList';

import { Row, Spinner, T8y } from 'ui';

import { ReactComponent as CloseIcon } from 'assets/images/icon/closeCart.svg';

import style from './cartPopup.module.scss';

const CartPopup = () => {
  const { checkout, sidebar } = Lang();

  const branding = useContext(BrandingContext);
  const settings = useContext(SettingsContext);

  const ref = useRef();
  const { latestTotalPrice, isLoadingLatestTotalPrice } = useStore(
    latestTotalPriceInfo$,
  );
  const cartAllItemsLength = useStore(cartAllItemsLength$);
  const cartFormattedData = useStore(cartFormattedData$);
  const isCheckoutNotAvailable = useStore($isCheckoutNotAvailable);

  useTotalPriceCalculating({
    cartFormattedData,
    deliveryMethodData: {
      method: null,
      address: {},
    },
  });

  const handleBtnClick = useCallback((isQuote) => {
    sendGAEvent(
      isQuote
        ? 'reservation_popup_start_getquote'
        : 'reservation_popup_start_checkout',
    );
    setIsCartPopupVisible(false);
    history.push(
      isQuote ? PATHS.RESERVATION_QUOTE : PATHS.RESERVATION_CHECKOUT,
    );
  }, []);

  return (
    <div className={style.cartReservationPopover}>
      <div className={style.cartReservationWrapper}>
        <Row justify="stretch" align="center" className={style.popoverHeader}>
          <T8y variant="t1v1" bold>
            {checkout.titles.yourReservation} ({cartAllItemsLength})
          </T8y>
          <button
            type="button"
            className={style.closeWrapper}
            onClick={() => {
              setIsCartPopupVisible(false);
              sendGAEvent('reservation_popup_close');
            }}
            data-testid="reservation-popup-close-btn"
          >
            <CloseIcon />
          </button>
        </Row>
        <div ref={ref} className={style.cartReservationList}>
          <ProductList />
        </div>
        <div className={style.popoverFooter}>
          <Row
            as={T8y}
            bold
            justify="stretch"
            data-testid="total"
            className={style.totalPriceWrapper}
          >
            <T8y variant="t2v1" className={style.totalPriceText} as="span" bold>
              {sidebar.itemsToHire}:
            </T8y>
            {isLoadingLatestTotalPrice ? (
              <Spinner dark size={1} />
            ) : (
              <T8y
                variant="t2v1"
                color="primary"
                className={style.totalPriceText}
                bold
                as="span"
                noWrap
              >
                {branding.currencySymbol}
                {latestTotalPrice.items_total?.toFixed(2)}
              </T8y>
            )}
          </Row>
          <Row direction="column" fullWidth>
            {!isCheckoutNotAvailable && (
              <CheckoutBtn
                onClick={() => handleBtnClick(false)}
                text={checkout.checkout}
                additionalClass={clsx(style.cartActionBtn, style.checkoutBtn)}
                isSelected
              />
            )}
            {settings.isQuoteEnabled && !settings.transparentPricingEnabled && (
              <CheckoutBtn
                onClick={() => handleBtnClick(true)}
                text={checkout.getQuote}
                additionalClass={clsx(style.cartActionBtn, style.quoteBtn)}
              />
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;
