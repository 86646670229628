export const about = {
  brands: {
    title: 'Valores da marca',
    sections: {
      greatValue: 'Excelente Valor',
      bigBoys: 'Escolhendo a Primeira Liga',
      forTheMany: 'Para muitos, não para poucos',
      relentlessInnovation: 'Inovação implacável',
      keepItSimple: 'Mantenha-o simples',
      entrepreneurial: 'Empreendedor',
      makingTheDifferencePeopleLives: 'Fazendo a diferença na vida das pessoas',
      caringAndFun: 'Honesto, Aberto, Cuidadoso e Divertido',
    },
  },

  depots: {
    title: 'Nossos depósitos estão em toda a região!',
    description:
      'Escolha Coleta durante o check-out e retire seus produtos em um de nossos depósitos - é sempre grátis!', // in main page
    showMoreDepots: 'Mostrar mais depósitos',
    showLessDepots: 'Mostrar menos depósitos',
  },

  easyLinkText:
    '{0}® faz parte da família de marcas {1}®. Para obter mais informações, visite {2} e saiba mais sobre a família de marcas easy.',
  easyHistoryLinkText:
    '{0}® faz parte da família de marcas {1}®. Para obter mais informações, visite {2} e conheça a história da marca.',
  vision: '{0} Visão',
  representativeIn: '{0} - {1} representante em {2}',
  about: 'Sobre {0}',
  contactUsTitle: 'Contacte-nos',
  history: 'História da família de marcas easy',
  representativeInRegion: '{0} representantes em {1} região',
};
