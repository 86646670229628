export const invoice = {
  titles: {
    name: 'Nome',
    tel: 'Tel',
    email: 'Email',
    address: 'Endereço',
    item: 'Item',
    sku: 'SKU',
    price: 'Preço',
    days: 'Dias',
    subtotal: 'Subtotal',
    insurance: 'Seguro',
    safetyFee: 'Taxa de segurança',
    sanitationFee: 'Taxa de Saneamento',
    tax: 'imposto',
    transport: 'Transporte',
    deposit: 'Depósito',
    total: 'Total',
    grandTotal: 'Total Geral',
  },
};
