export const header = {
  region: {
    region: 'Regiones',
    areYouIn: '¿Estás ubicado en',
    yes: 'Sí',
    no: 'No, elige',
    checkOther: 'Estas son las regiones en las que operamos:',

    serviceAvailability: 'Disponibilidad de servicio',
    weWillStart:
      'Pronto comenzaremos a ofrecer nuestros servicios en tu región!',
    meanwhileFeel:
      'Mientras tanto, siéntete libre de navegar por nuestro catálogo en',
    orLeaveYour:
      'O deja tu información de contacto y te avisaremos una vez que estemos activos en tu región',
    weDetected: 'Hemos visto que te encuentras en,',
    wouldYouLike: '¿Quieres modificar la región?',
    isNotAvailable: 'todavía no está disponible en tu región.',
    okUse: 'ok, usa',
    submit: 'Enviar',
  },

  getAQuote: 'Consigue un presupuesto',
  allProducts: 'Todos los productos',

  byUsingThis: 'Al utilizar este sitio, acepta el uso de cookies.',
  cookieBtn: 'Aceptar',

  backTo: 'Atrás',

  nav: {
    back: 'Atrás',
    backToPreviousPage: 'Volver a la página anterior',
    home: 'Página de inicio',
    catalog: 'Catálogo',
    hireByCategory: 'Alquiler',
    company: 'Empresa',
    training: 'Formación',
    aboutUs: 'Sobre Nosotros',
    blog: 'Blog',
    logIn: 'Acceder',
    logOut: 'Salir',
    customerPortal: 'Cuenta de confianza',
    reservation: 'Tu reserva',
    faq: 'Preguntas Frecuentes',
    carbonOffsetting: 'Compensación de carbono',
    menuLanguage: 'Español',
    signAs: 'Sesión iniciada como',
    maintenance: 'Mantenimiento industrial',
    otherServices: 'Otros servicios',
    jobTypes: 'Tipo de Trabajo',
    findHowWeAre: 'Descubre quiénes somos',
    searchResults: 'Resultados de la búsqueda',
    breadcrumbRegion: 'Región',
  },

  bar: {
    customize: 'Personalización',
    depots: 'Delegaciones',
    allDepots: 'Todas las delegaciones',
    seeAllDepots: 'Ver todas las delegaciones',
    allDepotsTitle: 'Nuestras direcciones de delegaciones en {0}',
    choose: 'Elige la delegación que más te conviene',
    yourChoice:
      'Tu elección aplicará un filtro a nuestro inventario. Para navegar el catálogo entero, selecciona todas las delegaciones.',
    regionDepots: 'Regiones y delegaciones',
    yourRegion: 'Tu Región',
    selectedRegion: 'Región seleccionada',
    region: 'Regiones',
    selectRegion: 'Elige la región',
    selectDepots: 'Elige la delegacion',
    back: 'Atrás',
    isCommitted: '{0} está comprometido con el alquiler sostenible {1}',
    learnMore: 'Aprende más',
  },

  callModal: {
    title: '¡Mejor precio en línea!',
    text: 'Para un mejor precio, crea tu presupuesto y haz tu pedido en línea.',
    closeBtnText: 'Ok, lo haré en línea',
    subText:
      'Si aún quieres llamarnos, hazlo a este número. Pero recuerda, ¡obtendrás un mejor precio si lo gestionas en línea!',
  },
};
