export const training = {
  seoTitle: 'Curso de Formación en Seguridad Laboral en España Central',

  checkout: {
    mainTitle: 'Inscripción al curso',
    numberOfSeatsTitle: 'Número de plazas',
    datesTitle: 'Fecha de inscripción',
    contactTitle: 'Datos de contacto',
    paymentTitle: 'Método de pago',
    enterDetails: 'Introduce los datos de contacto',
    maxNumberOfSeatsMessage:
      '¿Quieres reservar para 5 o más personas? ¿Eres una empresa que quiere formar a su personal? ¡Contáctanos para encontrar la mejor oferta para ti!',
  },

  titles: {
    signUpFor: 'Inscríbete a nuestros cursos de formación',
    closestDate: 'Fecha más cercana',
    gallery: 'Galería',
    schedule: 'Horario de los cursos',
    learning: 'Lo que vas a aprender',
    advantages: 'Por qué elegirnos',
    about: 'Acerca del certificado',
    reviews: 'Evaluaciones del curso',
    questions: 'Preguntas frecuentes',
    other: 'Otros cursos',
    description: 'Descripción del curso',
    haveAnyQuestions: '¿Tiene alguna pregunta? Contáctanos!',
    fillInTheForm: 'Completa el formulario para proceder al pago',
    partners: 'Curso realizado en colaboración con',
    exploreOurCourses: 'Explora nuestros cursos de formación',
    recommendedProducts: 'Productos Recomendados',
  },

  emptyDates: 'Fechas a definir',
  emptyDate: 'Fecha a definir',
  emptyLocation: 'Organizador del curso y ubicación a definir',
  emptyPrice: 'Precio a definir',
  emptyTheory: 'Horas de teoría y práctica a definir',
  noSchedule:
    'Programación disponible sobre pedido. Generalmente tenemos 4-5 cursos al mes. Por favor usa el formulario para hacer una reserva y te contactaremos a la brevedad.',
  perSeat: 'por persona',
};
