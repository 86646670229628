import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import { Lang } from 'lang';
import { currentTrainingLocalized$ } from 'models/trainings';

import { Button, Row, T8y } from 'ui';

import { ReactComponent as IconSuccess } from 'assets/images/icon/success.svg';

import style from './successEnquiryMessage.module.scss';

const SuccessEnquiryMessage = ({ isValidDates }) => {
  const { formatString, form } = Lang();

  const settings = useContext(SettingsContext);

  const currentTrainingLocalized = useStore(currentTrainingLocalized$);

  const history = useHistory();

  const handleGoToCheckout = useCallback(() => {
    sendGAEvent('training-proceed-to-checkout-button');
    history.push(PATHS.TRAINING_CHECKOUT(currentTrainingLocalized?.slug));
  }, [currentTrainingLocalized, history]);

  return (
    <>
      <Row direction="column" justify="center" align="center">
        <div className={style.successIconWrapper}>
          <IconSuccess />
        </div>
        <T8y variant="t1" color="mainGray" className={style.thankYouWrapper}>
          {form.success.thankYou}
        </T8y>
      </Row>

      <T8y
        variant="t1v1"
        color="mainGray"
        className={style.contactTextWrapper}
        center
      >
        {form.success.youHave}
      </T8y>

      {isValidDates && (
        <>
          <div className={style.checkoutTextWrapper}>
            <T8y variant="t1v1" color="mainGray" center>
              {form.success.futureCheckout}{' '}
              {!!settings.trainingCardDiscountValue && (
                <T8y
                  className={style.checkoutDiscountTextWrapper}
                  variant="t1v1"
                  color="mainGray"
                  center
                >
                  {form.success.checkoutDiscount}
                  <T8y
                    variant="t1v1"
                    color="mainGray"
                    className={style.checkoutDiscountTextWrapper}
                    bold
                  >
                    {formatString(
                      form.success.discountPercent,
                      settings.trainingCardDiscountValue,
                    )}
                    .
                  </T8y>{' '}
                </T8y>
              )}
            </T8y>
          </div>
          <Button
            className={style.trainingGoToCheckoutBtn}
            onClick={handleGoToCheckout}
            data-testid="goToCheckoutTraining_btn"
            isNoStretch
          >
            {form.success.okProceed}
          </Button>
        </>
      )}
    </>
  );
};

export default SuccessEnquiryMessage;
