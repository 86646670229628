import { PATHS } from 'AppPaths';
import { BIG_PRODUCT_CARD_FORMAT } from 'consts';
import { combine, createEvent, createStore, restore } from 'effector';
import { getQueryParam } from 'utils/formatStrings';
import { getUrlSearchAlgoliaParams } from 'utils/formatUrl';

import { history } from 'libs/history';

export const changeSortby = createEvent();
export const clearSortby = createEvent();
export const changeSearchStr = createEvent();
export const changeSearchResultsAlgolia = createEvent();
export const changeSearchStrFromUrl = createEvent();
export const changeRangeFilters = createEvent();
export const resetFilters = createEvent();

export const SORT_OPTIONS = [
  { slug: 'popular', value: 'popular', gaSlug: 'popularity' },
  { slug: 'date', value: 'date', gaSlug: 'new_products' },
  { slug: 'priceLow', value: 'price', order: 'asc', gaSlug: 'price_ascending' },
  {
    slug: 'priceHigh',
    value: 'price',
    order: 'desc',
    gaSlug: 'price_descending',
  },
];

const getDefaultSelectedSortBy = () => {
  const sortFromUrl = getUrlSearchAlgoliaParams()?.sort;
  const orderFromUrl = getUrlSearchAlgoliaParams()?.order;

  return SORT_OPTIONS.find((el) => {
    if (el.order && orderFromUrl) {
      return el.value === sortFromUrl && el.order === orderFromUrl;
    }

    return el.value === sortFromUrl;
  })?.slug;
};

export const selectedSortby$ = createStore(
  getDefaultSelectedSortBy() || SORT_OPTIONS[0].slug,
)
  .on(changeSortby, (_, p) => p)
  .on(clearSortby, (_) => SORT_OPTIONS[0].slug);

export const changeSelectedCardFormat = createEvent();

export const selectedCardFormat$ = createStore(BIG_PRODUCT_CARD_FORMAT).on(
  changeSelectedCardFormat,
  (_, p) => p,
);

export const defaultRangeValues = {
  power: [1, 120],
  weight: [10, 1600],
};

export const rangeFilters$ = createStore(defaultRangeValues)
  .on(changeRangeFilters, (s, p) => {
    return {
      ...s,
      [p.filter]: p.value,
    };
  })
  .on(resetFilters, (s, p) => defaultRangeValues);

export const selectedSortBySlug$ = selectedSortby$.map((s) => {
  return SORT_OPTIONS.find((el) => el.slug === s).value;
});

export const orderSorting$ = createStore(
  getUrlSearchAlgoliaParams()?.order || null,
).on(selectedSortby$.updates, (_, p) => (p === 'priceLow' ? 'asc' : 'desc'));

export const searchStr$ = restore(changeSearchStr, '');

export const filter$ = combine({
  sort: selectedSortBySlug$,
  order: orderSorting$,
  q: searchStr$,
  search: rangeFilters$,
});

// search
export const filterSearchString$ = filter$.map(({ sort, q, order, search }) => {
  let actualSort = sort;

  if (actualSort === 'price') {
    actualSort =
      order === 'asc' || !order ? actualSort + 'Low' : actualSort + 'High';
  }
  const isDefaultSort = SORT_OPTIONS[0].slug === actualSort;

  const jobTypeId = getQueryParam(history.location.search, 'jobTypeId');

  return new URLSearchParams(
    Object.entries({
      ...(!q && jobTypeId && { jobTypeId: jobTypeId }),
      ...(searchStr$.defaultState !== q && { q }),
      ...(!isDefaultSort && { sort }),
      ...(order && !isDefaultSort && { order }),
      ...(rangeFilters$.defaultState !== search && { search }),
    }).filter(([, i]) => i?.length),
  );
});

filterSearchString$.updates.watch((filter) => {
  // no IE
  if (history.location.pathname.includes('page')) {
    history.replace(
      `${history.location.pathname.split('page')[0]}page/1?${filter}`,
      history.location.state,
    );
    return;
  }
  history.replace(
    `${history.location.pathname}?${filter}`,
    history.location.state,
  );
});

searchStr$.updates.watch((str) => {
  const isSorting = SORT_OPTIONS.find(({ slug }) => slug === str);

  if (!history.location.pathname.includes(PATHS._SEARCH) && str && !isSorting) {
    history.push(`${PATHS.SEARCH(1)}?q=${str}`);
  }
});
