import React from 'react';
import clsx from 'clsx';
import { useWindowResize } from 'hooks';

import { useMatchMedia } from 'libs/useMatchMedia';

import style from './mainPageTemplate.module.scss';

const MainPageTemplate = () => {
  const isMobile = useMatchMedia('(max-width: 575px)');
  const [width] = useWindowResize();

  const bannerHeight = isMobile ? `${width - width * 0.2}px` : '580px';

  return (
    <>
      <div
        className={style.mainPageBlock}
        style={{
          height: bannerHeight,
        }}
      />
      <div className={clsx(style.mainPageBlock, style.topCategoriesTemplate)} />
      <div className={clsx(style.mainPageBlock, style.categoriesTemplate)} />
    </>
  );
};

export default MainPageTemplate;
