import { useContext, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { isETH } from 'consts';
import { SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { currentLang$ } from 'models/language';

export const useStripePromise = () => {
  const settings = useContext(SettingsContext);

  const currentLangState = useStore(currentLang$);

  return useMemo(() => {
    return loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
      locale: currentLangState || settings.defaultLanguage,
      ...(!isETH && {
        stripeAccount: settings.stripeAccountId,
      }),
    });
  }, [currentLangState, settings]);
};
