import React from 'react';
import { Tooltip, TooltipReference, useTooltipState } from 'reakit';
import clsx from 'clsx';
import { SMALL_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { $userInfo } from 'models/user';

import { useMatchMedia } from 'libs/useMatchMedia';

import { T8y } from 'ui';

import { default as membershipDiscount } from 'assets/images/icon/membershipDiscount.svg';

import style from './labels.module.scss';

const Labels = ({ price, label, showDiscount, isBigCard }) => {
  const { profile, dashboard } = Lang();

  const user = useStore($userInfo);
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const tooltipStatus = useTooltipState({
    unstable_offset: [70, -70],
  });

  const tooltipLabel = useTooltipState({
    unstable_offset: [20, -70],
  });

  const tooltipText = `${profile.membershipDiscount}: ${
    dashboard.discountTypes[user.status]
  }`;

  return (
    <div
      className={clsx(style.labelsWrapper, !isBigCard && style.smallCardLabels)}
    >
      {!!user.userDiscount && !!price && (
        <>
          <TooltipReference
            {...tooltipStatus}
            className={clsx(
              style.label,
              style.discountLabel,
              label && style.partLabel,
            )}
          >
            <div className={style.discountWrapper}>
              <img
                className={style.userDiscountImg}
                src={membershipDiscount}
                alt="membership"
              />
              <T8y
                color="light"
                variant="t2v1"
                bold
                className={clsx(
                  style.labelText,
                  !isBigCard && style.labelTextSmall,
                )}
              >
                -{user.userDiscount}%
              </T8y>
            </div>
          </TooltipReference>
          {!isSmallScreen && (
            <Tooltip {...tooltipStatus}>
              <div className={style.textTooltip}>
                <T8y color="light" variant="t2v1">
                  {tooltipText}
                </T8y>
              </div>
            </Tooltip>
          )}
        </>
      )}
      {label && (
        <TooltipReference
          {...tooltipLabel}
          className={clsx(
            style.label,
            style.customLabel,
            showDiscount && !!price && style.partLabel,
          )}
        >
          <T8y
            color="light"
            variant="t2v1"
            bold
            className={clsx(
              style.labelText,
              !isBigCard && style.labelTextSmall,
            )}
          >
            {label}
          </T8y>
          {!isSmallScreen && (
            <Tooltip {...tooltipLabel}>
              <div className={style.textTooltip}>
                <T8y color="light" variant="t2v1">
                  {label}
                </T8y>
              </div>
            </Tooltip>
          )}
        </TooltipReference>
      )}
    </div>
  );
};

export default Labels;
