import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const CheckoutSuccess = lazy(() =>
  lazyRetry(() => import('./index'), 'CheckoutSuccess'),
);

export const checkoutSuccessRoutes = [
  {
    path: PATHS.SUCCESS,
    exact: true,
    component: () => WithSuspense(CheckoutSuccess),
  },
];
