export const form = {
  titles: {
    signUp: 'Sign up for the corse',
    thankYouFor: 'Thank you for signing up!',
    youHaveSuccessfully: 'You have successfully',
    makeAnEnquiry: 'Make an Enquiry',
    makeAnEnquiryButton: 'Contact Us',
    checkBoxIAgree:
      'I consent to sharing my information with {0}. We will never share your information with third parties without your permission.',
    checkBoxLink: 'See Privacy Policy here',
    checkBoxIWish:
      "I want to be the first to hear about {0}'s latest offers, product launches, blogs and company news.",
    makeAnEnquiryDescription:
      'Please feel free to contact us if you have any questions. We’ll be happy to help!',
    howWouldYouLike: 'How would you like to be contacted?',
    pleaseEnterDetails: 'Please enter your contact details',
  },

  optionalField: 'optional',
  cancel: 'Cancel',
  saveChanges: 'Save Changes',

  fields: {
    search: 'Search',
    sortBy: 'Sort by',
    fullName: 'Full Name',
    emailAddress: 'Email Address',
    PhoneNumber: 'Phone Number',

    firstName: 'First name',
    lastName: 'Last name',
    dateOfBirth: 'Date of birth',
    birthplace: 'Birthplace',
    address: 'Address',

    companyName: 'Company Name',
    country: 'Country',
    vatNumber: 'VAT Number',
    sortCode: 'Sort Code',
    userType: 'User Type',
    accountNumber: 'Account Number',
    taxNumber: 'Tax Number',
    city: 'City',

    newEmail: 'New Email',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',

    deliveryAddress: 'Delivery Address',
    message: 'Message',
    yourCommentsOrQuestions: 'Your comments or questions',
    privacyLink: 'Privacy Policy',
    postcode: 'Postal code',
    resendLink: 'Re-send a link',
    forgotPassword: 'Forgot password',

    currentEmailAddress: 'Current Email Address',
    newEmailAddress: 'New Email Address',
    enterNewEmailAddress: 'Enter your new email address',
    password: 'Password',
    enterPassword: 'Enter your password',
    currentPassword: 'Current Password',
    repeatNewPassword: 'Repeat New Password',
    passwordConfirmation: 'Password Confirmation',
    enterNewPassword: 'Enter your new password',
    repeatYourNewPassword: 'Repeat your new password',
    enterCurrentPassword: 'Enter your current password',
    clear: 'Clear',
    purchaseOrderNumber: 'Purchase order number (Optional)',
  },

  radio: {
    phone: 'Phone',
    email: 'Email',
    chat: 'Chat / WhatsApp',
  },

  placeholders: {
    superConstruction: 'Super Construction Co.',
    italy: 'Italy',
    enterNewEmail: 'Enter new email',
    enterOldPassword: 'Enter old password',
    enterNewPassword: 'Enter new password',
    reEnterNewPassword: 'Re-enter new password',
    startTyping: 'Start typing...',
    startTypingDeliveryAddress: 'Start typing your delivery address...',
    enterPostalCode: 'Enter postal code',
    yourMessage: 'Your message',
    startTypingToFilter: 'Start typing to filter',
    ordersAvailable: 'Orders available',
    orderAvailable: 'Order available',
    quotesAvailable: 'Quotes available',
    quoteAvailable: 'Quote available',
    dateRange: 'Date Range',
    message: 'Detail your enquiry or any questions here',
    yourComment: 'Your comment',
    purchasePlaceholder: `Enter customer's reference number`,
  },

  select: {
    select: 'Select',
    popular: 'Popularity',
    priceLow: 'Price (low to high)',
    priceHigh: 'Price (high to low)',
    date: 'New arrivals',
    depotToLocation: 'Depot to Location',
    locationToDepot: 'Location to Depot',
    bothWaysDelivery: 'Delivery and Collection',
  },

  refineListing: {
    title: 'Refine Listing',
    reset: 'Reset',
    power: 'Power Output, kW',
    weight: 'Weighting Capacity, kg',
  },

  buttons: {
    signUp: 'Sign up',
    confirm: 'Confirm & Place Order',
    nextStep: 'Next step',
    back: 'Back',
    submitIndex: 'Submit Index',
    submitEnquiry: 'Submit Enquiry',
    browseSimilar: 'Browse Similar Options',
    all: 'All',
    signUpNow: 'Sign Up Now',
    makeReservation: 'Make an Enquiry',
    proceedToCheckout: 'Proceed to Checkout',
    viewUserGuidelines: 'View user guidelines',
    continueHiring: 'Continue Hiring',
    goToCheckout: 'Go to Checkout',
    submitRequest: 'Submit Request',
    viewDetails: 'View Details',
  },

  calendar: {
    selectHirePeriod: 'Select Hire Period',
    previous: 'Previous',
    reset: 'Reset to Default',
    next: 'Next',
    dayPlaceholder: 'DD',
    monthPlaceholder: 'MM',
    yearPlaceholder: 'YYYY',
  },

  errors: {
    cardNumber: 'Card number is a required field',
    cardExpiry: 'Expiration is a required field',
    cardCvc: 'CVC is a required field',
    regionEmail: 'Error',
    invalidData: 'Invalid data',
    noTerms:
      'You must read and agree to the GeneralTerms & Conditions in orders to proceed',
    wentWrong: 'Something went wrong',
    wentWrongTryLater: 'Something went wrong, try again later',
    productNotAvailable: 'This product is not available for ordering',
    deliveryFromWarehouseNotAvailable:
      'Delivery information session expired, please refresh the page',
  },

  validation: {
    required: 'Required field*',
    requiredInOneWord: 'Required*',
    emailPattern: 'Incorrect email',
    passwordLength: 'Password must have at least 6 characters',
    passwordDifferent: 'Passwords are different',
    invalidCreds: 'Invalid email or password',
    userImageUploadError: `Image can't be saved`,
    emailVerification: 'Email verification link sent on your email',
    minLength: 'Min. {0} characters',
    phonePatter: 'Incorrect phone number',
  },

  success: {
    regionEmail: 'Your request has been sent successfully!',
    thankYou: 'Thank you for your enquiry!',
    youHave:
      'We are working on your enquiry and will contact you shortly via email or phone.',
    futureCheckout:
      'You can reuse the information entered in the enquiry form and proceed to checkout.',
    checkoutDiscount: 'For all courses paid online we provide ',
    discountPercent: '{0}% discount',
    okProceed: 'Okay, Proceed to Checkout',
    thankYouForEnquiry: 'Thank you for submitting your enquiry!',
    weWillContact: 'We’ll contact you shortly.',
  },

  successGetQuote: {
    title: 'Your quote has been placed successfully',
    thankYouTitle: 'Thank you for your quote request!',
    msg: 'We have sent an email with requested quote to your email address.  We’ll be in touch soon!',
    newCheckoutMsg:
      'We have sent an email with requested quote to your email address.  We’ll be in touch soon!',
  },

  purchaseTooltip:
    'Add this if your customer wants a purchase order reference associated with the order. This will appear on the invoice your customer receives.',
};
