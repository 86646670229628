import React from 'react';
import clsx from 'clsx';

import style from './grid.module.scss';

export const Row = React.forwardRef(
  (
    {
      children,
      align = 'start',
      xlgAlign = align,
      lgAlign = xlgAlign,
      mdAlign = lgAlign,
      smAlign = mdAlign,
      xsAlign = smAlign,

      justify = 'start',
      xlgJustify = justify,
      lgJustify = xlgJustify,
      mdJustify = lgJustify,
      smJustify = mdJustify,
      xsJustify = smJustify,

      direction = 'row',
      xlgDirection = direction,
      lgDirection = xlgDirection,
      mdDirection = lgDirection,
      smDirection = mdDirection,
      xsDirection = smDirection,

      className,
      noWrap,
      inline,
      pointer,
      xsCenter,

      fullWidth,
      xsFullWidth,
      smFullWidth,
      mdFullWidth,
      lgFullWidth,
      xlgFullWidth,

      hide,
      xsHideMin,
      smHideMin,
      mdHideMin,
      lgHideMin,
      xsHide,
      smHide,
      mdHide,
      lgHide,

      mb,
      mb2,
      mb3,
      mb4,
      mb5,
      mt,
      mt2,
      mt3,
      mt4,
      mt5,
      mt9,
      mr,
      mr2,
      mr3,
      mr4,
      mr5,
      ml,
      ml2,
      ml3,
      ml4,
      ml5,

      as: Component = 'div',
      ...rest
    },
    ref,
  ) => {
    return (
      <Component
        ref={ref}
        className={clsx(
          [
            style.row,
            style[`row-align-${align}`],
            style[`xlg-row-align-${xlgAlign}`],
            style[`lg-row-align-${lgAlign}`],
            style[`md-row-align-${mdAlign}`],
            style[`sm-row-align-${smAlign}`],
            style[`xs-row-align-${xsAlign}`],

            style[`row-justify-${justify}`],
            style[`xlg-row-justify-${xlgJustify}`],
            style[`lg-row-justify-${lgJustify}`],
            style[`md-row-justify-${mdJustify}`],
            style[`sm-row-justify-${smJustify}`],
            style[`xs-row-justify-${xsJustify}`],

            style[`row-direction-${direction}`],
            style[`xlg-row-direction-${xlgDirection}`],
            style[`lg-row-direction-${lgDirection}`],
            style[`md-row-direction-${mdDirection}`],
            style[`sm-row-direction-${smDirection}`],
            style[`xs-row-direction-${xsDirection}`],

            className,
          ],
          {
            [style['row-noWrap']]: noWrap,
            [style['row-inline']]: inline,
            [style['row-pointer']]: pointer,
            [style['row-xsCenter']]: xsCenter,

            [style['row-fullWidth']]: fullWidth,
            [style['row-xsFullWidth']]: xsFullWidth,
            [style['row-smFullWidth']]: smFullWidth,
            [style['row-mdFullWidth']]: mdFullWidth,
            [style['row-lgFullWidth']]: lgFullWidth,
            [style['row-xlgFullWidth']]: xlgFullWidth,

            [style['row-hide']]: hide,
            [style['row-xsHide-min']]: xsHideMin,
            [style['row-smHide-min']]: smHideMin,
            [style['row-mdHide-min']]: mdHideMin,
            [style['row-lgHide-min']]: lgHideMin,

            [style['row-xsHide']]: xsHide,
            [style['row-smHide']]: smHide,
            [style['row-mdHide']]: mdHide,
            [style['row-lgHide']]: lgHide,

            [style['row-mb']]: mb,
            [style['row-mb2']]: mb2,
            [style['row-mb3']]: mb3,
            [style['row-mb4']]: mb4,
            [style['row-mb5']]: mb5,
            [style['row-mt']]: mt,
            [style['row-mt2']]: mt2,
            [style['row-mt3']]: mt3,
            [style['row-mt4']]: mt4,
            [style['row-mt5']]: mt5,
            [style['row-mt9']]: mt9,
            [style['row-mr']]: mr,
            [style['row-mr2']]: mr2,
            [style['row-mr3']]: mr3,
            [style['row-mr4']]: mr4,
            [style['row-mr5']]: mr5,
            [style['row-ml']]: ml,
            [style['row-ml2']]: ml2,
            [style['row-ml3']]: ml3,
            [style['row-ml4']]: ml4,
            [style['row-ml5']]: ml5,
          },
        )}
        {...rest}
      >
        {children}
      </Component>
    );
  },
);
