import React, { Suspense } from 'react';

import RouteLoadingSpinner from 'features/common/RouteLoadingSpinner';

const WithSuspense = (
  WrappedComponent,
  FallbackComponent = RouteLoadingSpinner,
) => (
  <Suspense fallback={<FallbackComponent />}>
    <WrappedComponent />
  </Suspense>
);

export default WithSuspense;
