import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { SMALL_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import MobileDropDown from 'layout/Footer/MobileDropDown';
import { trainingsLocalized$ } from 'models/trainings';

import { useMatchMedia } from 'libs/useMatchMedia';

import DesktopTrainingsList from './DesktopTrainingsList';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const Trainings = () => {
  const { footer } = Lang();

  const trainings = useStore(trainingsLocalized$);

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  return isSmallScreen ? (
    <MobileDropDown title={footer.training} titleAsLink path={PATHS.TRAINING}>
      {trainings.map(({ title, slug }, i) => (
        <T8y
          key={i}
          as={Link}
          to={PATHS.POST(slug)}
          variant="t1v1"
          color="primary"
          className={style.footerLink}
        >
          {title}
        </T8y>
      ))}
    </MobileDropDown>
  ) : (
    <DesktopTrainingsList />
  );
};

export default Trainings;
