import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const Training = lazy(() =>
  lazyRetry(() => import('./TrainingIndex'), 'TrainingIndex'),
);

export const trainingIndexRoutes = [
  {
    path: PATHS.TRAINING,
    exact: true,
    component: () => WithSuspense(Training),
  },
];
