export const footer = {
  titles: {
    supportedRegions: 'Regioni operative',
  },

  copyright: 'Copyright © {0} {1}. Tutti i diritti riservati.',
  termsConditions: 'Termini e Condizioni',
  cookiePolicy: 'Cookie Policy',
  generalTerms: 'Condizioni Generali',
  privacyPolicy: 'Policy',

  instantQuote: 'Preventivo Immediato',
  training: 'Formazione',
  information: 'Informazioni',
  aboutUs: 'Chi Siamo',
  blog: 'Blog',
  faq: 'Domande Frequenti',
  securePayments: 'Pagamenti sicuri',

  thisWebsite: 'This website is Powered by',
  rentuuOperates:
    'easyHire Technologies Ltd operates a global, fast-growing, cloud-based technology platform and franchise network for the multi-billion pound sterling equipment hire industry.',
  learnMoreEasyFamilyOfBrands:
    'Visita {0} e scopri di più sulla famiglia di marchi easy.',
  learnMoreEasyHistory: 'Visita {0} e scopri la storia del marchio.',

  followUs: 'Seguici',
};
