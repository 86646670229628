export const customer = {
  titles: {
    quotes: 'Presupuestos',
    yourRecentQuotes: 'Tus presupuestos más recientes y pasados',
    orders: 'Pedidos',
    yourRecentOrders: 'Tus pedidos recientes y pasados',
  },

  tabs: {
    recent: 'Recientes',
    past: 'Anteriores',
  },

  items: {
    quote: 'Presupuesto',
    order: 'Pedido',
    totalPrice: 'Precio total',
    hireDates: 'Fechas de contratación',
    dateSubmited: 'Fecha de envío',
    requestSummary: 'Resumen',
    hireDites: 'Fechas de alquiler',
    deliveryMethod: 'Métodos de entrega',
    deliveryAddress: 'Dirección de entrega',
    selfCollectionDepot: 'Retiro en sede',
    products: 'productos',
    estimateTotal: 'Total Estimado',
    completeCheckout: 'Completa el pago',
    viewInvoice: 'Ver factura',
    askQuestion: 'Haz una pregunta',
    selfCollection: 'Recogida',
    delivery: 'Dirección de entrega y recogida',
  },

  account: {
    userType: 'Perfil del usuario',
    individual: 'Particular',
    company: 'Empresa',
    authentication: 'Autenticación',
    changeEmail: 'Modifica tu e-mail',
    changePassword: 'Modifica tu contraseña',
  },

  notification: {
    quotes: 'Presupuestos',
    orders: 'Pedidos',
    supportChat: 'Asistencia por chat ',
    quoteIsReady: 'El presupuesto está listo para la verificación',
    emailNotificationWhen:
      'Recibe una notificación por correo electrónico cuando tu solicitud de presupuesto haya sido procesada y esté lista para la revisión.',
    paymentFailed: 'Pago no realizado',
    emailNotificatonIfOrder:
      'Recibe notificación por correo electrónico en caso de pago no realizado',
    newChatMessage: 'Nuevo mensaje de chat',
    emailNotificatonIfThere:
      'Recibe una notificación por correo electrónico si llega un nuevo mensaje de chat mientras estás desconectado',
  },
};
