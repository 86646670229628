import { useEffect, useMemo } from 'react';
import { PATHS } from 'AppPaths';
import { getCurrentTrainingFx, setLastTrainingUrl } from 'models/trainings';

import { history } from 'libs/history';

export const useGetRequestByUrl = () => {
  const pathname = useMemo(() => history?.location?.pathname || '', []);

  const dividedUrlParts = useMemo(
    () => pathname.split('/').filter((el) => Boolean(el)),
    [pathname],
  );

  const slugUrl = useMemo(() => [...dividedUrlParts].pop(), [dividedUrlParts]);

  useEffect(() => {
    if (
      slugUrl !== PATHS._TRAINING &&
      pathname.includes(PATHS._TRAINING) &&
      !pathname.includes(PATHS._CHECKOUT)
    ) {
      getCurrentTrainingFx(slugUrl).catch(({ response }) => {
        if (response?.status === 404) {
          history.push(PATHS.NOT_FOUND);
        }
      });
      setLastTrainingUrl(slugUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
};
