import { createEvent, createStore } from 'effector';

const DEFAULT_STATE = {
  slug: null,
  isCategory: false,
  isCategoryLoading: false,
  isProductCategoryPage: null,
};

export const setIsCategory = createEvent();
export const setDefaultIsCategory = createEvent();

export const isCategory$ = createStore({ ...DEFAULT_STATE })
  .on(setIsCategory, (s, p) => {
    return { ...s, ...p };
  })
  .on(setDefaultIsCategory, (s, p) => {
    return { ...DEFAULT_STATE };
  });
