import { about } from './it/about';
import { account } from './it/account';
import { carbonEmission } from './it/carbonEmission';
import { checkout } from './it/checkout';
import { common } from './it/common';
import { customer } from './it/customer';
import { dashboard } from './it/dashboard';
import { depots } from './it/depots';
import { error } from './it/error';
import { footer } from './it/footer';
import { form } from './it/form';
import { header } from './it/header';
import { invoice } from './it/invoice';
import { jobTypes } from './it/jobTypes';
import { main } from './it/main';
import { orders } from './it/orders';
import { products } from './it/products';
import { profile } from './it/profile';
import { search } from './it/search';
import { sidebar } from './it/sidebar';
import { success } from './it/success';
import { training } from './it/training';
import { verification } from './it/verification';

export const it = {
  about,
  account,
  training,
  main,
  footer,
  sidebar,
  checkout,
  products,
  header,
  form,
  profile,
  dashboard,
  customer,
  search,
  invoice,
  error,
  orders,
  carbonEmission,
  common,
  success,
  jobTypes,
  depots,
  verification,
};
