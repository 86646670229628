import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { BrandingContext } from 'contexts';

export const useMainPageStructureData = () => {
  const branding = useContext(BrandingContext);

  const [structuredData, setStructuredData] = useState(null);

  const getExternalTxt = useCallback(async () => {
    if (!branding.mainPageStructureDataFileSrc) {
      return null;
    }

    try {
      const { data } = await axios.get(branding.mainPageStructureDataFileSrc);
      return data;
    } catch (e) {
      return null;
    }
  }, [branding]);

  const getStructureData = useCallback(async () => {
    const text = await getExternalTxt();

    setStructuredData(text ? JSON.stringify(text) : null);
  }, [getExternalTxt]);

  useEffect(() => {
    getStructureData();
  }, [getStructureData]);

  return useMemo(() => structuredData, [structuredData]);
};
