import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { aboutUs$ } from 'models/aboutUs';

import style from './youtubeIframe.module.scss';

export const YoutubeIframe = () => {
  const aboutUs = useStore(aboutUs$);

  const src = aboutUs.aboutUsVideo || '';

  const isYouTube = useMemo(() => {
    return src.includes('youtube');
  }, [src]);

  const video = useMemo(() => {
    return (
      !!src && (
        <video
          className={style.box}
          poster={aboutUs.aboutUsVideoPoster}
          autoPlay
          muted
          loop
          playsInline
        >
          {<source src={src} type="video/mp4" />}
        </video>
      )
    );
  }, [aboutUs, src]);

  const youtube = useMemo(() => {
    return (
      !!src && (
        <div className={style.youtubeWrap}>
          <iframe
            src={
              src +
              '?autoplay=1&mute=1&controls=1&loop=1&modestbranding=1&autohide=1&showinfo=0&rel=0'
            }
            className={style.youtubeVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )
    );
  }, [src]);

  return isYouTube ? youtube : video;
};
