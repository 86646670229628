import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import SearchFallback from 'pages/search/SearchFallback';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const SearchWrapper = lazy(() =>
  lazyRetry(() => import('./SearchWrapper'), 'SearchWrapper'),
);

export const searchRoutes = [
  {
    path: PATHS.SEARCH(':page?'),
    exact: true,
    component: () => WithSuspense(SearchWrapper, SearchFallback),
  },
];
