import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDisclosureState } from 'reakit';
import { Lang } from 'lang';

import { REGIONS } from 'api';

import { Button, Field, inputStyle, Row, T8y } from 'ui';

import { ReactComponent as IconMail } from 'assets/images/icon/icons8-secured_letter.svg';

import style from './leaveContactsForm.module.scss';

const LeaveContactsForm = () => {
  const { checkout, header, form } = Lang();

  const disclosure = useDisclosureState();

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = useCallback(
    async (data) => {
      try {
        const { email } = data;
        const password = 'password';
        await REGIONS.send({ email, password });
        disclosure.hide();
        toast.success(form.success.regionEmail);
        reset();
      } catch (e) {
        console.warn(e);
        toast.error(form.errors.regionEmail);
      }
    },
    [disclosure, form.errors.regionEmail, form.success.regionEmail, reset],
  );

  return (
    <>
      <T8y variant="h3" mb2>
        {header.region.orLeaveYour}
      </T8y>
      <Row as="form" justify="stretch" onSubmit={handleSubmit(onSubmit)}>
        <Field icon={IconMail} className={style.emailField}>
          <input
            name="email"
            ref={register}
            className={inputStyle}
            type="email"
            placeholder={checkout.fields.emailAddress}
          />
        </Field>
        <Button type="submit">{header.region.submit}</Button>
      </Row>
    </>
  );
};

export default LeaveContactsForm;
