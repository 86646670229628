import React, { useContext, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { MOBILE_SCREEN } from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { isCartPopupVisible$ } from 'models/cart';
import {
  isFixedReservationBannerVisible$,
  setShowFreshChatBtn,
  showFreshChatBtn$,
} from 'models/layoutData';
import { $regions } from 'models/regions';
import { isSettingsRequestFinished$ } from 'models/settings';

import { useMatchMedia } from 'libs/useMatchMedia';

import { isMenuOpen$ } from '../Header/model';
import AttachmentTop from './AttachementTop';
import Brands from './Brands';
import Categories from './Categories';
import Information from './Information';
import PoweredBy from './PoweredBy';
import Regions from './Regions';
import Trainings from './Trainings';

import { Row, T8y } from 'ui';

import { ReactComponent as Watsapp } from 'assets/images/icon/watsapp.svg';

import style from './footer.module.scss';

const currentYear = new Date().getFullYear();

export const Footer = () => {
  const { footer, formatString } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const regions = useStore($regions);
  const isFixedReservationBannerVisible = useStore(
    isFixedReservationBannerVisible$,
  );
  const isMenuOpen = useStore(isMenuOpen$);
  const showFreshChatBtn = useStore(showFreshChatBtn$);
  const isCartPopupVisible = useStore(isCartPopupVisible$);
  const isSettingsRequestFinished = useStore(isSettingsRequestFinished$);
  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);

  const isChatsHidden = isCartPopupVisible && isMobile;

  useEffect(
    () => {
      setShowFreshChatBtn(!isChatsHidden);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.pathname, isChatsHidden],
  );

  const regionsForRender = useMemo(
    () => regions?.filter(({ url }) => url !== null) || [],
    [regions],
  );

  return (
    <footer>
      <AttachmentTop />
      <Row
        justify="center"
        smDirection="column"
        className={style.footerAttachmentContainer}
      >
        <div className={style.footerColumn}>
          <Categories />
        </div>
        {settings.showTrainingsInMenu && (
          <div className={style.footerColumn}>
            <Trainings />
          </div>
        )}
        <div className={style.footerColumn}>
          <Information />
        </div>
        {!!regionsForRender.length && (
          <div className={style.footerColumn}>
            <Regions regionsForRender={regionsForRender} />
          </div>
        )}
        <div className={style.footerColumn}>
          <PoweredBy />
        </div>
      </Row>
      <Brands />
      <T8y variant="t1v1" center className={style.copyright}>
        {formatString(footer.copyright, currentYear, branding.companyName)}
      </T8y>

      {branding.whatsappPhoneNumber && !isMenuOpen && (
        <T8y
          as="a"
          className={clsx(
            style.whatsappIcon,
            settings.isFreshChatHidden
              ? style.rightPosition
              : style.leftPosition,
            isFixedReservationBannerVisible && style.chatWithReservationPanel,
          )}
          href={'https://wa.me/' + branding.whatsappPhoneNumber}
          target="_blank"
          aria-label="Whatsapp"
        >
          <Watsapp />
        </T8y>
      )}
      {isSettingsRequestFinished &&
        !settings.isFreshChatHidden &&
        showFreshChatBtn && (
          <div
            className={clsx(
              style.freshChatBtn,
              isFixedReservationBannerVisible && style.chatWithReservationPanel,
            )}
            onClick={() => {
              if (window.Freshbots) {
                setShowFreshChatBtn(false);
                window.Freshbots.showWidget(true);
                window.Freshbots.initiateChat();
              }
            }}
          >
            <div className={style.freshChatLogo} />
          </div>
        )}
    </footer>
  );
};
