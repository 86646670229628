import { useCallback, useEffect, useLayoutEffect } from 'react';

export function useKeyUpEsc(handler) {
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (window.innerWidth && document.body.clientWidth) {
      document.body.style.paddingRight =
        document.body.scrollHeight !== window.innerHeight
          ? `${window.innerWidth - document.body.clientWidth}px`
          : '0';
    }
  }, []);

  const handleKeyUp = useCallback((e) => {
    const keys = {
      27: () => {
        e.preventDefault();
        handler();
        window.removeEventListener('keyup', handleKeyUp, false);
      },
    };

    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
