export const account = {
  header: 'Minha conta',
  subHeader: 'Todas as configurações e preferências da sua conta em uma página',
  save: 'Salvar',
  saveChanges: 'Salvar alterações',
  cancel: 'Cancelar',
  sections: {
    personalInformation: 'Informações pessoais',
    userType: 'Tipo de usuário',
    deliveryAddress: 'Endereço de entrega',
    authentication: 'Autenticação',
    emailNotifications: 'Notificações por e-mail',
  },

  addresses: {
    addressBlockHeader: 'Endereço',
    makeDefault: 'Tornar padrão',
    edit: 'Editar',
    delete: 'Deletar',
    defaultMark: 'Endereço padrão',
    addressLine: 'Linha de endereço',
    chooseCorrectAddress: 'Por favor, escolha o endereço correto da lista',
  },

  personal: {
    title: 'Valores da marca',
    profileImage: 'Imagem de perfil',
    uploadFile: 'Subir arquivo',
    deleteBtn: 'Deletar',
  },

  formFields: {
    nameFieldTitle: 'Nome completo',
    nameFieldPlaceholder: 'Digite seu nome',
    emailFieldTitle: 'Endereço de e-mail',
    emailFieldPlaceholder: 'Insira o seu endereço de email',
    phoneFieldTitle: 'Número de telefone',
    phoneFieldPlaceholder: 'Digite seu número de telefone',
  },
  youAreLoggedInAs: 'Você está logado como',
};
