export const dashboard = {
  header: 'Welcome',
  subHeader: 'This is your customer portal dashboard',

  getQuote: 'Get quote',
  viewOrder: 'View orders',
  support: 'Contact support',
  allProducts: 'All products',

  titles: {
    quickStart: 'Quick start',
    recentQuotes: 'Recent Quotes',
    recentOrders: 'Recent Orders',
    onHireOrders: 'On Hire',
    popularCategories: 'Popular Categories',
    noQuotes: 'No quotes to display. Please browse our {0}',
    noOrders: 'No orders to display. Please browse our {0}',
    catalog: 'Catalogue',
    popularProducts: 'Most Popular Products',
    benefitsCustomer: '{0} Account Benefits',
  },

  table: {
    estimateCellHeader: 'Estimate total',
    deliveryCellHeader: 'Delivery date',
    collectionCellHeader: 'Collection date',
  },

  quotesTable: {
    quoteCellHeader: 'Quote #',
    estimateCellHeader: 'Estimate total',
    deliveryCellHeader: 'Delivery date',
    collectionCellHeader: 'Collection date',
    btnText: 'View Quote',
  },

  ordersTable: {
    orderCellHeader: 'Order #',
    priceCellHeader: 'Price total',
    deliveryCellHeader: 'Delivery date',
    collectionCellHeader: 'Collection date',
    statusCellHeader: 'Status',
    btnText: 'View Index',
    dateCreated: 'Date Created',
    grandTotal: 'Grand Total',
    partnership: 'Developed In Partnership With',
  },

  discountText: 'Your discount on all inventory',

  discountTypes: {
    platinum: 'Platinum',
    gold: 'Gold',
    silver: 'Silver',
    top: 'Top',
  },

  discountPortalTypes: {
    platinum: 'Platinum Discount',
    gold: 'Gold Discount',
    silver: 'Silver Discount',
    top: 'Top Discount',
  },

  dashboardButtons: {
    newOrder: 'Start a New Order',
    copyPrevOrder: 'Copy Previous Order',
    newUser: 'Add New User',
    supportChat: 'Support Chat',
    reorder: 'Reorder',
    cancelOrder: 'Cancel Order',
    cancelRequestSent: 'Cancel request sent',
    changeEndDate: 'Change End Date',
  },
};
