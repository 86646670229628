import { useCallback, useEffect, useMemo } from 'react';
import { MOBILE_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { requestsPerPageStore$ } from 'models/prerender';

import { useMatchMedia } from 'libs/useMatchMedia';

const PRERENDER_TIMEOUT = 1000;

export const useAddPrerenderFlag = (pageName) => {
  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);

  const requestsPerPageStore = useStore(requestsPerPageStore$);

  const requestPerPage = useMemo(() => {
    return requestsPerPageStore[pageName] || [];
  }, [pageName, requestsPerPageStore]);

  useEffect(() => {
    window.prerenderReady = false;
  }, []);

  const setFlagForTraining = useCallback(() => {
    if (!isMobile) {
      setTimeout(() => {
        window.prerenderReady = true;
      }, PRERENDER_TIMEOUT);
    }
  }, [isMobile]);

  useEffect(() => {
    const isAllReqFinished = requestPerPage.every((el) => !!el);

    if (!isAllReqFinished) {
      window.prerenderReady = false;
      return;
    }

    if (pageName === 'training') {
      setFlagForTraining();
      return;
    }

    if (!isMobile) {
      window.prerenderReady = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, pageName, requestPerPage]);
};
