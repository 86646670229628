import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import { Lang } from 'lang';
import { actualRecentlyViewedProducts$ } from 'models/layoutData';
import { currProduct$ } from 'models/product';

import SliderWithMobileOverflowWrapper from 'features/common/SliderWithMobileOverflowWrapper';

import { T8y } from 'ui';

import style from './recentlyViewedProducts.module.scss';

const RecentlyViewedProducts = () => {
  const { main } = Lang();

  const recentlyViewedProducts = useStore(actualRecentlyViewedProducts$);

  const mobileSliderWrapperRef = useRef(null);

  const product = useStore(currProduct$);

  const handleProductClick = useCallback((product) => {
    sendGAEvent('product_selected_from_recently_viewed', {
      'product name': product.name,
    });
  }, []);

  useEffect(
    () => {
      if (mobileSliderWrapperRef?.current?.scroll) {
        mobileSliderWrapperRef.current.scroll(0, 0);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.pathname],
  );

  const actualRecentlyViewedProducts = useMemo(
    () =>
      product
        ? recentlyViewedProducts.filter((el) => product.slug !== el.slug)
        : recentlyViewedProducts,
    [product, recentlyViewedProducts],
  );

  if (!actualRecentlyViewedProducts.length) {
    return null;
  }

  return (
    <div className={style.carouselWrapper}>
      <T8y variant="h1v1" smVariant="t1" className={style.sectionTitle} bold>
        {main.titles.recentlyViewedProducts}
      </T8y>
      <SliderWithMobileOverflowWrapper
        testId="recently-viewed-products"
        products={actualRecentlyViewedProducts}
        handleProductClick={handleProductClick}
        isStartPositioning
        noSliderMobileWrapperRef={mobileSliderWrapperRef}
      />
    </div>
  );
};

export default RecentlyViewedProducts;
