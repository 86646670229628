export const search = {
  searchResultFor: 'Search results for',
  searchResults: 'Search Results',
  nothingFound:
    'No products are found. Please, browse our {0}, change search query or submit an enquiry using the form below',
  noItems: 'No items to display',
  searchHistory: 'Search History',
  popularCategories: 'Popular Categories',
  noResultsFound: 'Sorry, we couldn’t find any matches',
  searchResultForJobType: `Search Results for "{0}" job type`,
};
