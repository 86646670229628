export const carbonEmission = {
  pageTitle: 'Hire Carbon Neutral',

  ourCommitment: {
    title: 'Our Сommitment',
    text1:
      'One year after its launch, {0} has decided to take the road to become a zero-emission company, taking part in projects and solutions for climate change while offering the possibility of participating to all its partners and customers.',
    text2:
      'For each rental, we offset the CO2 emissions deriving from the use of the rented machinery, investing in sustainable projects. We are all aware that our commitment to the environment should not end there, but this is our first step to reduce emissions that directly involve our business.',
  },

  howDoesItWork: {
    title: 'How Does it Work',
    text1:
      'We are committed to obtaining the best result in offsetting our CO2 emissions. To accomplish this, we work together with First Climate, a leader in carbon offsetting and renewable energy solutions. The offsetting of emissions is based on a financial contribution that we pay to support high-impact sustainability projects.',
    text2:
      'We offer end customers the possibility to take part in the contribution, otherwise, {0} will cover the costs ourselves. We are continuously working to improve the effectiveness of CO2 emissions calculations.',
    carbonEmission: 'We’ll compensate CO2 emission for each rental transaction',
    team: 'You can participate yourself or we’ll take care of the total cost of the compensation',
    environment:
      'We’ll take part to environmentally sustainable projects to help reduce part of our impact on the environment',
    analyticsData:
      'We’ll continue to update and improve our data on average emission and usage per product/category',
  },

  supportWithFirstClimate: {
    title: 'The Project We Support With First Climate',

    backgroundTitle: 'Background',
    backgroundText1:
      'Since 1990, Indonesia has lost almost a quarter of its forest cover. Borneo‘s rainforests, like in many tropical areas, are under threat from timber cutting, mineral mining and the production of palm oil, pulp and rubber.',
    backgroundText2:
      'The problem continues to worsen as cleared areas provide easy access to previously remote reaches of the forest. Easier access has also resulted in a growth in illegal wildlife trade, posing a further threat to the wildlife in the area. It has been estimated that an equivalent area of a football field is deforested every minute.',
    backgroundText3:
      'The peat swamp forests in Borneo alone hold up to 70 times more carbon than the amounts emitted annually by burning fossil fuels across the globe. Therefore, the degradation of the forests can result in drastic loss of carbon stocks. Furthermore, the area is extraordinarily rich in biodiversity and is home to hund-reds of endangered species that are under threat from over exploitation.',

    projectTitle: 'The Project',
    projectText1:
      'The Rimba Raya Reserve is located in Borneo’s southeast stretching almost 100 kilometers from North to South along the borders of Tanjung Puting National Park. The reserve covers around 64,000 hectares and is dedicated to the protection and preservation of many endangered species, most notably, the endangered Bor-nean Orangutan whose population has declined over 95% in the last century. To protect areas of the forest, land use rights must be attained for the land bordering the protected areas. So far, the project financing supports 13 community based initiatives ranging from water filtration to supporting a shrimp aquaculture co-operative.',
    projectText2:
      'While focusing on reducing greenhouse gas emissions, all our projects also generate multiple co-benefits. These are supportive of the United Nations Sustainable Development Goals.',

    technologyBriefTitle: 'Technology Brief – How it Works',
    technologyBriefText1:
      'Carbon circulates within a cycle, consisting of the atmosphere, the plant, plant litter and the soil. Carbon dioxide drawn from the surrounding atmosphere is the main input of any plant’s photo-synthesis processes. The outputs are water, oxygen and carbohydrates. The latter are built into the plant’s fibre thereby fixing carbon in the plant‘s biomass. Ultimately, the carbon re-enters the atmosphere from decaying biomass litter or soil respiration.',
    technologyBriefText2:
      'Deforestation breaks this cycle with multi-fold negative effects. First, burning biomass directly in-creases the amount of carbon dioxide in the atmosphere. Secondly, it reduces the biosphere’s abso-lute capacity to fix carbon. Thirdly, the removal of plant cover accelerates the rate at which carbon fixed in soils is respired into the atmosphere. Lastly, the erosion of soils impedes the long-term recovery of vegetation on degraded areas. This is a particularly challenging issue in tropical climates where soils are mostly poor in nutrients.',

    projectStandardsTitle: 'Project Standards',
    projectStandardsVCSboldText: 'The Verified Carbon Standard (VCS)',
    projectStandardsVCSText:
      'is a global standard for the validation and verification of voluntary carbon emission reductions. Emissions Reductions from VCS projects have to be real, measurable, permanent,additional, unique, transparent, and third-party verified. Assessed against the background of the total volume of emission reductions, VCS is the globally leading standard for voluntary carbon offsets.',
    projectStandardsCCBSboldText:
      'The Climate, Community & Biodiversity (CCB) Standards',
    projectStandardsCCBSText:
      'were launched in 2005 to foster development of, and investment in, site-based projects that deliver credible and significant climate, community and biodiversity benefits in an integrated, sustainable manner.',

    additionalText:
      '* Emission calculation data are based on average product category estimates. Every day we work to improve and update them.',
  },
};
