import React from 'react';
import clsx from 'clsx';

import style from './spinner.module.scss';

export function Spinner({ dark: Dark, children, size, margin, className }) {
  return (
    <div
      className={clsx(style.spinner, className, { [style.dark]: Dark })}
      style={{ fontSize: size, margin }}
    >
      {children}
    </div>
  );
}
