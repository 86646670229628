export const orders = {
  order: 'Ordine',
  orders: 'Ordini',
  viewOrder: 'Visualizza Ordine',
  viewInvoice: 'Visualizza Fattura',
  viewQuote: 'View quote',
  viewQuotes: 'View quotes',
  productList: 'Elenco prodotti',
  youWantToCancelThisOrder: 'Sei sicuro di voler cancellare questo ordine?',
  provideCancellationReason: 'Se sì, fornisci il motivo della cancellazione',
  enterNewEndHireDateAndReason:
    'Per favore, comunicaci una nuova data di fine noleggio e un motivo per questa modifica',
  endHireDate: 'Nuova data di fine noleggio',
  provideReasonForThisChange: 'Fornisci un motivo per questa modifica',

  item: {
    orderStatus: "Stato dell'ordine",
    date: 'Data di creazione',
    transport: 'Transport',
    paymentType: 'Modalità di pagamento',
    productList: 'Elenco prodotti',
    documents: 'Documenti',
  },

  table: {
    orderId: 'ID Ordine',
    orderNumber: 'Numero ordine ',
    quoteNumber: 'Quote number',
    invoiceDate: 'Data fattura',
    status: 'Status',
    payment: 'Pagamento',
    startDate: 'Inizio noleggio',
    endDate: 'Fine noleggio',
    purchaseOrderNumber: `Numero d'ordine di Acquisto`,
  },

  paymentType: {
    card: 'Carta di credito',
    cash: 'Contanti',
    bank: 'Bonifico Bancario',
    other: 'Altro',
  },

  statusType: {
    error: 'Errore',
    paid: 'Pagato',
    not_paid: 'Non Pagato',
    new: 'Nuovo',
    in_progress: 'In Corso',
    completed: 'Completato',
    cancelled: 'Cancellato',
    all: 'Tutti Gli Ordini',
  },

  backTo: 'Torna alla lista degli ordini',
  backToQuotes: 'Back to quotes listing',
};
