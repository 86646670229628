import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { Lang } from 'lang';

import { Row } from 'ui';
import { T8y } from 'ui/Typography/Typography';

import style from './pagination.module.scss';

export const Pagination = ({
  currentPage = 1,
  slug = '',
  search = '',
  totalPages = 7,
  isSearch,
  onClickCallback,
}) => {
  const { sidebar } = Lang();

  const activeItem = useMemo(() => {
    return Number(currentPage) - 1;
  }, [currentPage]);

  if (!totalPages || totalPages === 1) {
    return null;
  }

  return (
    <Row
      className={style.paginationWrapper}
      justify="center"
      align="center"
      inline
      fullWidth
    >
      <T8y variant="t2" as={Row} align="center" mt>
        {sidebar.page}: &nbsp;
      </T8y>

      {Array.from({ length: totalPages }).map((_, i) => {
        const path = isSearch
          ? PATHS.SEARCH(i > 0 ? i + 1 : '', search)
          : PATHS.CATEGORY(slug, i + 1, search);

        return (
          <Row
            key={i}
            to={path}
            as={Link}
            align="center"
            justify="center"
            className={clsx(style.item, { [style.active]: activeItem === i })}
            onClick={() => {
              if (onClickCallback) {
                onClickCallback(i + 1);
              }
            }}
            data-testid={`paginationBtn-${i}`}
          >
            <span className={style.paginationNumber}>{i + 1}</span>
          </Row>
        );
      })}
    </Row>
  );
};
