import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useTrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    const dataLayerObject = {
      event: 'pageView',
      page: location.pathname + location.search,
    };
    const dataLayer = (window.dataLayer = window.dataLayer || []);
    dataLayer.push(dataLayerObject);
  }, [location]);
};
