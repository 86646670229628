import React, { useEffect, useMemo } from 'react';
import { RadioGroup, useRadioState } from 'reakit';
import clsx from 'clsx';
import { getDisplayedRegionName, REACT_APP_PREFIX } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  regionForDisplaying$,
  setRegionForDisplaying,
} from 'models/regionForDisplay';
import { $regions } from 'models/regions';
import { $warehouses } from 'models/warehouses';

import { Radio, Row, T8y } from 'ui';

import { ReactComponent as ArrowBack } from 'assets/images/icon/arrowBack.svg';

import style from '../header.module.scss';

const SubMenuRegion = ({ id, openId, setOpenId }) => {
  const {
    formatString,
    header: { bar },
  } = Lang();

  const radioType = useRadioState({ state: null });

  const regions = useStore($regions);
  const warehouses = useStore($warehouses);
  const regionForDisplaying = useStore(regionForDisplaying$);

  const activeRegions = useMemo(
    () => regions?.filter(({ url }) => url !== null),
    [regions],
  );

  //TODO move this and function from Region component to one hook
  const currentRegion = useMemo(() => {
    const regionBySlug = activeRegions?.find(({ url }) =>
      url.includes(`/${REACT_APP_PREFIX}`),
    );

    if (!regionForDisplaying) {
      return regionBySlug;
    }

    const actualRegion = activeRegions?.find(
      ({ url, state }) =>
        url.includes(`/${REACT_APP_PREFIX}`) && regionForDisplaying === state,
    );

    if (!actualRegion) {
      return regionBySlug;
    }

    return actualRegion;
  }, [activeRegions, regionForDisplaying]);

  useEffect(() => {
    radioType.setState(currentRegion?.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  const isHire = openId === id;

  return (
    <div className={style.category}>
      <Row
        justify="stretch"
        align="center"
        className={clsx(
          style.name,
          isHire ? style.border : style.triangle,
          openId > 0 && !isHire && style.none,
        )}
        onClick={() => {
          setOpenId(isHire ? 0 : 4);
        }}
        data-testid="mobile-regions-disclosure"
      >
        {isHire ? (
          <>
            <Row align="center">
              <ArrowBack className={style.backArrow} />{' '}
              <T8y variant="t1" color="light" className={style.menuTitle}>
                {bar.back}
              </T8y>
            </Row>
            <T8y variant="t3" color="lightGray" uppercase>
              {bar.yourRegion}
            </T8y>
          </>
        ) : (
          <Row direction="column">
            <T8y
              variant="t1"
              color="light"
              capitalize
              className={style.menuTitle}
            >
              {bar.yourRegion}
            </T8y>

            <T8y variant="t3" color="lightGray" capitalize>
              {bar.selectedRegion}: {currentRegion?.name || ''}
            </T8y>
          </Row>
        )}
      </Row>

      {isHire && (
        <>
          <T8y
            className={style.pLeft}
            color="lightGray"
            variant="t3"
            capitalize
            mb2
            mt2
          >
            {bar.selectRegion}
          </T8y>
          <RadioGroup
            as={Row}
            direction="column"
            {...radioType}
            aria-label="region"
            mb
            mt
          >
            {activeRegions?.map(({ state, name, url }, i) => (
              <Radio
                key={i}
                {...radioType}
                text={name}
                value={state}
                variant="t1"
                color="light"
                className={style.radio}
                onClick={() => {
                  setRegionForDisplaying(state);
                  window.location.replace(url);
                }}
              />
            ))}
          </RadioGroup>

          {!!warehouses?.length && (
            <Row direction="column" className={style.subMenuWrapperInner}>
              <T8y color="lightGray" variant="t3" mb2>
                {formatString(
                  bar.allDepotsTitle,
                  getDisplayedRegionName() || '',
                )}
              </T8y>

              {warehouses.map(({ id, name, address }) => {
                return (
                  <T8y key={id} color="light" className={style.mobileDepotItem}>
                    {address + name.split('-')[1]}
                  </T8y>
                );
              })}
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default SubMenuRegion;
