import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import { CircleButton, Row } from 'ui';

import style from './quantityChanger.module.scss';

export function QuantityChanger({
  startCount = 0,
  changeCount,
  small,
  light,
  maxDigits = 5,
  limit = false,
  maxQuantity,
  minQuantity = 0,
  additionalClass = null,
  isDisable = false,
  isLoading,
  inputStyle,
  circleBtnClassName,
  circleBtnDisableClassName,
  size,
}) {
  const [tempValue, setTempValue] = useState(null);
  const [isButtonClick, setIsButtonClick] = useState(false);

  useEffect(() => {
    if (tempValue !== null) {
      setTempValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCount]);

  const handleInputOnBlur = useCallback(() => {
    if (tempValue === null || isButtonClick) {
      return;
    }

    setTempValue(null);
    changeCount(1);
  }, [changeCount, isButtonClick, tempValue]);

  const handleChangeCount = useCallback(
    (count, type) => {
      setIsButtonClick(false);

      if (
        isDisable ||
        (type && isLoading) ||
        (type === 'increase' && limit) ||
        (type === 'decrease' && Number(count) < minQuantity) ||
        (!type &&
          ((!count.match(/^[1-9]\d*$/g) && count !== '') ||
            count.length > maxDigits ||
            (maxQuantity && Number(count) > maxQuantity) ||
            (minQuantity && Number(count) < minQuantity)))
      ) {
        return;
      }

      if (!type) {
        setTempValue(count);
        if (count === '') {
          return;
        }
      }

      changeCount(type && tempValue !== null ? 1 : Number(count));
      setTempValue(null);
    },
    [
      isDisable,
      isLoading,
      limit,
      minQuantity,
      maxDigits,
      maxQuantity,
      changeCount,
      tempValue,
    ],
  );

  return (
    <Row
      align="center"
      justify="center"
      noWrap
      className={clsx(
        style.wrap,
        { [style.small]: small },
        { [style.light]: light },
        additionalClass && style[additionalClass],
      )}
    >
      <CircleButton
        type="smallDecrease"
        disable={isDisable}
        size={size || (small && 11)}
        onClick={() => handleChangeCount(startCount - 1, 'decrease')}
        circleBtnClassName={circleBtnClassName}
        circleBtnDisableClassName={circleBtnDisableClassName}
        data-testid="quantityBtn-decrease"
        aria-label="quantity decrease"
      />
      <input
        data-testid="quantityInput"
        type="number"
        min="1"
        value={tempValue !== null ? tempValue : startCount}
        className={inputStyle || style.inputDefault}
        onChange={(e) => handleChangeCount(e.target.value)}
        onBlur={handleInputOnBlur}
        readOnly={isDisable}
        aria-label="quantity"
      />
      <CircleButton
        type="smallIncrease"
        disable={isDisable || limit}
        size={size || (small && 11)}
        onClick={() => handleChangeCount(startCount + 1, 'increase')}
        onMouseDown={() => setIsButtonClick(true)}
        circleBtnClassName={circleBtnClassName}
        circleBtnDisableClassName={circleBtnDisableClassName}
        data-testid="quantityBtn-increase"
        aria-label="quantity increase"
      />
    </Row>
  );
}
