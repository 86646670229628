import React from 'react';

import { ReactComponent as ES } from 'assets/images/icon/ES.svg';
import { ReactComponent as GB } from 'assets/images/icon/GB.svg';
import { ReactComponent as IT } from 'assets/images/icon/IT.svg';
import { ReactComponent as PT } from 'assets/images/icon/PT.svg';

export const setLanguageFlag = (flag) => {
  switch (flag) {
    case 'en-gb':
      return <GB />;
    case 'it':
      return <IT />;
    case 'es':
      return <ES />;
    case 'pt':
      return <PT />;
    default:
      return;
  }
};
