export const products = {
  allProducts: 'All products',
  forYourDates: 'For your dates',
  pricePerDay: 'Price per day',
  pricePerWeek: 'per week',
  exclVat: 'Price Excl. VAT',
  hiringPeriod: 'Hire Period',
  minHiringPeriod: 'Minimum period',
  discountText:
    'Your {0} membership provides a discount for all products in our {1}.',
  addToQuote: 'Add to Quote',
  addedToQuote: 'Added to Quote',
  yourReservation: 'Your Reservation',
  addedToReservation: 'Add to Reservation',
  maxQuantityReached: 'Max Quantity is Reached',
  hireTime: 'Hire time',
  price: 'Price',
  pricePer: 'per {0} days',
  pricePerOneDay: 'per 1 day',
  variations: ' Product Variations',
  userGuides: 'User guides',
  dimensionsOfTheUnit: 'Dimensions of the unit',
  CallUs: 'Call us if any questions',
  call: 'Call',
  requestCallback: 'Request a Callback',
  shareYourContactDetails:
    'Please share your contact details and we will call you back to answer all your questions.',
  comment: 'Comment',
  tableOfContents: 'Table of contents',
  frequentlyHiredTogether: 'Frequently hired together',
  goToGeneral: 'Go to general FAQ',
  day: 'day',
  days: 'days',
  additional: 'Additional 7 days, each',
  noDescription: 'No description',
  learnMore: 'Learn More...',
  yetToBeReviewed: 'Yet to be reviewed',
  averageScore: 'Average Score: {0}',
  finishAddToReservationModalText:
    'Product is successfully added to your reservation',
  carbonOffsettingText:
    'This hire is Carbon Neutral. We offset CO2 emission for this product.',
  ourDailyPrices: 'Our daily prices for longer term rental',
  save: 'Save {0} {1}',
  onAverage: 'on Average',
  promisePriceText:
    'Save {0} on tool hire and equipment rental. Order online and save money on the price you pay. Click and collect and delivery options are available.',
  clickRecommendationIcon: 'Click icon to read its description',
  quantity: 'Quantity',
  relatedJobTypes: 'Related Job Types',
  shareSpecs: 'Share Specs',
  viewThisAt: 'View this at',

  titles: {
    fullDescription: 'full description',
    fullReview: 'Full Review',
    faq: 'FAQ',
    perWeek: 'per week',
    reviews: 'Reviews',
    backToCategory: 'Back to Category',
    features: 'Features',
    feature: 'Feature',
    specifications: 'Specifications',
    thisProduct: 'This product has no specifications',
    otherCharges: 'Other Charges',
    toBeDefined: 'To be Defined',
    safetyRecommendations: 'Safety Recommendations',
    downloads: 'Downloads',
    shareThisPage: 'Share this page',
    video: 'Video',
    similarProducts: 'Similar Products',
  },

  consumables: {
    title: 'Required consumables for this product',
    subTitle: 'Please indicate the quantity of required consumables',
    showDetails: 'Show details',
    perItem: 'per item',
  },

  extra: {
    extras: 'Extras',
    modalTitle: 'Product Extras',
    subTitle: 'Tick extras you prefer and indicate their quantity',
    showDetalis: 'Show details',
    showMoreExtras: 'Show more extras',
    sale: 'Sale',
    select: 'Select',
    selected: 'Selected',
    skipThisStep: 'Skip This Step',
    skip: 'Skip',
    apply: 'Apply',
    addToCart: 'Add to Cart',
    needSelectReqExtra:
      'To order {0} you need to select required extras from the list below. You will get 1 extra item for every {0} hired. We are not providing {0} separately.',
  },

  other: {
    thisProduct: 'This product is available only hourly together with operator',
    pleaseShare:
      'Please, share your contact details in the form below and we will contact you shortly!',
    makeAnEnquiry: 'Make an Enquiry',
    perDay: 'per day',
    promisePriceSave: 'Save {0}%',
    file: 'File',
    changeDates: 'Change dates',
    hireStart: 'Hire Start',
    hireEnd: 'Hire End',
    readMore: 'Read more',
  },

  depots: {
    title: 'Our depots',
    description:
      'Stock is subject to availability. We update our system every 48 hours.',
    available: 'Available',
    unavailable: 'Not Available',
    toBeChecked: 'To be checked',
    qty: 'Qty',
  },

  noPrices: {
    title: 'Price on request',
    subTitle: 'The prices and contract details are available only on request.',
    text: 'To hire this product or find out more about it, please send us an inquiry by filling in the form listed below. It’s easy!',
  },

  feelFreeToContact:
    'Please feel free to contact us if you have any questions. We’ll be happy to help!',
  allCategoryProducts: 'All {0} products ({1})',

  benefits: {
    title: 'Benefits of hiring online with {0}',
    benefit1: 'Reviewed and trusted by users and companies',
    benefit2: 'Easy online checkout',
    benefit3: 'Personal account to manage orders',
    benefit4: 'Self-collection and delivery service',
  },

  getQuote: {
    modalTitle: 'Your Reservation Request',
    howToProceed: 'How would you like to proceed?',
    getQuote: 'Get Quote',
    getQuoteDescription:
      'Fill in a few fields and receive a quote with full pricing to your email.',
    checkoutDescription:
      'Share all the details required for hiring, view full pricing and make a payment.',
    checkoutAvailability: 'Check availability and proceed to checkout',
    checkoutAvailabilityDescription:
      'Share your contact details to check product availability and place your order online.',
    availabilityCheckTitle: 'Product Availability Check',
    notAvailable: "Product is not available, we'll get in touch soon!",
    notAvailableDescription:
      'We processed your request, but unfortunately the product is not in stock for the selected dates. One of our team members will contact you shortly to find a different solution for your request. Thank you!',
    available: 'Product is available',
  },

  availableLabels: {
    availableInStock: 'Available in stock',
    itemsAvailable: 'There are only {0} items in stock',
    oneItemAvailable: 'There is only 1 item in stock',
    unavailable: 'Not Available',
    outOfStock: 'Out of Stock',
  },

  referencesText: '{0} is a part of the {1} family of brands',
  easy: 'easy',
  easyJet: 'easyJet',
  showMoreVariations: 'Show more variations',
  notAvailableForCheckout:
    'To hire this product or find out more about it, please send us an inquiry by filling in the form listed below. It’s easy!',
  productIsOutOfStock: 'Product is out of stock',
  outOfStockText:
    'This product is out of stock. We are working hard to be back in stock as soon as possible. If you have any questions or you are interested in hiring this or similar product, please feel free to make an enquiry.',
  freeOfCharge: 'Free of charge',
  wasDiscount: 'Was',
  orderOnline: 'Order online and save money on the price you pay!',
  notAvailableForCheckoutProductCard:
    'The prices and contract details for this product are available only upon request. Please send us an inquiry to find out more information.',
  currentlyNoProducts: 'Currently there are no products in this category.',
  pingForAnyRequest:
    'For any request, don’t hesitate to call us {0} or {1} using the form below.',
  extraDay: 'Extra Day',
  startingFromPrice: 'Price starting from (Excl. VAT)',
  clearDates: 'Clear dates',
  notAvailableTitle: 'This product is currently not available',
  notAvailableText: `We are working to make it available again as soon as possible. If you have any questions or are interested in renting this or a similar product, please do not hesitate to make an enquiry`,
  onRequestOnlyTitle:
    'Prices and contract details are available on request only',
  onRequestOnlyText: `If you are interested in renting this product and want to know the
          prices and other details, use the form below to send us a request.
          It's easy!`,
  subtotalPerHiringPeriod: 'Subtotal for hiring period',
  subtotalPerDays: 'Subtotal for {0}',
  viewSimilarProducts: 'View Similar Products',
  sendEnquiry: 'Send Enquiry',
  duration: 'Duration',
  tbdDeliveryTooltip:
    'The delivery cost will be calculated on the checkout page based on your location and equipment selection',
  minimumPeriodIs: 'Minimum period is {0} days',
};
