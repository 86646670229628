import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { isETH } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { jobTypes$ } from 'models/jobTypes';

import { Row, T8y } from 'ui';

import { ReactComponent as ArrowBack } from 'assets/images/icon/arrowBack.svg';

import style from '../header.module.scss';

const SubMenuJobTypes = ({ id, openId, setOpenId, close }) => {
  const {
    header: { nav },
  } = Lang();

  const jobTypes = useStore(jobTypes$);

  const isHire = openId === id;

  const jobTypesTitle = isETH ? 'Partners' : nav.jobTypes;

  return (
    <div className={style.category}>
      <Row
        justify="stretch"
        align="center"
        className={clsx(
          style.name,
          isHire ? style.border : style.triangle,
          openId > 0 && !isHire && style.none,
        )}
        onClick={() => {
          setOpenId(isHire ? 0 : id);
        }}
        data-testid="mobile-job-types-disclosure"
      >
        {isHire ? (
          <>
            <Row align="center">
              <ArrowBack className={style.backArrow} />{' '}
              <T8y variant="t1" color="light" className={style.menuTitle}>
                {nav.back}
              </T8y>
            </Row>
            <T8y variant="t3" color="lightGray" uppercase>
              {jobTypesTitle}
            </T8y>
          </>
        ) : (
          <T8y variant="t1" color="light" className={style.menuTitle}>
            {jobTypesTitle}
          </T8y>
        )}
      </Row>

      {isHire && (
        <>
          {jobTypes.map(({ title, slug }, i) => (
            <T8y
              key={i}
              as={Link}
              to={PATHS.JOB_TYPE(slug)}
              onClick={close}
              color="light"
              variant="t1"
              className={style.categoryOnly}
            >
              {title}
            </T8y>
          ))}
        </>
      )}
    </div>
  );
};

export default SubMenuJobTypes;
