import React from 'react';
import { Menu, MenuButton, MenuItem, useMenuState } from 'reakit';
import clsx from 'clsx';

import { T8y } from 'ui';

import style from './select.module.scss';

export function Select({
  // array with name and slug
  options = [],
  selectConstText = '',
  aLabel,
  selected = options[0],
  defaultText,
  onClickOption,
  className,
  isNoCapitalize = false,
  textColor,
  textClassName,
  onClickMenuCallback,
  dataTestId,
}) {
  const menu = useMenuState();

  return (
    <>
      <MenuButton
        {...menu}
        className={clsx(className, style.menu)}
        onClick={() => {
          if (onClickMenuCallback) {
            onClickMenuCallback(menu.visible);
          }
        }}
        type="button"
        data-testid={dataTestId || 'selectBtn'}
      >
        <T8y
          variant="t1v1"
          xsVariant="t2v1"
          className={textClassName}
          capitalize={!isNoCapitalize}
          color={textColor}
        >
          {selected ? selectConstText + selected.name : defaultText}
        </T8y>
      </MenuButton>
      <Menu {...menu} aria-label={aLabel} className={style.options}>
        {options.map(({ name, slug }, i) => (
          <MenuItem
            {...menu}
            key={i}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClickOption(slug);
              menu.hide();
            }}
            className={clsx(
              style.option,
              selected && selected.slug === slug && style.chosenOption,
            )}
            data-testid={`selectOption-${i}`}
          >
            <T8y capitalize>{name}</T8y>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
