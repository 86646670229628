import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { LAPTOP_SCREEN, SMALL_SCREEN } from 'consts';
import { SettingsContext } from 'contexts';
import { useLayoutMatchMedia } from 'hooks';
import MobileItems from 'pages/product/MainBlock/MobileItem';
import ProductPricesTableItem from 'pages/product/MainBlock/ProductPricesTableItem';
import { getFormattedPricesForTable } from 'utils/helpers/formatPrices';

import style from './productPricesTable.module.scss';

const ProductPricesTable = ({
  selectingDetails,
  showDiscount,
  isNotAvailable,
  product,
  isCart,
}) => {
  const settings = useContext(SettingsContext);

  const isLaptop = useLayoutMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);
  const isSmallScreen = useLayoutMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const sortedPrices = useMemo(() => {
    if (!product?.prices) {
      return [];
    }

    const sortedPrices = [...product.prices].sort((a, b) =>
      a.period_from > b.period_from ? -1 : 1,
    );

    if (product.weekendRateEnabled) {
      sortedPrices.unshift({
        period_from: 'Friday',
        period_to: 'Monday',
        ...product.weekendRatePrice,
      });
    }

    return sortedPrices;
  }, [product]);

  const allPrices = useMemo(() => {
    return getFormattedPricesForTable({
      sortedPrices,
      additionalPrices: null,
      showInfinitySignInPrices: settings.showInfinitySignInPrices,
    });
  }, [settings.showInfinitySignInPrices, sortedPrices]);

  return (
    <div
      className={clsx(
        style.productPricesTable,
        isCart && style.productPricesTableCart,
      )}
    >
      {isSmallScreen || (isCart && isLaptop) ? (
        <MobileItems
          selectingDetails={selectingDetails}
          allPrices={allPrices}
          showDiscount={showDiscount}
          isNotAvailable={isNotAvailable}
          weekendRateEnabled={product.weekendRateEnabled}
          isCart={isCart}
        />
      ) : (
        <div
          className={
            allPrices.length < 6
              ? style.pricesWrapper
              : style.pricesWrapperTable
          }
        >
          {allPrices.map((el, index) => (
            <ProductPricesTableItem
              key={index}
              el={el}
              showDiscount={showDiscount}
              isNotAvailable={isNotAvailable}
              allPrices={allPrices}
            />
          ))}
        </div>
      )}
      <div
        className={clsx(
          style.extraDayNoteWrapper,
          (isSmallScreen || (isCart && isLaptop)) &&
            style.extraDayNoteWrapperMobile,
        )}
      ></div>
    </div>
  );
};

export default ProductPricesTable;
