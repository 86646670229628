import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { BrandingContext } from 'contexts';
import { useStore } from 'effector-react';
import { handleLogout } from 'hooks';
import { Lang } from 'lang';
import { $isUserLoggedIn, $userInfo } from 'models/user';

import { history } from 'libs/history';

import { SelectLanguage } from '../SelectLanguage/SelectLanguage';

import { Row, T8y } from 'ui';

import { ReactComponent as CartIcon } from 'assets/images/icon/basket.svg';
import { ReactComponent as Leaf } from 'assets/images/icon/leaf.svg';
import { ReactComponent as UserIcon } from 'assets/images/icon/user.svg';

import style from '../header.module.scss';

const MobileStaticLinks = ({ close, isShowCartSing }) => {
  const {
    formatString,
    header: { nav, bar },
  } = Lang();

  const branding = useContext(BrandingContext);

  const user = useStore($userInfo);
  const isUserLoggedIn = useStore($isUserLoggedIn);

  const handleClickOnProfile = useCallback(() => {
    if (isUserLoggedIn) {
      handleLogout();
    } else {
      history.replace(PATHS.PROFILE_LOGIN);
    }
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  return (
    <>
      <Row
        onClick={handleClickOnProfile}
        justify="stretch"
        align="center"
        className={clsx(style.mobileMenuTop, style.border)}
        pointer
      >
        {isUserLoggedIn ? (
          <Row direction="column" fullWidth>
            <Row justify="stretch" fullWidth>
              <T8y color="light" variant="t1" className={style.menuTitle}>
                {nav.logOut}
              </T8y>
              <UserIcon className={style.iconWrapper} />
            </Row>
            <T8y color="light" variant="t2v1" className={style.menuTitle}>
              {nav.signAs}
              {` ${user.first_name || ''} ${user.last_name || ''}`}
            </T8y>
          </Row>
        ) : (
          <>
            <T8y color="light" className={style.menuTitle}>
              {nav.logIn}
            </T8y>
            <UserIcon className={style.iconWrapper} />
          </>
        )}
      </Row>

      {isShowCartSing && (
        <Row
          as={Link}
          to={PATHS.RESERVATION}
          onClick={close}
          justify="stretch"
          align="center"
          className={clsx(style.mobileMenuTop, style.border)}
          pointer
        >
          <T8y color="light" className={style.menuTitle}>
            {nav.reservation}
          </T8y>
          <CartIcon className={style.iconWrapper} />
        </Row>
      )}
      <Row justify="stretch" align="center" className={style.mobileMenuTop}>
        <T8y color="light" className={style.menuTitle}>
          {nav.menuLanguage}
        </T8y>
        <SelectLanguage />
      </Row>
      <Row align="center" className={style.headerMessageMobile} noWrap>
        <Leaf />
        <span>
          {formatString(
            bar.isCommitted,
            branding.companyName || '',
            <T8y
              variant="t2"
              color="primary"
              as={Link}
              to={PATHS.CARBON_EMISSION}
              target="_blank"
              inline
              ml
            >
              {bar.learnMore}
            </T8y>,
          )}
        </span>
      </Row>
    </>
  );
};

export default MobileStaticLinks;
