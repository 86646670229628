export const profile = {
  noData: 'Não foram encontrados dados para os critérios especificados.',
  and: 'e',

  logIn: {
    header: 'Faça login em {0}',
    subHeader: 'Desfrute {0} recursos e serviços ao máximo',
    nameFieldTitle: 'Nome completo',
    nameFieldPlaceholder: 'Digite seu nome',
    emailFieldTitle: 'Endereço de e-mail',
    emailFieldPlaceholder: 'Insira o seu endereço de email',
    passwordFieldTitle: 'Senha',
    passwordFieldPlaceholder: 'Coloque sua senha',
    remember: 'Lembrar',
    forgotPassword: 'Esqueceu sua senha?',
    submit: 'Log In',
    sendMeAnActivationEmail: 'Envie-me um e-mail de ativação',
  },

  singUp: {
    header: 'Inscrever-se',
    subHeader: 'Crie uma conta e aproveite nossos serviços ao máximo',
    nameFieldTitle: 'Nome completo',
    nameFieldPlaceholder: 'Digite seu nome',
    emailFieldTitle: 'Endereço de e-mail',
    emailFieldPlaceholder: 'Insira o seu endereço de email',
    passwordFieldTitle: 'Senha',
    passwordFieldPlaceholder: 'Coloque sua senha',
    repeatPasswordFieldTitle: 'Repita a senha',
    repeatPasswordFieldPlaceholder: 'Digite sua senha novamente',
    goToLogin: 'Já tenho uma conta, vá para a página de login',
    submit: 'Inscrever-se',
    emailSentTitleThanks: 'Obrigado por inscrever-se!',
    emailSentTitleVerify: 'Verifique seu e-mail e ative a conta',
    emailSentText:
      'Enviamos um link de ativação para o seu endereço de e-mail. Por favor, verifique sua caixa de correio eletrônico e siga as instruções para checar seu e-mail e ativar a conta.',
    emailSentLinkDidNotReceive: 'Você não recebeu o e-mail?',
    emailSentLink: 'Reenviar Email de Ativação.',
    emailSentResendError:
      'O reenvio está disponível uma vez em 1 minuto, tente novamente mais tarde',
    tokenExpired: 'O token expirou.',
  },

  forgotPassword: {
    header: 'Esqueceu sua senha?',
    customerHeader: 'Portal do Cliente Esqueci a Senha',
    subHeader:
      'Digite o endereço de e-mail que você usou para se inscrever e enviaremos um link de redefinição de senha',
    emailFieldTitle: 'Endereço de e-mail',
    emailFieldPlaceholder: 'Insira o seu endereço de email',
    goToLogin: 'Vá para a página de login, lembrei-me da minha senha',
    submit: 'Enviar',
    sent: 'A mensagem foi enviada. Por favor verifique seu email.',
    cancel: 'Cancelar',
  },

  resetPassword: {
    header: 'Redefinição de senha',
    customerHeader: 'Redefinição de senha do Portal do cliente',
    subHeader: 'Por favor digite sua nova senha abaixo',
    emailAddressTitle: 'Endereço de e-mail',
    passwordFieldTitle: 'Nova Senha',
    passwordFieldPlaceholder: 'Coloque sua senha',
    repeatPasswordFieldTitle: 'Repita a nova senha',
    repeatPasswordFieldPlaceholder: 'Digite sua senha novamente',
    submit: 'Enviar',
    saveChanges: 'Salvar alterações',
  },

  activationAccount: {
    customerHeader: 'Ativação da Conta',
    subHeader: 'Por favor, digite sua senha abaixo',
    saveChanges: 'Ativar conta',
    passwordFieldTitle: 'Senha',
    repeatPasswordFieldTitle: 'Repita a senha',
  },

  validation: {
    required: 'Campo obrigatório*',
    emailPattern: 'E-mail incorreto',
    passwordLength: 'A senha deve ter pelo menos 6 caracteres',
    passwordNotIncludeSpaces: 'A senha não deve incluir espaços',
    passwordDifferent: 'As senhas são diferentes',
    invalidCreds: 'E-mail ou senha inválidos',
    userImageUploadError: 'A imagem não pode ser salva',
    invalidEmail: 'Endereço de email invalido',
  },

  menu: {
    dashboard: 'Painel',
    notifications: 'Notificações',
    catalogue: 'Catálogo',
    invoices: 'Faturas',
    settings: 'Configurações',
    management: 'Gestão',
    quotes: 'Cotações',
    orders: 'Pedidos',
    support: 'Suporte',
    myAccount: 'Minha conta',
    logOut: 'Log out',
    profile: 'Perfil',
    usersPermissions: 'Usuários e permissões',
  },

  dropzone: {
    dropText: 'Solte os arquivos aqui ...',
    dragText: 'Arraste o arquivo aqui ou',
    dragClickableText: 'escolha no seu computador',
  },

  toastMessages: {
    updateSuccess: 'A informação foi atualizada com sucesso',
    updateError: 'A informação não pode ser atualizada',
  },

  toastErrorMessages: {
    wrongOldPassword: 'A senha antiga está errada',
  },

  benefits: {
    title: 'Saiba mais sobre as vantagens de ter uma conta - Registe-se já!',
    subTitle:
      'As contas comerciais on-line foram desenvolvidas para ajudar nossos parceiros a atender às suas necessidades de aluguer de máquinas e ferramentas de forma regular, eficiente e fácil. Envie seu formulário de registro para ver se você se qualifica para uma conta comercial. Sinta-se à vontade para navegar e fazer pedidos em {0} usando sua conta enquanto seu registro é revisado.',
    discountMemberships:
      'Desbloqueie preços com desconto em pedidos de grande volume',
    noDeposit: 'Evite depósitos obrigatórios em produtos selecionados',
    paymentMethods: 'Mais formas de pagamento',
    payLater: 'Obter condições de crédito',
    orders:
      'Um lugar para manter todos os seus pedidos e pagamentos recentes e anteriores.',
    catalogue: 'Catálogo completo e atualizado com as novidades mais recentes.',
    percent:
      'Acesso instantâneo às nossas ofertas especiais, descontos e campanhas promocionais.',
    chat: 'Chats de suporte e assistência rápida, reservas em apenas 5 a 10 minutos.',
  },

  customerLogin: {
    header: 'Log-in da conta comercial',
    emailFieldTitle: 'Endereço de e-mail',
    passwordFieldTitle: 'Senha',
    forgotPassword: 'Esqueceu sua senha',
    register: 'Registre-se',
    submit: 'Login',
  },

  customRegister: {
    header: 'Registro de conta comercial',
    subHeader: 'Selecione seu perfil de registro abaixo',
    privateTab: 'Personal',
    companyTab: 'Empresa',
    fiscalCodeFieldTitle: 'Código fiscal',
    officeAddressFieldTitle: 'Endereço de escritório registrado',
    postalCodeFieldTitle: 'Código postal',
    provinceFieldTitle: 'Província',
    sectorOfActivity: 'Setor de atividade?',
    uniqueCodeFieldTitle: 'Código único',
    PECFieldTitle: 'PEC',
    howDidYouKnowAboutUs: 'Como você soube sobre nós',
    generalInformation: 'Informação Geral',
    contactInformation: 'Informação de contato',
    contactPhoneNumber: 'Número de telefone de contato',
    contactEmailAddress: 'Endereço de e-mail de contato',
    backToLogin: 'Volte ao login',
    businessName: 'Nome da empresa',
    thankYouTitle: 'Obrigado por sua inscrição!',
    thankYouSubTitle:
      'Você concluiu com sucesso o registro no Portal do Cliente. Por favor, verifique seu e-mail e siga as instruções que lhe enviamos.',
    goToLogin: 'Ir para Log In',
    privacyCheckbox: 'Eu li o',
    consentCheckbox:
      'Quero ser o primeiro a saber sobre as últimas ofertas, lançamentos de produtos, blogs e notícias da {0}.',
  },

  sectorOfActivity: {
    business: 'Empresa',
    constructions: 'Construção',
    public_authority: 'Autoridade pública',
    training_courses: 'Cursos de treinamento',
    gardening: 'Jardinagem',
    industry: 'Indústria',
    services: 'Serviços',
    transport: 'Transporte',
    tourism: 'Turismo',
    other: 'Outro',
  },

  aboutUs: {
    search_on_the_internet: 'Pesquisa na internet',
    television: 'Televisão',
    word_of_mouth: 'Boca a boca',
    headquarter_visibility: 'Visibilidade da sede',
    construction_warehouse: 'Armazém de construção',
    gardening_dealer: 'Negócio de jardinagem',
    magazines_and_newspapers: 'Revistas e jornais',
    other: 'Outro',
  },

  emptySection: {
    noInfo:
      'Você ainda não tem {0}. Para fazer um pedido, comece navegando em nosso {1}.',
    callUs: 'Não hesite em nos ligar {0} se tiver alguma dúvida.',
  },

  noDiscount: {
    title: 'Conta de associado',
    description:
      'Existem quatro tipos de descontos para nossos clientes: {0}, {1}, {2} e {3}. Contacte-nos para saber como obter um desconto em todos os produtos da nossa {4}.',
  },

  loginErrors: {
    incorrectCredentials: 'Usuário ou senha incorretos',
    accountIsNotActivated: 'A conta ainda não foi ativada',
  },
  paymentMethods: 'Métodos de Pagamento',
  pleaseFillAllFields:
    'Faltam informações em seu perfil. Por favor, preencha todos os campos em branco.',
  membershipDiscount: 'Desconto para membros',
  selectSegment: 'Selecione a qual segmento de cliente você pertence?',
  continue: 'Continuar',
  continueWithGoogle: 'Continuar com o Google',
  or: 'ou',
  registerWithEmail: 'Registre-se com e-mail',
  signedInWithGoogle: 'Conectado com a conta do Google',
  signedInWithRegisteredEmail: `Enviamos a mensagem para {0}.`,
  signedInWithRegisteredEmailCheck:
    'Por favor, verifique seu e-mail e siga as instruções.',
  membershipTooltip: `{0} é o seu plano atual com {1}. Entre em contato conosco para saber mais sobre o plano {2}. Entre em contato conosco para saber mais sobre o plano {3}.`,
  basic: 'Básico',
  standard: 'Standard',
  premium: 'Premium',
};
