import axios from 'axios';
import { REACT_APP_PREFIX } from 'consts';

import { getToken, instance } from './profileApi';

export const CATREGORIES = {
  getAll() {
    return instance.get('categories', { params: { all: 1 } });
  },
  getCategory(id) {
    return instance.get(`category/${id}`);
  },
  getLightCategories() {
    return instance.get('categoriesLight');
  },
  isCategory(slug) {
    return instance.get(`isCategory/${slug}`);
  },
  getPopularCategories() {
    return instance.get('categories/popular');
  },
};

export const PRODUCTS = {
  getAll(params) {
    const { slug, ...restParams } = params;
    return instance.get(`category/${slug}`, { params: restParams });
  },
  getAllCategories(params) {
    const { slug, ...restParams } = params;
    return instance.get(`category`, { params: restParams });
  },
  getPopular(selectedPackage) {
    return instance.get(`popularProducts`, {
      params: {
        ...(selectedPackage !== 'All' && { progetto: selectedPackage }),
      },
    });
  },
  getRecentlyViewed(slug) {
    return instance.post(`products/${slug}/recentlyViewed`);
  },
  getHiredTogether(slug) {
    return instance.get(`products/${slug}/hiredTogether`);
  },
  getProduct(slug) {
    return instance.get(`products/${slug}`);
  },
  getProductVariations(slug) {
    return instance.get(`/products/${slug}/variations`);
  },
  getProductJobTypes(slug) {
    return instance.get(`/products/${slug}/jobTypes`);
  },
};

export const CART = {
  getTotalPrice(params, signal) {
    return instance.post(`/totalPrice`, params, { signal });
  },
  getOrder(orderId) {
    return instance.get(`/orders/${orderId}`);
  },
  getPostcodePrice(postcode) {
    return instance.get(`/postcodePrice`, { params: { postcode } });
  },
};

export const SESSION = {
  newSession() {
    return instance.get(`/newSession`);
  },
};

export const CHECKOUT = {
  go(params) {
    return instance.post('/checkout', params);
  },
  pay(orderId) {
    return instance.get(`checkout/${orderId}/pay`);
  },
  createOrderIntent(params) {
    return instance.post('/checkout/twoIntent', params);
  },
  payTwo(orderId, params) {
    return instance.post(`checkout/${orderId}/payTwo`, params);
  },
  getTwoVerificationStatus(orderId) {
    return instance.get(`checkout/${orderId}/confirmTwo`);
  },
};

export const DISCOUNTS = {
  getDiscounts() {
    return instance.get(`/discounts`);
  },
};

export const WAREHOUSES = {
  getWarehouses() {
    return instance.get(`/warehouses`);
  },
};

export const REGIONS = {
  getRegions() {
    return instance.get(`/regions`);
  },
  send(params) {
    return instance.post(`/emails`, params);
  },
};

export const FILES = {
  upload(params) {
    return instance.post(`/files`, params);
  },
};

export const TRAININGS = {
  getAllTrainings() {
    return instance.get('/trainings');
  },
  getTrainingIndex() {
    return instance.get(`/trainingIndex/${1}`);
  },
  getTraining(slug) {
    return instance.get(`/trainings/${slug}`);
  },

  getTrainingTotalPrice(params) {
    const { slug, ...restParams } = params;
    return instance.post(`/trainings/${slug}/calc`, restParams);
  },
  createTrainingOrder(params) {
    const { slug, ...restParams } = params;
    return instance.post(`/trainings/${slug}/checkout`, restParams);
  },
  pay(orderId) {
    return instance.get(`/trainingCheckout/${orderId}/pay`);
  },
  signUpTraining(params) {
    return instance.post(`/email/training`, params);
  },
};

export const BANNERS = {
  getBanners() {
    return instance.get('banners');
  },
};

export const EMAILS = {
  sendEnquiry(params) {
    return instance.post('/email/search', params);
  },
  sendEnquiryProduct(params) {
    return instance.post('/email/product', params);
  },
};

export const PROMO = {
  checkPromoCode(params) {
    return instance.post('/promo', params);
  },
};

export const VIMEO = {
  getVideoPreview(id) {
    return instance.get(`https://www.vimeo.com/api/v2/video/${id}.json`, {
      responseType: 'application/json',
    });
  },
};

export const SETTINGS = {
  getSettings() {
    return instance.get('/settings/general', { params: { limit: 200 } });
  },
  getBranding() {
    return instance.get('/settings/branding', {
      params: { limit: 200 },
    });
  },
  getAboutUs() {
    return instance.get('/settings/aboutPage', {
      params: { limit: 200 },
    });
  },
  getMaintenance() {
    return instance.get('/settings/maintenancePage', {
      params: { limit: 200 },
    });
  },
};

export const ORDERS = {
  sendValidation(params) {
    return instance.post('/orders/validations', params);
  },
};

export const QUERIES = {
  getFakeOrder(id) {
    return instance.get(`/queries/${id}`);
  },
  createFakeOrder(params, config) {
    return instance.post('/queries', params, config);
  },
};

export const LEADS = {
  createLead(params) {
    return instance.post('/coldLead', params);
  },
  createColdLead(params) {
    return instance.post('/coldLead', params);
  },
};

export const BLOG = {
  getLastPosts() {
    return instance.get(`blog/posts`);
  },
};

export const SEARCH = {
  getPopularSearches() {
    return instance.get(`search/popular`);
  },
};

export const THIRD_PARTY_INTEGRATIONS = {
  getIsProductAvailable({ product_id, start_date, end_date, quantity }) {
    return instance.get(
      `products/${product_id}/availability?start_date=${start_date}&end_date=${end_date}&quantity=${quantity}`,
    );
  },
  getTerritoryId() {
    return instance.post(
      'https://b2m7fsyxq443jd5v72dbgjsjli0iobhy.lambda-url.us-east-1.on.aws/',
      { region_slug: REACT_APP_PREFIX },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        },
      },
    );
  },
};

export const JOB_TYPES = {
  getJobTypes() {
    return instance.get('jobTypes');
  },
  getJobType(slug) {
    return instance.get(`/jobTypes/${slug}`);
  },
  getJobTypeProducts(slug) {
    return instance.get(`/jobTypes/${slug}/products`);
  },
};

export const VERIFICATION = {
  setLaterVerification(params) {
    return instance.post('/idVerification/delay', params);
  },
  getLinkByOrderId(params) {
    return instance.post('/idVerification/link', params);
  },
};

export const TAFAQS = {
  getAllTAFaqs(params) {
    return axios
      .create({
        baseURL: `${process.env.REACT_APP_API_COUNTRY_BASE_URL}/api/v2/`,
        defaultInterceptors: true,
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .get('/faqs', params);
  },
};

export const Memberships = {
  getAllMemberships() {
    return instance.get('/getMembershipLevels');
  },
};

export const CRM = {
  sendTask(params) {
    return instance.post('/crm/task', params);
  },
};
