export const products = {
  allProducts: 'Todos los productos',
  forYourDates: 'Para tus fechas',
  pricePerDay: 'Precio por día',
  pricePerWeek: 'por semana',
  exclVat: 'Precio sin IVA',
  hiringPeriod: 'Duración del Alquiler',
  minHiringPeriod: 'Período mínimo',
  discountText:
    'Tu {0} membresía ofrece un descuento en todos los productos de nuestro {1}.',
  addToQuote: 'Agregar al presupuesto',
  addedToQuote: 'Agregado al presupuesto',
  yourReservation: 'Agregado a tu reserva',
  addedToReservation: 'Añadir a la reserva',
  maxQuantityReached: 'Cant. máxima alcanzada',
  hireTime: 'Duración del alquiler',
  price: 'Precio',
  pricePer: 'por {0} días',
  pricePerOneDay: 'por 1 día',
  variations: 'Variaciones del producto',
  userGuides: 'Dimensiones de la unidad',
  dimensionsOfTheUnit: 'Dimensiones de la unidad',
  CallUs: '¿Tienes una duda?',
  call: 'Llámanos',
  requestCallback: 'Solicitar una llamada',
  shareYourContactDetails:
    'Comparte tus datos de contacto y te devolveremos la llamada para responder a todas tus preguntas.',
  comment: 'Mensaje',
  tableOfContents: 'Tabla de contenidos',
  frequentlyHiredTogether: 'Frecuentemente alquilados juntos',
  goToGeneral: 'Ve a las Preguntas Frecuentes',
  day: 'día',
  days: 'días',
  additional: 'Costo por día a partir de los 7 días',
  noDescription: 'Sin descripción',
  learnMore: 'Learn More...',
  yetToBeReviewed: 'Aún no hay reseñas',
  averageScore: 'Valoración media: {0}',
  finishAddToReservationModalText:
    'El producto se agregó correctamente a su reserva',
  carbonOffsettingText:
    'Este alquiler es Carbono Neutral. Compensamos la emisión de carbono de este producto.',
  ourDailyPrices: 'Nuestros precios por día para alquileres más largos',
  save: 'Ahorra {0} {1}',
  onAverage: 'sobre el promedio',
  promisePriceText:
    'Ahorra {0} sobre el alquiler de maquinaria. Reserva online y ahorra! Disponemos de opciones de retiro y entrega.',
  clickRecommendationIcon: 'Haz click en el ícono para leer su descripción',
  quantity: 'Cantidad',
  relatedJobTypes: 'Trabajas Relacionados',
  shareSpecs: 'Compartir especificaciones',
  viewThisAt: 'Ver esto en',

  titles: {
    fullDescription: 'Descripción completa',
    fullReview: 'Evaluación completa',
    faq: 'Preguntas Frecuentes',
    perWeek: 'por semana',
    reviews: 'Evaluaciones',
    backToCategory: 'Volver a Categoría',
    features: 'Características',
    feature: 'Característica',
    specifications: 'Especificaciones',
    thisProduct: 'Este producto no tiene especificaciones',
    otherCharges: 'Otros Cargos',
    toBeDefined: 'A Determinar',
    safetyRecommendations: 'Recomendaciones de seguridad',
    downloads: 'Descargas',
    shareThisPage: 'Comparte esta página',
    video: 'Video',
    similarProducts: 'Productos similares',
  },

  consumables: {
    title: 'Consumibles requeridos para este producto',
    subTitle: 'Por favor, indica la cantidad de consumibles requeridos',
    showDetails: 'Mostrar detalles',
    perItem: 'por día',
  },

  extra: {
    extras: 'Extras',
    modalTitle: 'Productos extras',
    subTitle: 'Selecciona los extras que prefieras e indica el importe',
    showDetalis: 'Mostrar detalles',
    showMoreExtras: 'Mostrar más extras',
    sale: 'Promoción',
    select: 'Seleccionar',
    selected: 'Seleccionado',
    skipThisStep: 'Salta este paso',
    skip: 'Saltar',
    apply: 'Continuar',
    addToCart: 'Agregar al carrito',
    needSelectReqExtra:
      'Para pedir {0}, seleccionas los extras necesarios de la lista a continuación. Obtendrás 1 artículo extra por cada {0} contratado. No proporcionamos {0} por separado.',
  },

  other: {
    thisProduct:
      'Este producto solamente está disponible por hora y con operador',
    pleaseShare:
      'Por favor, rellena tus datos de contacto en el siguiente formulario y te contactaremos a la brevedad!',
    makeAnEnquiry: 'Contáctanos',
    perDay: 'por día',
    promisePriceSave: '{0}% OFF',
    file: 'Archivo',
    changeDates: 'Modifica Fechas',
    hireStart: 'Inicio del Alquiler',
    hireEnd: 'Fin del Alquiler',
    readMore: 'Seguir leyendo',
  },

  depots: {
    title: 'Nuestras delegaciones',
    description: 'Disponibilidad a confirmar. Sincronizamos cada 48 horas',
    available: 'Disponible',
    unavailable: 'No disponible',
    toBeChecked: 'A comprobar',
    qty: 'Cantidad',
  },

  noPrices: {
    title: 'Precio a consultar',
    subTitle:
      'Los detalles de precios y contratación están disponibles solo sobre pedido',
    text: 'Si estás interesado en contratar este producto y deseas conocer los precios y otros detalles, utiliza el formulario a continuación para enviarnos una consulta. ¡Es fácil!',
  },

  feelFreeToContact:
    'Si tienes alguna pregunta, no dudes en enviarnos una solicitud. ¡Estaremos encantados de ayudarte!',
  allCategoryProducts: 'Todos los productos de  {0} ({1})',

  benefits: {
    title: 'Beneficios de alquilar en línea con {0}',
    benefit1: 'Revisado y confiado por usuarios y empresas',
    benefit2: 'Pago en línea fácil',
    benefit3: 'Cuenta personal para gestionar pedidos',
    benefit4: 'Posibilidad de recogida y entrega',
  },

  getQuote: {
    modalTitle: 'Tu solicitud de reserva',
    howToProceed: '¿Cómo te gustaría proceder?',
    getQuote: 'Obtén un presupuesto',
    getQuoteDescription:
      'Completa algunos campos y recibe una cotización directamente en tu correo electrónico.',
    checkoutDescription:
      'Comparte los detalles del alquiler, obtén el precio y haz el pedido.',
    checkoutAvailability: 'Check availability and proceed to checkout',
    checkoutAvailabilityDescription:
      'Share your contact details to check product availability and place your order online.',
    availabilityCheckTitle: 'Product Availability Check',
    notAvailable: "Product is not available, we'll get in touch soon!",
    notAvailableDescription:
      'We processed your request, but unfortunately the product is not in stock for the selected dates. One of our team members will contact you shortly to find a different solution for your request. Thank you!',
    available: 'Product is available',
  },

  availableLabels: {
    availableInStock: 'Stock disponible',
    itemsAvailable: 'Sólo hay {0} unidades en stock',
    oneItemAvailable: 'Solo queda 1 unidad',
    unavailable: 'No disponible',
    outOfStock: 'Sin stock',
  },

  referencesText: '{0} – parte de la familia de marcas {1}, junto a {2}',
  easy: 'easy',
  easyJet: 'easyJet',
  showMoreVariations: 'Mostrar más variaciones',
  notAvailableForCheckout:
    'Si estás interesado en contratar este producto y deseas conocer otros detalles, utiliza el formulario a continuación para enviarnos una consulta. ¡Es fácil!',
  productIsOutOfStock: 'Producto no disponible',
  outOfStockText:
    'Este producto no se encuentra disponible. Estamos trabajando para volver a tenerlo en stock pronto. Si tienes preguntas o estás interesado en alquilar un producto similar, por favor envía una solicitud.',
  freeOfCharge: 'Sin cargo',
  wasDiscount: 'Era',
  orderOnline: 'Haz el pedido online y ahorra!',
  notAvailableForCheckoutProductCard:
    'El precio y los detalles del alquiler de este producto están sólo disponibles sobre pedido. Por favor envíanos un mensaje para solicitar más información.',
  currentlyNoProducts: 'Actualmente no hay productos en esta categoría.',
  pingForAnyRequest:
    'Para cualquier solicitud, no dude en llamarnos {0} o {1} mediante el siguiente formulario.',
  extraDay: 'Extra Day',
  startingFromPrice: 'Precio desde (sin IVA)',
  clearDates: 'Borrar Fechas',
  notAvailableTitle: 'Este producto, actualmente, no esta disponible',
  notAvailableText: `Estamos trabajando para que vuelva a estar disponible lo antes posible. Si tienes alguna duda o estas interesado en alquilar este producto o uno similar, no dudes en consultarnos.`,
  onRequestOnlyTitle:
    'Los precios y los detalles del contrato están disponibles solo bajo pedido',
  onRequestOnlyText: `Si estas interesado en alquilar este producto y deseas conocer los precios y otros detalles, utiliza el siguiente formulario para enviarnos una solicitud. ¡Es fácil!`,
  subtotalPerHiringPeriod: 'Subtotal por período de contratación',
  subtotalPerDays: 'Subtotal por {0}',
  viewSimilarProducts: 'Ver Productos Similares',
  sendEnquiry: 'Enviar Consulta',
  duration: 'Duración',
  tbdDeliveryTooltip:
    'El costo de envío se calculará en la página de pago según su ubicación y selección de equipo.',
  minimumPeriodIs: 'El período mínimo es de {0} días',
};
