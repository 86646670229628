import { useCallback, useContext, useMemo } from 'react';
import { POSTCODE_DELIVERY } from 'consts';
import { SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { useGetExtraFieldsValues, useGetIsDeliveryMethod } from 'hooks';
import { billingAddress$ } from 'models/cart';
import { $isUserLoggedIn } from 'models/user';
import { $UTMData } from 'models/UTMData';
import { getDeliveryMethod } from 'utils/helpers/getDeliveryMethod';

export const useGetCommonCheckoutData = ({
  details,
  deliveryMethodData,
  appliedPromoCode,
  cartFormattedData,
  subscription,
  isQuote,
}) => {
  const settings = useContext(SettingsContext);

  const isPostcodeDeliveryMethod = useGetIsDeliveryMethod(POSTCODE_DELIVERY);

  const userExtraFieldsValues = useGetExtraFieldsValues(
    settings.checkoutUserFormOptions?.extraFields,
    details,
  );

  const isUserLoggedIn = useStore($isUserLoggedIn);
  const billingAddress = useStore(billingAddress$);
  const UTMData = useStore($UTMData);

  const { selectedWarehouse, address, method, addressByPostcode } =
    deliveryMethodData;

  const isPostcode =
    isPostcodeDeliveryMethod || settings.fillAddressByPostcodeOnly;
  const isSelf = method === 'self';

  const getDeliveryAddressInfo = useCallback(() => {
    if (isSelf && !!selectedWarehouse) {
      return null;
    }

    if (isPostcode && address.postcode) {
      return {
        lat: addressByPostcode?.lat || undefined,
        lng: addressByPostcode?.lng || undefined,
        name: addressByPostcode?.name || undefined,
        postcode: address.postcode || undefined,
        ...(address.type && { type: address.type || undefined }),
        ...(isQuote && { place_id: address.place_id || undefined }),
      };
    }

    if (address && address.place_id) {
      return {
        lat: address.lat,
        lng: address.lng,
        name: address.name,
        ...(isQuote && { place_id: address.place_id || undefined }),
        ...(address.type && { type: address.type || undefined }),
      };
    }

    return null;
  }, [
    isSelf,
    selectedWarehouse,
    isPostcode,
    address,
    addressByPostcode,
    isQuote,
  ]);

  const deliveryMethod = useMemo(
    () => getDeliveryMethod(method, address, isPostcode),
    [address, isPostcode, method],
  );

  const getFormattedCheckoutData = useCallback(() => {
    const isIndividual = details.type === 'individual';
    const deliveryAddressInfo = getDeliveryAddressInfo();
    const isLoggedInNewCheckout = isUserLoggedIn ? 'oldUser' : 'guest';

    return {
      ...(isSelf &&
        selectedWarehouse && {
          warehouse_id: Number(selectedWarehouse.id),
        }),
      subscription,
      address: deliveryAddressInfo ? deliveryAddressInfo.name : undefined,
      delivery: deliveryAddressInfo,
      promo: (!isQuote && appliedPromoCode) || undefined,
      delivery_method: deliveryMethod,
      insuranceType: details.insurance,
      user: {
        email: details.email,
        name: details.name,
        phone: details.phone,
        type: details.type,
        vat_number: isIndividual ? undefined : details.vat_number,
        company: isIndividual ? undefined : details.company_name,
        company_id: isIndividual ? undefined : details.company_id,
        billingAddress:
          settings.showBillingAddressCheckout && billingAddress
            ? billingAddress
            : undefined,
        ...userExtraFieldsValues,
      },
      ...(UTMData && { utm: { ...UTMData } }),
      products: cartFormattedData,
      payment_method: details.paymentMethod || undefined,
      checkoutAs: isLoggedInNewCheckout,
    };
  }, [
    details,
    getDeliveryAddressInfo,
    isUserLoggedIn,
    deliveryMethod,
    isSelf,
    selectedWarehouse,
    subscription,
    isQuote,
    appliedPromoCode,
    settings,
    billingAddress,
    userExtraFieldsValues,
    UTMData,
    cartFormattedData,
  ]);

  return useMemo(() => getFormattedCheckoutData, [getFormattedCheckoutData]);
};
