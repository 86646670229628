import { combine, createEffect, createStore, restore } from 'effector';
import { createGate } from 'effector-react';
import { currentLang$ } from 'models/language';
import { settings$ } from 'models/settings';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

export const getMaintenanceFx = createEffect();

export const MaintenanceGate = createGate('maintenance');

export const maintenance$ = restore(getMaintenanceFx, {});

export const isMaintenanceRequestFinished$ = createStore(false).on(
  getMaintenanceFx.done,
  (_) => true,
);

export const isMaintenanceLoading$ = restore(
  getMaintenanceFx.pending.updates,
  false,
);

export const maintenanceLocalized$ = combine(
  maintenance$,
  currentLang$,
  settings$,
  (maintenance, lang, { defaultLanguage }) => ({
    ...maintenance,
    title: getTranslatedFieldValue(
      maintenance.maintenancePageTitle,
      lang,
      defaultLanguage,
    ),
    text: getTranslatedFieldValue(
      maintenance.maintenancePageText,
      lang,
      defaultLanguage,
    ),
    seoTitle: getTranslatedFieldValue(
      maintenance.maintenancePageSeoTitle,
      lang,
      defaultLanguage,
    ),
    metaDescription: getTranslatedFieldValue(
      maintenance.maintenancePageMetaDescription,
      lang,
      defaultLanguage,
    ),
    ...(maintenance.maintenancePageAdditionalInfo?.length && {
      additionalInfo: maintenance.maintenancePageAdditionalInfo.map((el) => ({
        ...el,
        title: getTranslatedFieldValue(el.title, lang, defaultLanguage),
        text: getTranslatedFieldValue(el.text, lang, defaultLanguage),
      })),
    }),
  }),
);
