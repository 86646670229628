import React, { useCallback, useMemo, useRef } from 'react';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { REACT_APP_PREFIX_VALID } from 'consts';
import { useStore } from 'effector-react';
import { useOnClickOutside } from 'hooks';
import { Lang } from 'lang';
import sortBy from 'lodash/sortBy';
import {
  regionForDisplaying$,
  setRegionForDisplaying,
} from 'models/regionForDisplay';
import { $regions } from 'models/regions';

import LeaveContactsForm from './LeaveContactsForm';

import { Row, T8y } from 'ui';

import { ReactComponent as RegionIcon } from 'assets/images/icon/region.svg';

import style from '../bar.module.scss';

const Region = () => {
  const { header } = Lang();

  const regions = useStore($regions);
  const regionForDisplaying = useStore(regionForDisplaying$);

  const disclosure = useDisclosureState();

  const wrapperRef = useRef(null);

  const sortedRegions = useMemo(() => {
    return sortBy(regions, [
      function (o) {
        return o.name;
      },
    ]);
  }, [regions]);

  useOnClickOutside(wrapperRef, disclosure.hide);

  const getRegionAppName = useCallback(
    (slug) => {
      const regionBySlug = regions
        .find(
          ({ url }) =>
            url?.split('/')[3] === slug ||
            url?.split('/')[3]?.replace('-', '') === slug,
        )
        ?.name.replace('-', '');

      if (!regionForDisplaying) {
        return regionBySlug;
      }

      const actualRegionName = regions.find(
        ({ url, state }) =>
          url?.split('/')[3] === slug && regionForDisplaying === state,
      )?.name;

      if (!actualRegionName) {
        return regionBySlug;
      }

      return actualRegionName === 'Emilia-Romagna'
        ? actualRegionName.replace('-', '')
        : actualRegionName;
    },
    [regionForDisplaying, regions],
  );

  const handleRegionClick = useCallback((url, state) => {
    if (url !== null) {
      setRegionForDisplaying(state);
      window.location.replace(url);
    }
  }, []);

  return (
    <div className={style.regionWrapper}>
      <Disclosure as={Row} align="center" noWrap ml2 pointer {...disclosure}>
        <RegionIcon />
        <T8y className={style.link} variant="t2" ml2>
          {getRegionAppName(REACT_APP_PREFIX_VALID)}
        </T8y>
      </Disclosure>

      <DisclosureContent {...disclosure} ref={wrapperRef}>
        <div className={style.regionWrapperInner}>
          <T8y variant="h3" mb2>
            {header.region.checkOther}
          </T8y>

          <Row direction="column" className={style.regionsColumns}>
            {sortedRegions.map(({ name, url, state }, i) => (
              <T8y
                key={i}
                color={url ? 'primary' : 'silver'}
                asLink
                inline
                mr
                style={{
                  textDecoration: !url && 'none',
                  cursor: !url && 'default',
                }}
                onClick={() => handleRegionClick(url, state)}
              >
                {name}
              </T8y>
            ))}
          </Row>
          <LeaveContactsForm />
        </div>
      </DisclosureContent>
    </div>
  );
};

export default Region;
