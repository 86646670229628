import { useCallback, useContext, useEffect, useMemo } from 'react';
import { MOBILE_SCREEN } from 'consts';
import { SettingsContext } from 'contexts';
import { setFixedReservationBannerVisible } from 'models/layoutData';

import { useMatchMedia } from 'libs/useMatchMedia';

import { useIntersection } from './useIntersection';

export const useHandleFixedBottomBarDisplaying = (
  reservationBtnWrapperRef,
  isExtraReservationModalsOpen,
  pointedElementRef,
) => {
  const settings = useContext(SettingsContext);

  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);
  const inViewport = useIntersection(pointedElementRef, '-100px');

  //if there's no inventory count only header as a fixed element
  const offsetFromTop = useMemo(() => {
    return settings.showCustomizeInventory ? 70 : 30;
  }, [settings.showCustomizeInventory]);

  const handleScroll = useCallback(() => {
    if (!reservationBtnWrapperRef.current || inViewport || !isMobile) {
      return;
    }

    //hide bar on the very page bottom
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      setFixedReservationBannerVisible(false);
      return;
    }

    setFixedReservationBannerVisible(
      reservationBtnWrapperRef.current.offsetTop -
        (window.pageYOffset + offsetFromTop) <=
        0,
    );
  }, [inViewport, isMobile, offsetFromTop, reservationBtnWrapperRef]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      setFixedReservationBannerVisible(false);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
};
