import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'AppPaths';

import trainingUniversal from 'assets/images/picture/trainingUniversal.jpg';

const HiddenLayoutImages = () => {
  const { pathname } = useLocation();

  const mobileImage = useMemo(() => {
    if (
      pathname.includes(PATHS._TRAINING) &&
      !pathname.includes(PATHS._CHECKOUT)
    ) {
      return trainingUniversal;
    }

    return null;
  }, [pathname]);

  if (!mobileImage) {
    return null;
  }

  return (
    <div
      style={{
        background: `url(${mobileImage}) no-repeat`,
        visibility: 'hidden',
      }}
    />
  );
};

export default HiddenLayoutImages;
