import { PATHS } from 'AppPaths';

import MobileSearch from './MobileSearch';

export const mobileSearchRoutes = [
  {
    path: PATHS.MOBILE_SEARCH,
    exact: true,
    component: MobileSearch,
  },
];
