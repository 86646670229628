import React from 'react';
import clsx from 'clsx';
import { LAPTOP_SCREEN, SMALL_SCREEN } from 'consts';
import {
  mainPagePopularSlider,
  mainPageTopCategoriesSlider,
} from 'utils/sliderSettings';

import { useMatchMedia } from 'libs/useMatchMedia';

import { Slider } from 'ui';

import style from './sliderWithMobileOverflow.module.scss';

const SliderWithMobileOverflow = ({
  items = [],
  isBigCarts,
  isStartPositioning,
  additionalCardsWrapperClassName,
  children,
  noSliderMobileWrapperRef,
}) => {
  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  return isSmallScreen ? (
    <div
      className={clsx(
        style.mobileScrollableContainer,
        items.length > 1 || isStartPositioning
          ? style.multipleItems
          : style.centeredContainer,
        additionalCardsWrapperClassName,
      )}
      ref={noSliderMobileWrapperRef}
    >
      {children}
    </div>
  ) : (
    <Slider
      slidesToShow={4}
      {...(isBigCarts
        ? { ...mainPageTopCategoriesSlider }
        : { ...mainPagePopularSlider })}
      className={style.mainSliderWrapper}
      arrows={!isSmallScreen}
      prevArrowClassName={clsx(
        style.mainSliderArrows,
        style.prevArrowClassName,
      )}
      nextArrowClassName={clsx(
        style.mainSliderArrows,
        style.nextArrowClassName,
      )}
      isAlwaysShowSlider={isLaptop && items.length > 2}
      isStartPositioning={isStartPositioning}
      swipeToSlide
      shortNav
    >
      {children}
    </Slider>
  );
};

export default SliderWithMobileOverflow;
