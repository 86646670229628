import React, { useContext } from 'react';
import { BrandingContext, SettingsContext } from 'contexts';
import { Lang } from 'lang';
import { getPriceRangeTextInTable } from 'utils/helpers/formatPrices';

import { Row, T8y } from 'ui';

import style from './desktopItem.module.scss';

const DesktopItem = ({ el, isShowOldPrice }) => {
  const { products } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  return (
    <Row direction="column" className={style.priceTableItem}>
      <T8y variant="t2v1" bold className={style.period}>
        {getPriceRangeTextInTable(el, products, settings.fixedRangesPrice)}
      </T8y>
      {isShowOldPrice && (
        <T8y variant="t4" color="midGray" className={style.oldPrice}>
          {branding.currencySymbol}
          {el.oldPrice ? parseFloat(el.oldPrice).toFixed(2) : 0}
        </T8y>
      )}
      <T8y variant="t2v1">
        {branding.currencySymbol}
        {el.price ? parseFloat(el.price).toFixed(2) : 0}
      </T8y>
    </Row>
  );
};

export default DesktopItem;
