export const products = {
  allProducts: 'Catalogo',
  forYourDates: 'Prezzo giornaliero',
  pricePerDay: 'Prezzo al giorno',
  pricePerWeek: 'a settimana',
  exclVat: 'Price Excl. IVA',
  hiringPeriod: 'Durata del Noleggio',
  minHiringPeriod: 'Periodo minimo',
  discountText:
    'La tua iscrizione  {0}  ti offre uno sconto su tutti i prodotti nel nostro {1}.',
  addToQuote: 'Aggiungi al preventivo',
  addedToQuote: 'Aggiunto al preventivo',
  yourReservation: 'Aggiungi alla Prenotazione',
  addedToReservation: 'Aggiungi alla prenotazione',
  maxQuantityReached: 'Qtà Massima Raggiunta',
  hireTime: 'Durata del Noleggio',
  price: 'Prezzo',
  pricePer: 'per {0} giorni',
  pricePerOneDay: 'per 1 giorno',
  variations: 'Varianti del prodotto',
  dimensionsOfTheUnit: 'Dimensioni del Prodotto',
  CallUs: 'Per qualsiasi domanda, chiamaci',
  call: 'Chiamaci',
  requestCallback: 'Richiedi una chiamata',
  shareYourContactDetails:
    'Inserisci i tuoi dati  e ti richiameremo per rispondere a tutte le tue domande.',
  comment: 'Messaggio',
  userGuides: 'Scheda Tecnica',
  tableOfContents: 'Tabella dei Contenuti',
  frequentlyHiredTogether: 'Sono spesso noleggiati insieme',
  goToGeneral: 'Tutte le FAQ',
  day: 'giorno',
  days: 'giorni',
  additional: 'Costo Giornarliero 7 Giorni in Più',
  noDescription: 'No description',
  learnMore: 'Maggiori Informazioni...',
  yetToBeReviewed: 'Nessuna recensione disponibile',
  averageScore: 'sulla media: {0}',
  finishAddToReservationModalText:
    'Il prodotto è stato aggiunto con successo alla tua prenotazione',
  carbonOffsettingText:
    "Questo noleggio è Carbon Neutral. Compensiamo l'emissione di CO2 per questo prodotto.",
  ourDailyPrices: 'I nostri prezzi giornalieri per noleggi a lungo termine',
  save: 'Risparmia {0}',
  onAverage: 'sulla media',
  promisePriceText:
    'Risparmia {0} sul noleggio di attrezzature e macchinari. Ordina online e risparmia sul prezzo. Sono disponibili opzioni di ritiro e consegna.',
  clickRecommendationIcon: 'Clicca sull’icona per leggerne la descrizione',
  quantity: 'Quantità',
  relatedJobTypes: 'Progetti Correlati',
  shareSpecs: 'Condividi le specifiche',
  viewThisAt: 'Visualizza su',

  titles: {
    fullDescription: 'Descrizione Completa',
    fullReview: 'Recensione Completa',
    faq: 'Tutte le FAQ',
    perWeek: 'per week',
    reviews: 'Recensione',
    backToCategory: 'Torna alla Categoria',
    features: 'Caratteristiche',
    feature: 'Caratteristica',
    specifications: 'Specifiche',
    thisProduct: 'Questo prodotto non ha specifiche',
    otherCharges: 'Costi addizionali',
    toBeDefined: 'Prezzo su richiesta',
    safetyRecommendations: 'Raccomandazioni di sicurezza',
    downloads: 'Documenti',
    shareThisPage: 'Condividi questa pagina',
    video: 'Video',
    similarProducts: 'Prodotti simili',
  },

  consumables: {
    title: 'Required consumables for this product',
    subTitle: 'Please indicate the quantity of required consumables',
    showDetails: 'Show details',
    perItem: 'per item',
  },

  extra: {
    extras: 'Extra',
    modalTitle: 'Prodotti Extra',
    subTitle: "Seleziona gli extra che preferisci e indica l'importo",
    showDetalis: 'Mostra Dettagli',
    showMoreExtras: 'Mostra più extra',
    sale: 'Promozione!',
    select: 'Selezione',
    selected: 'Selezionato',
    skipThisStep: 'Salta questo passaggio',
    skip: 'Salta',
    apply: 'Procedi',
    addToCart: 'Aggiungi al carrello',
    needSelectReqExtra:
      'To order {0} you need to select required extras from the list below. You will get 1 extra item for every {0} hired. We are not providing {0} separately.',
  },

  other: {
    thisProduct:
      "Questo prodotto è disponibile solo ad ore e insieme all'operatore",
    pleaseShare:
      'Inserisci i tuoi dati di contatto nel form qui sotto e ci metteremo in contatto con te a breve.',
    makeAnEnquiry: 'Invia la tua richiesta',
    perDay: 'Al giorno',
    promisePriceSave: '{0}% OFF',
    file: 'File',
    changeDates: 'Cambia Date',
    hireStart: 'Inizio Noleggio',
    hireEnd: 'Fine Noleggio',
    readMore: 'Leggi di più',
  },

  depots: {
    title: 'Le nostre filiali',
    description: 'La disponibilità è da confermare',
    available: 'Disponibile',
    unavailable: 'Non Disponibile',
    toBeChecked: 'Da verificare',
    qty: 'Qt',
  },

  noPrices: {
    title: 'Prezzo su richiesta',
    subTitle:
      'I prezzi e i dettagli del contratto sono disponibili solo su richiesta.',
    text: 'Se sei interessato a noleggiare questo prodotto e vuoi conoscere i prezzi e altri dettagli, utilizza il modulo sottostante per inviarci una richiesta. È facile!',
  },

  feelFreeToContact:
    'Non esitare a contattarci in caso di domande. Saremo felici di aiutarti!',
  allCategoryProducts: 'Tutti i prodotti {0} ({1})',

  benefits: {
    title: 'Vantaggi del noleggio online con {0}',
    benefit1: 'Affidabilità valutata direttamente da utenti e aziende',
    benefit2: 'Semplice checkout online',
    benefit3: 'Account personale per gestire gli ordini',
    benefit4: 'Possibilità di ritiro e consegna',
  },

  getQuote: {
    modalTitle: 'La tua richiesta di prenotazione',
    howToProceed: 'Come vorresti procedere?',
    getQuote: 'Richiedi un preventivo',
    getQuoteDescription:
      'Compila alcuni campi e ricevi un preventivo direttamente alla tua email.',
    checkoutDescription:
      'Condividi tutti i dettagli richiesti per il noleggio, visualizza il prezzo finale e procedi con la prenotazione.',
    checkoutAvailability:
      'Verifica la disponibilità e procedi con la prenotazione',
    checkoutAvailabilityDescription:
      'Condividi i tuoi dati di contatto per verificare la disponibilità del prodotto ed procedere con la prenotazione.',
    availabilityCheckTitle: 'Verifica disponibilità prodotto',
    notAvailable: 'Il prodotto non è disponibile, ti contatteremo presto!',
    notAvailableDescription:
      'Abbiamo ricevuto la tua richiesta, ma sfortunatamente il prodotto non è disponibile per le date selezionate. Uno dei membri del nostro team ti contatterà a breve per trovare una soluzione per la tua richiesta. Grazie!',
    available: 'Il prodotto è disponibile',
  },

  availableLabels: {
    availableInStock: 'Disponibile',
    itemsAvailable: 'Ci sono solo {0} prodotti disponibili',
    oneItemAvailable: "C'è solo 1 articolo in magazzino",
    unavailable: 'Non Disponibile',
    outOfStock: 'Non disponibile',
  },

  referencesText: '{0} – è un brand della famiglia {1} come {2}',
  easy: 'easy',
  easyJet: 'easyJet',
  showMoreVariations: 'Mostra più varianti',
  notAvailableForCheckout:
    'Per noleggiare questo prodotto o saperne di più, inviaci una richiesta compilando il modulo sotto riportato. È facile!',
  productIsOutOfStock: 'Prodotto non disponibile',
  outOfStockText:
    'Questo prodotto non è attualmente disponibile. Stiamo lavorando per renderlo nuovamente disponibile il prima possibile. Se hai domande o sei interessato a noleggiare questo o un prodotto simile, non esitare a fare una richiesta.',
  freeOfCharge: 'Gratuito',
  wasDiscount: 'Era',
  orderOnline: 'Ordina online e risparmia!',
  notAvailableForCheckoutProductCard:
    'I prezzi e i dettagli del noleggio per questo prodotto sono disponibili solo su richiesta. Inviaci una richiesta per saperne di più.',
  currentlyNoProducts: 'Al momento non ci sono prodotti in questa categoria.',
  pingForAnyRequest:
    'Per qualsiasi richiesta, non esitare a chiamarci {0} o {1} utilizzando il modulo di contatto sottostante.',
  extraDay: 'Extra Day',
  startingFromPrice: 'Prezzo a partire da (IVA esclusa)',
  clearDates: 'Cancella Date',
  notAvailableTitle: 'Questo prodotto non è al momento disponibile',
  notAvailableText: `Stiamo lavorando per renderlo nuovamente disponibile. Se hai domande o sei interessato a noleggiare questo o un prodotto simile, non esitare ad inviare la tua richiesta`,
  onRequestOnlyTitle:
    'Prezzi e dettagli del contratto sono disponibili solo su richiesta',
  onRequestOnlyText: `Se sei interessato al noleggio di questo prodotto e vuoi conoscere i prezzi e altri dettagli, utilizza il form sottostante per inviarci una richiesta. E' facile!`,
  subtotalPerHiringPeriod: 'Subtotale per il periodo di noleggio',
  subtotalPerDays: 'Subtotale per {0}',
  viewSimilarProducts: 'Visualizza Prodotti Simili',
  sendEnquiry: 'Invia richiesta',
  duration: 'Durata',
  tbdDeliveryTooltip:
    'Il costo della consegna verrà calcolato nella pagina di pagamento in base alla vostra posizione e alle macchine ed attrezzature selezionate',
  minimumPeriodIs: 'Il periodo minimo è di {0} giorni',
};
