import { useEffect } from 'react';
import { useStore } from 'effector-react';
import {
  isFixedReservationBannerVisible$,
  isFreshChatWasLoaded$,
  setIsFreshChatWasLoaded,
} from 'models/layoutData';
import {
  createObserverOnDOMElement,
  createObserverOnDOMElementWithSubscribe,
} from 'utils/createObserverOnDOMElement';

export const useHandleChatsPositioning = () => {
  const isFixedReservationBannerVisible = useStore(
    isFixedReservationBannerVisible$,
  );
  const isFreshChatWasLoaded = useStore(isFreshChatWasLoaded$);

  const manageElementVisibility = (el, bottom) => {
    if (!el) {
      return;
    }

    const isPanelCurrentlyVisible = isFixedReservationBannerVisible$.getState();

    if (isPanelCurrentlyVisible) {
      el.style.setProperty('bottom', `${bottom}px`, 'important');
    } else {
      el.style.setProperty('bottom', '30px');
    }
  };

  //observe if freshChat is appeared in DOM
  useEffect(() => {
    createObserverOnDOMElementWithSubscribe(
      document.body,
      'mobile-chat-container',
      () => {
        const fwChat = document.getElementById('mobile-chat-container');
        manageElementVisibility(fwChat, '75');
      },
    );

    createObserverOnDOMElement(document.body, 'mobile-chat-container', () => {
      setIsFreshChatWasLoaded(true);
    });
  }, []);

  useEffect(() => {
    const fwChat = document.getElementById('mobile-chat-container');

    if (!fwChat) {
      return;
    }

    manageElementVisibility(
      fwChat,
      isFixedReservationBannerVisible ? '75' : '30',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFreshChatWasLoaded]);

  useEffect(() => {
    const fwChat = document.getElementById('mobile-chat-container');

    if (fwChat) {
      manageElementVisibility(fwChat, '75');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFixedReservationBannerVisible]);
};
