import React, { useCallback, useMemo } from 'react';
import { forceVisible } from 'react-lazyload';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { sendEventWithCustomField } from 'googleAnalytics';
import { useGetStaticHeaderHeight } from 'hooks';

import { ReactComponent as MessageIcon } from 'assets/images/icon/message.svg';

import style from './message.module.scss';

const Message = () => {
  const offsetFromTop = useGetStaticHeaderHeight();

  const location = useLocation();

  const currElementId = useMemo(() => {
    return location.pathname.includes(PATHS._TRAINING)
      ? 'requestTrainingForm'
      : 'enquiryForm';
  }, [location.pathname]);

  const scrollToEnquiry = useCallback(() => {
    sendEventWithCustomField(
      'submit_enquiry_button',
      'eventLocation',
      'header',
    );
    forceVisible();

    setTimeout(() => {
      const el = document.getElementById(currElementId);

      if (!el) {
        return;
      }

      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - offsetFromTop;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }, 600);
  }, [currElementId, offsetFromTop]);

  return (
    <MessageIcon onClick={scrollToEnquiry} className={style.messageIcon} />
  );
};

export default Message;
