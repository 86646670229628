import { useEffect, useMemo } from 'react';
import { MOBILE_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { isCartPopupVisible$ } from 'models/cart';
import { isFreshChatWasLoaded$, setShowFreshChatBtn } from 'models/layoutData';
import { settings$ } from 'models/settings';

import { useMatchMedia } from 'libs/useMatchMedia';

export const useHideChats = ({ isAlwaysHide, isHideOnMobile }) => {
  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);

  const settings = useStore(settings$);
  const isFreshChatWasLoaded = useStore(isFreshChatWasLoaded$);
  const isCartPopupVisible = useStore(isCartPopupVisible$);

  const isHide = useMemo(() => {
    if (isAlwaysHide || (isCartPopupVisible && isMobile)) {
      return true;
    }

    return isHideOnMobile && isMobile;
  }, [isAlwaysHide, isCartPopupVisible, isHideOnMobile, isMobile]);

  useEffect(() => {
    if (settings.isFreshChatHidden && window.Freshbots) {
      window.Freshbots.deActivate();
    }
  }, [settings.isFreshChatHidden]);

  useEffect(() => {
    if (!isHide) {
      setShowFreshChatBtn(true);
      return;
    }

    if (window.Freshbots) {
      window.Freshbots.hideWidget();
    }

    setShowFreshChatBtn(false);

    return () => setShowFreshChatBtn(true);
  }, [isHide, isFreshChatWasLoaded]);

  useEffect(() => {
    if (!isHide) {
      return;
    }

    const whatsAppIcon = document.querySelector('a[href^="https://wa.me/"]');

    if (whatsAppIcon) {
      whatsAppIcon.setAttribute('style', 'display: none');
      return () => whatsAppIcon.removeAttribute('style');
    }
  }, [isHide]);
};
