export const checkout = {
  table: {
    visual: 'Visual',
    productTitle: 'Título do produto',
    hiringPeriod: 'Período de contratação',
    hirePerItem: 'Aluguer por item',
    qty: 'quant.',
    hirePrice: 'Preço do aluguer',
  },

  changeExtrasModal: {
    requiredExtrasTitle: 'Escolha os extras necessários',
    optionalExtrasTitle: 'Escolha extras opcionais',
    subTitle: 'Selecione os extras e indique a sua quantidade',
  },

  getQuote: 'Obter cotação',
  checkout: 'Checkout',
  checkoutReservation: 'Checkout',
  emailMe: 'Envie-me esta cotação por e-mail',
  transportationRoute: 'Rota de transporte',

  quoteHasExpiredTitle: 'O seu orçamento expirou',
  quoteHasExpiredSubTitle: 'Infelizmente, o orçamento que recebeu expirou.',
  quoteHasExpiredText:
    'Os nossos orçamentos são válidos durante 7 dias e este prazo já expirou. Para criar um novo orçamento, consulte o nosso {0} e siga as etapas para solicitar um orçamento.',
  viewCatalogue: 'Ver o catálogo',

  press: 'Pressionar',
  change: 'Alterar',
  optionalExtra: 'Extras opcionais',
  requiredExtra: 'Extras Necessários',
  requiredConsumable: 'Consumíveis Necessários',
  changeExtra: 'Alterar extras',
  for: 'para',
  free: 'Grátis',
  addMoreProducts: 'Adicionar mais produtos',
  payNow: 'Pague agora',
  deliveryCollection: 'Entrega e coleta',
  selfCollectionText:
    'Recolha as ferramentas/produtos que alugou no depósito que escolheu/especificou. Certifique-se de que dispõe de espaço suficiente para o transporte e de que o seu veículo é adequado para transportar os produtos.',
  deliveryText:
    'Os produtos que alugou serão entregues na morada que indicou. Será cobrada uma taxa. A taxa será baseada no endereço que você forneceu.',
  comingSoon: 'Em breve',
  emptyData: 'dados vazios',
  openingTimes: 'Horario de funcionamento',
  select: 'Selecione',
  selected: 'Selecionado',
  chooseAnotherDepot: 'Escolha outro depósito',
  chooseDepot: 'Escolha o depósito',
  impossibleDelivery:
    'Para solicitar que os produtos sejam entregues em seu local, entre em contato conosco em {0} ou em {1}.',
  impossibleSelfCollection:
    'A opção de coleta automática não está disponível para este produto. Prossiga com a opção de entrega ou entre em contato conosco em {0} para qualquer dúvida.',
  italy: 'Itália',
  fields: {
    hireStartDate: 'Data de início da contratação',
    hireEndDate: 'Data de término da contratação',
    chooseStartDate: 'Escolha a data de início',
    chooseEndDate: 'Escolha a data final',
    deliveryTimeSlot: 'Intervalo horário de entrega',
    collectionTimeSlot: 'Intervalo horário de coleta',
    addressLine: 'Linha de endereço',
    city: 'Cidade',
    deliveryPostcode: 'Código postal de entrega',
    country: 'País',
    vatNumber: 'Número de IVA',
    taxNumber: 'Número de identificação fiscal',
    fullName: 'Nome completo',
    phoneNumber: 'Número de telefone',
    emailAddress: 'Endereço de e-mail',
    deliveryAddress: 'Endereço de entrega',
  },

  placeholders: {
    startDate: 'DD/MM/AAAA',
    endDate: 'DD/MM/AAAA',
    deliveryTime: 'Horário de entrega',
    collectionTime: 'Horário de Coleta',
    address: 'Endereço',
    ofDelivery: 'De entrega',
    postcode: 'código postal',
    findPostcode: 'Comece a digitar seu código postal',
  },

  titles: {
    yourQuote: 'Sua cotação',
    chooseHowYou: 'Escolha como você gostaria de prosseguir',
    pleaseEnter: 'Por favor, insira os detalhes abaixo',
    payWithCard: 'CARTÃO DE CRÉDITO',
    hiringPeriod: 'Período de contratação',
    estimatePerWeek: 'Estimativa por semana',
    deliveryMethod: 'Método de Entrega',
    invalidCard: 'Número de cartão inválido',
    invalidCardTryAgain: 'Algo deu errado, tente mais uma vez',
    contactInformation: 'Informação de contato',
    selfCollection: 'Coleta',
    delivery: 'Entrega',
    selectYour: 'Selecione seu tipo de usuário',
    enterYour: 'Insira seus detalhes de contato',
    individual: 'Individual',
    company: 'Companhia',
    accountType: '{0} conta',
    selectWhich: 'Selecione de qual depósito coletar',
    yourReservation: 'Sua reserva',
    selfCollectionDepot: 'Depósito para retirada automática',
    deliveryAddress: 'Entrega em',
    yourDetalis: 'Seus detalhes',
    paymentMethod: 'Forma de pagamento',
    loggedInAs: 'Logado como',
    makeAddress: 'Tornar o endereço como padrão',
    accountSection: 'Você tem {0} conta?',
    depositType: 'Tipo de Depósito',
    depositProceed: 'Depósito padrão',
    creditAccountProceed: 'Depósito com base em seus registros de crédito',
    findDeliveryByPostcode: 'Encontre o endereço de entrega por código postal',
    findBillingAddress: 'Find billing address by Post Code',
  },

  radio: {
    payWithCredit: 'Pague com cartão de crédito',
    payByBank: 'Pagar por Transferência Bancária',
    payByBankText: 'Enviaremos um e-mail com todos os detalhes',
    payAtSelf: 'Pagar em depósito ao retirar',
    payLater: 'Pay in 90 days - no interest, no fees.',
    payLaterText:
      'Two lets your business pay later for the items you hire online, You will receive an invoice from Two when your order has been processed.',
    payAtTrainingLocation: 'Pague depois no local do curso',
    otherMethods: 'Outro método de pagamento',
    otherMethodsText:
      'Será contactado pelo nosso representante para escolher entre os métodos de pagamento disponíveis para você',
  },

  details: {
    createAccount: 'Criar conta {0}',
    no: 'Não',
    yes: 'Sim',
  },
  guest: 'Check out como convidado',
  guestText: 'Uma conta será criada automaticamente.',
  oldUser: 'Eu tenho {0} conta',
  newUser:
    'Criar conta {0} (ajudará a finalizar a compra mais rápido da próxima vez)',
  go: 'Ir',
  logOut: 'Sair',
  dontHaveAccount: 'Ainda não tem uma conta?',
  singUpSuccess: 'Obrigado por inscrever-se! Faça login para continuar.',
  stripeNote:
    'Usamos o Stripe para processar todos os seus pagamentos. A Stripe é certificada para PCI Service Provider Level 1. Este é o nível mais rigoroso de certificação disponível no setor de pagamentos.',
  descriptions: {
    fillFewFields:
      'Preencha alguns campos para verificar o preço total e receber uma cotação em seu e-mail.',
    shareAllThe:
      'Compartilhe todos os detalhes necessários para a contratação, veja o preço total e efetue o pagamento.',
    paymentSecured: 'O pagamento é protegido com certificado SSL',
    leaveTheseFields:
      'Deixe esses campos em branco se ainda não souber as datas. Enviaremos um e-mail com uma estimativa por semana.',
    theLossDamage:
      'O depósito por perdas e danos será devolvido ao seu cartão após a devolução segura dos produtos',
    yourQuoteIsEmpty:
      'Seu carrinho {0} está vazio. Navegue em nosso {1} e crie sua lista de reservas.',
    callUs: 'Ligue para nós {0} se tiver alguma dúvida.',
    startTyping: 'Comece a digitar seu endereço de entrega',
    enterAddress: 'Digite o endereço de entrega',
    depositProceed:
      'O depósito em seu resumo de custos será calculado usando nossas configurações padrão e refletirá o valor total de substituição do item',
    creditAccountProceed:
      'O depósito será definido e confirmado com você usando seus registros de crédito. Enviaremos um e-mail com um formulário eletrônico de solicitação de crédito com mais detalhes. Use esta opção se você quiser diminuir seu depósito',
    addressChosen: 'Endereço de entrega selecionado',
    billingAddress: 'Billing Address',
    enterBillingAddress: 'Enter billing address',
    changeBillingAddress: 'Change billing address',
    sameAsDeliveryAddress: 'Same as Delivery Address',
  },

  success: {
    yourEnquiry: 'Sua consulta foi realizada com sucesso',
    youWill:
      'Em breve você receberá um e-mail com seu orçamento de locação. Você pode usar essa cotação para fazer os pedidos quando estiver pronto.',
    youShould:
      'Você receberá um e-mail de confirmação detalhando seus pedidos em breve.',
    goTo: 'Vá para Administrar',
    availableAddress: 'Entrega: ',
  },

  errors: {
    outOfRegion:
      'Lamentamos, mas inseriu um endereço fora da região {region}. Escolha a retirada em um de nossos depósitos ou verifique se o produto está disponível em sua região acessando nossa {homepage}.',
    outOfDistance:
      'Desculpe, não oferecemos entrega em seu endereço.\nUse a opção de coleta e retire os produtos em um de nossos depósitos, é sempre grátis.',
    postalCodeDefined:
      'O preço na sua área ainda não foi definido. Proceda à  opção de coleta ou contacte-nos',
    differentTypes:
      'Para alguns produtos em seu carrinho, a opção de entrega não está disponível. Por favor, selecione um depósito ou exclua os seguintes produtos do carrinho',
    noWarehouses:
      'Os produtos não estão disponíveis para serem recolhidos nos nossos depósitos',
    sameDay:
      'Não podemos acomodar entrega no mesmo dia ou fim de semana para esses produtos. Por favor, altere o período do contrato ou continue com a opção de coleta',
  },

  steps: {
    title: 'Checkout',
    payConfirmation: 'Sua reserva',
    delivery: 'Entrega ou coleta própria',
    details: 'Seus detalhes',
    confirmAndPay: 'Confirme e pague',
  },

  toastMessages: {
    requiredWarehouse: 'O armazém deve ser selecionado',
    requiredAddress: 'O endereço de entrega deve ser preenchido corretamente',
  },

  promo: {
    title: 'Você tem um código promocional?',
    placeholder: 'Digite seu código promocional',
    apply: 'Aplicar',
    remove: 'Retirar',
    success: 'Seu código promocional é aplicado ao resumo de custos!',
    notValid: 'Ops, seu código promocional não é válido',
    promoAlreadyRedeemed: 'Código promocional já resgatado',
    promoForAuthorizedUsersOnly:
      'Esta promoção está disponível apenas para usuários autorizados',
    promoDoesntExist: 'O código promocional não existe',
    codeHasExpired: 'Este código promocional expirou',
    promoNotValidForProducts:
      'Este código promocional não é válido para seus produtos',
    doYouHavePromotional: 'Você tem um código promocional?',
    promotionalCode: 'Código promocional',
    promoCodeApplied: 'Código promocional aplicado',
    promoCodeNote:
      'O código promocional pode ser aplicado apenas uma vez durante o checkout online',
    promoForNonAuthorizedUsersOnly:
      'Esta promoção está disponível apenas para usuários não autorizados',
  },
  generateUrl: 'Gerar URL para esta lista de reservas',
  getEmailQuote: 'Obter cotação por e-mail',
  urlIsCopied: 'O URL é copiado para a área de transferência',
  enterComment: 'Comentários adicionais: (opcional)',
  confirmedAvailability: 'a disponibilidade é confirmada',
  futureDelivery:
    'Os custos de entrega na sua morada serão definidos posteriormente.',
  deliveryNotAvailable: 'Atualmente não disponível',
  findNewAddress: 'Encontrar novo endereço',
  backToResults: 'Voltar aos resultados',
  continueShopping: 'Continue comprando',
  proceedViewFullPayment:
    'Prossiga para ver o detalhamento completo dos preços',
  backToReservation: 'Voltar para reserva',
  selectDeliveryViewCostSummary:
    'Selecione seu método de entrega para ver o resumo de custo completo',
  selectPayment: 'Selecione o método de pagamento para confirmar seu pedido',
  continueToDelivery: 'Continuar para entrega',
  continuePayment: 'Continuar com o pagamento',
  placeOrder: 'Realizar pedido',
  alreadyHaveAnAcc: 'Já tem uma conta?',
  dontHaveAnAcc: 'Não tem uma conta?',
  imCompany: 'Eu sou uma empresa',
  imNotCompany: 'I am not a company',
  imNotCompanyNote: `Select this checkbox if you're not a registered company. This will exclude the 'Pay in 90 days' option.`,
  card: 'Cartão',
  date: 'Data',

  stripeErrors: {
    authentication_required:
      'Não foi possível autenticar seu método de pagamento. Por favor, escolha outro método de pagamento e tente novamente.',
    approve_with_id: 'O pagamento não foi autorizado. Tente novamente.',
    card_not_supported:
      'O cartão não suporta este tipo de compra. Entre em contato com o emissor do cartão.',
    card_velocity_exceeded:
      'Você excedeu o saldo ou limite de crédito disponível no cartão.',
    currency_not_supported: 'O cartão não aceita a moeda especificada.',
    expired_card: 'O seu cartão expirou. Tente com outro cartão.',
    incorrect_cvc:
      'O número do CVC está incorreto. Tente novamente com o número CVC correto.',
    invalid_cvc:
      'O número do CVC está incorreto. Tente novamente com o número CVC correto.',
    invalid_expiry_month:
      'O mês de vencimento é inválido. Tente novamente usando a data de expiração correta.',
    invalid_expiry_year:
      'O ano de validade é inválido. Tente novamente com a data de expiração correta.',
    issuer_not_available:
      'Não foi possível contatar o emissor do cartão. Tente fazer o pagamento novamente.',
    pin_try_exceeded:
      'O número máximo de tentativas de PIN foi excedido. Utilize outro cartão ou método de pagamento.',
  },

  deliveryOptions: 'Opções de Entrega',
  paymentOptions: 'Opções de Pagamento',
  debitCreditCards: 'Cartões de débito/crédito',
  payLater: 'Pague mais tarde',
  delivery: 'Entrega',
  charge: 'Pagar',
  freeProduct: 'Livre',
};
