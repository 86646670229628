import { useMemo } from 'react';
import { languagesOverridesMapLocales } from 'consts';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import { useStore } from 'effector-react';
import { currentLang$ } from 'models/language';

export function useGetLocalizedWeeksLabels(formatLabels = 'EEE') {
  const currentLangState = useStore(currentLang$);

  return useMemo(() => {
    const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 });

    return Array.from(Array(7)).map((e, i) =>
      format(addDays(firstDOW, i), formatLabels, {
        locale: languagesOverridesMapLocales[currentLangState],
      }),
    );
  }, [currentLangState, formatLabels]);
}
