export const header = {
  region: {
    region: 'Region',
    areYouIn: 'Are you located in',
    yes: 'Yes',
    no: 'No, use',
    checkOther: 'Check other regions we are available in',

    serviceAvailability: 'Service Availability',
    weWillStart:
      'We will start offering our hiring services in your region soon!',
    meanwhileFeel: 'Meanwhile, feel free to browse our catalogo in:',
    orLeaveYour:
      'Or leave your contacts, and we’ll let you know once we launch in your region',
    weDetected: "We've detected you are located in",
    wouldYouLike: 'Would you like us to change the region accordingly',
    isNotAvailable: 'is not available in your region yet.',
    okUse: 'ok, use',
    submit: 'Submit',
  },

  getAQuote: 'Get a quote',
  allProducts: 'All products',

  byUsingThis: 'By using this site you agree to the use of cookies.',
  cookieBtn: 'Got it',

  backTo: 'Back to',

  nav: {
    back: 'Back',
    backToPreviousPage: 'Back to previous page',
    home: 'Home',
    catalog: 'Catalog',
    hireByCategory: 'Hire',
    company: 'Company',
    training: 'Training',
    aboutUs: 'About Us',
    blog: 'Blog',
    logIn: 'Log in',
    logOut: 'Log Out',
    customerPortal: 'Customer Portal',
    reservation: 'Your reservation',
    faq: 'FAQ',
    carbonOffsetting: 'Carbon offsetting',
    menuLanguage: 'English',
    signAs: 'Signed in as',
    maintenance: 'Maintenance',
    otherServices: 'Other services',
    jobTypes: 'Job Types',
    findHowWeAre: 'About {0}',
    searchResults: 'Search results',
    breadcrumbRegion: 'Region',
  },

  bar: {
    customize: 'Customize inventory',
    depots: 'Depots',
    allDepots: 'All depots',
    seeAllDepots: 'See all depots',
    allDepotsTitle: 'Our depots addresses in {0}',
    choose: 'Choose depots that work for you',
    yourChoice:
      'Your choice will filter our inventory. At least one depot should be selected. To browse the whole catalogo select all depots.',
    regionDepots: 'region & depots',
    yourRegion: 'Your Region',
    selectedRegion: 'Selected Region',
    region: 'Region',
    selectRegion: 'Select Index',
    selectDepots: 'Select Depots',
    back: 'Back',
    isCommitted: '{0} is committed to sustainable equipment rental {1}',
    learnMore: 'Learn More',
  },

  callModal: {
    title: 'Best Price Online!',
    text: 'For the lowest prices, create your quote online or place your order online.',
    closeBtnText: 'Ok, I’ll do it online',
    subText:
      'If you still want to call us, here is the number. But remember, by calling us, it will cost you  more!',
  },
};
