import React, { useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { BrandingContext } from 'contexts';
import { getCompanyNameSeo } from 'utils/getSeoMeta';

const Meta = ({
  metaDescription,
  value,
  product = {},
  isAddHireWord,
  robots,
  isNotFoundPage,
  prerenderInfo,
}) => {
  const branding = useContext(BrandingContext);

  const { page } = useParams();

  const title = value || product?.title || '';

  const description = product?.description || metaDescription || '';

  const image = product?.image || '';

  const link = useMemo(
    () => {
      let metaCanonicalStr = `${window.location.origin}${window.location.pathname}`;

      metaCanonicalStr =
        page > 0
          ? metaCanonicalStr.replace(/\/\d+\/?$/, '/')
          : metaCanonicalStr;

      if (metaCanonicalStr.slice(-1) !== '/') {
        metaCanonicalStr = metaCanonicalStr + '/';
      }

      return metaCanonicalStr;
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, window.location.pathname],
  );

  return (
    <>
      <Helmet>
        <title>
          {`${title} ${
            isAddHireWord ? getCompanyNameSeo(title, branding) : ''
          }`}
        </title>

        <meta name="description" content={description} />
        <meta name="robots" content={robots} />
        <link rel="canonical" href={link} />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
        <meta itemProp="image" content={image} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={link} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        {isNotFoundPage && <meta name="prerender-status-code" content="404" />}
      </Helmet>
      {!!prerenderInfo && (
        <Helmet>
          <meta
            name="prerender-status-code"
            content={prerenderInfo.prerenderStatusCode.toString()}
          />
          {prerenderInfo.prerenderHeaderLocation && (
            <meta
              name="prerender-header"
              content={`Location: ${prerenderInfo.prerenderHeaderLocation}`}
            />
          )}
        </Helmet>
      )}
    </>
  );
};

export default Meta;
