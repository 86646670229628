export const profile = {
  noData: 'No data was found for the specified criteria.',
  and: 'and',

  logIn: {
    header: 'Log in to {0}',
    subHeader: 'Enjoy {0} features and services at full',
    nameFieldTitle: 'Full name',
    nameFieldPlaceholder: 'Enter your name',
    emailFieldTitle: 'Email Address',
    emailFieldPlaceholder: 'Enter your email address',
    passwordFieldTitle: 'Password',
    passwordFieldPlaceholder: 'Enter your password',
    remember: 'Remember',
    forgotPassword: 'Forgot your password?',
    submit: 'Log In',
    sendMeAnActivationEmail: 'Send me an activation email',
  },

  singUp: {
    header: 'Sign up for your account',
    subHeader: 'Create an account and enjoy our services at full',
    nameFieldTitle: 'Full name',
    nameFieldPlaceholder: 'Enter your name',
    emailFieldTitle: 'Email Address',
    emailFieldPlaceholder: 'Enter your email address',
    passwordFieldTitle: 'Password',
    passwordFieldPlaceholder: 'Enter your password',
    repeatPasswordFieldTitle: 'Repeat Password',
    repeatPasswordFieldPlaceholder: 'Re-enter your password',
    goToLogin: 'I have an account already, go to Log In page',
    submit: 'Sign Up',
    emailSentTitleThanks: 'Thank you for signing up!',
    emailSentTitleVerify: 'Please verify your email and activate account',
    emailSentText:
      'We’ve sent an activation link to your email address. Please, check your emailbox and follow instructions to verify your email and activate account.',
    emailSentLinkDidNotReceive: 'You didn’t receive the email?',
    emailSentLink: 'Re-send activation email.',
    emailSentResendError:
      'Re-send is available once in 1 minute, please try again later',
    tokenExpired: 'Token has expired.',
  },

  forgotPassword: {
    header: 'Forgot your password?',
    customerHeader: 'Customer Portal Forgot Password',
    subHeader:
      'Enter email address you used for signing up and we’ll send you a password reset link',
    emailFieldTitle: 'Email Address',
    emailFieldPlaceholder: 'Enter your email address',
    goToLogin: 'Go to Log In page, I’ve remembered my password',
    submit: 'Submit',
    sent: 'The message was sent. Please, check your email.',
    cancel: 'Cancel',
  },

  resetPassword: {
    header: 'Password Reset',
    customerHeader: 'Customer Portal Password Reset',
    subHeader: 'Please enter your new password below',
    emailAddressTitle: 'Email Address',
    passwordFieldTitle: 'New Password',
    passwordFieldPlaceholder: 'Enter your password',
    repeatPasswordFieldTitle: 'Repeat New Password',
    repeatPasswordFieldPlaceholder: 'Re-enter your password',
    submit: 'Submit',
    saveChanges: 'Save Changes',
  },

  activationAccount: {
    customerHeader: 'Trade Account Activation',
    subHeader: 'Please enter your password below',
    saveChanges: 'Activate Account',
    passwordFieldTitle: 'Password',
    repeatPasswordFieldTitle: 'Repeat Password',
  },

  validation: {
    required: 'Required field*',
    emailPattern: 'Incorrect email',
    passwordLength: 'Password must have at least 6 characters',
    passwordNotIncludeSpaces: 'Password should not include spaces',
    passwordDifferent: 'Passwords are different',
    invalidCreds: 'Invalid email or password',
    userImageUploadError: `Image can't be saved`,
    invalidEmail: 'Invalid email address',
  },

  menu: {
    dashboard: 'Dashboard',
    notifications: 'Notifications',
    catalogue: 'Catalogue',
    invoices: 'Invoices',
    settings: 'Settings',
    management: 'Management',
    quotes: 'Quotes',
    orders: 'Orders',
    support: 'Support',
    myAccount: 'My account',
    logOut: 'Log out',
    profile: 'Profile',
    usersPermissions: 'Users & Permissions',
  },

  dropzone: {
    dropText: 'Drop the files here ...',
    dragText: 'Drag File here or',
    dragClickableText: 'choose from your computer',
  },

  toastMessages: {
    updateSuccess: 'The info was successfully updated',
    updateError: `The info can't be updated`,
  },

  toastErrorMessages: {
    wrongOldPassword: 'Old password is wrong',
  },

  benefits: {
    title: 'Find out more on the advantages of Trade Accounts - Register now!',
    subTitle:
      'Online Accounts were developed to help our partners meet their plant and tool hire needs regularly, efficiently, and easily. Feel free to browse and order through {0} using your account while your registration confirmation email comes through - check your junk mailbox.',
    discountMemberships: 'Unlock discount pricing on volume orders',
    noDeposit: 'Avoid mandatory deposits for select products',
    paymentMethods: 'Receive more payment methods',
    payLater: 'Get credit terms',
    orders: 'One place to keep all your recent and past orders and payments.',
    catalogue: 'Full and up-to-date catalogo with the most newest arrivals.',
    percent:
      'Instant access to our special offers, discounts and promo campaings.',
    chat: 'Support chats and quick assistance, reservations in just 5-10 minutes.',
  },

  customerLogin: {
    header: 'Trade Account Log-In',
    emailFieldTitle: 'Email Address',
    passwordFieldTitle: 'Password',
    forgotPassword: 'Forgot your password',
    register: 'Register',
    submit: 'Login',
  },

  customRegister: {
    header: 'Trade Account Registration',
    subHeader: 'Please select your registration profile below',

    privateTab: 'Personal',
    companyTab: 'Business',

    fiscalCodeFieldTitle: 'Fiscal Code',
    officeAddressFieldTitle: 'Registered Office Address',
    postalCodeFieldTitle: 'Postal Code',
    provinceFieldTitle: 'Province',
    sectorOfActivity: 'Sector of activity?',
    uniqueCodeFieldTitle: 'Unique code',
    PECFieldTitle: 'PEC',
    howDidYouKnowAboutUs: 'How did you know about us',

    generalInformation: 'General Information',
    contactInformation: 'Contact Information',
    contactPhoneNumber: 'Contact Phone Number',
    contactEmailAddress: 'Contact Email Address',
    backToLogin: 'Back To Login',
    businessName: 'Business Name',

    thankYouTitle: 'Thank you for your registration!',
    thankYouSubTitle:
      'You have successfully completed registration for Customer Portal. Please, check your email and follow the instructions that we sent to you.',
    goToLogin: 'Go to Log In',
    privacyCheckbox: 'I have read the',
    consentCheckbox:
      'I want to be the first to hear about {0}’s latest offers, product launches, blogs and company news.',
  },

  sectorOfActivity: {
    business: 'Business',
    constructions: 'Constructions',
    public_authority: 'Public authority',
    training_courses: 'Training courses',
    gardening: 'Gardening',
    industry: 'Industry',
    services: 'Services',
    transport: 'Transport',
    tourism: 'Tourism',
    other: 'Other',
  },

  aboutUs: {
    search_on_the_internet: 'Search on the Internet',
    television: 'Television',
    word_of_mouth: 'Word of mouth',
    headquarter_visibility: 'Headquarter visibility',
    construction_warehouse: 'Construction warehouse',
    gardening_dealer: 'Gardening dealer',
    magazines_and_newspapers: 'Magazines and newspapers',
    other: 'Other',
  },

  emptySection: {
    noInfo: 'You have no {0} yet. To place an order start by browsing our {1}.',
    callUs: 'Don’t hesitate to call us {0} if any questions.',
  },

  noDiscount: {
    title: 'Trade Account Membership',
    description:
      'There are four types of discounts for our customers: {0}, {1}, {2} and {3}. Contact us to find out how to be receive a discount on all the products in our {4}.',
  },

  loginErrors: {
    incorrectCredentials: `Incorrect username or password`,
    accountIsNotActivated: 'Account is not activated yet',
  },

  paymentMethods: 'Payment Methods',
  pleaseFillAllFields:
    'Your profile is missing information. Please fill in all blank fields below.',
  membershipDiscount: 'Membership Discount',
  selectSegment: 'Please select to which customer segment do you belong?',
  continue: 'Continue',
  continueWithGoogle: 'Continue with Google',
  or: 'or',
  registerWithEmail: 'Register with email',
  signedInWithGoogle: 'Signed in with Google account',
  signedInWithRegisteredEmail: `We've sent the message to {0}.`,
  signedInWithRegisteredEmailCheck:
    'Please check your email and follow the instructions.',
  membershipTooltip: `{0} is your current plan with {1}. Contact us to find out more about the {2} plan. Contact us to find out more about the {3} plan.`,
  basic: 'Basic',
  standard: 'Standard',
  premium: 'Premium',
};
