import React from 'react';
import clsx from 'clsx';

import { QuantityChanger } from 'ui';

import style from './productQuantityChanger.module.scss';

const ProductQuantityChanger = ({
  startCount,
  maxQuantity,
  minQuantity,
  changeCount,
  limit,
  isLoading,
  isAvailableProducts,
  className,
  size = 36,
  isProductDisabled,
}) => {
  return (
    <div
      className={clsx(
        style.customQuantityChangerWrapper,
        style.newChanger,
        className,
      )}
    >
      <QuantityChanger
        startCount={startCount}
        maxQuantity={maxQuantity}
        minQuantity={minQuantity}
        changeCount={changeCount}
        limit={limit}
        inputStyle={style.quantityInput}
        circleBtnClassName={style.circleBtnClassName}
        circleBtnDisableClassName={style.circleBtnDisableClassName}
        additionalClass="quantity"
        size={size}
        isLoading={isLoading}
        isDisable={isAvailableProducts || isProductDisabled || isLoading}
      />
    </div>
  );
};

export default ProductQuantityChanger;
