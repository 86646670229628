import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { SMALL_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import MobileDropDown from 'layout/Footer/MobileDropDown';
import { categoriesLocalized$ } from 'models/product';

import { useMatchMedia } from 'libs/useMatchMedia';

import DesktopCategoriesList from './DesktopCategoriesList';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const Categories = () => {
  const { main } = Lang();

  const categories = useStore(categoriesLocalized$);

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  return isSmallScreen ? (
    <MobileDropDown title={main.titles.hireByCategory}>
      {categories.map(({ name, slug }, i) => (
        <T8y
          key={i}
          as={Link}
          to={{
            pathname: PATHS.CATEGORY(slug),
            presetType: 'category',
          }}
          variant="t1v1"
          color="primary"
          className={style.footerLink}
        >
          {name}
        </T8y>
      ))}
    </MobileDropDown>
  ) : (
    <DesktopCategoriesList />
  );
};

export default Categories;
