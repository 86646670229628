import { REACT_APP_PREFIX } from 'consts';

export const PATHS = Object.freeze({
  ROOT: REACT_APP_PREFIX ? `/${REACT_APP_PREFIX}/` : '/',
  _PRODUCTS: 'products',
  _SEARCH: 'search',
  _PRODUCT: 'product',
  _ABOUT: 'slides-us',
  _TRAINING: 'trainings',
  _INVOICE: 'invoice',
  _CART: 'cart',
  _CHECKOUT: 'checkout',
  _SUCCESS: 'success',
  _SUCCESS_PAY_LATER: 'success-pay-later',
  _POLICY: 'policy',
  _TERMS: 'terms',
  _COOKIE_POLICY: 'cookie-policy',
  _GENERAL_TERMS: 'general-terms',
  _DELIVERY_TIME_SELECT: 'time-select',
  _LOGIN: 'login',
  _NOT_FOUND: '404',
  _PROFILE: 'profile',
  _REGISTRATION: 'registration',
  _RESET: 'reset',
  _AUTH: 'auth',
  _FORGOT: 'forgot-password',
  _ACCOUNT: 'account',
  _DASHBOARD: 'dashboard',
  _ORDERS: 'orders',
  _INVOICES: 'invoices',
  _QUOTES: 'quotes',
  _SUPPORT: 'support',
  _PAY_CONFIRMATION: 'pay-confirmation',
  _DELIVERY: 'delivery',
  _DETAILS: 'details',
  _CONFIRM_AND_PAY: 'confirm-and-pay',
  _THANK_YOU: 'thank-you',
  _SETTINGS: 'settings',
  _NOTIFICATIONS: 'notifications',
  _CARBON_EMISSION: 'carbon-emission',
  _MAINTENANCE: 'maintenance',
  _ACCOUNT_ACTIVATION: 'account-activation',
  _MOBILE_SEARCH: 'mobile-search',
  _RESERVATION: 'reservation',
  _QUOTE: 'quote',
  _JOB_TYPES: 'partners',
  _DEPOTS: 'depots',
  _VERIFICATION: 'verification',
  _VERIFICATION_SUCCESS: 'verification-success',

  get PRODUCTS() {
    return `${this.ROOT}${this._PRODUCTS}`;
  },
  get EMAIL_VERIFICATION() {
    return `${this.ROOT}${this._AUTH}/email/verify/:id?/:hash?`;
  },
  get EMAIL_REGISTERED() {
    return `${this.ROOT}${this._AUTH}/registered`;
  },
  get ADMIN_VERIFICATION() {
    return `${this.ROOT}admin/verify/`;
  },
  SEARCH(page = null, search = '') {
    if (!page || page === 1) {
      return `${this.ROOT}${this._SEARCH}/${search}`;
    }

    return `${this.ROOT}${this._SEARCH}/${page}/${search}`;
  },
  CATEGORY(slug, page = null, search = '') {
    if (!page || page === 1) {
      return `${this.ROOT}${slug}/${search}`;
    }

    return `${this.ROOT}${slug}/${page}/${search}`;
  },
  PRODUCT(slug) {
    return `${this.ROOT}${slug}/`;
  },
  get TRAINING() {
    return `${this.ROOT}${this._TRAINING}/`;
  },
  get VERIFICATION() {
    return `${this.ROOT}${this._VERIFICATION}/`;
  },
  get VERIFICATION_FRAME() {
    return `${this.ROOT}${this._VERIFICATION}/process/`;
  },
  POST(slug) {
    return `${this.ROOT}${this._TRAINING}/${slug}/`;
  },
  TRAINING_CHECKOUT(slug) {
    return `${this.ROOT}${this._TRAINING}/${this._CHECKOUT}/${slug}/`;
  },
  get TRAINING_CHECKOUT_SUCCESS() {
    return `${this.ROOT}${this._TRAINING}/${this._CHECKOUT}/success/`;
  },
  INVOICE(order_hash) {
    return `${this.ROOT}${this._INVOICE}/${order_hash}`;
  },
  get ABOUT() {
    return `${this.ROOT}${this._ABOUT}/`;
  },
  get CARBON_EMISSION() {
    return `${this.ROOT}${this._CARBON_EMISSION}/`;
  },
  get CART() {
    return `${this.ROOT}${this._CART}/`;
  },
  CART_PROPS(orderId) {
    return `${this.ROOT}${this._CHECKOUT}/?id=${orderId}`;
  },
  CART_OLD_PROPS(orderId) {
    return `${this.ROOT}${this._CART}/${orderId}`;
  },
  get CHECKOUT() {
    return `${this.ROOT}${this._CHECKOUT}/`;
  },
  get CHECKOUT_PAY_CONFIRMATION() {
    return `${this.ROOT}${this._CHECKOUT}/${this._PAY_CONFIRMATION}/`;
  },
  get CHECKOUT_DELIVERY() {
    return `${this.ROOT}${this._CHECKOUT}/${this._DELIVERY}/`;
  },
  get CHECKOUT_DETAILS() {
    return `${this.ROOT}${this._CHECKOUT}/${this._DETAILS}/`;
  },
  get CHECKOUT_CONFIRM_AND_PAY() {
    return `${this.ROOT}${this._CHECKOUT}/${this._CONFIRM_AND_PAY}/`;
  },
  get RESERVATION() {
    return `${this.ROOT}${this._RESERVATION}/`;
  },
  get RESERVATION_CHECKOUT() {
    return `${this.ROOT}${this._RESERVATION}/${this._CHECKOUT}/`;
  },
  get RESERVATION_QUOTE() {
    return `${this.ROOT}${this._RESERVATION}/${this._QUOTE}/`;
  },
  get SUCCESS() {
    return `${this.ROOT}${this._SUCCESS}/`;
  },
  get VERIFICATION_SUCCESS() {
    return `${this.ROOT}${this._VERIFICATION_SUCCESS}/`;
  },
  get POLICY() {
    return `${this.ROOT}${this._POLICY}/`;
  },
  get TERMS() {
    return `${this.ROOT}${this._TERMS}/`;
  },
  get COOKIE_POLICY() {
    return `${this.ROOT}${this._COOKIE_POLICY}/`;
  },
  get GENERAL_TERMS() {
    return `${this.ROOT}${this._GENERAL_TERMS}/`;
  },
  get PROFILE_RESET() {
    return `${this.ROOT}password-reset/:email?/:token?`;
  },
  get PROFILE_FORGOT() {
    return `${this.ROOT}${this._PROFILE}/${this._FORGOT}/`;
  },
  get PROFILE_REGISTRATION() {
    return `${this.ROOT}${this._PROFILE}/${this._REGISTRATION}/`;
  },
  get PROFILE_LOGIN() {
    return `${this.ROOT}${this._PROFILE}/${this._LOGIN}/`;
  },
  get PROFILE() {
    return `${this.ROOT}${this._PROFILE}/`;
  },
  get NOT_FOUND() {
    return `${this.ROOT}${this._NOT_FOUND}/`;
  },
  get PROFILE_DASHBOARD() {
    return `${this.ROOT}${this._PROFILE}/${this._DASHBOARD}/`;
  },
  get PROFILE_NOTIFICATIONS() {
    return `${this.ROOT}${this._PROFILE}/${this._NOTIFICATIONS}/`;
  },
  get PROFILE_ACCOUNT() {
    return `${this.ROOT}${this._PROFILE}/${this._ACCOUNT}/`;
  },
  get PROFILE_ORDERS() {
    return `${this.ROOT}${this._PROFILE}/${this._ORDERS}/`;
  },
  get PROFILE_INVOICES() {
    return `${this.ROOT}${this._PROFILE}/${this._INVOICES}/`;
  },
  PROFILE_ORDER(number) {
    return `${this.ROOT}${this._PROFILE}/${this._ORDERS}/${number}/`;
  },
  get PROFILE_QUOTES() {
    return `${this.ROOT}${this._PROFILE}/${this._QUOTES}/`;
  },
  PROFILE_QUOTE(number) {
    return `${this.ROOT}${this._PROFILE}/${this._QUOTES}/${number}/`;
  },
  get PROFILE_SETTINGS_ACCOUNT() {
    return `${this.ROOT}${this._PROFILE}/${this._ACCOUNT}/${this._SETTINGS}/${this._ACCOUNT}/`;
  },
  get MAINTENANCE() {
    return `${this.ROOT}${this._MAINTENANCE}/`;
  },
  get ACCOUNT_ACTIVATION() {
    return `${this.ROOT}${this._ACCOUNT_ACTIVATION}/`;
  },
  get MOBILE_SEARCH() {
    return `${this.ROOT}${this._MOBILE_SEARCH}/`;
  },
  JOB_TYPE(slug) {
    return `${this.ROOT}${this._JOB_TYPES}/${slug}/`;
  },
  get DEPOTS() {
    return `${this.ROOT}${this._DEPOTS}/`;
  },
});
