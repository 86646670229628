import { useEffect } from 'react';
import { REACT_APP_PREFIX } from 'consts';
import { useStore } from 'effector-react';
import * as CR from 'models/cart';
import { getObject, setObject } from 'utils/localStorage';

const NEW_CHECKOUT_KEY = `isNewCheckout_${REACT_APP_PREFIX}`;

export const useRemoveOldCheckoutLocalStore = () => {
  const cartLength = useStore(CR.cartLength$);

  useEffect(() => {
    const isNewCheckout = getObject(NEW_CHECKOUT_KEY);

    if (isNewCheckout) {
      return;
    }

    if (!cartLength) {
      setObject(NEW_CHECKOUT_KEY, true);
    }

    if (cartLength) {
      CR.resetCart();
      CR.clearDeliveryStorage();
      CR.clearDetailsStorage();
      setObject(NEW_CHECKOUT_KEY, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
