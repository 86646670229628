import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { Lang } from 'lang';

import { Row, T8y } from 'ui';

import { ReactComponent as ArrowBack } from 'assets/images/icon/arrowBack.svg';

import style from '../header.module.scss';

const SubMenuProfile = ({ id, openId, setOpenId, close }) => {
  const {
    header: { nav },
    customer,
    profile,
  } = Lang();

  const isHire = openId === id;

  return (
    <div className={style.category}>
      <Row
        justify="stretch"
        align="center"
        className={clsx(
          style.name,
          isHire ? style.border : style.triangle,
          openId > 0 && !isHire && style.none,
        )}
        onClick={() => {
          setOpenId(isHire ? 0 : id);
        }}
      >
        {isHire ? (
          <>
            <Row align="center">
              <ArrowBack className={style.backArrow} />{' '}
              <T8y variant="t1" color="light" className={style.menuTitle}>
                {nav.back}
              </T8y>
            </Row>
            <T8y variant="t3" color="lightGray" uppercase>
              {nav.customerPortal}
            </T8y>
          </>
        ) : (
          <T8y variant="t1" color="light" className={style.menuTitle}>
            {nav.customerPortal}
          </T8y>
        )}
      </Row>

      {isHire && (
        <>
          <T8y
            as={Link}
            to={PATHS.PROFILE_DASHBOARD}
            onClick={close}
            color="light"
            variant="t1"
            className={style.categoryOnly}
            capitalize
          >
            {profile.menu.dashboard}
          </T8y>

          <T8y
            as={Link}
            to={PATHS.PROFILE_ORDERS}
            onClick={close}
            color="light"
            variant="t1"
            className={style.categoryOnly}
            capitalize
          >
            {profile.menu.orders}
          </T8y>

          <T8y
            as={Link}
            to={PATHS.PROFILE_QUOTES}
            onClick={close}
            color="light"
            variant="t1"
            className={style.categoryOnly}
            capitalize
          >
            {customer.titles.quotes}
          </T8y>

          <T8y
            as={Link}
            to={PATHS.PROFILE_INVOICES}
            onClick={close}
            color="light"
            variant="t1"
            className={style.categoryOnly}
            capitalize
          >
            {profile.menu.invoices}
          </T8y>

          <T8y
            as={Link}
            to={PATHS.PROFILE_ACCOUNT}
            onClick={close}
            color="light"
            variant="t1"
            className={style.categoryOnly}
            capitalize
          >
            {profile.menu.settings}
          </T8y>
        </>
      )}
    </div>
  );
};

export default SubMenuProfile;
