import { PATHS } from 'AppPaths';
import { getDisplayedRegionName } from 'consts';
import { createEvent, createStore } from 'effector';

import { branding$ } from '../branding';

export const setBreadCrumbs = createEvent();
export const removeBreadCrumbs = createEvent();
export const setBreadCrumbsIsLoading = createEvent();

export const breadCrumbsIsLoading$ = createStore(false).on(
  setBreadCrumbsIsLoading,
  (_, p) => p,
);

export const breadCrumbs$ = createStore(null)
  .on(
    setBreadCrumbs,
    (
      s,
      {
        header,
        checkout,
        products,
        isTrainingCheckout,
        about,
        carbonEmission,
        training,
        trainingLocalisation,
        posts,
        categoryGrandParent,
        categoryParent,
        currentCategory,
        isProductMobile,
        product,
        search,
        maintenance,
        jobType,
        depotLocations,
      },
    ) => {
      const branding = branding$.getState();

      const regionName = getDisplayedRegionName();

      return {
        home: {
          name: header.nav.home,
          link: branding.indexPageLink,
          isExternalLink: true,
          isProductMobile,
        },
        ...(products && {
          allProducts: {
            name: `${header.nav.breadcrumbRegion}: ${regionName}`,
            link: PATHS.ROOT,
          },
          ...(checkout && {
            checkout: {
              name: checkout.checkout,
              link: PATHS.RESERVATION,
            },
          }),
          ...(categoryGrandParent && {
            stateGrandChild: {
              link: PATHS.CATEGORY(categoryGrandParent.slug),
              presetType: 'category',
              name: categoryGrandParent?.name,
            },
          }),
          ...(categoryParent && {
            stateParent: {
              link: PATHS.CATEGORY(categoryParent.slug),
              presetType: 'category',
              name: categoryParent.name,
            },
          }),
          ...(currentCategory && {
            category: {
              link: PATHS.CATEGORY(currentCategory.slug),
              presetType: 'category',
              name: currentCategory?.name,
            },
          }),
          ...(product && {
            product: {
              link: PATHS.PRODUCT(product.slug),
              presetType: 'product',
              name: product.name,
            },
          }),
        }),
        ...(training && {
          training: { name: header.nav.training, link: PATHS.TRAINING },
          ...(posts && {
            posts: { name: posts.title, link: PATHS.POST(posts.slug) },
          }),
        }),
        ...(jobType && {
          jobType: { name: jobType.title, link: PATHS.JOB_TYPE(jobType.slug) },
        }),
        ...(isTrainingCheckout && {
          training: { name: header.nav.training, link: PATHS.TRAINING },
          posts: {
            name: trainingLocalisation,
            link: PATHS.TRAINING,
          },
        }),
        ...(search && {
          search: {
            name: header.nav.searchResults,
          },
        }),
        ...(about && {
          about: {
            link: PATHS.ABOUT,
            name: about.name,
          },
        }),
        ...(carbonEmission && {
          carbonEmission: {
            link: PATHS.CARBON_EMISSION,
            name: carbonEmission.name,
          },
        }),
        ...(maintenance && {
          maintenance: {
            link: PATHS.MAINTENANCE,
            name: header.nav.maintenance,
          },
        }),
        ...(depotLocations && {
          depotLocations: {
            link: PATHS.DEPOTS,
            name: depotLocations.name,
          },
        }),
      };
    },
  )
  .on(removeBreadCrumbs, () => null);
