import { TRAININGS } from 'api';

import {
  getCurrentTrainingFx,
  getTrainingIndexFx,
  getTrainingsFx,
} from './index';

getTrainingsFx.use(async () => {
  const { data } = await TRAININGS.getAllTrainings();

  return data.data;
});

getCurrentTrainingFx.use(async (slugUrl) => {
  const { data } = await TRAININGS.getTraining(slugUrl);
  return data;
});

getTrainingIndexFx.use(async () => {
  const { data } = await TRAININGS.getTrainingIndex();

  return data;
});
