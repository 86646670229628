import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { handleLogout } from 'hooks';
import { Lang } from 'lang';
import { $userInfo } from 'models/user';

import { T8y } from 'ui';

import { ReactComponent as Person } from 'assets/images/icon/personWhire.svg';

import style from './adminBar.module.scss';

const AdminBar = () => {
  const {
    header: { nav },
    account,
  } = Lang();

  const userInfo = useStore($userInfo);

  useEffect(() => {
    return () => {
      handleLogout();
    };
  }, []);

  return (
    <div className={style.barWrapper}>
      <Person />
      <T8y variant="t1v1" className={style.logInText}>
        {account.youAreLoggedInAs} {userInfo.first_name} {userInfo.last_name} -{' '}
        <span className={style.logOutText} onClick={handleLogout}>
          {nav.logOut}
        </span>
      </T8y>
    </div>
  );
};

export default AdminBar;
