import algoliasearch from 'algoliasearch';
import { enGB, es, it, pt } from 'date-fns/locale';
import { capitalizeFirstLetter } from 'utils/formatStrings';

import eaCategoryPicture from 'assets/images/picture/category/defaultCategoryEA.png';
import earCategoryPicture from 'assets/images/picture/category/defaultCategoryEAR.png';
import enCategoryPicture from 'assets/images/picture/category/defaultCategoryEN.png';
import ethCategoryPicture from 'assets/images/picture/category/defaultCategoryETH.png';

export const REACT_APP_PREFIX = window.location.pathname.replace(
  /^\/([^\/]*).*$/,
  '$1',
);

export const REACT_APP_PREFIX_VALID = REACT_APP_PREFIX?.replace('-', '');

export const REACT_APP_GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const LOCAL_ENVIRONMENT = process.env.REACT_APP_COMPANY;

export const REACT_APP_PUBLIC_URL = `${process.env.PUBLIC_URL}/${REACT_APP_PREFIX}`;

export const REACT_APP_POSTCODE_API_KEY =
  process.env.REACT_APP_POSTCODE_API_KEY;

export const REACT_APP_GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const getDomain = () => {
  switch (LOCAL_ENVIRONMENT) {
    case 'ETH':
      return 'easytoolhire.com';
    case 'EA':
      return 'easyalquiler.com';
    case 'EN':
      return 'easynoleggio.net';
    case 'EAR':
      return 'easyaluguer.com';
    default:
      return 'easytoolhire.com';
  }
};

export const getInitialLang = () => {
  switch (LOCAL_ENVIRONMENT) {
    case 'ETH':
      return 'en-gb';
    case 'EA':
      return 'es';
    case 'EN':
      return 'it';
    case 'EAR':
      return 'pt';
    default:
      return 'en-gb';
  }
};

export const isETH = LOCAL_ENVIRONMENT === 'ETH';
export const isEA = LOCAL_ENVIRONMENT === 'EA';
export const isEN = LOCAL_ENVIRONMENT === 'EN';
export const isEAR = LOCAL_ENVIRONMENT === 'EAR';
export const isRegionLazio = REACT_APP_PREFIX === 'lazio';
export const isRegionEmilia = REACT_APP_PREFIX === 'emiliaromagna';
export const isRegionVeneto = REACT_APP_PREFIX === 'veneto';
export const isRegionCampania = REACT_APP_PREFIX === 'campania';
export const isRegionCentral = REACT_APP_PREFIX === 'espanacentral';
export const isRegionGalicia = REACT_APP_PREFIX === 'galicia';

export const getDisplayedRegionName = (region) => {
  switch (region || REACT_APP_PREFIX) {
    case 'emiliaromagna':
      return 'Emilia-Romagna';
    case 'comunidad-valenciana':
      return 'Comunidad Valenciana';
    case 'espanacentral':
      return 'España Central';
    case 'catalunya':
      return 'Cataluña';
    case 'larioja':
      return 'La Rioja';
    case 'north-east-england':
      return 'North East England';
    case 'north-west-england':
      return 'North West England';
    case 'east-midlands':
      return 'East Midlands';
    case 'east-of-england':
      return 'East of England';
    case 'south-east-england':
      return 'South East England';
    case 'south-west-england':
      return 'South West England';
    case 'west-midlands':
      return 'West Midlands';
    default:
      return capitalizeFirstLetter(region || REACT_APP_PREFIX);
  }
};

export const getDisplayedCompanyName = () => {
  switch (LOCAL_ENVIRONMENT) {
    case 'ETH':
      return 'easyToolhire';
    case 'EA':
      return 'easyAlquiler';
    case 'EN':
      return 'easyNoleggio';
    case 'EAR':
      return 'easyAluguer';
    default:
      return 'easyNoleggio';
  }
};

const DEFAULT_COMPANY_TAGS = {
  ETH: 'hire',
  EA: 'alquiler',
  EN: 'noleggio',
  EAR: 'aluguer',
};

export const isProdEnv =
  window.location.hostname !== 'localhost' &&
  !REACT_APP_PUBLIC_URL.includes('stage.') &&
  !REACT_APP_PUBLIC_URL.includes('dev.');

export const DEFAULT_COMPANY_TAG = DEFAULT_COMPANY_TAGS[LOCAL_ENVIRONMENT];
export const COUNTRY_NAME = getDisplayedCompanyName().toLowerCase();

const getAlgoliaBaseName = () => {
  let apiPrefix = 'api';
  if (REACT_APP_PUBLIC_URL.includes('stage.')) {
    apiPrefix = 'stage-api';
  }
  if (REACT_APP_PUBLIC_URL.includes('dev.')) {
    apiPrefix = 'dev-api';
  }

  if (!isProdEnv) {
    apiPrefix = 'stage-api';
  }

  const regionSlug = [
    'east-of-england',
    'east-midlands',
    'west-midlands',
    'south-east-england',
    'south-west-england',
  ].includes(REACT_APP_PREFIX)
    ? 'wales'
    : REACT_APP_PREFIX;

  return `${apiPrefix}_${regionSlug}`;
};

const getAlgoliaSuggestionBaseName = () => {
  let apiPrefix = 'api';

  if (REACT_APP_PUBLIC_URL.includes('stage.')) {
    apiPrefix = 'stage_api';
  }

  if (REACT_APP_PUBLIC_URL.includes('dev.')) {
    apiPrefix = 'dev_api';
  }

  return `${apiPrefix}_${COUNTRY_NAME}`;
};

export const ALGOLIA_CLIENT = algoliasearch(
  process.env.REACT_APP_ALGOLIA_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
);

export const ALGOLIA_SEARCH_INDEX = `${getAlgoliaBaseName()}_products`;
export const ALGOLIA_SEARCH_CATEGORIES_INDEX = `${getAlgoliaBaseName()}_categories`;
export const ALGOLIA_SEARCH_TRAININGS_INDEX = `${getAlgoliaBaseName()}_trainings_new`;

export const ALGOLIA_SEARCH_AUTO_SUGGESTIONS = `${getAlgoliaSuggestionBaseName()}_query_suggestions`;

export const COMPANY_NAME = getDisplayedCompanyName();

export const languagesOverridesMapLocales = {
  'en-gb': enGB,
  it: it,
  es: es,
  pt: pt,
};

export const SELF_COLLECTION = 'self-collection';
export const NOT_AVAILABLE_DELIVERY = 'not-available';
export const PER_AREA_DELIVERY = 'deliveryPerArea';
export const PER_MILE_DELIVERY = 'deliveryPerMile';
export const POSTCODE_DELIVERY = 'postcode';

export const DEFAULT_DELIVERY_METHODS = [
  NOT_AVAILABLE_DELIVERY,
  SELF_COLLECTION,
  PER_MILE_DELIVERY,
  PER_AREA_DELIVERY,
  POSTCODE_DELIVERY,
];

export const BIG_PRODUCT_CARD_FORMAT = 'big';
export const SMALL_PRODUCT_CARD_FORMAT = 'small';

export const MOBILE_SCREEN = 575;
export const SMALL_SCREEN = 767;
export const LAPTOP_SCREEN = 991;
export const DESKTOP_SCREEN = 1200;

const DEFAULT_PHONE_VALIDATION = {
  ETH: 'GB',
  EA: 'ES',
  EN: 'IT',
  EAR: 'PT',
};

export const DEFAULT_PHONE_COUNTRY =
  DEFAULT_PHONE_VALIDATION[LOCAL_ENVIRONMENT];

export const HITS_PER_PAGE = 24;

export const REACT_APP_API_HIDE_PRODUCT_CTA =
  process.env.REACT_APP_API_HIDE_PRODUCT_CTA;

const CATEGORY_IMAGES = {
  ETH: ethCategoryPicture,
  EA: eaCategoryPicture,
  EN: enCategoryPicture,
  EAR: earCategoryPicture,
};

export const categoryDefaultImageSrc = CATEGORY_IMAGES[LOCAL_ENVIRONMENT];

export const API_VERSION = process.env.REACT_APP_API_VERSION;

export const TWO_TEXTS_BANNER = [
  'Add items to your cart',
  'Provide your company name on checkout',
  'Select "pay in 90 days" as a payment method',
];

export const TWO_TEXTS_POPUP = [
  'Add to Cart: Choose your items and add them to your cart',
  'Checkout as a Business: At checkout, provide your company name',
  'Choose Payment Option: Opt for "pay in 90 days" with interest-free trade credit',
];

export const ETH_PRIVACY_POLICY_LINK =
  'https://www.iubenda.com/privacy-policy/53062915';
export const ETH_TERMS_LINK =
  'https://www.iubenda.com/terms-and-conditions/53062915';
