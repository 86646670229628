import { createEvent, createStore } from 'effector';
import { persist } from 'effector-storage/session';

export const setRegionForDisplaying = createEvent();

export const regionForDisplaying$ = createStore(null).on(
  setRegionForDisplaying,
  (_, p) => p,
);

persist({ store: regionForDisplaying$, key: 'regionForDisplaying' });
