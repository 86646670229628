import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { Lang } from 'lang';
import { getUserInfo } from 'models/user';

import { history } from 'libs/history';
import { setIsGoogleLogin, setToken } from 'api/profileApi';

import { handleLogout } from './userLogOut';

export const useHandleAdminVerification = () => {
  const { form } = Lang();

  const tryToLogInAsUser = useCallback(
    async (token) => {
      if (!token) {
        return;
      }

      try {
        setToken(token);
        setIsGoogleLogin(false);
        getUserInfo();
        history.push(PATHS.PROFILE_DASHBOARD);
      } catch (e) {
        toast.error(form.errors.wentWrong);
        handleLogout();
        history.push(PATHS.PROFILE_LOGIN);
      }
    },
    [form.errors.wentWrong],
  );

  useEffect(() => {
    const urlString = window.location.href;

    if (urlString.includes(PATHS.ADMIN_VERIFICATION)) {
      const url = new URL(urlString);
      const token = url.searchParams.get('token');
      tryToLogInAsUser(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
