import { PATHS } from 'AppPaths';
import axios from 'axios';
import { API_VERSION, LOCAL_ENVIRONMENT, REACT_APP_PREFIX } from 'consts';
import tokenEventObserver from 'utils/tokenObserver';

import { history } from 'libs/history';

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_COUNTRY_BASE_URL}/api/${API_VERSION}/${REACT_APP_PREFIX}/`,
  defaultInterceptors: true,
});

const TOKEN = `token_${LOCAL_ENVIRONMENT}`;
const LOGIN_WITH = `token_${LOCAL_ENVIRONMENT}_isGoogle`;
const EMAIL = 'email';

export const setEmail = (email) => {
  localStorage.setItem(EMAIL, email);
};

export const setToken = (token) => {
  localStorage.setItem(TOKEN, token);

  if (token) {
    instance.defaults.headers['Authorization'] = `Bearer ${token}`;
    tokenEventObserver.broadcast(true);
  }
};

export const getIsGoogleLogin = () => {
  const item = localStorage.getItem(LOGIN_WITH);
  return item ? JSON.parse(item) : false;
};

export const setIsGoogleLogin = (isGoogleLogin) => {
  localStorage.setItem(LOGIN_WITH, isGoogleLogin);
};

export const removeIsGoogleLogin = () => {
  localStorage.removeItem(LOGIN_WITH);
};

export const getEmail = () => localStorage.getItem(EMAIL);

export const getToken = () => localStorage.getItem(TOKEN);

export const haveToken = () => getToken() !== null;

export const removeToken = () => {
  localStorage.removeItem(TOKEN);
  instance.defaults.headers['Authorization'] = undefined;
  tokenEventObserver.broadcast(false);
};

if (getToken()) {
  instance.defaults.headers.common['Authorization'] =
    `Bearer ${localStorage.getItem(TOKEN)}`;
}

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      removeToken();
    }

    return Promise.reject(error);
  },
);

export const logout = () => {
  removeToken();
  history.replace(PATHS.PROFILE_LOGIN);
};

export const USER = {
  getAllOrders(params) {
    const { tenant, ...restParams } = params;
    return axios
      .create({
        baseURL: `${process.env.REACT_APP_API_COUNTRY_BASE_URL}/api/${API_VERSION}/${tenant}/`,
        defaultInterceptors: true,
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .get('user/orders', restParams);
  },
  getAllQuotes(params) {
    const { tenant, ...restParams } = params;
    return axios
      .create({
        baseURL: `${process.env.REACT_APP_API_COUNTRY_BASE_URL}/api/${API_VERSION}/${tenant}/`,
        defaultInterceptors: true,
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .get('user/quotes', restParams);
  },
  getOrder(id) {
    return instance.get(`user/orders/${id}`);
  },
  checkOrderItemsQuantity(id) {
    return instance.get(`user/orders/${id}/checkQuantity`);
  },
  edit(params) {
    return instance.put(`user/edit`, params);
  },
  upload(params) {
    return instance.post('user/uploads', params);
  },
  attachFile(params) {
    return instance.post('user/files', params);
  },
  deleteFile(id) {
    return instance.delete(`user/files/${id}`);
  },
  type(params) {
    return instance.post('user/type', params);
  },
  getAddresses() {
    return instance.get('/user/addresses');
  },
  getAddress(id) {
    return instance.get(`/user/addresses/${id}`);
  },
  createAddress(params) {
    return instance.post('user/addresses', params);
  },
  updateAddress(params, id) {
    return instance.patch(`/user/addresses/${id}`, params);
  },
  deleteAddress(id) {
    return instance.delete(`/user/addresses/${id}`);
  },
  cancelOrder(id, params) {
    return instance.post(`/user/orders/${id}/requests/cancel`, params);
  },
  changeEndDateOrder(id, params) {
    const { tenant, ...restParams } = params;

    return axios
      .create({
        baseURL: `${process.env.REACT_APP_API_COUNTRY_BASE_URL}/api/${API_VERSION}/${tenant}/`,
        defaultInterceptors: true,
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .post(`/user/orders/${id}/requests/extend`, restParams);
  },
  finishRegister({ type, subscription, token }) {
    return axios
      .create({
        baseURL: `${process.env.REACT_APP_API_COUNTRY_BASE_URL}/api/${API_VERSION}/${REACT_APP_PREFIX}/`,
        defaultInterceptors: true,
        headers: { Authorization: `Bearer ${token}` },
      })
      .post('/user/finishRegister', { type, subscription });
  },
};

export const AUTH = {
  login({ email, password }) {
    return instance.post('auth/login', {
      email,
      password,
    });
  },
  registration(data) {
    return instance.post('auth/register', {
      ...data,
    });
  },
  googleLogin({ code }) {
    return instance.post('auth/google/login', {
      code,
    });
  },
  googleRegister({ code }) {
    return instance.post('auth/google/register', {
      code,
    });
  },
  me() {
    return instance.get('auth/me');
  },
  refresh() {
    return instance.post('auth/refresh');
  },
  forgot({ email }) {
    return instance.post('auth/forgot', {
      email,
    });
  },
  reset({ password, token, email, password_confirmation }) {
    return instance.post('auth/reset', {
      password: password,
      password_confirmation,
      token,
      email,
    });
  },
  verify(rest) {
    return instance.get(`auth/email/verify/${rest}`);
  },
  resendLink() {
    const email = getEmail();
    return instance.get('auth/email/resend', { params: { email } });
  },
};
