import React from 'react';
import { Button } from 'reakit';
import clsx from 'clsx';

import { Row, T8y } from 'ui';

import { ReactComponent as Cross } from 'assets/images/icon/cart/cross.svg';
import { ReactComponent as DecreaseIcon } from 'assets/images/icon/cart/minus.svg';
import { ReactComponent as IncreaseIcon } from 'assets/images/icon/cart/plus.svg';
import { ReactComponent as SmallDecreaseIcon } from 'assets/images/icon/cart/smallMinus.svg';
import { ReactComponent as SmallIncreaseIcon } from 'assets/images/icon/cart/smallPlus.svg';

import style from './circleButton.module.scss';

export function CircleButton({
  size = 23,
  onClick,
  type = 'default',
  label,
  disable = false,
  hide,
  circleBtnClassName,
  circleBtnDisableClassName,
  ...rest
}) {
  return (
    <Button
      as={Row}
      align="center"
      onClick={onClick}
      className={clsx(
        style.wrap,
        disable && (circleBtnDisableClassName || style.disable),
        hide && style.hide,
      )}
      {...rest}
    >
      <Row
        className={circleBtnClassName || style.btn}
        align="center"
        justify="center"
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        {iconSet[type]}
      </Row>
      {label && (
        <T8y variant="t2" as="span" className={style.label}>
          {label}
        </T8y>
      )}
    </Button>
  );
}

const iconSet = {
  decrease: <DecreaseIcon />,
  increase: <IncreaseIcon />,
  smallDecrease: <SmallDecreaseIcon />,
  smallIncrease: <SmallIncreaseIcon />,
  cross: <Cross />,
  default: null,
};
