import React, { useCallback, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import { useGetProductDatesSettings, useHideChats } from 'hooks';
import { Lang } from 'lang';
import { picker_state$ } from 'models/cart';
import { currentLang$ } from 'models/language';
import DatePickerDisclosureItem from 'pages/product/MainBlock/DatesPanel/DatePickerDisclosureItem';
import { useCalendarSettings } from 'pages/product/useCalendarSettings';
import { getFormattedHireDates } from 'utils/datesСalculation';

import { DatePickerCalendar, T8y } from 'ui';

import { ReactComponent as ClearIcon } from 'assets/images/icon/closeFilterSearch.svg';

import style from './calendarModal.module.scss';

const MobileCalendarModal = ({
  cartItem,
  setIsCalendarModalOpen,
  handleDatesChange,
  daysText,
  gaEvent,
}) => {
  const { products, form } = Lang();
  const calendarWrapperRef = useRef(null);

  const state = useStore(picker_state$);
  const currentLangState = useStore(currentLang$);
  useHideChats({ isAlwaysHide: true });

  const { deliveryWeekends } = useGetProductDatesSettings();

  const {
    selectedValues,
    onDatesChanges,
    blockedDatesWithDaysMargin,
    hiringDaysCount,
    minBookingDays,
    minBookingDate,
  } = useCalendarSettings(cartItem.product, cartItem.dates);

  const handleClose = useCallback(() => {
    if (selectedValues.endDate) {
      handleDatesChange(selectedValues);
    }

    setIsCalendarModalOpen(false);

    if (gaEvent) {
      sendGAEvent(gaEvent);
    }
  }, [gaEvent, handleDatesChange, selectedValues, setIsCalendarModalOpen]);

  const { startDate, endDate } = getFormattedHireDates(
    currentLangState,
    selectedValues.startDate,
    selectedValues.endDate,
  );

  // DO NOT disable scroll on touch devices
  // https://github.com/reakit/reakit/issues/469
  useEffect(() => {
    const scrollBox = calendarWrapperRef.current;

    disableBodyScroll(scrollBox);
    return () => enableBodyScroll(scrollBox);
  }, []);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={style.calendarModalWrapper}
    >
      <div className={style.calendarModalInner}>
        <div>
          <div className={style.headerTitle}>
            <div className={style.headerTitleText}>
              <T8y variant="h2" color="light" bold>
                {form.calendar.selectHirePeriod}
              </T8y>
              <T8y color="light">{daysText}</T8y>
            </div>
            <div
              className={style.clearIconWrapper}
              onClick={handleClose}
              data-testid="calendar-close-btn"
            >
              <ClearIcon />
            </div>
          </div>
          <div className={style.headerInfo}>
            <div className={style.headerInfoFirstLine}>
              <div className={style.calendarModalTextQuantity}>
                <T8y variant="t1v1" color="newGray">
                  {products.duration}:
                </T8y>
                &nbsp;
                <T8y
                  variant="t1v1"
                  className={
                    hiringDaysCount
                      ? style.hiringDaysCountBlack
                      : style.hiringDaysCountGray
                  }
                  bold
                >
                  {hiringDaysCount}
                  &nbsp;{products[hiringDaysCount === 1 ? 'day' : 'days']}
                </T8y>
              </div>
            </div>
            <div className={style.calendarModalDateItems}>
              <DatePickerDisclosureItem
                date={!!selectedValues.startDate && startDate}
                titleSlug="hireStart"
                isInCalendar
              />
              <DatePickerDisclosureItem
                date={!!selectedValues.endDate && endDate}
                titleSlug="hireEnd"
                isInCalendar
              />
            </div>
          </div>
        </div>
        <div className={style.mobileCalendarBody} ref={calendarWrapperRef}>
          <DatePickerCalendar
            calendarState={state}
            start={selectedValues.startDate}
            end={selectedValues.endDate}
            handleDateChange={onDatesChanges}
            minBookingDate={minBookingDate}
            minBookingDays={minBookingDays}
            deliveryWeekends={deliveryWeekends}
            blockedDatesList={blockedDatesWithDaysMargin}
            edge={state?.focusedInput === 'startDate' ? 'start' : 'end'}
            numberOfMonths={12}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileCalendarModal;
