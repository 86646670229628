export const success = {
  title: 'Il tuo ordine è andato a buon fine!',
  thankYouTitle: 'Grazie per il tuo ordine!',
  thankYouTextGuest:
    'Abbiamo ricevuto il tuo ordine e ti contatteremo al più presto. Riceverai via email i dettagli del tuo ordine e un link per attivare il tuo Trade Account.',
  thankYouText:
    'Stiamo lavorando al tuo ordine e ti terremo aggiornato. Verifica la tua email e segui le istruzioni che verranno inviate.',
  pleaseVerify:
    'Per favore esegui la verifica online della tua identità e indirizzo',
  youCanAlso:
    'Puoi anche fornire  di persona un documento identificativo e uno che dimostri il tuo indirizzo.',
  additionalInfo:
    "Dopo l'attivazione, potrai usare l'account per tenere traccia del tuo ordine, modificare i dati del tuo profilo e gestire gli indirizzi di consegna direttamente dal nostro sito web.",
  goHome: 'Torna alla home',
  courseSectionTitle: 'Ricorda che offriamo anche corsi di formazione!',
  blogSectionTitle: 'Leggi il blog {0}',
  insuranceWithCreditAccountWorking:
    'We are working hard to finalise your order and will keep you updated on its progress.',
  insuranceWithCreditAccount:
    'If you do not have an {0} trade account, please check your inbox for an email that details your order and contains a credit form. Please sign the credit form and email the completed form back to the {0} team.',
};
