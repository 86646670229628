export const error = {
  header: '404 Página no encontrada',
  sorry:
    'Lo sentimos, no podemos encontrar la página que estás buscando. Es posible que aún puedas encontrarla utilizando la búsqueda, siguiendo los enlaces a continuación o visitando nuestra {0}.',
  link: 'Página de inicio',
  callOrWrite: 'No dudes en llamarnos {0} o {1}, ¡es gratis!',
  popularCategories: 'Categorías populares',
  browseFullCatalogue: 'Explora el catálogo completo',
  checkoutOurBlog: 'Visita nuestro blog',
  goToBlogHomepage: 'Ir a la página de inicio del blog',

  critical: {
    title: 'Oops... Algo ha salido mal',
    subTitle: 'Estamos trabajando para solucionar el problema!',
    text: '{0} si tienes alguna pregunta',
    sendEmail: 'Envíanos un correo electrónico\n',
    refreshAndTryAgain: 'Refresca la página e intentalo de nuevo',
    refreshThePage: 'Refrescar la página',
  },
};
