import React, { useContext } from 'react';
import { BrandingContext } from 'contexts';
import { sendClickOnPhoneIcon } from 'googleAnalytics';

import ModalWithCallSign from 'features/common/ModalWithCallSign';

import { useMatchMedia } from 'libs/useMatchMedia';

import CallSign from './CallSign';

import { T8y } from 'ui';

import style from '../header.module.scss';

const Phone = () => {
  const branding = useContext(BrandingContext);

  const isTablet = useMatchMedia('(max-width: 1280px)');

  if (!branding.companyPhoneNumberCall) {
    return <div className={style.phoneIconMockedLink} />;
  }

  if (branding.showModalWithCall) {
    return <ModalWithCallSign />;
  }

  if (isTablet) {
    return <CallSign />;
  }

  return (
    <T8y
      className={style.phoneNumberLink}
      color="light"
      as="a"
      href={`tel:${branding.companyPhoneNumberCall}`}
      onClick={() => {
        sendClickOnPhoneIcon('header');
      }}
      noWrap
    >
      {branding.companyPhoneNumber}
    </T8y>
  );
};

export default Phone;
