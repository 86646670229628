import React from 'react';

import { Spinner } from 'ui';

import style from './routeLoadingSpinner.module.scss';

const RouteLoadingSpinner = () => {
  return (
    <div className={style.routeSpinnerWrapper}>
      <Spinner dark size={5} />
    </div>
  );
};

export default RouteLoadingSpinner;
