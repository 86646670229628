export const form = {
  titles: {
    signUp: 'Inscríbete a nuestro curso de formación',
    thankYouFor: '¡Gracias por registrarte!',
    youHaveSuccessfully:
      'Tu inscripción en el curso fue exitosa. Consulta tu correo electrónico y sigue las instrucciones.',
    makeAnEnquiry: 'Contáctanos',
    makeAnEnquiryButton: 'Contáctanos',
    checkBoxIAgree:
      'Doy mi consentimiento para compartir mi información con {0}. {0} nunca compartirá tu información con terceras personas sin tu consentimiento',
    checkBoxLink: 'Ver la política de Privacidad aquí',
    checkBoxIWish:
      'Quiero ser el primero en conocer las últimas ofertas, lanzamientos de productos, artículos de blog y noticias de {0}',
    makeAnEnquiryDescription:
      'Si tienes alguna pregunta, no dudes en enviarnos una solicitud. ¡Estaremos encantados de ayudarte!',
    howWouldYouLike: '¿Cómo te gustaría ser contactado?',
    pleaseEnterDetails: 'Please enter your contact details',
  },

  optionalField: 'opcional',
  cancel: 'Cancelar',
  saveChanges: 'Guardar cambios',

  fields: {
    search: 'Buscar',
    sortBy: 'Ordenar por',
    fullName: 'Nombre y apellidos',
    emailAddress: 'Dirección de correo electrónico',
    PhoneNumber: 'Número de teléfono',

    firstName: 'Nombre',
    lastName: 'Apellidos',
    dateOfBirth: 'Fecha de nacimiento',
    birthplace: 'Lugar de nacimiento',
    address: 'Dirección',

    companyName: 'Nombre de empresa',
    country: 'País',
    vatNumber: 'IVA',
    sortCode: 'BIC/SWIFT',
    userType: 'Tipo de usuario',
    accountNumber: 'Número de cuenta',
    taxNumber: 'Número de identificación fiscal',
    city: 'Ciudad',

    newEmail: 'Nueva dirección de correo electrónico',
    oldPassword: 'Contraseña anterior',
    newPassword: 'Nueva contraseña',
    confirmNewPassword: 'Confirmar nueva contraseña',

    deliveryAddress: 'Dirección de entrega',
    message: 'Mensaje',
    yourCommentsOrQuestions: 'Comentarios o preguntas',
    privacyLink: 'Política de privacidad',
    postcode: 'Código Postal',
    resendLink: 'Reenviar enlace',
    forgotPassword: '¿Has olvidado la contraseña?',

    currentEmailAddress: 'Dirección de correo electrónico actual',
    newEmailAddress: 'Nueva dirección de correo electrónico',
    enterNewEmailAddress: 'Introduce tu correo electrónico',
    password: 'Contraseña',
    enterPassword: 'Introduce tu contraseña',
    currentPassword: 'Contraseña actual',
    repeatNewPassword: 'Repite la nueva contraseña',
    passwordConfirmation: 'Confirmación de contraseña',
    enterNewPassword: 'Introduce tu contraseña',
    repeatYourNewPassword: 'Repite tu nueva contraseña',
    enterCurrentPassword: 'Introduce tu contraseña actual',
    clear: 'Borrar',
    purchaseOrderNumber: 'Número de Pedido (Opcional)',
  },

  radio: {
    phone: 'Teléfono',
    email: 'Correo electrónico',
    chat: 'Chat / WhatsApp',
  },

  placeholders: {
    superConstruction: 'Super Construction Co.',
    italy: 'España',
    enterNewEmail: 'Ingresa un nuevo correo electrónico',
    enterOldPassword: 'Ingresa la contraseña anterior',
    enterNewPassword: 'Introduce la nueva contraseña',
    reEnterNewPassword: 'Re-introduce la nueva contraseña',
    startTyping: 'Empieza a escribir...',
    startTypingDeliveryAddress: 'Escribe la dirección de entrega...',
    enterPostalCode: 'Introduce el código postal',
    yourMessage: 'Tu mensaje',
    startTypingToFilter: 'Empieza a escribir para filtrar',
    ordersAvailable: 'pedidos disponibles',
    orderAvailable: 'Pedido disponible',
    quotesAvailable: 'presupuestos disponibles',
    quoteAvailable: 'presupuesto disponible',
    dateRange: 'Rango de fechas',
    message:
      'Agrega a tu consulta la mayor cantidad de información posible (fechas, ciudad, notas adicionales, etc)',
    yourComment: 'Tu mensaje',
    purchasePlaceholder: 'Ingrese el número de referencia del cliente',
  },

  select: {
    select: 'Selecciona',
    popular: 'Popularidad',
    priceLow: 'Precio (ascendente)',
    priceHigh: 'Precio (descendente)',
    date: 'Nuevos productos',
    depotToLocation: 'Sede a destino',
    locationToDepot: 'Destino a sede',
    bothWaysDelivery: 'Entrega ida y vuelta',
  },

  refineListing: {
    title: 'Refinar listado',
    reset: 'Reiniciar',
    power: 'Potencia de salida, kW',
    weight: 'Capacidad de ponderación, kg',
  },

  buttons: {
    signUp: 'Inscribirse',
    confirm: 'Confirmar y realizar pedido',
    nextStep: 'Próximo paso',
    back: 'Atrás',
    submitIndex: 'Enviar Solicitud',
    submitEnquiry: 'Contáctanos',
    browseSimilar: 'Ver Productos Similares',
    all: 'Todas',
    signUpNow: 'Contáctanos',
    makeReservation: 'Contáctanos',
    proceedToCheckout: 'Ir a cesta',
    viewUserGuidelines: 'Ver guía de usuario',
    continueHiring: 'Volver al catálogo',
    goToCheckout: 'Finaliza tu pedido',
    submitRequest: 'Enviar solicitud',
    viewDetails: 'Ver detalles',
  },

  calendar: {
    selectHirePeriod: 'Seleccionar período de alquiler',
    previous: 'Anterior',
    reset: 'Restablecer los valores predeterminados',
    next: 'Próximo',
    dayPlaceholder: 'DD',
    monthPlaceholder: 'MM',
    yearPlaceholder: 'AAAA',
  },

  errors: {
    cardNumber: '"Tarjeta de crédito" es un campo requerido',
    cardExpiry: '"Fecha de vencimiento" es un campo requerido',
    cardCvc: '"CVC" es un campo requerido',
    regionEmail: 'Error',
    invalidData: 'Datos inválidos',
    noTerms:
      'Debes leer y aceptar los Términos y condiciones generales para poder continuar.',
    wentWrong: 'Algo salió mal',
    wentWrongTryLater: 'Algo ha ido mal, prueba de nuevo más tarde',
    productNotAvailable: 'Este producto no está disponible',
    deliveryFromWarehouseNotAvailable:
      'Método de envío no disponible, por favor refresca la página',
  },

  validation: {
    required: 'Campo requerido*',
    requiredInOneWord: 'Obligatorio*',
    emailPattern: 'Dirección de correo errónea',
    passwordLength: 'La contraseña debe tener al menos 6 caracteres',
    passwordDifferent: 'Las contraseñas son diferentes',
    invalidCreds: 'Correo electrónico o contraseña no válidos',
    userImageUploadError: `La imagen no se puede guardar`,
    emailVerification:
      'Enlace de verificación de correo electrónico enviado en tu identificación de correo electrónico',
    minLength: 'Mín. {0} caracteres',
    phonePatter: 'Número de teléfono incorrecto',
  },

  success: {
    regionEmail: 'Tu solicitud ha sido enviada con éxito',
    thankYou: '¡Gracias por tu solicitud!',
    youHave:
      'Estamos procesando tu pedido y te contactaremos a la brevedad por teléfono o correo electrónico.',
    futureCheckout:
      'Puedes reutilizar la información ingresada en el formulario y proceder al pago.',
    checkoutDiscount: 'Para todos los cursos pagados online ofrecemos un  ',
    discountPercent: 'descuento del {0}%',
    okProceed: 'Ok, proceder al pago',
    thankYouForEnquiry: '¡Gracias por enviar tu solicitud!',
    weWillContact: 'En breve nos pondremos en contacto contigo',
  },

  successGetQuote: {
    title: 'Tu solicitud de presupuesto ha sido recibida con éxito',
    thankYouTitle: 'Gracias por solicitarnos el presupuesto',
    msg: 'Te lo hemos enviado a tu correo electrónico. Si tienes problemas, no te olvides de revisar la carpeta de promociones o spam. ¡Seguimos en contacto!',
    newCheckoutMsg:
      'Te lo acabamos de enviar por correo electrónico a la dirección que nos has proporcionado. ¡Seguimos en contacto!',
  },

  purchaseTooltip:
    'Agregue esto si su cliente desea una referencia de orden de compra asociada con el pedido. Esto aparecerá en la factura que reciba su cliente.',
};
