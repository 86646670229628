import React from 'react';
import { usePopularProductData } from 'hooks';

import SliderWithMobileOverflowWrapper from 'features/common/SliderWithMobileOverflowWrapper';

function PopularProductsSlider({
  title,
  wrapperClassName,
  additionalCardsWrapperClassName,
}) {
  const { products } = usePopularProductData('All');

  if (products.length <= 3) {
    return null;
  }

  return (
    <div className={wrapperClassName}>
      {title}
      <SliderWithMobileOverflowWrapper
        products={products}
        additionalCardsWrapperClassName={additionalCardsWrapperClassName}
        testId="popular-products"
      />
    </div>
  );
}

export default PopularProductsSlider;
