import React, { Fragment } from 'react';
import { TWO_TEXTS_POPUP } from 'consts';

import { Modal, T8y } from 'ui';

import { ReactComponent as CloseIcon } from 'assets/images/icon/closeCart.svg';
import { ReactComponent as EthLogo } from 'assets/images/icon/ethLogo.svg';
import { ReactComponent as TwoIcon } from 'assets/images/icon/twoLogo.svg';
import { ReactComponent as Multiply } from 'assets/images/icon/x.svg';

import style from './twoCheckoutInfoModal.module.scss';

const TwoCheckoutInfoModal = ({ setIsTwoModalOpen }) => {
  return (
    <Modal
      header={
        <div className={style.customHeader}>
          <div className={style.logosWrapper}>
            <EthLogo />
            <Multiply className={style.multiplyIcon} />
            <TwoIcon className={style.twoIcon} />
          </div>
          <CloseIcon
            data-testid="close-icon"
            onClick={() => setIsTwoModalOpen(false)}
          />
        </div>
      }
      setIsOpen={setIsTwoModalOpen}
      classNameHeader={style.header}
      classNameModal={style.wrapper}
      classNameContent={style.content}
      isCloseIconHidden
    >
      <T8y className={style.headerText} bold>
        Hire now, pay later with Two.
      </T8y>
      <T8y className={style.headerSubText}>
        90 days interest-free trade credit. Eligible for business only.
      </T8y>
      <div className={style.texts}>
        {TWO_TEXTS_POPUP.map((el, index) => (
          <Fragment key={index}>
            <div className={style.option}>
              <div className={style.circle}>{index + 1}</div>
              <T8y variant="t1v1">{el}</T8y>
            </div>
            {index < TWO_TEXTS_POPUP.length - 1 && (
              <div className={style.line} />
            )}
          </Fragment>
        ))}
      </div>
      <T8y variant="t4" className={style.note}>
        By checking out with Two you confirm that you have the right to purchase
        on behalf of your company. Two uses soft searches with credit reference
        agencies that do not affect your credit rating nor the credit rating of
        your business.
      </T8y>
    </Modal>
  );
};

export default TwoCheckoutInfoModal;
