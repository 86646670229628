import { useEffect } from 'react';
import { REACT_APP_PREFIX } from 'consts';
import { useStore } from 'effector-react';
import { $userInfo } from 'models/user';

export const useSendUserAttributesToHotjar = () => {
  const user = useStore($userInfo);

  useEffect(() => {
    if (user?.id && window.hj) {
      window.hj('identify', `user-${user.id}`, {
        'Region slug': REACT_APP_PREFIX,
      });
    }
  }, [user]);
};
