import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $userInfo, $userLoading, getUserInfo } from 'models/user';

import { haveToken } from 'api/profileApi';

export const useGetUserIfTokenPresents = () => {
  const user = useStore($userInfo);
  const userLoading = useStore($userLoading);

  useEffect(() => {
    if (!haveToken()) {
      return;
    }
    // TODO: check that needed
    if (!userLoading && (!user || !user.id)) {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
