import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useGetStaticHeaderHeight = () => {
  const [height, setHeight] = useState(100);

  useEffect(() => {
    try {
      const resizeObserver = new ResizeObserver(() => {
        const offsetHeight =
          document.getElementById('static-header')?.offsetHeight;
        setHeight(offsetHeight || 100);
      });
      resizeObserver.observe(document.getElementById('static-header'));

      return () => resizeObserver.disconnect();
    } catch (e) {
      console.warn(e);
      const offsetHeight =
        document.getElementById('static-header')?.offsetHeight;
      setHeight(offsetHeight || 100);
    }
  }, []);

  return height;
};
