import { useCallback, useContext, useEffect, useMemo } from 'react';
import { POSTCODE_DELIVERY } from 'consts';
import { SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { useGetIsDeliveryMethod } from 'hooks';
import * as CR from 'models/cart';
import { $isUserLoggedIn } from 'models/user';

import { CART } from 'api';

export const useTotalPriceCalculating = ({
  type,
  checkoutAs,
  insurance,
  cartFormattedData,
  deliveryMethodData,
  appliedPromoCode,
  isQuote,
}) => {
  const settings = useContext(SettingsContext);
  const isPostcodeDeliveryMethod = useGetIsDeliveryMethod(POSTCODE_DELIVERY);
  const isUserLoggedIn = useStore($isUserLoggedIn);

  const isPostcode =
    isPostcodeDeliveryMethod || settings.fillAddressByPostcodeOnly;

  const { method, address, selectedWarehouse, addressByPostcode } =
    deliveryMethodData;

  const isSelf = method === 'self';

  const deliveryData = useMemo(() => {
    if (isSelf || (isPostcode && !address.postcode)) {
      return null;
    }

    if (isPostcode) {
      return {
        lng: addressByPostcode?.lng || undefined,
        lat: addressByPostcode?.lat || undefined,
        postcode: address.postcode || undefined,
        ...(address.type && { type: address.type || undefined }),
      };
    }

    if (address.place_id) {
      return {
        lng: address.lng || undefined,
        lat: address.lat || undefined,
        ...(isPostcode && { postcode: address.postcode || undefined }),
        ...(address.type && { type: address.type }),
      };
    }

    return null;
  }, [address, addressByPostcode, isPostcode, isSelf]);

  const calculatedCheckoutAs =
    checkoutAs || isUserLoggedIn ? 'oldUser' : 'guest';

  const getTotalPrice = useCallback(
    (signal) => {
      CR.setTotalPriceLoading();

      if (!cartFormattedData || !cartFormattedData.length) {
        CR.setTotalPrice({});
        return;
      }

      CART.getTotalPrice(
        {
          ...(isSelf &&
            selectedWarehouse && {
              warehouse_id: Number(selectedWarehouse.id),
            }),
          ...(appliedPromoCode && { promo: appliedPromoCode }),
          delivery: deliveryData,
          user_type: type || undefined,
          products: cartFormattedData,
          insuranceType: insurance,
          checkoutAs: calculatedCheckoutAs,
          isQuote,
        },
        signal,
      )
        .then(({ data }) => {
          CR.setLatestTotalPricePostcode(deliveryData?.postcode || null);
          CR.setTotalPrice(data.data);
        })
        .catch((err) => {
          if (err.message !== 'canceled') {
            CR.setTotalPrice({});
          }
          console.warn(err);
        });
    },
    [
      cartFormattedData,
      isSelf,
      selectedWarehouse,
      appliedPromoCode,
      deliveryData,
      type,
      insurance,
      calculatedCheckoutAs,
      isQuote,
    ],
  );

  useEffect(() => {
    if (window.AbortController) {
      const controller = new window.AbortController();

      getTotalPrice(controller.signal);

      return () => {
        controller.abort();
      };
    } else {
      getTotalPrice();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cartFormattedData,
    selectedWarehouse,
    type,
    insurance,
    appliedPromoCode,
    calculatedCheckoutAs,
    deliveryData,
    address.place_id,
    address.postcode,
    isSelf,
  ]);
};
