export const error = {
  header: '404 Page Not Found',
  sorry:
    'We’re sorry, we can’t find the page you are looking for. You may still be able to find it using search, following the links below or visiting our {0}.',
  link: 'Homepage',
  callOrWrite: 'Don’t hesitate to call us {0} or {1} – it’s free!',
  popularCategories: 'Popular Categories',
  browseFullCatalogue: 'Browse Full Catalogue',
  checkoutOurBlog: 'Check out our Blog',
  goToBlogHomepage: 'Go to Blog Homepage',

  critical: {
    title: 'Oops... There’s something went wrong',
    subTitle: 'We’re working hard to solve the problem!',
    text: '{0} if any questions.',
    sendEmail: 'Send us an email ',
    refreshAndTryAgain: 'Please refresh the page and try again.',
    refreshThePage: 'Refresh the page',
  },
};
