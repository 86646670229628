import React, { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { useGate, useStore } from 'effector-react';
import { useGetStaticHeaderHeight, useHideChats } from 'hooks';
import CommonHeader from 'layout/Header/CommonHeader';
import MobileSearchHeader from 'layout/Header/MobileSearchHeader';
import { PopularCategoriesGate } from 'models/common';
import { RegionsGate } from 'models/regions';
import { trainingsGate } from 'models/trainings';

import { isMenuOpen$ } from './model';

import style from './header.module.scss';

function Header() {
  useGate(trainingsGate);
  useGate(RegionsGate);
  useGate(PopularCategoriesGate);

  const location = useLocation();

  const headerRef = useRef(null);
  const actualHeaderRef = useRef(null);

  const isMenuOpen = useStore(isMenuOpen$);

  const isMobileSearchPage = useMemo(
    () => location.pathname.includes(PATHS.MOBILE_SEARCH),
    [location.pathname],
  );

  useHideChats({ isAlwaysHide: isMenuOpen });
  const height = useGetStaticHeaderHeight();

  return (
    <header
      className={clsx(
        style.headerWrapper,
        isMobileSearchPage && style.mobileSearchHeader,
      )}
      style={{
        height: `${height - 1}px`,
      }}
      ref={headerRef}
    >
      <div
        ref={actualHeaderRef}
        id="static-header"
        className={style.headerContent}
      >
        {isMobileSearchPage ? (
          <MobileSearchHeader />
        ) : (
          <CommonHeader isMenuOpen={isMenuOpen} />
        )}
      </div>
    </header>
  );
}

export default Header;
