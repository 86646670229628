import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'effector-react';
import { handleLogout } from 'hooks/userLogOut';
import { Lang } from 'lang';
import { details$ } from 'models/cart';
import { $isUserLoggedIn, $userInfo, getUserInfo } from 'models/user';

import { AUTH, setEmail, setIsGoogleLogin, setToken } from 'api/profileApi';

export const useUserLoginOnCheckout = () => {
  const { profile } = Lang();

  const details = useStore(details$);
  const user = useStore($userInfo);
  const isUserLoggedIn = useStore($isUserLoggedIn);

  const [isOpenForgotPassword, setIsOpenForgotPassword] = useState(false);
  const [logInError, setLogInError] = useState(null);
  const [isLogInPending, setIsLogInPending] = useState(false);

  const { register, errors, setError, reset, trigger, getValues, watch } =
    useForm({
      reValidateMode: 'onChange',
      mode: 'onChange',
    });

  const values = watch();

  useEffect(() => {
    setLogInError(false);
  }, [values.email, values.password]);

  useEffect(() => {
    reset({ ...details });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logIn = useCallback(
    async (data) => {
      const { email, password } = data;

      setIsLogInPending(true);
      try {
        const {
          data: { access_token },
        } = await AUTH.login({ email, password });

        if (access_token) {
          setToken(access_token);
          setIsGoogleLogin(false);
          getUserInfo();
        }

        setLogInError(null);
      } catch (e) {
        if (e.response.data?.slug === 'accountIsNotActivated') {
          setEmail(email);
        }
        setLogInError({
          slug: e.response.data?.slug,
          error: e.response.data?.errors,
        });
        setError('login', {
          type: 'invalidCred',
          message: profile.validation.invalidCreds,
        });
      } finally {
        setIsLogInPending(false);
      }
    },
    [profile.validation.invalidCreds, setError],
  );

  const userName = useMemo(() => {
    if (!user.id) {
      return '';
    }

    return `${user.first_name || ''} ${user.last_name || ''}`;
  }, [user]);

  const onSubmit = useCallback(async () => {
    const isFormValid = await trigger();

    if (isFormValid) {
      const data = getValues();

      if (isUserLoggedIn) {
        handleLogout();
        return;
      }

      logIn(data);
    }
  }, [getValues, isUserLoggedIn, logIn, trigger]);

  useEffect(() => {
    if (isUserLoggedIn) {
      reset({
        email: user.email,
        password: null,
      });
      setEmail(user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  const { isNotActivatedAccount, isUserLoginError } = useMemo(
    () => ({
      isNotActivatedAccount:
        !isUserLoggedIn && logInError?.slug === 'accountIsNotActivated',
      isUserLoginError: !isUserLoggedIn && logInError,
    }),
    [isUserLoggedIn, logInError],
  );

  const showForgotPassword = useMemo(
    () => !isUserLoggedIn && !isNotActivatedAccount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNotActivatedAccount],
  );

  return useMemo(
    () => ({
      isUserLoginError,
      showForgotPassword,
      userName,
      onSubmit,
      isOpenForgotPassword,
      setIsOpenForgotPassword,
      isLogInPending,
      register,
      errors,
      isNotActivatedAccount,
      logInError,
    }),
    [
      errors,
      isLogInPending,
      isOpenForgotPassword,
      isUserLoginError,
      onSubmit,
      register,
      showForgotPassword,
      userName,
      isNotActivatedAccount,
      logInError,
    ],
  );
};
