export const carbonEmission = {
  pageTitle: 'Contrate Carbono Neutro',
  ourCommitment: {
    title: 'Nosso compromisso',
    text1:
      'Um ano após seu lançamento, a {0} decidiu trilhar o caminho para se tornar uma empresa de emissão zero, participando de projetos e soluções para as mudanças climáticas e oferecendo a possibilidade de participação a todos os seus parceiros e clientes.',
    text2:
      'Em cada aluguer compensamos as emissões de CO2 derivadas da utilização das máquinas alugadas, investindo em projetos sustentáveis. Todos sabemos que nosso compromisso com o meio ambiente não deve parar por aí, mas este é o primeiro passo para reduzir as emissões causadas por nosso negócio.',
  },

  howDoesItWork: {
    title: 'Como funciona',
    text1:
      'Estamos empenhados em obter o melhor resultado na compensação das nossas emissões de CO2. Para conseguir isso, trabalhamos em conjunto com a First Climate, líder em soluções de compensação de carbono e energia renovável. A compensação de emissões é baseada em uma contribuição financeira que pagamos para apoiar projetos de sustentabilidade de alto impacto.',
    text2:
      'Oferecemos aos clientes a possibilidade de participar da contribuição, caso contrário {0} arcará com os custos. Trabalhamos continuamente para melhorar a eficácia dos cálculos de emissão de CO2.',
    carbonEmission:
      'Compensaremos a emissão de CO2 por cada transação de aluguer',
    team: 'Você pode participar ou arcaremos com o custo total da compensação',
    environment:
      'Vamos participar de projetos ambientalmente sustentáveis ​​para ajudar a reduzir parte do nosso impacto no meio ambiente',
    analyticsData:
      'Continuaremos atualizando e melhorando nossos dados sobre emissões e do uso médio por produto/categoria.',
  },

  supportWithFirstClimate: {
    title: 'O Projeto que Apoiamos com a First Climate',
    backgroundTitle: 'Antecedentes',
    backgroundText1:
      'Desde 1990, a Indonésia perdeu quase um quarto de sua floresta. As florestas tropicais de Bornéu, como em muitas áreas tropicais, estão ameaçadas pela exploração madeireira, extração mineral e produção de óleo de palma, celulose e borracha.',
    backgroundText2:
      'O problema se agrava à medida que as áreas desmatadas facilitam o acesso a regiões anteriormente remotas da floresta. A facilidade de acesso também resultou no crescimento do comércio ilegal de animais silvestres, o que representa uma ameaça adicional na região. Estima-se que a cada minuto seja desmatada uma área equivalente à de um campo de futebol.',
    backgroundText3:
      'Só as florestas de pântanos de turfa em Bornéu contêm até 70 vezes mais carbono do que as quantidades emitidas anualmente pela queima de combustíveis fósseis em todo o mundo. Portanto, a degradação das florestas pode resultar em perda drástica dos estoques de carbono. Além disso, a área é extraordinariamente rica em biodiversidade e abriga centenas de espécies ameaçadas de extinção pela superexploração.',
    projectTitle: 'O projeto',
    projectText1:
      'A Reserva Rimba Raya está localizada no sudeste de Bornéu e se estende por quase 100 quilômetros de norte a sul ao longo das fronteiras do Parque Nacional Tanjung Puting. A reserva cobre cerca de 64.000 hectares e é dedicada à proteção e preservação de muitas espécies ameaçadas, em particular o orangotango de Bornéu, cuja população diminuiu mais de 95% no último século. Para proteger as áreas florestais, devem ser obtidos os direitos de uso das terras limítrofes das áreas protegidas. Até agora, o financiamento do projeto apóia 13 iniciativas comunitárias, desde a filtragem da água até o apoio a uma cooperativa de aquicultura de camarão.',
    projectText2:
      'Embora focados na redução das emissões de gases de efeito estufa, todos os nossos projetos também geram múltiplos cobenefícios. Estes apoiam os Objetivos de Desenvolvimento Sustentável das Nações Unidas.',
    technologyBriefTitle: 'Resumo de tecnologia - como funciona',
    technologyBriefText1:
      'O carbono circula dentro de um ciclo, que consiste na atmosfera, a planta, a serapilheira e o solo. O dióxido de carbono extraído da atmosfera circundante é o principal insumo para os processos de fotossíntese de qualquer planta. A saída resultante é água, oxigênio e carboidratos. Estes últimos são integrados à fibra da planta, fixando assim o carbono na biomassa vegetal. Em última análise, o carbono reentra na atmosfera a partir da decomposição da biomassa ou da respiração do solo.',
    technologyBriefText2:
      'O desmatamento quebra esse ciclo com múltiplos efeitos negativos. Primeiro, a queima de biomassa aumenta diretamente a quantidade de dióxido de carbono na atmosfera. Em segundo lugar, reduz a capacidade absoluta da biosfera de fixar carbono. Em terceiro lugar, a remoção da cobertura vegetal acelera a taxa na qual o carbono fixado nos solos é respirado na atmosfera. Por último, a erosão dos solos impede a recuperação a longo prazo da vegetação nas áreas degradadas. Esta é uma questão particularmente desafiadora em climas tropicais, onde os solos são pobres em nutrientes',
    projectStandardsTitle: 'Padrões do projeto',
    projectStandardsVCSboldText: 'O Padrão de Carbono Verificado (VCS)',
    projectStandardsVCSText:
      'é um padrão global para validação e verificação de reduções voluntárias de emissões de carbono. As reduções de emissões de projetos VCS devem ser reais, mensuráveis, permanentes, adicionais, únicas, transparentes e verificadas por terceiros. Avaliado em relação ao volume total de reduções de emissões, o VCS é o padrão líder global para compensações voluntárias de carbono.',
    projectStandardsCCBSboldText:
      'Os Padrões de Clima, Comunidade e Biodiversidade (CCB)',
    projectStandardsCCBSText:
      'foram lançados em 2005 para promover o desenvolvimento e o investimento em projetos locais que oferecem benefícios significativos e confiáveis para o clima, a comunidade e a biodiversidade de maneira integrada e sustentável.',
    additionalText:
      '* Os dados de cálculo de emissão são baseados em estimativas médias de categoria de produto. Todos os dias trabalhamos para melhorá-los e atualizá-los.',
  },
};
