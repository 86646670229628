import React, { useContext } from 'react';
import { BrandingContext } from 'contexts';
import { sendClickOnPhoneIcon } from 'googleAnalytics';

import { T8y } from 'ui';

import { ReactComponent as CallIcon } from 'assets/images/icon/phoneWhite.svg';

import style from '../../header.module.scss';

const CallSign = () => {
  const branding = useContext(BrandingContext);

  return (
    <T8y
      className={style.phoneIconLink}
      color="light"
      as="a"
      href={`tel:${branding.companyPhoneNumberCall}`}
      onClick={() => {
        sendClickOnPhoneIcon('header');
      }}
      noWrap
      bold
    >
      <CallIcon />
      <span className={style.hiddenNumber}>{branding.companyPhoneNumber}</span>
    </T8y>
  );
};

export default CallSign;
