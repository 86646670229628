import React, { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { useKeyUpEsc, useOnClickOutside } from 'hooks';

import { ReactComponent as CloseIcon } from 'assets/images/icon/whiteClose.svg';

import style from './modal.module.scss';

export const Modal = ({
  setIsOpen,
  header,
  classNameOverlay,
  classNameHeader,
  classNameContent,
  classNameModal,
  classNameClose,
  children,
  clickFunction,
  selectorExcludeEl,
  isCloseIconHidden,
  onCloseIconClickCallback,
  onCloseCallback,
  dataTestId,
  CustomCloseIcon,
}) => {
  const modalRef = useRef(null);

  useKeyUpEsc(() => setIsOpen(false));

  useOnClickOutside(
    modalRef,
    () => {
      if (onCloseCallback) {
        onCloseCallback();
      }

      if (clickFunction) {
        clickFunction(setIsOpen);
      } else {
        setIsOpen(false);
      }
    },
    selectorExcludeEl,
  );

  const handleCloseIconClick = useCallback(() => {
    if (onCloseCallback) {
      onCloseCallback();
    }

    if (onCloseIconClickCallback) {
      onCloseIconClickCallback();
    }

    setIsOpen(false);
  }, [onCloseCallback, onCloseIconClickCallback, setIsOpen]);

  return ReactDOM.createPortal(
    <div
      className={clsx(style.modalOverlay, classNameOverlay)}
      data-testid={dataTestId}
    >
      <div className={clsx(style.modal, classNameModal)} ref={modalRef}>
        {header && (
          <div className={clsx(style.modalHeader, classNameHeader)}>
            {header}
            {!isCloseIconHidden && (
              <button
                type="button"
                className={clsx(style.closeButton, classNameClose)}
                onClick={handleCloseIconClick}
                data-testid="closeModalBtn"
              >
                <CloseIcon />
              </button>
            )}
            {CustomCloseIcon && <CustomCloseIcon />}
          </div>
        )}
        <div className={clsx(style.modalContent, classNameContent)}>
          {children}
        </div>
      </div>
    </div>,
    document.body,
  );
};
