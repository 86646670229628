import { useContext, useMemo } from 'react';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { currentLang$ } from 'models/language';

export const useGetLocalizedBrandingField = (fieldName) => {
  const currentLangState = useStore(currentLang$);
  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const brandingData = useMemo(() => {
    return branding[fieldName];
  }, [branding, fieldName]);

  return useMemo(() => {
    if (!brandingData) {
      return undefined;
    }

    return (
      brandingData[currentLangState] || brandingData[settings.defaultLanguage]
    );
  }, [brandingData, currentLangState, settings]);
};
