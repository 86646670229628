import React, { useMemo } from 'react';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { useSectionAutoCollapsing } from 'layout/Footer/useSectionAutoCollapsing';
import { $regions } from 'models/regions';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const DesktopRegionsList = ({ handleRegionClick }) => {
  const { footer, sidebar } = Lang();

  const regions = useStore($regions);

  const disclosure = useDisclosureState();

  useSectionAutoCollapsing(disclosure);

  const regionsForRender = useMemo(
    () => regions?.filter(({ url }) => url !== null) || [],
    [regions],
  );

  const { regionsForFirstRender, restRegions } = useMemo(
    () => ({
      regionsForFirstRender: regionsForRender.slice(0, 8),
      restRegions: regionsForRender.slice(8),
    }),
    [regionsForRender],
  );

  return (
    <>
      <T8y variant="t1v1" mb2 bold className={style.title}>
        {footer.titles.supportedRegions}
      </T8y>
      <div>
        {regionsForFirstRender.map(({ name, url, state }, i) => (
          <T8y
            key={i}
            as="a"
            variant="t2v1"
            color="primary"
            target="_blank"
            href={url || '#'}
            rel="noopener noreferrer"
            onClick={(e) => handleRegionClick(e, url, state)}
            className={style.footerLink}
          >
            {name}
          </T8y>
        ))}
      </div>
      {!!restRegions.length && (
        <>
          <DisclosureContent {...disclosure} className={style.restTrainings}>
            {restRegions.map(({ name, url, state }, i) => (
              <T8y
                key={i}
                as="a"
                variant="t2v1"
                color="primary"
                target="_blank"
                href={url || '#'}
                rel="noopener noreferrer"
                onClick={(e) => handleRegionClick(e, url, state)}
                className={style.footerLink}
              >
                {name}
              </T8y>
            ))}
          </DisclosureContent>
          <Disclosure {...disclosure}>
            <T8y
              variant="t2v1"
              color="primary"
              mt2
              pointer
              className={style.footerLink}
            >
              {disclosure.visible ? sidebar.showLess : sidebar.showMore}...
            </T8y>
          </Disclosure>
        </>
      )}
    </>
  );
};

export default DesktopRegionsList;
