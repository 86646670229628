export const search = {
  searchResultFor: 'Risultati della ricerca',
  searchResults: 'Risultati della ricerca',
  nothingFound:
    'Non è stato trovato nessun prodotto. Consulta il nostro {0}, modifica la ricerca o contattaci usando il modulo di seguito',
  noItems: 'Nessun articolo da visualizzare',
  searchHistory: 'Cronologia di ricerca',
  popularCategories: 'Categorie popolari',
  noResultsFound: 'Ci dispiace, non abbiamo trovato corrispondenze',
  searchResultForJobType: `Risultati della ricerca per il progetto "{0}"`,
};
