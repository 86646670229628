import { getProductsForGoogleAnalytics } from 'utils/formatProducts';
import { getSelectionText } from 'utils/helpers/getSelection';

import { history } from 'libs/history';

export const addProductToReservation = (products, currencyCode) => {
  window.dataLayer = window.dataLayer || [];
  if (products && products.length) {
    window.dataLayer.push({
      event: 'ee-add-to-cart',
      ecommerce: {
        currencyCode,
        add: {
          products: getProductsForGoogleAnalytics(products),
        },
      },
    });
  }
};

export const sendViewProductPageContent = (
  productsForGoogleAnalytics,
  currencyCode,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ViewContent',
    ecommerce: {
      currencyCode,
      add: {
        products: productsForGoogleAnalytics,
      },
    },
  });
};

export const sendCheckoutEvent = (
  event,
  step,
  productsForGoogleAnalytics,
  currencyCode,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ecommerce: {
      currencyCode,
      add: {
        products: productsForGoogleAnalytics,
      },
      checkout: {
        actionField: { step },
        products: productsForGoogleAnalytics,
      },
    },
  });
};

export const sendPageView = (pageName, companyName = '') => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'virtual-pageview',
    virtualPagePath: history?.location?.pathname,
    virtualPageName: `${pageName} | ${companyName} | VirtualPageview`,
  });
};

export const sendGAEvent = (event, additionalFields) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...(additionalFields && {
      ...additionalFields,
    }),
  });
};

export const sendEventWithEventLabel = (event, label) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    eventLabel: label,
  });
};

export const sendEventWithElementLocation = (
  event,
  elementLocation,
  additionalFields,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    elementLocation,
    ...(additionalFields && {
      ...additionalFields,
    }),
  });
};

export const sendClickOnPhoneIcon = (elementLocation) => {
  sendEventWithElementLocation('phone number click', elementLocation);
};

export const sendEventWithCustomField = (event, fieldName, value) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event, [fieldName]: value });
};

export const sendUserClickedOnDefaultResult = (event, result) => {
  sendEventWithCustomField(event, 'selected field', result);
};

export const sendCopyToClipboard = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'textCopied',
    clipboardText: getSelectionText(),
  });
};
