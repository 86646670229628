import { REACT_APP_PREFIX } from 'consts';

import { LEADS } from 'api';

export const createLead = async (
  data,
  attributes,
  type,
  source,
  successCallback,
  wentWrongCallback,
) => {
  try {
    const res = await LEADS.createColdLead({
      ...data,
      lead_type: type,
      page_url: window.location.href,
      full_name: data.name,
      phone: data.phone || undefined,
      company_name: data.company_name || undefined,
      email: data.email || undefined,
      message: data.message || undefined,
      subscription: data.subscription,
      source: source || undefined,
      attributes,
    });

    if (res?.data?.lead_id && window.hj) {
      window.hj('identify', `${type}-${res.data.lead_id}`, {
        'Region slug': REACT_APP_PREFIX,
      });
    }

    if (successCallback) {
      successCallback(res.data);
    }
  } catch (e) {
    console.warn(e);
    if (wentWrongCallback) {
      wentWrongCallback();
    }
  }
};
