import { useMemo } from 'react';

export const useGetExtraFieldsValuesFromUser = (
  extraFields = [],
  user = {},
) => {
  return useMemo(() => {
    if (!user.first_name) {
      return {};
    }
    return extraFields.reduce(
      (acc, el) => {
        if (el.type === 'company') {
          acc.companyExtraFields[el.name] =
            user.company_details?.[el.name] ||
            user.address?.[el.name] ||
            user[el.name] ||
            null;
          return acc;
        }

        if (el.type === 'individual') {
          acc.individualExtraFields[el.name] =
            user.address?.[el.name] || user[el.name] || null;
          return acc;
        }

        acc.commonExtraFields[el.name] =
          user.address?.[el.name] || user[el.name] || null;
        return acc;
      },
      {
        companyExtraFields: {},
        commonExtraFields: {},
        individualExtraFields: {},
      },
    );
  }, [user, extraFields]);
};
