import { useContext, useMemo } from 'react';
import { DEFAULT_DELIVERY_METHODS } from 'consts';
import { SettingsContext } from 'contexts';

export const useGetIsDeliveryMethod = (type) => {
  const settings = useContext(SettingsContext);

  return useMemo(
    () => DEFAULT_DELIVERY_METHODS[settings.defaultDeliveryMethodType] === type,
    [settings, type],
  );
};
