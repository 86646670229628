import React, { useCallback, useContext, useMemo } from 'react';
import { PATHS } from 'AppPaths';
import { isEAR, isEN, isETH } from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel } from 'googleAnalytics';
import { Lang } from 'lang';
import MultipleLinksMenuWrapper from 'layout/Header/MultipleLinksMenuWrapper';
import { currentLang$ } from 'models/language';
import { isWarehouses$ } from 'models/warehouses';

const Company = ({ setIsSubMenuOpen }) => {
  const { formatString, header, depots } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const isWarehouses = useStore(isWarehouses$);

  const currentLangState = useStore(currentLang$);

  const linksList = useMemo(() => {
    const initialLinks = [
      {
        path: PATHS.ABOUT,
        title: formatString(
          header.nav.findHowWeAre,
          branding.companyName || '',
        ),
        isCapitalize: !isETH && !isEAR,
      },
      {
        href: branding.blogLink,
        title: header.nav.blog,
        isCapitalize: true,
        isExternal: true,
      },
      {
        href: branding.faqLink?.[currentLangState],
        title: header.nav.faq,
        isExternal: true,
      },
      {
        path: PATHS.CARBON_EMISSION,
        title: header.nav.carbonOffsetting,
        isCapitalize: true,
      },
    ];

    if (isWarehouses) {
      initialLinks.splice(1, 0, {
        path: PATHS.DEPOTS,
        title: formatString(depots.title, ''),
        isCapitalize: !isEN,
      });
    }

    if (settings.showMaintenancePage) {
      initialLinks.splice(2, 0, {
        path: PATHS.MAINTENANCE,
        title: header.nav.maintenance,
        isCapitalize: true,
      });
    }

    return initialLinks;
  }, [
    branding,
    currentLangState,
    formatString,
    header,
    settings,
    depots,
    isWarehouses,
  ]);

  const handleLinkClicked = useCallback((itemTitle) => {
    sendEventWithEventLabel('regional_page_header_about', itemTitle);
  }, []);

  return (
    <MultipleLinksMenuWrapper
      setIsSubMenuOpen={setIsSubMenuOpen}
      linksList={linksList}
      onClickCallback={handleLinkClicked}
    />
  );
};

export default Company;
