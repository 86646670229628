import { useCallback, useMemo, useState } from 'react';
import { sendGAEvent } from 'googleAnalytics';

export const useGetExtrasActions = (
  optionalExtras,
  reqExtras,
  consumables,
  setExtras,
  setIsOpen,
) => {
  const [actualOptionalExtras, setActualOptionalExtras] = useState(
    JSON.parse(JSON.stringify(optionalExtras)),
  );
  const [actualReqExtras, setActualReqExtras] = useState(
    JSON.parse(JSON.stringify(reqExtras)),
  );
  const [actualConsumables, setActualConsumables] = useState(
    JSON.parse(JSON.stringify(consumables)),
  );

  const handleChangeExtras = useCallback(
    (value) => {
      sendGAEvent('extras-apply-button');

      const { optionalSaleExtra, optionalExtra } =
        value === 'skip'
          ? { optionalSaleExtra: [], optionalExtra: [] }
          : actualOptionalExtras.reduce(
              (acc, el) => {
                if (el.quantity) {
                  if (el.slug === 'optional-sale-extra') {
                    acc.optionalSaleExtra.push(el);
                  } else {
                    acc.optionalExtra.push(el);
                  }
                }
                return acc;
              },
              { optionalSaleExtra: [], optionalExtra: [] },
            );
      setExtras({
        optionalExtra,
        optionalSaleExtra,
        reqExtra: actualReqExtras,
        consumables: actualConsumables,
      });
      setIsOpen(false);
    },
    [
      actualConsumables,
      actualOptionalExtras,
      actualReqExtras,
      setExtras,
      setIsOpen,
    ],
  );

  const changeAttributes = useCallback(
    (extras, type, val, index) => {
      const newAttributes = [...extras];
      newAttributes[index].quantity = val;

      if (type === 'consumables') {
        setActualConsumables(newAttributes);
        return;
      }
      type === 'reqExtra'
        ? setActualReqExtras(newAttributes)
        : setActualOptionalExtras(newAttributes);
    },
    [setActualConsumables, setActualReqExtras, setActualOptionalExtras],
  );

  return useMemo(
    () => ({
      actualConsumables,
      actualOptionalExtras,
      actualReqExtras,
      changeAttributes,
      handleChangeExtras,
    }),
    [
      actualConsumables,
      actualOptionalExtras,
      actualReqExtras,
      changeAttributes,
      handleChangeExtras,
    ],
  );
};
