export const orders = {
  order: 'Pedido',
  orders: 'Pedidos',
  viewOrder: 'Ver pedido',
  viewInvoice: 'Ver factura',
  viewQuote: 'Ver presupuesto',
  viewQuotes: 'View quotes',
  productList: 'Lista de productos',
  youWantToCancelThisOrder: '¿Estás seguro de querer cancelar este pedido',
  provideCancellationReason:
    'Si es así, por favor explica el motivo de la cancelación',
  enterNewEndHireDateAndReason:
    'Por favor, elige una nueva fecha de alquiler y dinos el motivo de este cambio. ¡Te contactaremos a la brevedad!',
  endHireDate: 'Nueva fecha de finalización del alquiler',
  provideReasonForThisChange: 'Motivo del cambio',

  item: {
    orderStatus: 'Estado del pedido',
    date: 'Fecha de creación',
    transport: 'Transporte',
    paymentType: 'Método de pago',
    productList: 'Lista de productos',
    documents: 'Documentos',
  },

  table: {
    orderId: 'ID del pedido',
    orderNumber: 'Número de pedido',
    quoteNumber: 'Número de presupuesto',
    invoiceDate: 'Fecha de facturación',
    status: 'Estado',
    payment: 'Pago ',
    startDate: 'Inicio del alquiler',
    endDate: 'Finalización del alquiler',
    purchaseOrderNumber: 'Número de Pedido',
  },

  paymentType: {
    card: 'Tarjeta de crédito',
    cash: 'Efectivo',
    bank: 'Transferencia bancaria',
    other: 'Otro',
  },

  statusType: {
    error: 'Error',
    paid: 'Pagado',
    not_paid: 'No pagado',
    new: 'Nuevo',
    in_progress: 'En curso',
    completed: 'Completado',
    cancelled: 'Cancelado',
    all: 'Todos los pedidos',
  },

  backTo: 'Volver a la lista de pedidos',
  backToQuotes: 'Volver al listado de presupuestos',
};
