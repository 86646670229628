export const sidebar = {
  yourReservation: 'Your Reservation',
  yourQuote: 'Your Quote',
  costSummary: 'Cost Summary',
  proceed: 'Proceed',
  estimatePerWeek: 'Cost Summary',
  damageWaiver: 'Damage waiver',
  subtotal: 'Subtotal',
  transport: 'Transport',
  delivery: 'Delivery (Charge)',
  collection: 'Collection',
  selfCollection: 'Self-collect',
  vat: 'Vat',
  total: 'Total',
  totalForCourse: 'Total',
  totalExclVat: 'Total (excl. VAT)',
  deposit: 'Deposit',
  gasTankDeposit: 'Gas Tank Deposit',
  iAgree: 'I agree to the',
  free: 'To be agreed',
  insurance: 'Insurance',
  tbd: 'TBC',
  gratis: 'Free',
  safetyFee: 'Safety Fee',
  sanitationFee: 'Sanitation Fee',
  adminFee: 'Admin Fee',
  slightDamageWaiverFee: 'Slight Damage Waiver',
  grandTotal: 'Grand Total',
  grandTotalProductCheckout: 'Grand Total',
  invoice: 'Invoice',
  logisticAdditionalText:
    'Collection on site: free.\n ' +
    'Home delivery: the cost is negotiated based on the distance from the office.',
  logisticAdditionalTextOnlySelfCollection:
    'Self-Collection: free.\n ' +
    'Delivery: Delivery is not available for this product.',
  logisticAdditionalTextOnlyDelivery:
    'Self-Collection option is not available for this product.\n ' +
    'Home delivery: the cost is negotiated based on the distance from the office.',
  logisticAdditionalTextMixedDeliveries:
    'Self-Collection: free.\n ' +
    'Home delivery: the cost is negotiated based on the distance from the office.',
  depositAdditionalText:
    ' Deposit not charged at this time. \n ' +
    'Loss & damage deposit will be charged three days before confirmed start date and returned to your card upon safe return of the inventory',
  promoCodeAdditionalText: 'Congrats! Your discount is {0}% \n Promo Code: {1}',
  maxDiscount: 'Max Discount',
  page: 'Page',
  expand: 'Expand',
  collapse: 'Collapse',
  products: 'Products',
  showMore: 'Show more',
  showLess: 'Show less',
  hide: 'Hide',
  discount: 'Discount',
  courseCost: 'Course cost',
  refundableDepositWarning:
    'A refundable deposit will be blocked for this product.',
  depositMayBeApplied:
    'A refundable deposit may be applied based on the grand total of your order.',
  clickAddToReservation:
    'Click Add to Reservation and proceed to Checkout to view a full Cost Summary.',
  itemsToHire: 'Items to hire',
  tradeAccountDiscount: 'Trade Account Discount',
};
