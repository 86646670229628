import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { currentLang$ } from 'models/language';
import {
  getNearestDateIndex,
  getTrainingFormatDate,
} from 'utils/datesСalculation';

const emptyData = {
  closestDate: null,
  locationNames: [],
  locationAddresses: [],
  price: null,
  organizer: null,
};

export const useClosestTrainingDate = (item) => {
  const currentLangState = useStore(currentLang$);

  return useMemo(() => {
    if (!item) {
      return {
        ...emptyData,
      };
    }

    if (item.scheduleType === 0 && item.schedules) {
      const now = Date.now();
      const datesLocationsList = item.schedules.reduce((acc, location) => {
        return [
          ...acc,
          ...location.variations
            .filter((el) => el.start && new Date(el.start).getTime() > now)
            .map((el) => ({
              date: el.start,
              locationId: location.id,
              dateId: el.id,
              ...el,
            })),
        ];
      }, []);

      const nearestDateIndex = getNearestDateIndex(datesLocationsList);

      if (isNaN(nearestDateIndex)) {
        const activeLocations = item.schedules.filter(
          (el) => el.variationsType === 1 && el.location?.placeId,
        );

        return {
          closestDate: null,
          locationNames: activeLocations.map((el) => ({
            address: el.location.name,
            city: el.location.city,
          })),
          locationAddresses: activeLocations.map((el) => ({
            lat: el.location.lat,
            lng: el.location.lng,
            ...el.location,
          })),
          price:
            activeLocations.length === 1 &&
            activeLocations[0].variations.length === 1
              ? activeLocations[0].variations[0].price
              : null,
          organizer:
            activeLocations.length === 1 ? activeLocations[0].organizer : null,
        };
      }
      const locationId = datesLocationsList[nearestDateIndex].locationId;
      const nearestDateId = datesLocationsList[nearestDateIndex]?.dateId;

      const locationInfo = item.schedules.find((el) => el.id === locationId);
      const datesInfo = locationInfo?.variations.find(
        (el) => el.id === nearestDateId,
      );

      const price = datesInfo.price;
      const closestDate = getTrainingFormatDate(
        currentLangState,
        datesInfo.start,
        datesInfo.end,
      );
      const location = locationInfo?.location;

      return {
        closestDate,
        locationNames: [{ address: location?.name }],
        locationAddresses: [
          { lat: location?.lat, lng: location?.lng, ...location },
        ],
        organizer: locationInfo?.organizer,
        price,
      };
    } else {
      const activeLocations =
        item.noSchedule?.locations &&
        !!item.noSchedule.locations.filter((el) => !!el.placeId).length;

      return {
        closestDate: null,
        locationNames: activeLocations
          ? item.noSchedule.locations.map((el) => ({
              address: el.name,
              city: el.city,
            }))
          : [],
        locationAddresses: activeLocations
          ? item.noSchedule.locations.map((el) => ({
              lat: el.lat,
              lng: el.lng,
              ...el,
            }))
          : [],
        price:
          item.noSchedule?.variations?.length === 1
            ? item.noSchedule.variations[0].price
            : null,
        organizer: item.noSchedule?.organizer,
      };
    }
  }, [item, currentLangState]);
};
