import React, { useMemo } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en';
import es from 'react-phone-number-input/locale/es';
import it from 'react-phone-number-input/locale/it';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { phoneMaskCountry$, setPhoneMaskCountry } from 'models/common';

import 'react-phone-number-input/style.css';
import style from './phoneInputWithCountry.module.scss';

const PhoneInputWithCountryInForm = ({
  className,
  placeholder,
  value,
  setValue,
  control,
  name,
  ...rest
}) => {
  const { formatString, form } = Lang();

  const phoneMaskCountry = useStore(phoneMaskCountry$);

  const labels = useMemo(() => {
    switch (phoneMaskCountry) {
      case 'GB':
        return en;
      case 'ES':
        return es;
      case 'IT':
        return it;
      default:
        return en;
    }
  }, [phoneMaskCountry]);

  return (
    <PhoneInputWithCountry
      name={name || 'phone'}
      rules={{
        validate: {
          minLength: (value) =>
            value?.trim().length > 5 ||
            formatString(form.validation.minLength, 6),
          rightLength: (value) => {
            return isPossiblePhoneNumber(value) || form.validation.phonePatter;
          },
        },
      }}
      defaultValue={value}
      control={control}
      type="tel"
      defaultCountry={phoneMaskCountry}
      labels={labels}
      value={value}
      onChange={setValue}
      placeholder={placeholder}
      autoComplete="shipping tel"
      onCountryChange={setPhoneMaskCountry}
      className={clsx(style.phoneCountryInput, className)}
      {...rest}
      international
      limitMaxLength
    />
  );
};

export default PhoneInputWithCountryInForm;
