//https://www.codemzy.com/blog/fix-chunkloaderror-react
// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
          return window.location.reload();
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
