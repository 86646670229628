export const main = {
  h1: 'Alquiler de herramientas y maquinaria fácil y eficiente en España',
  h2: 'Nuestra misión es hacer que el alquiler de herramientas y maquinaria sea fácil y accesible para todos. Encuentra lo que buscas para un proyecto de paisajismo, un trabajo de renovación y más ',

  howItWorks: {
    title: 'Cómo funciona',
    sections: [
      {
        title: '1. Elige Productos',
        description: 'Mira el catálogo y elige',
      },
      {
        title: '2. Revisa tu reserva ',
        description: 'Refina tu lista, consulta el precio ',
      },
      {
        title: '3. Realiza el pago en 4 sencillos pasos',
        description: 'Especifica los detalles de entrega y pago',
      },
    ],
  },

  subscribeToNewsletter: {
    stayUpdated: 'Suscríbete a nuestra Newsletter',
    enterYourEmail:
      'Suscríbete a la newsletter de easyAlquiler para recibir las últimas noticias y ofertas de la industria.',
    agreementText:
      'Al enviar sus datos, acepta que {0} se comunique con usted de acuerdo con nuestra {1}',
    linkText: 'Política de privacidad',
    thankYouForSubscribing: '¡Gracias por suscribirte a nuestra newsletter!',
    signUp: 'Suscríbete',
  },

  titles: {
    eventTypes: 'Tipos de eventos ',
    certifiedPartner: '{0} es un socio certificado',
    proudToWork: 'Algunos de nuestros clientes',
    familyOfBrands: 'Parte de la familia de marcas easy',
    popularProducts: 'Productos populares',
    frequentlyHired: 'Contratados juntos con frecuencia',
    easyAndBudget:
      'El servicio de alquiler de herramientas sencillo y eficaz en España',
    ourMission:
      'Nuestra misión es hacer que el proceso de alquiler de herramientas sea fluido, "easy" y accesible para todos. Encuentra y contrata lo que necesitas para cualquier tipo de proyecto, desde renovación hasta mantenimiento, jardinería y mucho más.',
    topCategories: 'Alquilar Categorías Principales',
    hireByCategory: 'Alquiler por Categoría',
    recentlyViewedProducts: 'Visto recientemente',
  },

  notices: {
    noPopularProduct: 'No hay productos populares para este paquete',
    newArrivals: 'Nuevos productos',
  },
};
