import { useEffect } from 'react';
import { getValue, setValue } from 'utils/localStorage';

import { REACT_APP_PREFIX } from '../consts';

export const useDefineRegionLocalStore = () => {
  useEffect(() => {
    const referralRegion = getValue('referralRegion');

    if (!referralRegion || referralRegion !== REACT_APP_PREFIX) {
      setValue('referralRegion', REACT_APP_PREFIX);
    }
  }, []);
};
