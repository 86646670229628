import { createEffect, createStore, restore } from 'effector';
import { createGate } from 'effector-react';

export const getSettingsFx = createEffect();

export const settings$ = createStore({ extraFieldsCheckout: [] }).on(
  getSettingsFx.doneData,
  (_, p) => {
    const dateWithTimezoneFromSettings = new Date(
      new Date().toLocaleDateString('en-US', { timeZone: p?.regionTimezone }),
    );

    const currYear = dateWithTimezoneFromSettings.getFullYear();
    const nextYear = new Date(
      dateWithTimezoneFromSettings.setFullYear(
        dateWithTimezoneFromSettings.getFullYear() + 1,
      ),
    ).getFullYear();

    const newBlockedDates = [];

    if (p?.blockedDatesList) {
      p.blockedDatesList.forEach((el) => {
        newBlockedDates.push(`${currYear}-${el.date}`);
        newBlockedDates.push(`${nextYear}-${el.date}`);
      });
    }

    return {
      ...p,
      blockedDatesList: newBlockedDates,
    };
  },
);

export const isSettingsRequestFinished$ = createStore(false).on(
  getSettingsFx.done,
  (_) => true,
);

export const isSettingsLoading$ = restore(getSettingsFx.pending.updates, false);

export const settingsLoaded$ = createStore(false).on(
  getSettingsFx.doneData,
  (_) => true,
);

export const faqsTAGate = createGate('faqsTA');
export const getFaqsTAFx = createEffect();

export const isFaqsTALoading$ = getFaqsTAFx.pending.map((state) => !state);

export const faqsTA$ = createStore([]).on(getFaqsTAFx.doneData, (_, p) => p);
