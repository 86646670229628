import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { POSTCODE_DELIVERY } from 'consts';
import { SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { useGetIsDeliveryMethod } from 'hooks/useGetIsDeliveryMethod';
import { Lang } from 'lang';
import {
  addItemToCart,
  changeDelivery,
  changeDetails,
  getFakeOrderFx,
  resetCart,
} from 'models/cart';
import { settingsLoaded$ } from 'models/settings';
import { $isUserLoggedIn } from 'models/user';
import { getDateInFormat } from 'utils/datesСalculation';
import { getQueryParam } from 'utils/formatStrings';

import { history } from 'libs/history';
import { PRODUCTS } from 'api';

export const useGetFakeOrder = (location) => {
  const { form } = Lang();

  const settings = useContext(SettingsContext);
  const isPostcodeDeliveryMethod = useGetIsDeliveryMethod(POSTCODE_DELIVERY);

  const settingsLoaded = useStore(settingsLoaded$);
  const isUserLoggedIn = useStore($isUserLoggedIn);

  const [isLoadingOrderDetail, setIsLoadingOrderDetail] = useState(false);
  const [emailDetails, setEmailDetails] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);

  const isPostcode = useMemo(() => {
    return isPostcodeDeliveryMethod || settings.fillAddressByPostcodeOnly;
  }, [isPostcodeDeliveryMethod, settings.fillAddressByPostcodeOnly]);

  const id = useMemo(() => {
    return getQueryParam(location.search, 'id');
  }, [location.search]);

  const mode = useMemo(() => {
    return getQueryParam(location.search, 'mode');
  }, [location.search]);

  const prefilledDetailsInfo = useCallback(() => {
    const deliveryAddresses = emailDetails.delivery?.name
      ? emailDetails.delivery.name.split(',')
      : [];

    changeDelivery({
      method: emailDetails.delivery_method === 'delivery' ? 'delivery' : 'self',
      isAvailable: true,
      ...(emailDetails.warehouse_id && {
        warehouse: {
          id: emailDetails.warehouse_id,
        },
        address: {
          place_id: null,
          name: null,
          lat: null,
          lng: null,
          postcode: null,
          type: null,
        },
        addressByPostcode: {
          place_id: null,
          name: null,
          lat: null,
          lng: null,
          addressLine1: null,
          addressLine2: null,
          town: null,
          country: null,
        },
      }),
      ...(!emailDetails.warehouse_id && {
        warehouse: null,
        ...(isPostcode
          ? {
              address: {
                place_id: null,
                name: null,
                lat: null,
                lng: null,
                postcode: emailDetails.delivery.postcode,
                type: emailDetails.delivery.type,
              },
              addressByPostcode: {
                place_id: emailDetails.delivery.place_id,
                name: emailDetails.delivery.name,
                lat: emailDetails.delivery.lat,
                lng: emailDetails.delivery.lng,
                addressLine1: deliveryAddresses[0],
                addressLine2: deliveryAddresses[1],
                town: deliveryAddresses[2],
                postcode: deliveryAddresses[3],
                country: deliveryAddresses[4],
              },
            }
          : {
              address: {
                place_id: emailDetails.delivery.place_id,
                name: emailDetails.delivery.name,
                lat: emailDetails.delivery.lat,
                lng: emailDetails.delivery.lng,
                postcode: emailDetails.delivery.postcode,
                type: emailDetails.delivery.type,
              },
              addressByPostcode: {
                place_id: null,
                name: null,
                lat: null,
                lng: null,
                addressLine1: null,
                addressLine2: null,
                town: null,
                country: null,
              },
            }),
      }),
    });

    changeDetails({
      id: isUserLoggedIn ? emailDetails.user.id : null,
      checkoutAs: isUserLoggedIn ? 'oldUser' : 'guest',
      name: emailDetails.user.name,
      phone: emailDetails.user.phone,
      paymentMethod: null,
      type: emailDetails.user.type,
      company_name: emailDetails.user.company,
      company_id: emailDetails.user.company_id,
      insurance: null,
      ...emailDetails.user,
    });
  }, [emailDetails, isPostcode, isUserLoggedIn]);

  const fakeOrder = useCallback(async () => {
    if (id && settingsLoaded) {
      setIsLoadingOrderDetail(true);

      try {
        const { products, details } = await getFakeOrderFx(id);

        resetCart();

        for (const item of products) {
          const { extra } = item;

          const { data: productFullInfo } = await PRODUCTS.getProduct(
            item.slug,
          );

          const attributes = productFullInfo.data?.attributes;

          const optionalExtra = extra.filter(
            (el) => el.slug === 'optional-extra',
          );
          const optionalSaleExtra = extra.filter(
            (el) => el.slug === 'optional-sale-extra',
          );
          const reqExtra = extra.filter((el) => el.slug === 'required-extra');

          const consumables = extra.filter((el) => el.slug === 'consumables');

          addItemToCart({
            id: item.id,
            count: item.quantity,
            product: { ...item, attributes },
            optionalExtra,
            optionalSaleExtra,
            consumables,
            reqExtra,
            dates: {
              start: getDateInFormat(new Date(item.start_date), 'dd/MM/yyyy'),
              end: getDateInFormat(new Date(item.end_date), 'dd/MM/yyyy'),
            },
          });
        }

        setEmailDetails(details || null);

        if (mode === 'checkout') {
          history.replace(PATHS.RESERVATION_CHECKOUT);
        }
      } catch (e) {
        if (e.response?.status === 404) {
          setIsNotFound(true);
        } else {
          toast.error(form.errors.wentWrong);
        }
      } finally {
        setIsLoadingOrderDetail(false);
      }
    }
  }, [id, settingsLoaded, form.errors.wentWrong, mode]);

  useEffect(() => {
    fakeOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, settingsLoaded]);

  useEffect(() => {
    if (emailDetails) {
      prefilledDetailsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailDetails]);

  return useMemo(
    () => ({ isLoadingOrderDetail, isNotFound }),
    [isLoadingOrderDetail, isNotFound],
  );
};
