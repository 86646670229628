import React, { useCallback, useState } from 'react';
import { Lang } from 'lang';

import CheckoutInputField from 'features/common/CheckoutInputField';
import GoogleMapsWrapper from 'features/common/GoogleMapsWrapper';

import { Autocomplete } from '@react-google-maps/api';

import style from './locationSearchInput.module.scss';

const FIELDS = [
  'place_id',
  'geometry',
  'name',
  'formatted_address',
  'address_components',
];

export const LocationSearchInput = ({
  addressName,
  additionalClass,
  setAddress,
  handleChangeStr,
  innerRef,
  inputName,
  error,
  dataTestId,
  placeholder,
  title,
  isWholeSectionDisabled,
}) => {
  const { form } = Lang();

  const [autocomplete, setAutocomplete] = useState();

  const getPostcode = useCallback((address_components) => {
    for (let i = 0; i < address_components.length; i++) {
      for (let j = 0; j < address_components[i].types.length; j++) {
        if (address_components[i].types[j] === 'postal_code') {
          return address_components[i].long_name;
        }
      }
    }
    return null;
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (!autocomplete) {
      setAddress();
      return;
    }

    const currPlace = autocomplete.getPlace();

    let postcode = null;

    if (currPlace.address_components) {
      postcode = getPostcode(currPlace.address_components);
    }

    if (currPlace.geometry !== undefined) {
      setAddress(currPlace, postcode);
    }
  }, [autocomplete, getPostcode, setAddress]);

  const onLoad = useCallback((autocomplete) => {
    setAutocomplete(autocomplete);
  }, []);

  return (
    <GoogleMapsWrapper>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        fields={FIELDS}
        className={style.locationWrapper}
      >
        <CheckoutInputField
          additionalClass={additionalClass}
          placeholder={placeholder || form.placeholders.startTyping}
          title={title}
          onChange={handleChangeStr}
          defaultValue={addressName}
          innerRef={innerRef}
          errorText={error}
          inputName={inputName}
          data-testid={dataTestId}
          disabled={isWholeSectionDisabled}
        />
      </Autocomplete>
    </GoogleMapsWrapper>
  );
};
