import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { useBreadCrumbs } from 'hooks';
import {
  currentCategory$,
  currentCategoryGrandParent$,
  currentCategoryParent$,
} from 'models/product';

import { Row, Spinner } from 'ui';

import style from './categoryProductTemplate.module.scss';

const CategoryProductTemplate = () => {
  const categoryParent = useStore(currentCategoryParent$);
  const categoryGrandParent = useStore(currentCategoryGrandParent$);
  const currentCategory = useStore(currentCategory$);

  const breadCrumbs = useMemo(
    () => ({
      products: true,
      categoryGrandParent,
      categoryParent,
      currentCategory,
    }),
    [currentCategory, categoryGrandParent, categoryParent],
  );

  useBreadCrumbs(breadCrumbs);

  return (
    <Row noWrap>
      <div className={style.commonContainer}>
        <Spinner dark size={5} margin="30px 0 0" />
      </div>
    </Row>
  );
};

export default CategoryProductTemplate;
