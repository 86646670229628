import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { SMALL_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import {
  sendEventWithCustomField,
  sendEventWithEventLabel,
} from 'googleAnalytics';
import { useHideChats, useWindowResize } from 'hooks';
import { changeSearchStr, changeSearchStrFromUrl } from 'models/filter';
import {
  isLastSearchResultsEmpty$,
  mobileSearch$,
  setSearchHistory,
} from 'models/search';
import { scrollToTop } from 'utils/scrollToTop';

import AlgoliaAutocompleteSearchWrapper from 'features/search/AlgoliaAutocompleteSearchWrapper';
import SearchResults from 'features/search/SearchResults';

import { useMatchMedia } from 'libs/useMatchMedia';

import style from './mobileSearch.module.scss';

const MobileSearch = () => {
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);
  const [width] = useWindowResize();
  const history = useHistory();
  const searchListRef = useRef();

  useHideChats({ isAlwaysHide: true });
  const isLastSearchResultsEmpty = useStore(isLastSearchResultsEmpty$);
  const mobileSearch = useStore(mobileSearch$);

  useEffect(() => {
    if (!isSmallScreen && width !== 0) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const sendAnalytics = useCallback(
    (valueForSearch) => {
      if (
        isLastSearchResultsEmpty &&
        history.location.state?.from?.includes('/search')
      ) {
        sendEventWithEventLabel(
          'no_search_results_page_start_search',
          valueForSearch,
        );
      } else {
        sendEventWithCustomField(
          'user_start_search',
          'user input',
          valueForSearch,
        );
      }
    },
    [history.location.state, isLastSearchResultsEmpty],
  );

  const goToSearchPage = useCallback(
    (valueForSearch) => {
      if (!valueForSearch) {
        return;
      }

      changeSearchStrFromUrl(null);
      changeSearchStr(valueForSearch);
      setSearchHistory({
        title: valueForSearch,
        type: 'product',
      });
      sendAnalytics(valueForSearch);
      history.push(`${PATHS.SEARCH(1)}?q=${valueForSearch}`);
    },
    [history, sendAnalytics],
  );

  return (
    <AlgoliaAutocompleteSearchWrapper>
      <SearchResults
        currentRefinement={mobileSearch.currentRefinement}
        refine={mobileSearch.refine}
        searchListRef={searchListRef}
        hits={mobileSearch.hits}
        goToSearchPage={goToSearchPage}
        searchListClassName={style.searchListMobile}
      />
    </AlgoliaAutocompleteSearchWrapper>
  );
};

export default MobileSearch;
