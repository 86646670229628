import { SETTINGS } from 'api';

import { getBrandingFx } from './index';

getBrandingFx.use(async () => {
  const { data } = await SETTINGS.getBranding();
  let finalData = { ...data };

  if (finalData.companyPhoneNumber.replace(/[^A-Z0-9]/gi, '')) {
    finalData.companyPhoneNumberCall = finalData.companyPhoneNumber.replace(
      /[^A-Z0-9]/gi,
      '',
    );
  }

  return { ...finalData };
});
