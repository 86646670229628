export const form = {
  titles: {
    signUp: 'Ti interessa il corso?',
    thankYouFor: 'Grazie per esserti iscritto',
    youHaveSuccessfully:
      'La tua iscrizione al Corso Piattaforme aeree è andata a buon fine. Controlla la tua email e segui le istruzioni.',
    makeAnEnquiry: 'Invia la Tua Richiesta',
    makeAnEnquiryButton: 'Contattaci',
    checkBoxIAgree:
      'Acconsento a condividere i miei dati con {0}. Non comunicheremo mai i tuoi dati a terze parti senza il tuo consenso',
    checkBoxLink: 'Vedi la Privacy Policy qui',
    checkBoxIWish: 'Desidero iscrivermi alla newsletter di {0}',
    makeAnEnquiryDescription:
      'Non esitare a contattarci in caso di domande. Saremo felici di aiutarti!',
    howWouldYouLike: 'Come preferisci essere contattato?',
    pleaseEnterDetails: 'Inserisci i tuoi dati di contatto',
  },

  optionalField: 'facoltativo',
  cancel: 'Cancella',
  saveChanges: 'Salvare le Modifiche',

  fields: {
    search: 'Ricerca',
    sortBy: 'Seleziona per',
    fullName: 'Nome e Cognome',
    emailAddress: 'Indirizzo email',
    PhoneNumber: 'Numero di telefono',
    userType: 'Tipologia di Utente',

    firstName: 'Nome',
    lastName: 'Cognome',
    dateOfBirth: 'Data di nascita',
    birthplace: 'Luogo di Nascita',
    address: 'Indirizzo',

    companyName: 'Ragione Sociale',
    country: 'Paese',
    vatNumber: 'Partita IVA',
    sortCode: 'BIC/SWIFT',
    accountNumber: 'Numero Conto Corrente',
    taxNumber: 'Codice Fiscale',
    city: 'Città',

    newEmail: 'Nuova e-mail',
    oldPassword: 'Password precedente',
    newPassword: 'Nuova password',
    confirmNewPassword: 'Conferma nuova password',

    deliveryAddress: 'Indirizzo di Consegna',
    message: 'Messaggio',
    yourCommentsOrQuestions: 'Commenti e domande',
    privacyLink: 'Sulla Privacy',
    postcode: 'Postal code',
    resendLink: 'Invia di nuovo il link',
    forgotPassword: 'Password dimenticata',

    currentEmailAddress: 'Indirizzo email attuale',
    newEmailAddress: 'Nuovo Indirizzo Email',
    enterNewEmailAddress: 'Inserisci il tuo nuovo indirizzo email',
    password: 'Password',
    enterPassword: 'Inserisci la tua password',
    currentPassword: 'Password Corrente',
    repeatNewPassword: 'Ripeti Nuova Password',
    passwordConfirmation: 'Conferma Password',
    enterNewPassword: 'Inserisci la tua nuova password',
    repeatYourNewPassword: 'Ripeti la tua nuova password',
    enterCurrentPassword: 'Inserisci la tua password attuale',
    clear: 'Cancella',
    purchaseOrderNumber: `Numero d'ordine di Acquisto (Opzionale)`,
  },

  radio: {
    phone: 'Telefono',
    email: 'Email',
    chat: 'Chat / WhatsApp',
  },

  placeholders: {
    superConstruction: 'Super Construction Co.',
    italy: 'Italy',
    enterNewEmail: 'Inserisci la nuova email',
    enterOldPassword: 'Inserisci la vecchia password',
    enterNewPassword: 'Inserisci la nuova password',
    reEnterNewPassword: 'Ripeti la nuova password',
    startTyping: 'Inizia a scrivere...',
    startTypingDeliveryAddress: 'Inserisci il tuo indirizzo di consegna...',
    enterPostalCode: 'Inserisci il codice postale',
    yourMessage: 'Scrivi messaggio',
    startTypingToFilter: 'Inizia a Scrivere per Filtrare',
    ordersAvailable: 'Ordini Disponibili',
    orderAvailable: 'Ordine disponibile',
    quotesAvailable: 'Quotes available',
    quoteAvailable: 'Quote available',
    dateRange: 'Intervallo di date',
    message:
      'Aggiungi alla tua richiesta quante più informazioni possibili (requisiti tecnici, città, note aggiuntive, ecc.)',
    yourComment: 'Il tuo messaggio',
    purchasePlaceholder: 'Inserisci il numero di riferimento del cliente',
  },

  select: {
    select: 'Selezione',
    popular: 'Popolari',
    priceLow: 'Prezzo (crescente)',
    priceHigh: 'Prezzo (decrescente)',
    date: 'Nuovi arrivi',
    depotToLocation: 'Dal negozio alla zona di consegna',
    locationToDepot: 'Dalla zona di consegna al negozio',
    bothWaysDelivery: 'Consegna e ritiro',
  },

  refineListing: {
    title: 'Affina la scheda',
    reset: 'Ripristina',
    power: 'Potenza di uscita, kW',
    weight: 'Capacità di ponderazione, kg',
  },

  buttons: {
    signUp: 'Contattaci',
    confirm: "Conferma e Invia l'Ordine",
    nextStep: 'Vai Avanti',
    back: 'Indietro',
    submitIndex: 'Invia Richiesta',
    submitEnquiry: 'Invia Richiesta',
    browseSimilar: 'Visualizza Prodotti Simili',
    all: 'Guarda Tutti',
    signUpNow: 'Contattaci',
    makeReservation: 'Richiedi più informazioni',
    proceedToCheckout: 'Procedi con il checkout',
    viewUserGuidelines: 'Visualizza scheda tecnica',
    continueHiring: 'Torna al catalogo',
    goToCheckout: 'Vai al checkout',
    submitRequest: 'Invia richiesta',
    viewDetails: 'Visualizza dettagli',
  },

  calendar: {
    selectHirePeriod: 'Seleziona periodo di noleggio',
    previous: 'Precedente',
    reset: 'Reimpostare i valori predefiniti',
    next: 'Il prossimo',
    dayPlaceholder: 'GG',
    monthPlaceholder: 'MM',
    yearPlaceholder: 'AAAA',
  },

  errors: {
    cardNumber: 'Il numero di carta è un campo obbligatorio',
    cardExpiry: 'La data di scadenza è un campo obbligatorio',
    cardCvc: 'Il CVC è un campo obbligatorio',
    regionEmail: 'Error',
    invalidData: 'Dati non validi',
    noTerms:
      'You must read and agree to the GeneralTerms & Conditions in orders to proceed',
    wentWrong: 'Qualcosa è andato storto',
    wentWrongTryLater: 'Qualcosa è andato storto, riprova più tardi',
    productNotAvailable: 'Questo prodotto non è disponibile',
    deliveryFromWarehouseNotAvailable:
      'Sessione sulla consegna scaduta, aggiorna la pagina',
  },

  validation: {
    required: 'Campo Obbligatorio*',
    requiredInOneWord: 'Obbligatorio*',
    emailPattern: 'Indirizzo email errato',
    passwordLength: 'La password deve avere almeno 6 caratteri',
    passwordDifferent: 'Le passwords non coincidono',
    invalidCreds: 'Email o password non valida',
    userImageUploadError: `L'immagine non può essere salvata`,
    emailVerification:
      "Ti è stata inviata un'email con il link per confermare il tuo indirizzo di posta elettronica",
    minLength: 'Min. {0} caratteri',
    phonePatter: 'Numero di telefono non corretto',
  },

  success: {
    regionEmail: 'La tua richiesta è stata inviata con successo!',
    thankYou: 'Grazie per la richiesta!',
    youHave:
      'Stiamo lavorando alla tua richiesta e ti contatteremo  al più presto via e-mail o telefono.',
    futureCheckout:
      'Puoi riutilizzare le informazioni inserite nel modulo di richiesta e procedere al pagamento.',
    checkoutDiscount: 'Per tutti i corsi pagati online offriamo uno ',
    discountPercent: 'sconto del {0}%',
    okProceed: 'Completa la tua prenotazione',
    thankYouForEnquiry: 'Grazie per aver inviato il modulo con la richiesta!',
    weWillContact: 'Ti ricontatteremo a breve.',
  },

  successGetQuote: {
    title: 'La tua richiesta di preventivo è stata ricevuta con successo',
    thankYouTitle: 'Grazie per la tua richiesta di preventivo!',
    msg: "Abbiamo inviato un'e-mail con il preventivo richiesto al tuo indirizzo e-mail. Ti contatteremo presto!",
    newCheckoutMsg:
      "Abbiamo inviato un'e-mail con il preventivo richiesto al tuo indirizzo e-mail. Ti contatteremo presto!",
  },

  purchaseTooltip: `Aggiungi questo se il cliente desidera un riferimento all'ordine associato al numero d'ordine d'acquisto. Questo comparirà sulla fattura che il cliente riceve.`,
};
