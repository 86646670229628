import React from 'react';
import clsx from 'clsx';
import { Lang } from 'lang';

import { T8y } from 'ui';

import { ReactComponent as DatePickerIcon } from 'assets/images/icon/calendarOrangeIcon.svg';

import style from './datePickerDisclosureItem.module.scss';

const DatePickerDisclosureItem = ({
  titleSlug,
  date,
  isDatesHighlighted,
  isInCalendar,
}) => {
  const { products } = Lang();

  return (
    <div
      className={clsx(
        date
          ? style.datePickerDisclosureItemWithDate
          : style.datePickerDisclosureItem,
        isDatesHighlighted && !date && style.highlightedDate,
      )}
    >
      <div>
        {date ? (
          <T8y
            variant="t5"
            color="newGray"
            boldLight
            className={style.datePickerDisclosureItemHeader}
          >
            {products.other[titleSlug]}
          </T8y>
        ) : (
          <T8y variant="t2" xsVariant="t3" color="newGray" boldLight>
            {products.other[titleSlug]}
          </T8y>
        )}
        <T8y
          color="mainGray"
          variant="t2"
          className={style.datePickerDisclosureItemDate}
          boldLight
        >
          {date}
        </T8y>
      </div>
      <DatePickerIcon
        className={isInCalendar ? style.calendarInputIcon : undefined}
      />
    </div>
  );
};

export default DatePickerDisclosureItem;
