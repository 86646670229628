import { useCallback, useContext, useMemo } from 'react';
import { isETH } from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import { getMainPriceForCard } from 'utils/helpers/formatPrices';

export const useProductStructuredData = (product, calculatedPrice) => {
  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const convertToPlain = useCallback((html) => {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;

    const plainText =
      tempDivElement.textContent || tempDivElement.innerText || '';

    tempDivElement.remove();
    return plainText;
  }, []);

  const isHardcodedPrice = isETH && product.displayedRange !== 'week';

  const hardcodedPrice = useMemo(() => {
    if (isHardcodedPrice) {
      const mainPrice = getMainPriceForCard({ product, isProductPage: true });

      if (!mainPrice) {
        return null;
      }

      return {
        price: Number(mainPrice.price?.toFixed(2)),
        oldPrice: Number(mainPrice.oldPrice?.toFixed(2)),
      };
    }

    return null;
  }, [isHardcodedPrice, product]);

  return useMemo(() => {
    if (!branding || !settings || !calculatedPrice?.price) {
      return null;
    }

    try {
      let data = {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        name: product.names[settings.defaultLanguage] || '',
        image: product.image?.medium || branding.defaultImageProduct,
        description:
          convertToPlain(
            product.descriptions[settings.defaultLanguage] || '',
          ) || '',
        sku: product.sku,
        url: window.location.href,
        brand: {
          '@type': 'Brand',
          name: branding.companyName,
        },
        offers: {
          '@type': 'AggregateOffer',
          url: window.location.href,
          priceCurrency: branding.currency,
          lowPrice: hardcodedPrice?.price || calculatedPrice.price || 0,
          highPrice: hardcodedPrice?.oldPrice || calculatedPrice.oldPrice || 0,
          offerCount: 1,
        },
      };

      if (product.reviews && product.reviews.length) {
        data.aggregateRating = {
          '@type': 'AggregateRating',
          ratingValue: product.rating,
          reviewCount: product.reviews.length,
        };

        data.review = product.reviews.map((el) => ({
          '@type': 'Review',
          datePublished: el.added_on || '',
          reviewBody: el.message || '',
          author: { '@type': 'Person', name: el.name || '' },
          reviewRating: {
            '@type': 'Rating',
            bestRating: 5,
            ratingValue: el.rating || 1,
            worstRating: 1,
          },
        }));
      }

      return JSON.stringify(data);
    } catch (e) {
      return null;
    }
  }, [
    branding,
    calculatedPrice,
    convertToPlain,
    hardcodedPrice,
    product,
    settings,
  ]);
};
