import React, { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { Lang } from 'lang';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const FooterLinks = lazy(() =>
  lazyRetry(() => import('./FooterLinks'), 'FooterLinks'),
);

const FooterLinkPolicy = () => {
  const { footer } = Lang();
  return <FooterLinks field="privacyPolicy" title={footer.privacyPolicy} />;
};
const FooterLinkTerms = () => {
  const { footer } = Lang();
  return <FooterLinks field="termsConditions" title={footer.termsConditions} />;
};
const FooterLinkCookiePolicy = () => {
  const { footer } = Lang();
  return <FooterLinks field="cookiePolicy" title={footer.cookiePolicy} />;
};
const FooterLinkGeneralTerms = () => {
  const { footer } = Lang();
  return <FooterLinks field="generalTerms" title={footer.generalTerms} />;
};

export const policyRoutes = [
  {
    path: PATHS.POLICY,
    exact: true,
    component: () => WithSuspense(FooterLinkPolicy),
  },
];

export const termsRoutes = [
  {
    path: PATHS.TERMS,
    exact: true,
    component: () => WithSuspense(FooterLinkTerms),
  },
];

export const CookiePolicyRoutes = [
  {
    path: PATHS.COOKIE_POLICY,
    exact: true,
    component: () => WithSuspense(FooterLinkCookiePolicy),
  },
];

export const generalTermsRoutes = [
  {
    path: PATHS.GENERAL_TERMS,
    exact: true,
    component: () => WithSuspense(FooterLinkGeneralTerms),
  },
];
