import React from 'react';
import { useStore } from 'effector-react';
import { useGetProductDatesSettings } from 'hooks';
import { Lang } from 'lang';
import { picker_state$ } from 'models/cart';
import { useCalendarSettings } from 'pages/product/useCalendarSettings';

import { Button, DatePickerCalendar, T8y } from 'ui';

import style from './datePicker.module.scss';

const DatePicker = ({ cartItem = {}, onChangeDates, onCancel }) => {
  const { account } = Lang();

  const state = useStore(picker_state$);

  const {
    selectedValues,
    onDatesChanges,
    blockedDatesWithDaysMargin,
    minBookingDays,
    minBookingDate,
  } = useCalendarSettings(cartItem.product, cartItem.dates);

  const { deliveryWeekends } = useGetProductDatesSettings();

  const isSaveBtnDisabled =
    !selectedValues.startDate || !selectedValues.endDate;

  return (
    <div className={style.datePicker}>
      <DatePickerCalendar
        calendarState={state}
        start={selectedValues.startDate}
        end={selectedValues.endDate}
        handleDateChange={onDatesChanges}
        minBookingDate={minBookingDate}
        minBookingDays={minBookingDays}
        deliveryWeekends={deliveryWeekends}
        blockedDatesList={blockedDatesWithDaysMargin}
        edge={state?.focusedInput === 'startDate' ? 'start' : 'end'}
        numberOfMonths={1}
      />
      <div className={style.datePickerButtons}>
        <T8y
          variant="t2"
          color="newGray"
          className={style.cancelButton}
          onClick={onCancel}
        >
          {account.cancel}
        </T8y>
        <Button
          onClick={() => onChangeDates(selectedValues)}
          className={style.saveButton}
          data-testid="save-dates-btn"
          small
          disabled={isSaveBtnDisabled}
        >
          {account.save}
        </Button>
      </div>
    </div>
  );
};

export default DatePicker;
