import {
  combine,
  createEffect,
  createEvent,
  createStore,
  guard,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import orderBy from 'lodash/orderBy';
import { getProductWithActualPrices } from 'utils/getProductWithActualPrices';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

import { currentLang$ } from '../language';
import { settings$ } from '../settings';
import { $userInfo } from '../user';

export const trainingsGate = createGate('trainings');

export const getTrainingsFx = createEffect();

export const trainings$ = restore(getTrainingsFx, []);

export const isTrainingsRequestFinished$ = createStore(false).on(
  getTrainingsFx.done,
  (_) => true,
);

const isIdle = getTrainingsFx.pending.map((state) => !state);

guard({
  source: trainingsGate.open,
  filter: isIdle,
  target: getTrainingsFx,
});

export const isTrainingsLoading$ = restore(
  getTrainingsFx.pending.updates,
  false,
);

export const trainingsLocalized$ = combine(
  trainings$,
  currentLang$,
  settings$,
  (trainings, lang, { defaultLanguage }) => {
    const localizedList = trainings.map((el) => {
      return {
        ...el,
        title: getTranslatedFieldValue(el.title, lang, defaultLanguage),
        shortDescription: getTranslatedFieldValue(
          el.shortDescription,
          lang,
          defaultLanguage,
        ),
        mainImageThumb: el.mainImageThumb?.main,
        mainImageWebpThumb: el.mainImageThumb?.webp?.main,
        menuTitle: getTranslatedFieldValue(
          el.menuTitles,
          lang,
          defaultLanguage,
        ),
        description: getTranslatedFieldValue(
          el.description,
          lang,
          defaultLanguage,
        ),
      };
    });

    return orderBy(localizedList, (item) => item.orderId, ['asc']);
  },
);

export const getCurrentTrainingFx = createEffect();
export const clearCurrentTraining = createEvent();

export const currentTraining$ = restore(getCurrentTrainingFx, null).on(
  clearCurrentTraining,
  (_) => null,
);

export const setLastTrainingUrl = createEvent();

export const lastTrainingUrl$ = createStore(null)
  .on(setLastTrainingUrl, (s, p) => {
    return s !== p ? p : s;
  })
  .on(clearCurrentTraining, (_, p) => null);

const isIdleTraining = getCurrentTrainingFx.pending.map((state) => !state);

guard({
  source: lastTrainingUrl$.updates,
  filter: combine(isIdleTraining, lastTrainingUrl$, (...checks) =>
    checks.every(Boolean),
  ),
  target: getCurrentTrainingFx,
});

export const isTrainingLoading$ = restore(
  getCurrentTrainingFx.pending.updates,
  false,
);

export const isCurrentTrainingRequestFinished$ = createStore(false)
  .on(getCurrentTrainingFx.done, (_) => true)
  .on(clearCurrentTraining, (_) => false);

export const actualCurrentTrainingData$ = combine(
  currentTraining$,
  $userInfo,
  (training, user) => {
    const products = training?.recommendedProducts;

    if (!training) {
      return null;
    }

    const userDiscount = user.tier?.discount;

    if (!products?.length || !userDiscount) {
      return training;
    }

    return {
      ...training,
      recommendedProducts: products.map((product) =>
        getProductWithActualPrices(product, userDiscount),
      ),
    };
  },
);

export const currentTrainingLocalized$ = combine(
  actualCurrentTrainingData$,
  currentLang$,
  settings$,
  (el, lang, { defaultLanguage }) => {
    if (!el) {
      return null;
    }

    return {
      ...el,
      mainImage: { src: el.mainImage.main },
      mainImageThumb: { src: el.mainImageThumb.main },
      description: getTranslatedFieldValue(
        el.description,
        lang,
        defaultLanguage,
      ),
      title: getTranslatedFieldValue(el.title, lang, defaultLanguage),
      shortDescription: getTranslatedFieldValue(
        el.shortDescription,
        lang,
        defaultLanguage,
      ),
      theory: getTranslatedFieldValue(el.theory, lang, defaultLanguage),
      learningText: getTranslatedFieldValue(
        el.learningText,
        lang,
        defaultLanguage,
      ),
      certificateText: getTranslatedFieldValue(
        el.certificateText,
        lang,
        defaultLanguage,
      ),
      certificateImage: { src: el.certificateImage?.main },
      schedules: el.schedules.data.map((schedule) => ({
        ...schedule,
        variations: schedule.variations.data,
        organizer: getTranslatedFieldValue(
          schedule.organizer,
          lang,
          defaultLanguage,
        ),
      })),
      scheduleDescription: getTranslatedFieldValue(
        el.scheduleDescription,
        lang,
        defaultLanguage,
      ),
      noSchedule: el.noSchedule && {
        ...el.noSchedule,
        locations: el.noSchedule.locations.data,
        organizer: getTranslatedFieldValue(
          el.noSchedule.organizer,
          lang,
          defaultLanguage,
        ),
        description: getTranslatedFieldValue(
          el.noSchedule.description,
          lang,
          defaultLanguage,
        ),
        variations: el.noSchedule.variations.data,
      },
      faqs: el.faqs.data.map((faq) => ({
        question: getTranslatedFieldValue(faq.question, lang, defaultLanguage),
        answer: getTranslatedFieldValue(faq.answer, lang, defaultLanguage),
      })),
      reviews: el.reviews.data.map((review) => ({
        ...review,
        name: getTranslatedFieldValue(review.name, lang, defaultLanguage),
        text: getTranslatedFieldValue(review.text, lang, defaultLanguage),
        position: getTranslatedFieldValue(
          review.position,
          lang,
          defaultLanguage,
        ),
      })),
      benefits: el.benefits.data.map((benefit) => ({
        ...benefit,
        title: getTranslatedFieldValue(benefit.title, lang, defaultLanguage),
        text: getTranslatedFieldValue(benefit.text, lang, defaultLanguage),
      })),
      galleryImages: el.galleryImages.data.map((el) => ({ src: el.main })),
      partnerImages: el.partnerImages.data.map((el) => ({ src: el.main })),
      recommendedProducts: el.recommendedProducts.map((product) => ({
        ...product,
        name: getTranslatedFieldValue(product.names, lang, defaultLanguage),
      })),
    };
  },
);

export const $fetchError =
  restore < Error > (getCurrentTrainingFx.failData, null);

export const $currentTrainingGetStatus = combine({
  loading: getCurrentTrainingFx.pending,
  error: $fetchError,
});

export const trainingIndexGate = createGate('trainings');

export const getTrainingIndexFx = createEffect();

export const isTrainingIndexRequestFinished$ = createStore(false).on(
  getTrainingIndexFx.done,
  (_) => true,
);

const isIdleIndex = getTrainingIndexFx.pending.map((state) => !state);

export const trainingIndex$ = restore(getTrainingIndexFx, null);

export const trainingIndexLocalized$ = combine(
  trainingIndex$,
  currentLang$,
  settings$,
  (trainingIndex, lang, { defaultLanguage }) => {
    if (!trainingIndex) {
      return {};
    }
    return {
      ...trainingIndex?.data,
      image: trainingIndex?.data.image.main,
      introductoryTitle: getTranslatedFieldValue(
        trainingIndex.data.introductoryTitle,
        lang,
        defaultLanguage,
      ),
      introductoryText: getTranslatedFieldValue(
        trainingIndex.data.introductoryText,
        lang,
        defaultLanguage,
      ),
      title: getTranslatedFieldValue(
        trainingIndex.data.title,
        lang,
        defaultLanguage,
      ),
      description: getTranslatedFieldValue(
        trainingIndex.data.text,
        lang,
        defaultLanguage,
      ),
    };
  },
);

export const isTrainingIndexLoading$ = restore(
  getTrainingIndexFx.pending.updates,
  false,
);

guard({
  source: trainingIndexGate.open,
  filter: isIdleIndex,
  target: getTrainingIndexFx,
});
