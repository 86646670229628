import React, { useContext, useEffect } from 'react';
import { LAPTOP_SCREEN, MOBILE_SCREEN } from 'consts';
import { TrainingContext } from 'contexts';
import { useStore } from 'effector-react';
import { useLayoutMatchMedia } from 'hooks';
import { Lang } from 'lang';
import { currentTrainingLocalized$ } from 'models/trainings';

import TrainingBtn from 'features/trainings/TrainingBtn';

import RequestTrainingForm from '../RequestTrainingForm';

import { T8y } from 'ui';

import trainingUniversal from 'assets/images/picture/trainingUniversal.jpg';

import style from './firstScreen.module.scss';

const FirstScreen = () => {
  const { form } = Lang();

  const isLaptop = useLayoutMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);
  const isMobile = useLayoutMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);
  const { formRef, isFormOpenedState } = useContext(TrainingContext);

  const currentTrainingLocalized = useStore(currentTrainingLocalized$);

  const [isFormOpened, setIsFormOpened] = isFormOpenedState;

  useEffect(() => {
    if (currentTrainingLocalized && typeof setIsFormOpened === 'function') {
      setIsFormOpened(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrainingLocalized]);

  return (
    <>
      <div className={style.firstScreenWrapper}>
        <div
          style={{
            backgroundImage: `url('${
              isMobile
                ? trainingUniversal
                : currentTrainingLocalized?.mainImage.src
            }')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          className={style.mainImage}
        />
        {!isMobile && (
          <div>
            <T8y as="h1" variant="h1" className={style.title}>
              {currentTrainingLocalized?.seoH1 ||
                currentTrainingLocalized?.title ||
                ''}
            </T8y>
            <T8y
              className={style.subTitle}
              mb3
              dangerouslySetInnerHTML={{
                __html: currentTrainingLocalized?.shortDescription,
              }}
            />
          </div>
        )}
        {!isLaptop && <RequestTrainingForm />}
      </div>
      {isMobile && (
        <div className={style.mobileTitle}>
          <T8y as="h1" variant="h1" className={style.title} center>
            {currentTrainingLocalized?.title || ''}
          </T8y>
          <T8y
            className={style.subTitle}
            mb3
            center
            dangerouslySetInnerHTML={{
              __html: currentTrainingLocalized?.shortDescription,
            }}
          />
        </div>
      )}
      {isLaptop && (
        <div ref={formRef}>
          {isFormOpened ? (
            <div className={style.smallScreenFormWrapper}>
              <RequestTrainingForm />
            </div>
          ) : (
            <div className={style.trainingFormOpenBtnWrapper}>
              <TrainingBtn
                text={form.buttons.makeReservation}
                elementLocation="top"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FirstScreen;
