import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { useSectionAutoCollapsing } from 'layout/Footer/useSectionAutoCollapsing';
import { categoriesLocalized$ } from 'models/product';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const DesktopCategoriesList = () => {
  const { main, sidebar } = Lang();

  const disclosure = useDisclosureState();

  useSectionAutoCollapsing(disclosure);

  const categories = useStore(categoriesLocalized$);

  const { categoriesForFirstRender, restCategories } = useMemo(
    () => ({
      categoriesForFirstRender: categories.slice(0, 8),
      restCategories: categories.slice(8),
    }),
    [categories],
  );

  return (
    <>
      <T8y variant="t1v1" mb2 bold className={style.title}>
        {main.titles.hireByCategory}
      </T8y>
      <div>
        {categoriesForFirstRender.map(({ name, slug }, i) => (
          <T8y
            key={i}
            as={Link}
            to={{
              pathname: PATHS.CATEGORY(slug),
              presetType: 'category',
            }}
            variant="t2v1"
            color="primary"
            className={style.footerLink}
          >
            {name}
          </T8y>
        ))}
      </div>
      {!!restCategories.length && (
        <>
          <DisclosureContent {...disclosure} className={style.restTrainings}>
            {restCategories.map(({ name, slug }, i) => (
              <T8y
                key={i}
                as={Link}
                to={{
                  pathname: PATHS.CATEGORY(slug),
                  presetType: 'category',
                }}
                variant="t2v1"
                color="primary"
                className={style.footerLink}
              >
                {name}
              </T8y>
            ))}
          </DisclosureContent>
          <Disclosure {...disclosure}>
            <T8y
              variant="t2v1"
              color="primary"
              mt2
              pointer
              className={style.footerLink}
            >
              {sidebar[disclosure.visible ? 'showLess' : 'showMore']}...
            </T8y>
          </Disclosure>
        </>
      )}
    </>
  );
};

export default DesktopCategoriesList;
