export const about = {
  brands: {
    title: 'Brand Values',
    sections: {
      greatValue: 'Great Value',
      bigBoys: 'Taking on the big boys',
      forTheMany: 'For the many not the few',
      relentlessInnovation: 'Relentless innovation',
      keepItSimple: 'Keep it simple',
      entrepreneurial: 'Entrepreneurial',
      makingTheDifferencePeopleLives: 'Making the difference in people’s lives',
      caringAndFun: 'Honest, Open, Caring and Fun',
    },
  },

  depots: {
    title: 'Our depots are region wide!',
    description:
      'Сhoose Self-Collection during checkout and collect your products in one of our depots - it’s always free!', // in main page
    showMoreDepots: 'Show more depots',
    showLessDepots: 'Show less depots',
  },

  easyLinkText:
    '{0}® is part of the {1}® family of brands. For more information please visit {2} and learn more about the easy family of brands.',
  easyHistoryLinkText:
    '{0}® is part of the {1}® family of brands. For more information please visit {2} and learn about the brand history.',

  vision: '{0} Vision',
  representativeIn: '{0} - {1} representative in {2}',
  about: 'About {0}',
  contactUsTitle: 'Contact Us',
  history: 'History of the easy family of brands',
  representativeInRegion: '{0} representatives in {1} region',
};
