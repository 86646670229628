import React from 'react';
import { Lang } from 'lang';

import { Row, T8y } from 'ui';

import { ReactComponent as Easy } from 'assets/images/picture/brands/footerEasy.svg';
import { ReactComponent as EasyHistory } from 'assets/images/picture/brands/footerEasyHistory.svg';

import style from './brands.module.scss';

const Brands = () => {
  const { footer, formatString } = Lang();

  return (
    <div className={style.brandsWrapper}>
      <Row
        justify="stretch"
        xsDirection="column"
        xsAlign="center"
        className={style.brandsContainer}
      >
        <div className={style.brand}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://easy.com/"
            className={style.brandLogo}
            aria-label="easy.com"
          >
            <Easy />
          </a>
          <T8y variant="t1v1" color="silver">
            {formatString(
              footer.learnMoreEasyFamilyOfBrands,
              <T8y
                as="a"
                variant="t1v1"
                color="primary"
                bold
                target="_blank"
                rel="noopener noreferrer"
                href="https://easy.com/"
              >
                easy.com
              </T8y>,
            )}
          </T8y>
        </div>
        <div className={style.brand}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://easyhistory.info/"
            className={style.brandLogo}
            aria-label="easyHistory.info"
          >
            <EasyHistory />
          </a>
          <T8y variant="t1v1" color="silver">
            {formatString(
              footer.learnMoreEasyHistory,
              <T8y
                as="a"
                variant="t1v1"
                color="primary"
                bold
                target="_blank"
                rel="noopener noreferrer"
                href="https://easyhistory.info/"
              >
                easyHistory.info
              </T8y>,
            )}
          </T8y>
        </div>
      </Row>
    </div>
  );
};

export default Brands;
