export const dashboard = {
  header: 'Bienvenido',
  subHeader: 'Este es tu panel de control',

  getQuote: 'Obtén un presupuesto',
  viewOrder: 'Tus pedidos',
  support: 'Atención al Cliente',
  allProducts: 'Todos los productos',

  titles: {
    quickStart: 'Comienzo rápido',
    recentQuotes: 'Presupuestos recientes',
    recentOrders: 'Pedidos recientes',
    onHireOrders: 'Pedidos Activos',
    popularCategories: 'Categorías Populares',
    noQuotes:
      'No hay presupuestos para mostrar. Navega por nuestra {1} para encontrar lo que necesitas',
    noOrders:
      'No hay pedidos para mostrar.  Navega por nuestra {1} para encontrar lo que necesitas',
    catalog: 'Catalogo',
    popularProducts: 'Productos más populares',
    benefitsCustomer: 'Beneficios de las cuentas de confianza',
  },

  table: {
    estimateCellHeader: 'Total Estimado',
    deliveryCellHeader: 'Fecha de entrega',
    collectionCellHeader: 'Fecha de recogida',
  },

  quotesTable: {
    quoteCellHeader: 'Presupuesto #',
    estimateCellHeader: 'Total Estimado',
    deliveryCellHeader: 'Fecha de entrega',
    collectionCellHeader: 'Fecha de recogida',
    btnText: 'Ver cotización',
  },

  ordersTable: {
    orderCellHeader: 'Pedido #',
    priceCellHeader: 'Precio total',
    deliveryCellHeader: 'Fecha de entrega',
    collectionCellHeader: 'Fecha de recogida',
    statusCellHeader: 'Estado',
    btnText: 'Ver factura',
    dateCreated: 'Fecha de creación',
    grandTotal: 'Total Final',
    partnership: 'Desarrollado en asociación con',
  },

  discountText: 'Tu descuento es válido en todo el catálogo',

  discountTypes: {
    platinum: 'Platinum',
    gold: 'Gold',
    silver: 'Silver',
    top: 'Top',
  },

  discountPortalTypes: {
    platinum: 'Descuento Platinum',
    gold: 'Descuento de Gold',
    silver: 'Descuento de Silver',
    top: 'Descuento de Top',
  },

  dashboardButtons: {
    newOrder: 'Nuevo pedido',
    copyPrevOrder: 'Copiar pedido anterior',
    newUser: 'Añadir nuevo usuario',
    supportChat: 'Chat de soporte',
    reorder: 'Repetir pedido',
    cancelOrder: 'Cancelar pedido',
    cancelRequestSent: 'Solicitud de cancelación enviada',
    changeEndDate: 'Cambiar fechas',
  },
};
