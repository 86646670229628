export const invoice = {
  titles: {
    name: 'Name',
    tel: 'Tel',
    email: 'Email',

    address: 'Address',

    item: 'Item',
    sku: 'SKU',
    price: 'price',
    days: 'Days',

    subtotal: 'Subtotal',
    insurance: 'Insurance',
    safetyFee: 'Safety Fee',
    sanitationFee: 'Sanitation Fee',
    tax: 'tax',
    transport: 'Transport',
    deposit: 'Deposit',

    total: 'Total',
    grandTotal: 'Grand Total',
  },
};
