import { sortByAlphabet } from 'utils/formatStrings';

export const getCategoriesWithChildren = (hits) => {
  const r = [],
    newEL = {};

  hits.forEach(function (el) {
    const modifiedEl = { ...el };

    if (newEL[modifiedEl.id] && newEL[modifiedEl.id].childs) {
      modifiedEl.childs =
        newEL[modifiedEl.id] && sortByAlphabet(newEL[modifiedEl.id].childs);
    }
    newEL[modifiedEl.id] = modifiedEl;
    if (!modifiedEl.parent_id) {
      r.push(modifiedEl);
    } else {
      newEL[modifiedEl.parent_id] = newEL[modifiedEl.parent_id] || {};
      newEL[modifiedEl.parent_id].childs =
        newEL[modifiedEl.parent_id].childs || [];
      newEL[modifiedEl.parent_id].childs.push(modifiedEl);
      newEL[modifiedEl.parent_id].childs = sortByAlphabet(
        newEL[modifiedEl.parent_id].childs,
      );
    }
  });

  return r;
};
