import React from 'react';
import { Link } from 'react-router-dom';
import { useGetStaticHeaderHeight } from 'hooks';
import { capitalizeFirstLetterAllWords } from 'utils/formatStrings';

import { T8y } from 'ui';

import style from './multipleLinksMenuWrapper.module.scss';

const MultipleLinksMenuWrapper = ({
  linksList,
  setIsSubMenuOpen,
  onClickCallback,
}) => {
  const offsetFromTop = useGetStaticHeaderHeight();

  return (
    <div className={style.multipleLinksMenuWrapper}>
      <div
        className={style.menuItems}
        style={{ maxHeight: `calc(100vh - ${offsetFromTop}px)` }}
      >
        {linksList.map((el, index) => (
          <T8y
            as={el.isExternal ? 'a' : Link}
            to={el.path || '#'}
            href={el.href || '#'}
            variant="t1"
            key={index}
          >
            <div
              className={style.linkWrapper}
              onClick={() => {
                if (onClickCallback) {
                  onClickCallback(el.title);
                }

                setIsSubMenuOpen(false);
              }}
            >
              {el.isCapitalize
                ? capitalizeFirstLetterAllWords(el.title)
                : el.title}
            </div>
          </T8y>
        ))}
      </div>
    </div>
  );
};

export default MultipleLinksMenuWrapper;
