import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { REACT_APP_PUBLIC_URL } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { cartFormattedData$, createFakeOrderFx } from 'models/cart';
import * as CR from 'models/cart';

import { useGetCommonCheckoutData } from './useGetCommonCheckoutData';

export const useGetFakeOrderLink = (cancelToken) => {
  const { form } = Lang();

  const details = useStore(CR.details$);

  const {
    address,
    method,
    warehouse: selectedWarehouse,
    addressByPostcode,
  } = useStore(CR.deliveryMethod$);

  const cartFormattedData = useStore(cartFormattedData$);
  const getFormattedCheckoutData = useGetCommonCheckoutData({
    details,
    deliveryMethodData: {
      address,
      method,
      selectedWarehouse,
      addressByPostcode,
    },
    cartFormattedData,
  });

  const checkoutData = getFormattedCheckoutData();

  return useCallback(async () => {
    try {
      const res = await createFakeOrderFx({
        checkoutData,
        config: cancelToken ? { cancelToken } : undefined,
      });

      return `${REACT_APP_PUBLIC_URL}/${PATHS._RESERVATION}?id=${res.slug}`;
    } catch (e) {
      if (e.message !== 'manual cancel') {
        toast.error(form.errors.wentWrong);
      }
    }
  }, [cancelToken, checkoutData, form.errors.wentWrong]);
};
