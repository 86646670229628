import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { PATHS } from 'AppPaths';

const RedirectToCategory = () => {
  const { slug } = useParams();

  return <Redirect to={PATHS.CATEGORY(slug)} />;
};

export default RedirectToCategory;
