import { guard } from 'effector';

import { WAREHOUSES } from 'api';

import { getWarehousesFx, WarehousesGate } from './';

getWarehousesFx.use(async () => {
  const { data } = await WAREHOUSES.getWarehouses();
  return data.data;
});

const isIdle = getWarehousesFx.pending.map((state) => !state);

guard({
  source: WarehousesGate.open,
  filter: isIdle,
  target: getWarehousesFx,
});
