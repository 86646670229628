import React from 'react';
import { getDisplayedRegionName } from 'consts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { $warehouses } from 'models/warehouses';

import { Row, T8y } from 'ui';

import { ReactComponent as CloseIcon } from 'assets/images/icon/closeFilterSearch.svg';
import { ReactComponent as LocationIcon } from 'assets/images/icon/mapMarker18.svg';

import style from './warehousesList.module.scss';

const WarehousesList = ({ closeWarehousesList }) => {
  const { formatString, header } = Lang();

  const warehouses = useStore($warehouses);

  return (
    <Row direction="column" noWrap className={style.infoWrapper}>
      <Row
        justify="stretch"
        align="center"
        fullWidth
        noWrap
        className={style.warehouseListHeader}
      >
        <T8y mr2 bold>
          {formatString(
            header.bar.allDepotsTitle,
            getDisplayedRegionName() || '',
          )}
        </T8y>
        <CloseIcon onClick={closeWarehousesList} />
      </Row>
      <div className={style.warehouseListWrapper}>
        <div className={style.warehouseList}>
          {warehouses?.map(({ id, name, address }) => (
            <Row key={id} noWrap className={style.warehouseItem}>
              <LocationIcon />
              <div>
                <T8y variant="t3" boldLight className={style.name}>
                  {name}
                </T8y>
                <T8y variant="t4" color="midGray">
                  {address}
                </T8y>
              </div>
            </Row>
          ))}
        </div>
      </div>
    </Row>
  );
};

export default WarehousesList;
