import React, { useCallback, useContext, useMemo } from 'react';
import { PATHS } from 'AppPaths';
import { ETH_TERMS_LINK, isETH } from 'consts';
import { BrandingContext } from 'contexts';
import { Lang } from 'lang';

import { Checkbox, Row, T8y } from 'ui';

import style from './agreementFormButtons.module.scss';

export function AgreementFormButtons({
  isCheckBoxInfo,
  setIsCheckBoxInfo,
  isSubscription,
  setIsSubscription,
}) {
  const { formatString } = Lang();
  const { sidebar, footer, form } = Lang();

  const branding = useContext(BrandingContext);

  const handleSetSubscription = useCallback(
    (e) => {
      setIsSubscription(e.target.checked);
    },
    [setIsSubscription],
  );

  const handleSetIsCheckboxInfo = useCallback(() => {
    setIsCheckBoxInfo(!isCheckBoxInfo);
  }, [isCheckBoxInfo, setIsCheckBoxInfo]);

  const wishText = useMemo(() => {
    return formatString(form.titles.checkBoxIWish, branding.companyName || '');
  }, [branding.companyName, form.titles.checkBoxIWish, formatString]);

  const handlePolicyClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(isETH ? ETH_TERMS_LINK : PATHS.TERMS, '_blank');
  }, []);

  return (
    <>
      <Row direction="column" className={style.paymentPolicy}>
        <Checkbox
          checked={isCheckBoxInfo}
          onChange={handleSetIsCheckboxInfo}
          label={
            <Row className={style.formFieldCheckbox}>
              <T8y variant="t1" as="p" mr>
                {sidebar.iAgree}
              </T8y>
              <T8y
                variant="t1"
                inline
                color="primary"
                onClick={handlePolicyClick}
              >
                {footer.termsConditions}
              </T8y>
              <T8y color="primary" variant="t1">
                *
              </T8y>
            </Row>
          }
          policy
          size="small"
          data-testid="policy-checkbox"
        />
      </Row>

      <Row direction="column" className={style.paymentPolicy}>
        <Checkbox
          checked={isSubscription}
          onChange={handleSetSubscription}
          label={
            <T8y variant="t1" className={style.formFieldCheckbox}>
              {wishText}
            </T8y>
          }
          policy
          size="small"
          data-testid="subscription-checkbox"
        />
      </Row>
    </>
  );
}
