import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const order = lazy(() => lazyRetry(() => import('./Order'), 'NewCheckout'));

export const orderRoutes = [
  {
    path: [
      PATHS.RESERVATION,
      PATHS.RESERVATION_CHECKOUT,
      PATHS.RESERVATION_QUOTE,
    ],
    exact: true,
    component: () => WithSuspense(order),
  },
];
