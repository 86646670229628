import LocalizedStrings from 'react-localization';
import { useStore } from 'effector-react';
import { currentLang$ } from 'models/language';

import { enGb } from './en-gb';
import { es } from './es';
import { it } from './it';
import { pt } from './pt';

export const allStrings = {
  'en-gb': {
    ...enGb,
  },
  it: {
    ...it,
  },
  es: {
    ...es,
  },
  pt: {
    ...pt,
  },
};

let strings = new LocalizedStrings({
  'en-gb': {
    ...enGb,
  },
  it: {
    ...it,
  },
  es: {
    ...es,
  },
  pt: {
    ...pt,
  },
});

export const Lang = () => {
  const currentLangState = useStore(currentLang$);
  strings.setLanguage(currentLangState);

  return strings;
};
