import { useContext, useEffect } from 'react';
import { BrandingContext } from 'contexts';
import { sendPageView } from 'googleAnalytics';

export const useSendPageViewGAEvent = (pageName) => {
  const branding = useContext(BrandingContext);

  useEffect(
    () => {
      sendPageView(pageName, branding.companyName);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
