import React, { useContext } from 'react';
import clsx from 'clsx';
import { BrandingContext } from 'contexts';

import style from '../header.module.scss';

const Hamburger = ({ open, onClick }) => {
  const branding = useContext(BrandingContext);

  return (
    <div
      className={clsx(
        style.hamburger,
        open && style.open,
        branding.showPhoneIconInHeader && style.withPhone,
      )}
      onClick={onClick}
      data-testid="mobile-menu-hamburger"
    >
      <button type="button" aria-label="Open the menu">
        <span />
        <span />
        <span />
      </button>
    </div>
  );
};

export default Hamburger;
