export const search = {
  searchResultFor: 'Procurar resultados por',
  searchResults: 'Procurar Resultados',
  nothingFound:
    'Nenhum produto foi encontrado. Por favor, navegue em nosso {0}, altere a pesquisa ou envie uma consulta usando o formulário abaixo',
  noItems: 'Nenhum item para exibir',
  searchHistory: 'Histórico de busca',
  popularCategories: 'Categorias populares',
  noResultsFound: 'Desculpe, não encontramos nenhuma correspondência',
  searchResultForJobType: `Resultado da pesquisa para o tipo de trabalho "{0}"`,
};
