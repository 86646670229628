import { PATHS } from 'AppPaths';
import { clearUserInfo } from 'models/user';

import { history } from 'libs/history';
import { removeToken } from 'api/profileApi';

export const handleLogout = () => {
  removeToken();
  clearUserInfo();

  if (history.location.pathname?.includes('profile')) {
    history.replace(PATHS.PROFILE_LOGIN);
  }
};
