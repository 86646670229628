import { guard } from 'effector';

import { JOB_TYPES } from 'api';

import {
  getJobTypeFx,
  getJobTypeProductsFx,
  getJobTypesFx,
  jobTypesGate,
} from './';

getJobTypesFx.use(async () => {
  const { data } = await JOB_TYPES.getJobTypes();

  return data.data || [];
});

const isIdle = getJobTypesFx.pending.map((state) => !state);

guard({
  source: jobTypesGate.open,
  filter: isIdle,
  target: getJobTypesFx,
});

getJobTypeFx.use(async (slugUrl) => {
  const { data } = await JOB_TYPES.getJobType(slugUrl);
  return data.data;
});

getJobTypeProductsFx.use(async (slugUrl) => {
  const { data } = await JOB_TYPES.getJobTypeProducts(slugUrl);
  return data.data;
});
