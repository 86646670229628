export const profile = {
  noData: 'Non sono stati trovati dati per i criteri specificati.',
  and: 'e',

  logIn: {
    header: 'Accedi a easyNoleggio',
    subHeader: 'Approfitta delle offerte e dei servizi di {0}',
    nameFieldTitle: 'Nome e cognome',
    nameFieldPlaceholder: 'Inserisci il tuo nome',
    emailFieldTitle: 'Indirizzo e-mail',
    emailFieldPlaceholder: 'Inserisci il tuo indirizzo e-mail',
    passwordFieldTitle: 'Password',
    passwordFieldPlaceholder: 'Inserisci la tua password',
    remember: 'Ricordami',
    forgotPassword: 'Hai dimenticato la tua password?',
    submit: 'Accedi',
    sendMeAnActivationEmail: "Inviami l'e-mail di attivazione",
  },

  singUp: {
    header: 'Registrati',
    subHeader: 'Crea un account e approfitta di tutti i nostri servizi',
    nameFieldTitle: 'Nome e cognome',
    nameFieldPlaceholder: 'Inserisci il tuo nome',
    emailFieldTitle: 'Indirizzo e-mail',
    emailFieldPlaceholder: 'Inserisci il tuo indirizzo e-mail',
    passwordFieldTitle: 'Password',
    passwordFieldPlaceholder: 'Inserisci la tua password',
    repeatPasswordFieldTitle: 'Ripeti la password',
    repeatPasswordFieldPlaceholder: 'Reinserisci la password',
    goToLogin: 'Ho già un account, vai alla pagina di login',
    submit: 'Registrati',
    emailSentTitleThanks: 'Felici di averti a bordo!',
    emailSentTitleVerify:
      'Ora verifica la tua email per attivare il tuo account.',
    emailSentText:
      "Abbiamo mandato un link di attivazione all'indirizzo email che hai fornito. Per favore controlla la tua casella di posta e segui le istruzioni per verificare la tua email per attivare il tuo account.",
    emailSentLinkDidNotReceive:
      'Non è arrivata nessuna email? Clicca qui per un re-invio.',
    emailSentLink: "inviare nuovamente l'e-mail di attivazione.",
    emailSentResendError:
      'Il re-invio è possibile solo una volta al minuto, per favore riprova più tardi',
    tokenExpired: 'token sia scaduto.',
  },

  forgotPassword: {
    header: 'Hai dimenticato la password?',
    customerHeader: 'Ho dimenticato la password del Portale Clienti',
    subHeader:
      "Inserisci l'indirizzo e-mail che hai usato per la registrazione e ti invieremo un link per il ripristino della password",
    emailFieldTitle: 'Indirizzo e-mail',
    emailFieldPlaceholder: 'Inserisci il tuo indirizzo e-mail',
    goToLogin: 'Vai alla pagina di Accesso, mi sono ricordato la mia password',
    submit: 'Invia',
    sent: 'Ti abbiamo inviato una email. Controlla la tua casella di posta.',
    cancel: 'Cancella',
  },

  resetPassword: {
    header: 'Vai alla pagina di Accesso, mi sono ricordato la mia password',
    customerHeader: 'Ripristina la Password del Portale Clienti',
    subHeader: 'Inserisci la tua nuova password qui sotto',
    emailAddressTitle: 'Indirizzo e-mail',
    passwordFieldTitle: 'Nuova password',
    passwordFieldPlaceholder: 'Inserisci la tua password',
    repeatPasswordFieldTitle: 'Ripeti la nuova password',
    repeatPasswordFieldPlaceholder: 'Reinserisci la tua nuova password',
    submit: 'Salva Modifiche',
    saveChanges: 'Salvare le Modifiche',
  },

  activationAccount: {
    customerHeader: 'Attivazione del Trade Account',
    subHeader: 'Per favore inserisci la tua password qui sotto',
    saveChanges: 'Attiva Account',
    passwordFieldTitle: 'Password',
    repeatPasswordFieldTitle: 'Ripeti la password',
  },

  validation: {
    required: 'Campo Obbligatorio*',
    emailPattern: 'Indirizzo email errato',
    passwordLength: 'La password deve avere almeno 6 caratteri',
    passwordDifferent: 'Le passwords non coincidono',
    passwordNotIncludeSpaces: 'La password non deve includere spazi',
    invalidCreds: 'Email o password non valida',
    userImageUploadError: `Image can't be saved`,
    invalidEmail: 'Indirizzo email non riconosciuto',
  },

  menu: {
    dashboard: 'Inizio',
    notifications: 'Notifiche',
    catalogue: 'Catalogo',
    invoices: 'Fatture',
    settings: 'Impostazioni',
    management: 'Gestione',
    quotes: 'Preventivi',
    orders: 'Ordini',
    support: 'Assistenza',
    myAccount: 'Il mio account',
    logOut: 'Esci',
    profile: 'Profilo',
    usersPermissions: 'Utenti e autorizzazioni',
  },

  dropzone: {
    dropText: 'Drop the files here ...',
    dragText: 'Drag File here or',
    dragClickableText: 'choose from your computer',
  },

  toastMessages: {
    updateSuccess: 'Le informazioni sono state aggiornate con successo',
    updateError: 'Le informazioni non possono essere aggiornate',
  },

  toastErrorMessages: {
    wrongOldPassword: 'La password attuale è errata',
  },

  benefits: {
    title: 'Scopri i vantaggi di Trade account - Registrati adesso!',
    subTitle:
      'Il portale clienti è stato sviluppato per aiutare i nostri partner a noleggiare regolarmente le attrezzature in modo semplice e rapido. Inserisci tutte le tue informazioni personali o quelle della tua azienda una sola volta. Fatto!',
    discountMemberships:
      'Sblocca sconti vantaggiosi con i tuoi volumi di noleggio',
    noDeposit: 'Evita il deposito ogni volta sul tuo ordine',
    paymentMethods: 'Sblocca piú metodi di pagamento',
    payLater: 'Posponi il pagamento fino a 15 giorni',
    orders:
      'Conserva tutti tuoi ordini recenti, passati e i pagamenti in un unico posto.',
    catalogue: 'Catalogo sempre aggiornato con le novità più recenti.',
    percent:
      'Accesso immediato alle nostre offerte speciali, sconti e promozioni.',
    chat: 'Chat di supporto e assistenza rapida, prenotazioni in soli 5-10 minuti.',
  },

  customerLogin: {
    header: 'Accesso al Trade Account',
    emailFieldTitle: 'Email',
    passwordFieldTitle: 'Password',
    forgotPassword: 'Hai dimenticato la tua password',
    register: 'Registrati',
    submit: 'Login',
  },

  customRegister: {
    header: 'Registrazione sul Trade Account',
    subHeader: 'Per favore, seleziona a quale categoria appartieni',

    privateTab: 'Privato',
    companyTab: 'Azienda',

    fiscalCodeFieldTitle: 'Codice Fiscale',
    officeAddressFieldTitle: 'Indirizzo Sede Legale',
    postalCodeFieldTitle: 'CAP',
    provinceFieldTitle: 'Regione',
    sectorOfActivity: 'Settore di appartenenza?',
    uniqueCodeFieldTitle: 'Codice Univoco',
    PECFieldTitle: 'PEC',
    howDidYouKnowAboutUs: 'Come hai conosciuto la nostra azienda',

    generalInformation: 'Informazioni Generali',
    contactInformation: 'Informazioni di Contatto',
    contactPhoneNumber: 'Numero di Telefono',
    contactEmailAddress: 'Indirizzo E-mail',
    backToLogin: 'Torna alla Pagina di Login',
    businessName: 'Ragione Sociale',

    thankYouTitle: "Grazie per l'iscrizione!",
    thankYouSubTitle:
      'Hai completato con successo la registrazione al Portale Clienti. Per favore, controlla la tua posta elettronica e segui le istruzioni che ti abbiamo inviato',
    goToLogin: "Esegui l'accesso",
    privacyCheckbox: "Ho letto l'informativa",
    consentCheckbox:
      'Acconsento alla ricezione di comunicazioni commerciali, incluse quelle automatizzate, su prodotti e servizi offerti da {0} e/o sue collegate.',
  },

  sectorOfActivity: {
    business: 'Attività Commerciale',
    constructions: 'Edilizia',
    public_authority: 'Ente Pubblico',
    training_courses: 'Formazione',
    gardening: 'Giardinaggio',
    industry: 'Industria',
    services: 'Servizi',
    transport: 'Trasporti',
    tourism: 'Turismo',
    other: 'Altro',
  },

  aboutUs: {
    search_on_the_internet: 'Ricerca Su Internet',
    television: 'Televisione',
    word_of_mouth: 'Passaparola',
    headquarter_visibility: 'Visibilità Sede',
    construction_warehouse: 'Magazzino Edile',
    gardening_dealer: 'Rivenditore Giardinaggio',
    magazines_and_newspapers: 'Riviste e giornali',
    other: 'Altro',
  },

  emptySection: {
    noInfo:
      'A oggi non hai ancora effettuato {0}. Per eseguire un ordine consulta il nostro {1}.',
    callUs:
      'Per maggiori informazioni non esitare a contattarci al numero verde {0}',
  },

  noDiscount: {
    title: 'Sconti speciali per i nostri abbonati',
    description:
      'Abbiamo quattro tipi di scontistiche riservate ai nostri clienti: {0}, {1}, {2} e {3}. Contattaci per sapere come avere diritto ad uno sconto su tutti i prodotti presenti nel nostro {4}.',
  },

  loginErrors: {
    incorrectCredentials: `E-mail o password non validi`,
    accountIsNotActivated: "L'account non è ancora stato attivato",
  },

  paymentMethods: 'Metodi di pagamento',
  pleaseFillAllFields:
    'Nel tuo profilo mancano alcune informazioni. Compila tutti i campi vuoti qui sotto.',
  membershipDiscount: 'Sconto Membro',
  selectSegment: 'A quale segmento appartieni?',
  continue: 'Continua',
  continueWithGoogle: 'Continua con Google',
  or: 'o',
  registerWithEmail: `Registrati con l'email`,
  signedInWithGoogle: 'Accedi con Google',
  signedInWithRegisteredEmail: `Abbiamo inviato un messaggio a {0}.`,
  signedInWithRegisteredEmailCheck:
    'Controlla la tua mail e segui le indicazioni.',
  membershipTooltip: `{0} è il tuo piano attuale con {1}. Contattaci per saperne di più sul piano {2}. Contattaci per saperne di più sul piano {3}.`,
  basic: 'Basic',
  standard: 'Standard',
  premium: 'Premium',
};
