export const orders = {
  order: 'Order',
  orders: 'Orders',
  viewOrder: 'View order',
  viewInvoice: 'View invoice',
  viewQuote: 'View quote',
  viewQuotes: 'View quotes',
  productList: 'Product List',
  youWantToCancelThisOrder: 'Are you sure you want to cancel this order?',
  provideCancellationReason: 'If yes, please provide cancellation reason',
  enterNewEndHireDateAndReason:
    "Please, let us know a new end hire date and a reason for this change. We'll contact you shortly!",
  endHireDate: 'New End Hire Date',
  provideReasonForThisChange: 'Provide a reason for this change',

  item: {
    orderStatus: 'Order Status',
    date: 'Date Created',
    transport: 'Transport',
    paymentType: 'Payment Type',
    productList: 'Product List',
    documents: 'Documents',
  },

  table: {
    orderId: 'Order ID',
    orderNumber: 'Order number',
    quoteNumber: 'Quote number',
    invoiceDate: 'Invoice Date',
    status: 'Status',
    payment: 'Payment',
    startDate: 'Rent Start',
    endDate: 'Rent End',
    purchaseOrderNumber: 'Purchase Order Number',
  },

  paymentType: {
    card: 'Credit Card',
    cash: 'Cash',
    bank: 'Bank Transfer',
    other: 'Other',
  },

  statusType: {
    error: 'Error',
    paid: 'Paid',
    not_paid: 'Not Paid',
    new: 'New',
    in_progress: 'In Progress',
    completed: 'Completed',
    cancelled: 'Cancelled',
    all: 'All Orders',
  },

  backTo: 'Back to orders listing',
  backToQuotes: 'Back to quotes listing',
};
