import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { ReactComponent as FacebookIcon } from 'assets/images/icon/socialMedia/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'assets/images/icon/socialMedia/linkedin.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/icon/socialMedia/twitter.svg';
import { ReactComponent as WhatsappIcon } from 'assets/images/icon/socialMedia/whatsapp.svg';

export const SocialMediaButtons = ({
  title,
  url,
  hashtag,
  summary,
  classNameWrapper,
  classNameButtons,
}) => {
  return (
    <div className={classNameWrapper}>
      <FacebookShareButton
        url={url}
        quote={title}
        hashtag={hashtag}
        className={classNameButtons}
      >
        <FacebookIcon />
      </FacebookShareButton>

      <WhatsappShareButton url={url} title={title} className={classNameButtons}>
        <WhatsappIcon />
      </WhatsappShareButton>

      <LinkedinShareButton
        url={url}
        title={title}
        summary={summary}
        className={classNameButtons}
      >
        <LinkedinIcon />
      </LinkedinShareButton>

      <TwitterShareButton
        url={url}
        title={title}
        hashtag={hashtag}
        className={classNameButtons}
      >
        <TwitterIcon />
      </TwitterShareButton>
    </div>
  );
};
