import { createEffect, createStore, restore } from 'effector';
import { createGate } from 'effector-react';

export const WarehousesGate = createGate('warehouses');

export const getWarehousesFx = createEffect();

export const isWarehousesLoading$ = restore(
  getWarehousesFx.pending.updates,
  false,
);

export const isWarehousesRequestFinished$ = createStore(false).on(
  getWarehousesFx.done,
  (_) => true,
);

export const $warehouses = restore(getWarehousesFx, []);

export const isWarehouses$ = $warehouses.map((el) => !!el?.length);
