import { useEffect, useState } from 'react';

export const useIntersection = (
  element = { current: null },
  rootMargin,
  threshold,
) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!element.current) {
      return;
    }

    try {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(
            entry.intersectionRect.y >= 0 &&
              entry.intersectionRect.y <= window.innerHeight &&
              entry.isIntersecting,
          );
        },
        { rootMargin, threshold },
      );

      const currNode = element.current;
      currNode && observer.observe(currNode);

      return () => {
        try {
          observer.unobserve(currNode);
        } catch (e) {
          console.warn(e);
        }
      };
    } catch (e) {
      console.warn(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.current, rootMargin]);

  return isVisible;
};
