import React, { useContext } from 'react';
import clsx from 'clsx';
import { isETH } from 'consts';
import { SettingsContext } from 'contexts';
import { Lang } from 'lang';

import { ReactComponent as NoDepositLogo } from 'assets/images/icon/benefits/NoDepositLogo.svg';
import { ReactComponent as PayLaterLogo } from 'assets/images/icon/benefits/PayLaterLogo.svg';
import { ReactComponent as PaymentMethodsLogo } from 'assets/images/icon/benefits/PaymentMethodsLogo.svg';
import { ReactComponent as PercentLogo } from 'assets/images/icon/benefits/PercentLogo.svg';
import { ReactComponent as ListOrdersIcon } from 'assets/images/icon/ListOrders.svg';

import style from './benefits.module.scss';

export const Benefits = ({ customBenefitsItem, customBenefitsWrapper }) => {
  const { profile: profileLocalization } = Lang();

  const settings = useContext(SettingsContext);

  return (
    <div className={clsx(style.benefitsWrapper, customBenefitsWrapper)}>
      {isETH ? (
        <>
          <div
            className={clsx(style.informationItemWrapper, customBenefitsItem)}
          >
            <div className={style.layoutItemWrapper}>
              <ListOrdersIcon />
            </div>
            <span>View order history for speedy reordering</span>
          </div>
          <div
            className={clsx(style.informationItemWrapper, customBenefitsItem)}
          >
            <div className={style.layoutItemWrapper}>
              <PaymentMethodsLogo />
            </div>
            <span>Unlock more payment methods</span>
          </div>
          <div
            className={clsx(style.informationItemWrapper, customBenefitsItem)}
          >
            <div className={style.layoutItemWrapper}>
              <PayLaterLogo />
            </div>
            <span>Option to apply for a credit account</span>
          </div>
        </>
      ) : (
        <>
          {settings.showDiscountsSectionInCP && (
            <div
              className={clsx(style.informationItemWrapper, customBenefitsItem)}
            >
              <div className={style.layoutItemWrapper}>
                <PercentLogo />
              </div>
              <span>{profileLocalization.benefits.discountMemberships}</span>
            </div>
          )}
          <div
            className={clsx(style.informationItemWrapper, customBenefitsItem)}
          >
            <div className={style.layoutItemWrapper}>
              <NoDepositLogo />
            </div>
            <span>{profileLocalization.benefits.noDeposit}</span>
          </div>
          <div
            className={clsx(style.informationItemWrapper, customBenefitsItem)}
          >
            <div className={style.layoutItemWrapper}>
              <PaymentMethodsLogo />
            </div>
            <span>{profileLocalization.benefits.paymentMethods}</span>
          </div>
          <div
            className={clsx(style.informationItemWrapper, customBenefitsItem)}
          >
            <div className={style.layoutItemWrapper}>
              <PayLaterLogo />
            </div>
            <span>{profileLocalization.benefits.payLater}</span>
          </div>
        </>
      )}
    </div>
  );
};
