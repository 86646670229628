import { getDisplayedCompanyName } from 'consts';
import { createEffect, createStore, restore } from 'effector';

export const getBrandingFx = createEffect();

export const branding$ = restore(getBrandingFx, {
  companyName: getDisplayedCompanyName(),
  currencySymbol: '',
});

export const isBrandingRequestFinished$ = createStore(false).on(
  getBrandingFx.done,
  (_) => true,
);

export const brandingLoaded$ = createStore(false).on(
  getBrandingFx.doneData,
  (_, data) => true,
);
