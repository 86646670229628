export const footer = {
  titles: {
    supportedRegions: 'Regiões apoiadas',
  },

  copyright: 'Copyright © {0} {1}. Todos os preços estão sujeitos a IVA.',
  termsConditions: 'Termos e Condições',
  cookiePolicy: 'Política de Cookies',
  generalTerms: 'Termos gerais',
  privacyPolicy: 'Política',
  instantQuote: 'Cotação instantânea',
  training: 'Treinamento',
  information: 'Informação',
  aboutUs: 'Sobre nós',
  blog: 'Blog',
  faq: 'Perguntas frequentes',
  securePayments: 'Secured payment',

  thisWebsite: 'This website is Powered by',
  rentuuOperates:
    'easyHire Technologies Ltd operates a global, fast-growing, cloud-based technology platform and franchise network for the multi-billion pound sterling equipment hire industry.',
  learnMoreEasyFamilyOfBrands:
    'Visite {0} e saiba mais sobre a família de marcas easy.',
  learnMoreEasyHistory: 'Visite {0} e conheça a história da marca.',

  followUs: 'Siga-nos',
};
