export const carbonEmission = {
  pageTitle: 'Noleggia ‘Carbon Neutral’',

  ourCommitment: {
    title: 'Il nostro impegno',
    text1:
      "Dopo un anno dal lancio, {0} ha deciso di intraprendere la lunga strada per diventare un'azienda carbon neutral, prendendo parte a progetti e soluzioni per il cambiamento climatico e offrendo la possibilità di partecipare a tutti i clienti finali.",
    text2:
      "Per ogni noleggio effettuato compensiamo le emissioni di anidride carbonica derivanti dall'utilizzo del macchinario noleggiato, investendo in progetti sostenibili. Siamo tutti consapevoli che l'impegno verso l'ambiente non finisca qui, ma questo è il nostro primo passo per ridurre le emissioni che ci coinvolgono direttamente come business.",
  },

  howDoesItWork: {
    title: 'Come lo facciamo',
    text1:
      'Ci impegniamo per ottenere il miglior risultato compensando le nostre emissioni di CO2. Per raggiungere questo obiettivo, collaboriamo con FirstClimate, leader nelle soluzioni di compensazione del carbonio e di energia rinnovabile. La compensazione delle emissioni si basa su un contributo finanziario che paghiamo per sostenere progetti di sostenibilità ad alto impatto.',
    text2:
      "{0} si farà carico dei costi della compensazione. Lavoriamo continuamente per migliorare l'efficacia dei calcoli delle emissioni di CO2.",
    carbonEmission: 'Compensiamo le emissioni di CO2 per ogni noleggio',
    team: 'Copriamo noi il costo totale della compensazione',
    environment:
      "Prendiamo parte a progetti sostenibili volti a ridurre il nostro impatto sull'ambiente",
    analyticsData:
      'Lavoriamo tutti i giorni per migliorare e rendere sempre più efficiente il calcolo delle emissioni prodotte',
  },

  supportWithFirstClimate: {
    title: 'Il progetto che sosteniamo con',

    backgroundTitle: 'Background',
    backgroundText1:
      "Dal 1990, l'Indonesia ha perso quasi un quarto della sua copertura forestale. Le foreste pluviali del Borneo, come in molte aree tropicali, sono minacciate da deforestazione, estrazione di minerali e produzione di olio di palma, cellulosa e gomma.",
    backgroundText2:
      "Il problema continua a peggiorare poiché le aree sgomberate forniscono un facile accesso a zone precedentemente remote della foresta. Un accesso più facile ha anche causato la crescita del commercio illegale di animali selvatici, rappresentando un'ulteriore minaccia per la fauna selvatica nell'area. È stato stimato che un'area equivalente ad un campo di calcio viene deforestata ogni minuto.",
    backgroundText3:
      "Le foreste torbiere del Borneo contengono fino a 70 volte più carbonio rispetto alle quantità emesse ogni anno dalla combustione di combustibili fossili in tutto il mondo. Pertanto, il degrado delle foreste può comportare una drastica perdita di stock di carbonio. Inoltre, l'area è straordinariamente ricca di biodiversità e ospita centinaia di specie in via di estinzione minacciate da un eccessivo sfruttamento.",

    projectTitle: 'Il progetto',
    projectText1:
      "La Riserva di Rimba Raya si trova nel sud-est del Borneo e si estende per quasi 100 chilometri da nord a sud lungo i confini del Parco Nazionale Tanjung Puting. La riserva si estende per circa 64.000 ettari ed è dedicata alla protezione e alla conservazione di molte specie in via di estinzione, in particolare, l'orangutan Bor-nean in via di estinzione la cui popolazione è diminuita di oltre il 95% nel secolo scorso. Per proteggere le aree della foresta, è necessario ottenere i diritti di utilizzo del suolo per i terreni che confinano con le aree protette. Finora, il finanziamento del progetto supporta 13 iniziative basate sulla comunità che vanno dalla filtrazione dell'acqua al sostegno di una cooperativa di acquacoltura di gamberetti.",
    projectText2:
      'Pur concentrandosi sulla riduzione delle emissioni di gas serra, tutti i nostri progetti generano anche molteplici vantaggi collaterali. Questi supportano gli obiettivi di sviluppo sostenibile delle Nazioni Unite.',

    technologyBriefTitle: 'Tecnologia - come funziona',
    technologyBriefText1:
      "Il carbonio circola all'interno di un ciclo, costituito dall'atmosfera, dalla pianta, dai rifiuti vegetali e dal suolo. L'anidride carbonica estratta dall'atmosfera circostante è l'input principale dei processi di fotosintesi di qualsiasi pianta. Gli outputs sono acqua, ossigeno e carboidrati. Questi ultimi sono incorporati nella fibra della pianta fissando così il carbonio nella biomassa della pianta. Alla fine, il carbonio rientra nell'atmosfera dalla decomposizione dei rifiuti di biomassa o dalla respirazione del suolo.",
    technologyBriefText2:
      "La deforestazione interrompe questo ciclo con molteplici effetti negativi. In primo luogo, la combustione della biomassa aumenta direttamente la quantità di anidride carbonica nell'atmosfera. In secondo luogo, riduce la capacità assoluta della biosfera di fissare il carbonio. In terzo luogo, la rimozione della copertura vegetale accelera la velocità con cui il carbonio fissato nel suolo viene respirato nell'atmosfera. Infine, l'erosione dei suoli impedisce il recupero a lungo termine della vegetazione nelle aree degradate. Questo è un problema particolarmente impegnativo nei climi tropicali dove i suoli sono per lo più poveri di sostanze nutritive.",

    projectStandardsTitle: 'Project Standard',
    projectStandardsVCSboldText: 'Il Verified Carbon Standard (VCS)',
    projectStandardsVCSText:
      'è uno standard globale per la validazione e la verifica delle riduzioni volontarie delle emissioni di carbonio. Le riduzioni delle emissioni dei progetti VCS devono essere reali, misurabili, permanenti, aggiuntive, uniche, trasparenti e verificate da terze parti. Valutato sullo sfondo del volume totale di riduzione delle emissioni, VCS è lo standard leader a livello mondiale per la compensazione volontaria del carbonio.',
    projectStandardsCCBSboldText:
      'Gli standard per il clima, la comunità e la biodiversità (CCB)',
    projectStandardsCCBSText:
      'sono stati lanciati nel 2005 per promuovere lo sviluppo e gli investimenti in progetti basati sul sito che forniscono vantaggi credibili e significativi per il clima, la comunità e la biodiversità in modo integrato e sostenibile.',

    additionalText:
      '* I dati del calcolo delle emissioni sono basati su stime medie di categoria di prodotto. Ogni giorno lavoriamo per migliorarci ed aggiornare i calcoli',
  },
};
