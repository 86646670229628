import { useMemo } from 'react';

export const useGetExtraFieldsValues = (extraFields = [], data = {}) => {
  return useMemo(() => {
    return extraFields.reduce((extraFieldsValues, el) => {
      if (!el.type || el.type === data.type) {
        extraFieldsValues[el.name] = data[el.name] || null;
      }
      return extraFieldsValues;
    }, {});
  }, [data, extraFields]);
};
