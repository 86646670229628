export const account = {
  header: 'Mi cuenta',
  subHeader:
    'Todas las configuraciones y preferencias de tu cuenta en una página',

  save: 'Guardar',
  saveChanges: 'Guardar cambios',
  cancel: 'Cancelar',

  sections: {
    personalInformation: 'Datos Personales',
    userType: 'Particular',
    deliveryAddress: 'Dirección de entrega',
    authentication: 'Autenticación',
    emailNotifications: 'Notificaciones de correo electrónico',
  },

  addresses: {
    addressBlockHeader: 'Dirección',
    makeDefault: 'Por Defecto',
    edit: 'Modificar',
    delete: 'Borrar',
    defaultMark: 'Dirección principal',
    addressLine: 'Dirección',
    chooseCorrectAddress:
      'Per favore seleziona l’indirizzo corretto in questa lista',
  },

  personal: {
    title: 'Valores de la Marca',
    profileImage: 'Imagen de perfil',
    uploadFile: 'Subir archivo ...',
    deleteBtn: 'Borrar',
  },

  formFields: {
    nameFieldTitle: 'Nombre y apellidos',
    nameFieldPlaceholder: 'Ingresa tu nombre',
    emailFieldTitle: 'Dirección de correo electrónico',
    emailFieldPlaceholder:
      'Por favor, introduce tu dirección de correo electrónico',
    phoneFieldTitle: 'Número de teléfono',
    phoneFieldPlaceholder: 'Introduce tu teléfono',
  },

  youAreLoggedInAs: 'Has accedido como',
};
