import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { PATHS } from 'AppPaths';
import { sendGAEvent } from 'googleAnalytics';
import { Lang } from 'lang';
import { isBot } from 'utils/helpers/getUserBrowserInfo';
import { sendMsgToSlack } from 'utils/sendMsgToSlack';

import { history } from 'libs/history';

import { Button, Row, T8y } from 'ui';

import { ReactComponent as EasyBrands } from 'assets/images/icon/easyBrands.svg';
import { ReactComponent as ErrorSign } from 'assets/images/icon/errorSign.svg';

import style from './errorFallback.module.scss';

export const ErrorFallback = ({ error }) => {
  const { error: errorLocalized, formatString } = Lang();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && !isBot) {
      sendMsgToSlack(error);
      sendGAEvent('error-something-went-wrong');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshPage = useCallback(() => {
    if (window.location?.pathname.includes(PATHS.NOT_FOUND)) {
      window.location.replace(PATHS.ROOT);
    } else {
      window.location.reload();
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta name="prerender-status-code" content="503" />
      </Helmet>
      <Row align="center" justify="center" className={style.errorHeaderWrapper}>
        <div
          className={style.logoWrapper}
          onClick={() => {
            history.push(PATHS.ROOT);
          }}
          data-testid="go-home-button"
        >
          <EasyBrands />
        </div>
      </Row>
      <Row
        direction="column"
        align="center"
        justify="center"
        className={style.errorPageContainer}
      >
        <div className={style.errorLogoWrapper}>
          <ErrorSign />
        </div>
        <T8y className={style.errorTitle} center bold>
          {errorLocalized.critical.title}
        </T8y>
        <T8y className={style.errorSubTitle} center>
          {errorLocalized.critical.subTitle}
        </T8y>
        <T8y className={style.refreshText} center>
          {errorLocalized.critical.refreshAndTryAgain}
        </T8y>
        <Button onClick={handleRefreshPage} className={style.refreshButton}>
          {errorLocalized.critical.refreshThePage}
        </Button>
        <T8y className={style.errorText}>
          {formatString(
            errorLocalized.critical.text,
            <T8y
              variant="t2"
              as="a"
              color="primary"
              href="mailto:office@rentuu.com"
              ml
            >
              {errorLocalized.critical.sendEmail}
            </T8y>,
          )}
        </T8y>
      </Row>
    </div>
  );
};
