import React from 'react';
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom';
import {
  ALGOLIA_SEARCH_AUTO_SUGGESTIONS,
  ALGOLIA_SEARCH_CATEGORIES_INDEX,
  ALGOLIA_SEARCH_INDEX,
  ALGOLIA_SEARCH_TRAININGS_INDEX,
} from 'consts';
import { getStandardSearchClient } from 'utils/formatUrl';

const AlgoliaAutocompleteSearchWrapper = ({ children }) => {
  const searchClient = getStandardSearchClient();

  return (
    <InstantSearch indexName={ALGOLIA_SEARCH_INDEX} searchClient={searchClient}>
      <Index indexName={ALGOLIA_SEARCH_CATEGORIES_INDEX}>
        <Configure hitsPerPage={5} />
      </Index>
      <Index indexName={ALGOLIA_SEARCH_AUTO_SUGGESTIONS}>
        <Configure hitsPerPage={5} />
      </Index>
      <Index indexName={ALGOLIA_SEARCH_TRAININGS_INDEX}>
        <Configure hitsPerPage={50} />
      </Index>
      <Configure hitsPerPage={200} />
      {children}
    </InstantSearch>
  );
};

export default AlgoliaAutocompleteSearchWrapper;
