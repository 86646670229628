export const checkout = {
  table: {
    visual: 'Immagine',
    productTitle: 'Nome prodotto',
    hiringPeriod: 'Durata del Noleggio',
    hirePerItem: 'Costo per articolo',
    qty: 'Qta',
    hirePrice: 'Prezzo di noleggio',
  },

  changeExtrasModal: {
    requiredExtrasTitle: 'Scegli gli accessori extra obbligatori',
    optionalExtrasTitle: 'Scegli accessori extra opzionali',
    subTitle: 'Seleziona gli accessori extra e indica la quantità',
  },

  getQuote: 'Ricevi Preventivo',
  checkoutReservation: 'Procedi con la prenotazione',
  checkout: 'Vai al checkout',
  emailMe: 'Inviami il preventivo via Email',
  transportationRoute: 'Percorso Trasporto',

  quoteHasExpiredTitle: 'Il vostro preventivo è scaduto',
  quoteHasExpiredSubTitle: 'Purtroppo il preventivo ricevuto è scaduto.',
  quoteHasExpiredText:
    'I nostri preventivi sono validi per 7 giorni e questo lasso di tempo è scaduto. Per creare un nuovo preventivo, sfogliare il nostro {0} e seguire i passaggi per richiedere un preventivo.',
  viewCatalogue: 'Visualizza il catalogo',

  press: 'Clicca',
  change: 'Cambia',
  optionalExtra: 'Accessori Extra Opzionali',
  requiredExtra: 'Accessori Extra Obbligatori',
  requiredConsumable: 'Materiali di consumo richiesti',
  changeExtra: 'Modifica Extra',
  for: 'per',
  free: 'Gratuito',

  addMoreProducts: 'Aggiungi altri articoli',
  payNow: 'Paga Ora',

  deliveryCollection: 'Indirizzo di consegna e ritiro',
  selfCollectionText:
    'Ritira e riconsegna i prodotti ordinati presso una delle nostre filiali, ma assicurati di avere spazio sufficiente per il loro trasporto.',
  deliveryText:
    "I prodotti saranno consegnati e successivamente ritirati all'indirizzo da te indicato. Verrà addebitato un costo di trasporto in base alla tua posizione.",
  comingSoon: 'Presto disponibile',

  emptyData: 'Vuoto',
  openingTimes: 'Orario di apertura',
  select: 'Seleziona',
  selected: 'Selezionato',
  chooseAnotherDepot: 'Scegli un altro magazzino',
  chooseDepot: 'Scegli il magazzino',
  impossibleDelivery:
    'Per richiedere la consegna dei prodotti presso la tua sede, puoi contattarci al numero {0} o {1}.',
  impossibleSelfCollection:
    "L'opzione di ritiro in sede non è disponibile per questo prodotto. Si prega di procedere con l'opzione di consegna o di contattarci a  {0} per qualsiasi domanda.",

  italy: 'Italia',

  fields: {
    hireStartDate: 'Data Inizio Noleggio',
    hireEndDate: 'Data Fine Noleggio',
    chooseStartDate: 'Scegli la data di inizio',
    chooseEndDate: 'Scegli la data di fine',
    deliveryTimeSlot: 'Delivery Time Slot',
    collectionTimeSlot: 'Collection Time Slot',
    addressLine: 'Via e numero civico',
    city: 'Città',
    deliveryPostcode: 'CAP',
    country: 'Paese',
    vatNumber: 'Partita IVA',
    taxNumber: 'Codice Fiscale',
    fullName: 'Nome e Cognome',
    phoneNumber: 'Numero di telefono',
    emailAddress: 'Indirizzo email',
    deliveryAddress: 'Delivery Address',
  },

  placeholders: {
    startDate: 'DD/MM/YYYY',
    endDate: 'DD/MM/YYYY',
    deliveryTime: 'Delivery Time',
    collectionTime: 'Collection Time',
    address: 'Indirizzo',
    ofDelivery: ' di consegna',
    postcode: 'CAP',
    findPostcode: 'Inizia a digitare il tuo codice postale',
  },

  titles: {
    yourQuote: 'Il tuo preventivo',
    chooseHowYou: 'Scegli come procedere',
    pleaseEnter: 'Inserisci le seguenti informazioni',
    payWithCard: 'Paga con Carta',
    hiringPeriod: 'Periodo di Noleggio',
    estimatePerWeek: 'Costo',
    deliveryMethod: 'Logistica',
    invalidCard: 'Numero di carta non valido',
    invalidCardTryAgain: 'Qualcosa è andato storto, riprova un’altra volta',
    contactInformation: 'Informazioni di contatto',
    selfCollection: 'Ritiro',
    delivery: 'Consegna',
    selectYour: 'Utente',
    enterYour: 'Inserisci i tuoi dati di contatto',
    individual: 'Privato',
    company: 'Azienda',
    accountType: 'Account {0}',
    selectWhich: 'Seleziona il magazzino per il ritiro',
    yourReservation: 'La tua Prenotazione',
    selfCollectionDepot: 'Ritiro in Sede',
    deliveryAddress: 'Delivery at',
    yourDetalis: 'I tuoi Dati',
    paymentMethod: 'Modalità di pagamento',
    loggedInAs: 'accesso effettuato come',
    makeAddress: "Rendi l'Indirizzo Predefinito",
    accountSection: 'Hai già un {0} account?',
    depositType: 'Deposit Type',
    depositProceed: 'Default deposit',
    creditAccountProceed: 'Deposit based on your credit records',
    findDeliveryByPostcode: "Cerca l'indirizzo per codice postale",
    findBillingAddress: 'Find billing address by Post Code',
  },

  radio: {
    payWithCredit: 'Paga con Carta di Credito',
    payByBank: 'Paga con bonifico bancario',
    payByBankText: "Ti invieremo un'email  con tutti i dettagli",
    payAtSelf: 'Paga presso il punto di ritiro',
    payLater: 'Pay in 90 days - no interest, no fees.',
    payLaterText:
      'Two lets your business pay later for the items you hire online, You will receive an invoice from Two when your order has been processed.',
    payAtTrainingLocation: 'Pagamento presso la sede del Corso',
    otherMethods: 'Altro metodo di pagamento',
    otherMethodsText:
      'Sarai contattato da un nostro rappresentante per scegliere uno dei metodi di pagamento disponibili',
  },

  details: {
    createAccount: 'Profilo su {0}',
    no: 'No',
    yes: 'Si',
  },

  guest: 'Continua come ospite',
  guestText: 'Verrà automaticamente create un trade account.',
  oldUser: 'Ho un account {0}',
  newUser: 'Create {0} account (will help to checkout faster next time)',
  go: 'Procedi',
  logOut: 'Esci',
  dontHaveAccount: 'Non hai ancora un account?',
  singUpSuccess: 'Thank you for signing up! Log in to continue.',

  stripeNote:
    'Ci serviamo del servizio offerto da Stripe per processare i pagamenti. Stripe ha ottenuto la certificazione Payment Card Industry Data Security Livello 1, che si colloca al livello più alto per quel che riguarda la sicurezza nel settore dei pagamenti online.',

  descriptions: {
    fillFewFields:
      'Fornisci alcune informazioni aggiuntive così da verificare il prezzo finale e ricevere un preventivo direttamente in email',
    shareAllThe:
      'Fornisci le informazioni richieste per il noleggio, visualizza il costo totale e procedi con il pagamento.',
    paymentSecured: 'Pagamento sicuro SSL',
    leaveTheseFields:
      'Lascia questi campi vuoti se non sai ancora le date del tuo progetto. Ti invieremo una email con il costo settimanale stimato.',
    theLossDamage:
      'The loss & damage deposit will be returned to your card upon safe return of the inventory',
    yourQuoteIsEmpty:
      'Il tuo carrello {0} è vuoto. Sfoglia il nostro {1} e crea la tua lista di prenotazioni.',
    callUs: 'Chiamaci al numero verde {0} per qualsiasi consulta.',
    startTyping: 'Inserisci il tuo indirizzo di consegna',
    enterAddress: "Inserisci l'indirizzo di consegna",
    depositProceed:
      'The deposit in your cost summary will be calculated using our default settings and will reflect the full replacement value of the item',
    creditAccountProceed:
      'The deposit will be defined and confirmed with you using your credit records. We will email you an electronic Credit Application form with further details. Use this option if you would like to decrease your deposit',
    addressChosen: 'Indirizzo di consegna selezionato',
    billingAddress: 'Billing Address',
    enterBillingAddress: 'Enter billing address',
    changeBillingAddress: 'Change billing address',
    sameAsDeliveryAddress: 'Same as Delivery Address',
  },

  success: {
    yourEnquiry: 'Abbiamo ricevuto la tua richiesta',
    youWill:
      "A breve riceverai un email con il preventivo. Potrai utilizzare il preventivo ricevuto per inoltrare l'ordine. Grazie",
    youShould:
      'Riceverai presto una email di conferma con i dettagli del tuo ordine',
    goTo: 'Vai al tuo profilo',
    availableAddress: 'Consegna: ',
  },

  errors: {
    outOfRegion:
      'Siamo spiacenti, hai inserito un indirizzo fuori dalla regione {region}. Valuta il ritiro in sede gratutito in uno dei nostri depositi, chiamaci al numero {phone} oppure verifica se il prodotto è disponibile nella tua regione visitando la nostra {homepage}.',
    outOfDistance:
      'Siamo spiacenti, ma non effettuiamo consegne al tuo indirizzo.\n' +
      'Utilizza il Ritiro in Sede e preleva i prodotti da uno dei nostri depositi, è gratis.',
    postalCodeDefined:
      'Il prezzo di trasporto nella tua area resta da definire, puoi procedere con il ritiro alla sede o contattarci',
    differentTypes: `Per alcuni prodotti presenti nel tuo carrello, non è disponibile la consegna. Seleziona un deposito dove ti è comodo ritirare il prodotto. Oppure toglilo dal carrello`,
    noWarehouses: 'Products are not available at our Self-Collection depots',
    sameDay:
      'Non è possibile effettuare la consegna in giornata o nel fine settimana per questi prodotti. Per favore, modifica il periodo di noleggio o scegli il ritiro in sede:',
  },

  steps: {
    title: 'Pagamento',
    payConfirmation: 'La tua Prenotazione',
    delivery: 'Ritiro in Sede o Consegna',
    details: 'I tuoi Dati',
    confirmAndPay: 'Conferma e Paga',
  },

  toastMessages: {
    requiredWarehouse: 'Devi selezionare una filiale',
    requiredAddress:
      "L'indirizzo di consegna deve essere inserito correttamente",
  },

  promo: {
    title: 'Hai un codice sconto?',
    placeholder: 'Inserisci il codice qui',
    apply: 'Ricalcola',
    remove: 'Cancella',
    success:
      'Il tuo codice promozionale verrà applicato al riepilogo dei costi!',
    notValid: 'Oops, il tuo codice promozionale non è valido',
    promoAlreadyRedeemed: 'Codice promozionale già utilizzato',
    promoForAuthorizedUsersOnly:
      'Questa promozione è disponibile solo per gli utenti autorizzati',
    promoDoesntExist: 'Questo codice promozionale non esiste',
    codeHasExpired: 'Questo codice promozionale è scaduto',
    promoNotValidForProducts:
      'Questo codice promozionale non è valido per i tuoi prodotti',
    doYouHavePromotional: 'Hai un codice promozionale?',
    promotionalCode: 'Codice promozionale',
    promoCodeApplied: 'Codice promozionale applicato',
    promoCodeNote:
      'Il codice promozionale può essere applicato solo una volta, durante il checkout',
    promoForNonAuthorizedUsersOnly:
      'Questa promozione è disponibile solo per gli utenti non autorizzati',
  },

  generateUrl: 'Genera URL per questa lista di prenotazioni',
  getEmailQuote: 'Ricevi il preventivo via Email',
  urlIsCopied: "L'URL è stato copiato negli appunti",
  enterComment: 'Inserisci il tuo commento  (facoltativo)',
  confirmedAvailability: 'disponibilità  confermata',
  futureDelivery:
    'I costi della consegna al tuo indirizzo saranno definiti in seguito.',
  deliveryNotAvailable: 'Non disponibile',
  findNewAddress: 'Cerca nuovo indirizzo',
  backToResults: 'Torna ai risultati',
  continueShopping: 'Continua lo shopping',
  proceedViewFullPayment: 'Vedi sommario costi',
  backToReservation: 'Torna alla prenotazione',
  selectDeliveryViewCostSummary:
    'Seleziona il metodo di consegna per vedere il sommario costi completo',
  selectPayment:
    'Seleziona il metodo di pagamento per confermare il tuo ordine',
  continueToDelivery: 'Continua su “Consegna”',
  continuePayment: 'Continua con il pagamento',
  placeOrder: 'Ordina',
  alreadyHaveAnAcc: 'Hai già un account?',
  dontHaveAnAcc: 'Non hai un account?',
  imCompany: 'Sono un’azienda',
  imNotCompany: 'I am not a company',
  imNotCompanyNote: `Select this checkbox if you're not a registered company. This will exclude the 'Pay in 90 days' option.`,
  card: 'Carta',
  date: 'Data',

  stripeErrors: {
    authentication_required:
      'Non siamo in grado di autenticare il tuo metodo di pagamento. Scegli un altro metodo di pagamento e riprova.',
    approve_with_id:
      'Il pagamento non può essere autorizzato. Per favore, riprova.',
    card_not_supported: `La carta non supporta questo tipo di acquisto. Si prega di contattare l'emittente della carta.`,
    card_velocity_exceeded:
      'Hai superato il saldo o il limite di credito disponibile sulla carta.',
    currency_not_supported: 'La carta non supporta la valuta specificata.',
    expired_card: 'La carta è scaduta. Si prega di provare con un’altra carta.',
    incorrect_cvc:
      'Il codice CVC non è corretto. Riprova utilizzando il codice CVC corretto.',
    invalid_cvc:
      'Il codice CVC non è corretto. Riprova utilizzando il codice CVC corretto.',
    invalid_expiry_month:
      'Il mese di scadenza non è valido. Si prega di riprovare usando la data di scadenza corretta.',
    invalid_expiry_year:
      'L’anno di scadenza non è valido. Si prega di riprovare usando la data di scadenza corretta.',
    issuer_not_available: `Non è stato possibile raggiungere l'emittente della carta. Prova ad effettuare nuovamente il pagamento.`,
    pin_try_exceeded: `Il numero consentito di tentativi PIN è stato superato. Si prega di utilizzare un'altra carta o metodo di pagamento.`,
  },

  deliveryOptions: 'Opzioni di Consegna',
  paymentOptions: 'Modalità di Pagamento',
  debitCreditCards: 'Carte di credito/debito',
  payLater: 'Paga dopo',
  delivery: 'Consegna',
  charge: 'A pagamento',
  freeProduct: 'Gratis',
};
