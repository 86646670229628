import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import {
  appliedPromoCode$,
  cartFormattedData$,
  changeAppliedPromoCode,
  changePromoCode,
  changePromoCodeMaxDiscount,
  details$,
  promoCode$,
} from 'models/cart';
import { $userInfo } from 'models/user';

import { PROMO } from 'api';

export const usePromoCode = () => {
  const user = useStore($userInfo);
  const appliedPromoCode = useStore(appliedPromoCode$);
  const promoCode = useStore(promoCode$);
  const details = useStore(details$);
  const cartFormattedData = useStore(cartFormattedData$);

  const [promoError, setPromoError] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoadingPromo, setIsLoadingPromo] = useState(false);

  useEffect(
    () => {
      if (promoCode) {
        sendPromo();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    setIsSuccessMessage(!!appliedPromoCode);
  }, [appliedPromoCode]);

  const sendPromo = useCallback(async () => {
    if (!promoCode.trim().length) {
      setPromoError(true);
      return;
    }
    try {
      setIsLoadingPromo(true);

      const { data } = await PROMO.checkPromoCode({
        promo: promoCode,
        checkoutAs: details.checkoutAs || 'guest',
        email: details.email || undefined,
        products: cartFormattedData.map((el) => el.id),
        ...(user.id && { user_id: user.id }),
      });

      if (data.error) {
        changeAppliedPromoCode(null);
        changePromoCodeMaxDiscount(null);
        setPromoError(data);
        return;
      }

      changeAppliedPromoCode(promoCode);
      changePromoCodeMaxDiscount(data.data?.maxDiscount);
      setPromoError(false);
    } catch (e) {
      changeAppliedPromoCode(null);
      setPromoError(e.response?.data || true);
      changePromoCodeMaxDiscount(null);
    } finally {
      setIsLoadingPromo(false);
    }
  }, [
    details.checkoutAs,
    details.email,
    promoCode,
    user.id,
    cartFormattedData,
  ]);

  const handleChangePromoCode = useCallback(
    (value) => {
      changePromoCode(value);
      if (appliedPromoCode) {
        changeAppliedPromoCode(null);
      }
      if (promoError) {
        setPromoError(false);
      }
    },
    [appliedPromoCode, promoError],
  );

  const removePromo = useCallback(() => {
    changePromoCode('');
    changeAppliedPromoCode(null);
  }, []);

  const handlePromoBtnClick = useCallback(() => {
    if (isSuccessMessage) {
      removePromo();
      return;
    }

    sendGAEvent('checkout_page_promocode_apply');
    sendPromo();
  }, [isSuccessMessage, removePromo, sendPromo]);

  return useMemo(
    () => ({
      sendPromo,
      handleChangePromoCode,
      isSuccessMessage,
      setIsSuccessMessage,
      isLoadingPromo,
      promoCode,
      promoError,
      handlePromoBtnClick,
    }),
    [
      handleChangePromoCode,
      isLoadingPromo,
      isSuccessMessage,
      promoCode,
      sendPromo,
      promoError,
      handlePromoBtnClick,
    ],
  );
};
