export const search = {
  searchResultFor: 'Resultados de la búsqueda',
  searchResults: 'Resultados de la búsqueda',
  nothingFound:
    'No se han encontrado resultados. Por favor, consulta nuestro {0}, modifica la búsqueda o contáctanos usando el siguiente formulario',
  noItems: 'No hay elementos para mostrar',
  searchHistory: 'Historial de búsqueda',
  popularCategories: 'Categorías populares',
  noResultsFound: 'Lo sentimos, no hemos encontrado coincidencias',
  searchResultForJobType: `Resultados de la búsqueda de "{0}" trabajo`,
};
