import { guard } from 'effector';

import { REGIONS } from 'api';

import { $regions, getRegionsFx, RegionsGate } from './';

getRegionsFx.use(async () => {
  const { data } = await REGIONS.getRegions();

  return data.data;
});

guard({
  source: RegionsGate.open,
  filter: $regions.map((e) => !e.length),
  target: getRegionsFx,
});
