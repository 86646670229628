export const about = {
  brands: {
    title: 'Valores de la marca',
    sections: {
      greatValue: 'Creemos en el valor de nuestra marca',
      bigBoys: 'Nos enfrentamos a los grandes',
      forTheMany: 'Ayudamos a todos, no sólo a unos pocos',
      relentlessInnovation: 'Innovamos implacablemente',
      keepItSimple: 'Lo mantenemos simple',
      entrepreneurial: 'Tenemos un gran espíritu emprendedor',
      makingTheDifferencePeopleLives:
        'Hacemos la diferencia en la vida de las personas',
      caringAndFun:
        'Somos honestos, abiertos, y nos importan nuestros clientes',
    },
  },

  depots: {
    title: 'Nuestras delegaciones se encuentran en toda la región!',
    description:
      'Elige la recogida al confirmar el pedido  y recógelo en una de nuestras sucursales: es gratis!', // in main page
    showMoreDepots: 'Mostrar más delegaciones',
    showLessDepots: 'Mostrar menos delegaciones',
  },

  easyLinkText:
    '{0}® forma parte de la familia de marcas {1}®. Para obtener más información, visita {2} y obtén más información sobre la familia de marcas easy.',
  easyHistoryLinkText:
    '{0}® forma parte de la familia de marcas {1}®. Para obtener más información, visita {2} y conoce la historia de la marca.',

  vision: 'La misión de {0}',
  representativeIn: '{0} - representante {1} en {2}',
  about: '{0}',
  contactUsTitle: 'Contáctenos',
  history: 'Historia de la familia de marcas easy',
  representativeInRegion: '{0} representantes en {1} región',
};
