import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { BrandingContext } from 'contexts';
import { Search } from 'layout/Header/MobileSearchHeader/SearchInput';

import AlgoliaAutocompleteSearchWrapper from 'features/search/AlgoliaAutocompleteSearchWrapper';

import { Row } from 'ui';

import { ReactComponent as ClearIcon } from 'assets/images/icon/search/closeNew.svg';

import style from './mobileSearchHeader.module.scss';

const MobileSearchHeader = () => {
  const history = useHistory();
  const branding = useContext(BrandingContext);

  return (
    <div className={style.mobileSearchHeader}>
      <Row
        justify="center"
        align="center"
        noWrap
        className={style.mobileSearchHeaderLogoWrapper}
      >
        <Link to={PATHS.ROOT} className={style.mobileSearchLogoWrapper}>
          {branding.searchLogo && (
            <img src={branding.searchLogo} alt={branding.companyName} />
          )}
        </Link>
      </Row>
      <div className={style.clearIcon} onClick={history.goBack}>
        <ClearIcon />
      </div>
      <AlgoliaAutocompleteSearchWrapper>
        <Search />
      </AlgoliaAutocompleteSearchWrapper>
    </div>
  );
};

export default MobileSearchHeader;
