import { combine, createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import FuzzySearch from 'fuzzy-search';
import { getDateFromString, isInInterval } from 'utils/datesСalculation';

import { clearUserInfo } from '../user';

export const OrdersGate = createGate('orders');

export const getOrdersFx = createEffect();

export const setOrders = createEvent();
export const setIsOrdersLoading = createEvent();
export const getOrders = createEvent();

export const changeOrdersSearchStr = createEvent();

export const changeSelectedStatus = createEvent();

export const changeSelectedDates = createEvent();

export const setIsOrdersRequestFinished = createEvent();

export const $orders = createStore([])
  .on(setOrders, (_, orders) => orders)
  .on(clearUserInfo, () => []);

export const $isOrdersLoading = createStore(false).on(
  setIsOrdersLoading,
  (_, p) => p,
);

export const $isOrdersRequestFinished = createStore(false)
  .on(getOrdersFx.finally, (_, state) => true)
  .on(setIsOrdersRequestFinished, (_, p) => p);

const ordersSearcher = (state) =>
  new FuzzySearch(state, ['orderNumber'], { sort: true });

export const $filter = createStore({
  search: '',
  status: 'all',
  dates: [
    new Date(new Date().getFullYear(), 0, 1),
    new Date(new Date().getFullYear(), 11, 31),
  ],
})
  .on(changeSelectedStatus, (s, p) => {
    return {
      ...s,
      status: p,
    };
  })
  .on(changeSelectedDates, (s, p) => {
    return {
      ...s,
      dates: p,
    };
  })
  .on(changeOrdersSearchStr, (s, p) => {
    return {
      ...s,
      search: p,
    };
  });

export const filteredOrders$ = combine($orders, $filter, (orders, filter) => {
  let modifyOrders = [...orders];
  if (filter.status !== 'all') {
    modifyOrders = modifyOrders.filter((el) => el.status === filter.status);
  }

  if (filter.dates.length) {
    const interval = { start: filter.dates[0], end: filter.dates[1] };
    modifyOrders = modifyOrders.filter((el) =>
      isInInterval(getDateFromString(el.date, 'dd/MM/yyyy HH:mm'), interval),
    );
  }

  if (filter.search.length) {
    modifyOrders = ordersSearcher(modifyOrders).search(filter.search);
  }

  return modifyOrders;
});

export const setOrderCompleted = createEvent();
export const clearOrderCompleted = createEvent();

export const $orderCompleted = createStore({
  userPromo: null,
})
  .on(setOrderCompleted, (s, p) => p)
  .on(clearOrderCompleted, (s, p) => {
    return { userPromo: null };
  });

export const clearIdVerificationSlug = createEvent();

export const $idVerificationSlug = createStore(null)
  .on(setOrderCompleted, (s, p) => p.idVerification?.slug || s)
  .on(clearIdVerificationSlug, () => null);
