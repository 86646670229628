import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { BrandingContext } from 'contexts';
import { Lang } from 'lang';

import { QuantityChanger, Row, T8y } from 'ui';

import style from './attributesModalItem.module.scss';

const AttributesModalItem = ({
  item,
  changeCount,
  minQuantity = 0,
  testId,
}) => {
  const { products, sidebar } = Lang();

  const branding = useContext(BrandingContext);

  const price = useMemo(
    () => item.price * item.quantity,
    [item.price, item.quantity],
  );

  const priceInfo = useMemo(() => {
    if (!!item.price) {
      return `${branding.currencySymbol}${item.price}`;
    }

    return item.quantity ? sidebar.gratis : products.freeOfCharge;
  }, [
    branding,
    item.price,
    item.quantity,
    products.freeOfCharge,
    sidebar.gratis,
  ]);

  return (
    <Row
      data-testid={testId}
      className={clsx(
        style.optionalExtraItem,
        item.quantity ? style.active : undefined,
      )}
      align="center"
      noWrap
    >
      <Row className={style.itemInfoBlock} align="center" noWrap>
        <img src={item.src || branding.defaultImageExtra} alt="extra img" />
        <div>
          <T8y variant="t1v1" className={style.optionalExtraName}>
            {item.value}
          </T8y>
          <T8y color="mainGray" bold variant="t2v1">
            {priceInfo}
          </T8y>
        </div>
      </Row>
      <Row className={style.itemPriceBlock} align="center" noWrap>
        <QuantityChanger
          minQuantity={minQuantity}
          startCount={item.quantity}
          changeCount={changeCount}
          circleBtnClassName={style.circleBtnClassName}
          circleBtnDisableClassName={style.circleBtnDisableClassName}
          size={36}
          inputStyle={style.quantityInput}
        />
        {!!item.quantity && (
          <T8y
            color="primary"
            variant="t2v1"
            bold
            fullWidth
            end
            className={style.extraModalItemPrice}
          >
            {branding.currencySymbol}
            {price.toFixed(2)}
          </T8y>
        )}
      </Row>
    </Row>
  );
};

export default AttributesModalItem;
