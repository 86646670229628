import { guard } from 'effector';

import { SETTINGS, TAFAQS } from 'api';

import {
  faqsTAGate,
  getFaqsTAFx,
  getSettingsFx,
  isFaqsTALoading$,
} from './index';

getSettingsFx.use(async () => {
  const { data } = await SETTINGS.getSettings();
  return { ...data };
});

getFaqsTAFx.use(async () => {
  const { data } = await TAFAQS.getAllTAFaqs();
  return data.data;
});

guard({
  source: faqsTAGate.open,
  filter: isFaqsTALoading$,
  target: getFaqsTAFx,
});
