import { useContext, useMemo } from 'react';
import { SettingsContext } from 'contexts';
import {
  getBlockedDatesByDaysWeek,
  getDateInFormat,
} from 'utils/datesСalculation';

export const useGetProductDatesSettings = () => {
  const settings = useContext(SettingsContext);

  const additionalBlockedDates = useMemo(
    () =>
      getBlockedDatesByDaysWeek(
        settings.blockedWeekDays,
        settings.regionTimezone,
      ).map((el) => getDateInFormat(el, 'yyyy-MM-dd')),
    [settings],
  );

  const finalBlockedDates = useMemo(() => {
    const blockedDates = settings.blockedDatesList || [];

    if (additionalBlockedDates) {
      return blockedDates.concat(additionalBlockedDates);
    }

    return blockedDates;
  }, [additionalBlockedDates, settings]);

  return useMemo(
    () => ({
      deliveryWeekends: settings.deliveryWeekends,
      defaultHiringPeriod: settings.defaultHiringPeriod,
      finalBlockedDates,
    }),
    [finalBlockedDates, settings],
  );
};
