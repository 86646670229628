import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Popover,
  PopoverArrow,
  PopoverDisclosure,
  usePopoverState,
} from 'reakit/Popover';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { LAPTOP_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { handleLogout } from 'hooks';
import { Lang } from 'lang';
import { $isUserLoggedIn } from 'models/user';

import { useMatchMedia } from 'libs/useMatchMedia';

import { T8y } from 'ui';

import { ReactComponent as UserIcon } from 'assets/images/icon/user.svg';

import style from '../header.module.scss';

const ProfileSing = () => {
  const {
    header: { nav },
  } = Lang();

  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);

  const isUserLoggedIn = useStore($isUserLoggedIn);

  const popover = usePopoverState({
    animated: 250,
    unstable_offset: [0, 2],
  });

  const handleProfileSingLogout = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      handleLogout();
      popover.hide();
    },
    [popover],
  );

  if (isLaptop) {
    return null;
  }

  return (
    <div
      onMouseEnter={isUserLoggedIn ? popover.show : undefined}
      onMouseLeave={popover.hide}
      className={style.profileWrapper}
    >
      <PopoverDisclosure {...popover}>
        <Link
          tabIndex="0"
          to={PATHS.PROFILE}
          className={clsx(style.basket, isUserLoggedIn && style.profileLogged)}
          aria-label="profile"
        >
          <UserIcon />
        </Link>
      </PopoverDisclosure>
      <Popover {...popover} tabIndex={0}>
        <div className={style.profileTooltip} onClick={handleProfileSingLogout}>
          <PopoverArrow {...popover} />
          <T8y variant="t1v1" color="primary" bold>
            {nav.logOut}
          </T8y>
        </div>
      </Popover>
    </div>
  );
};

export default ProfileSing;
