import { createEffect, createStore, restore } from 'effector';
import { createGate } from 'effector-react';

export const blogGate = createGate('banners');

export const getBlogPostsFx = createEffect();

export const blogPosts$ = restore(getBlogPostsFx, []);

export const isBlogPostsRequestFinished$ = createStore(false).on(
  getBlogPostsFx.done,
  (_) => true,
);

export const isBlogPostsLoadingDone$ = getBlogPostsFx.pending.map(
  (state) => !state,
);
