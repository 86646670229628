import React from 'react';
import clsx from 'clsx';

import { Button, Spinner } from 'ui';

import style from './checkoutBtn.module.scss';

const CheckoutBtn = ({
  text,
  onClick,
  isBlocked,
  isSelected,
  isDefault,
  isSubmit,
  isDisabled,
  isLoading,
  additionalClass,
  ...rest
}) => {
  return (
    <Button
      className={clsx(
        style.checkoutBtn,
        {
          [style.blocked]: isBlocked,
          [style.selected]: isSelected,
          [style.default]: isDefault,
          [style.submit]: isSubmit,
          [style.disabled]: isDisabled,
          [style.loading]: isLoading,
        },
        additionalClass,
      )}
      onClick={!isDisabled && !isBlocked && !isLoading ? onClick : undefined}
      {...rest}
    >
      {isLoading ? <Spinner size={1} /> : text}
    </Button>
  );
};

// eslint-disable-next-line import/no-default-export
export default CheckoutBtn;
