import React from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { sendGAEvent } from 'googleAnalytics';
import { Lang } from 'lang';

import { Row } from 'ui';

import { ReactComponent as SearchIcon } from 'assets/images/icon/search/searchNew.svg';

import style from './mobileSearchInput.module.scss';

const MobileSearchInput = ({ small }) => {
  const { form } = Lang();

  const history = useHistory();

  return (
    <Row
      justify="center"
      align="center"
      className={clsx(style.searchInputWrapper, small && style.small)}
      noWrap
    >
      <div
        className={style.searchField}
        onClick={() => {
          sendGAEvent('user_click_search_field');
          history.push(PATHS.MOBILE_SEARCH, {
            from: history.location.pathname,
          });
        }}
      >
        <Row align="center" className={style.searchBtn}>
          <SearchIcon />
        </Row>
        <input className={style.searchInput} placeholder={form.fields.search} />
      </div>
    </Row>
  );
};

export default MobileSearchInput;
