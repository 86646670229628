export const sidebar = {
  yourReservation: 'Sua reserva',
  yourQuote: 'Sua cotação',
  costSummary: 'Custo total',
  proceed: 'Continuar',
  estimatePerWeek: 'Custo total',
  damageWaiver: 'Isenção de danos',
  subtotal: 'Subtotal',
  transport: 'Transporte',
  delivery: 'Entrega (taxa)',
  collection: 'Coleta',
  selfCollection: 'Coleta no local',
  vat: 'IVA',
  total: 'Total',
  totalForCourse: 'Total',
  totalExclVat: 'Total (sem IVA)',
  deposit: 'Depósito',
  gasTankDeposit: 'Caução de Combustível',
  iAgree: 'Estou de acordo com',
  free: 'A ser acordado',
  insurance: 'Seguro',
  tbd: 'A confirmar',
  gratis: 'Grátis',
  safetyFee: 'Taxa de segurança',
  sanitationFee: 'Taxa de Saneamento',
  adminFee: 'Taxa de administração',
  slightDamageWaiverFee: 'Isenção de Danos Ligeiros',
  grandTotal: 'Total geral',
  grandTotalProductCheckout: 'Total geral',
  invoice: 'Fatura',
  logisticAdditionalText:
    'Coleta no local: grátis.\n Entrega ao domicílio: o custo é negociado em função da distância ao depósito.',
  logisticAdditionalTextOnlySelfCollection:
    'Coleta no local: grátis.\n Entrega: A entrega não está disponível para este produto.',
  logisticAdditionalTextOnlyDelivery:
    'Coleta no local não está disponível para este produto.\n Entrega ao domicílio: o custo é negociado em função da distância ao depósito.',
  logisticAdditionalTextMixedDeliveries:
    'Coleta no local: grátis.\n Entrega ao domicílio: o custo é negociado em função da distância ao depósito.',
  depositAdditionalText:
    ' Depósito não cobrado neste momento. \n O depósito por perdas e danos será cobrado três dias antes da data de início confirmada e devolvido ao seu cartão após a devolução segura dos produtos',
  promoCodeAdditionalText:
    'Parabéns! Seu desconto é de {0}% \n Código promocional: {1}',
  maxDiscount: 'Desconto máximo',
  page: 'Página',
  expand: 'Expandir',
  collapse: 'Ocultar',
  products: 'Produtos',
  showMore: 'Mostre mais',
  showLess: 'Mostrar menos',
  hide: 'Ocultar',
  discount: 'Desconto',
  courseCost: 'Custo do curso',
  refundableDepositWarning:
    'Um depósito reembolsável será bloqueado para este produto.',
  depositMayBeApplied:
    'Um depósito reembolsável pode ser aplicado com base no total geral do seu pedido.',
  clickAddToReservation:
    'Clique em "Adicionar à Reserva" e siga para o Checkout para ver um Resumo completo dos custos.',
  itemsToHire: 'Aluguel de Máquinas',
  tradeAccountDiscount: 'Desconto de conta comercial',
};
