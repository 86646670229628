import { useEffect, useState } from 'react';
import { clearUserInfo } from 'models/user';
import tokenEventObserver from 'utils/tokenObserver';

import { haveToken } from 'api/profileApi';

export const useHaveToken = () => {
  const [isToken, setIsToken] = useState(haveToken());

  useEffect(() => {
    tokenEventObserver.subscribe(setIsToken);
    return () => tokenEventObserver.unsubscribe(setIsToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isToken) {
      clearUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToken]);

  return isToken;
};
