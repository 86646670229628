import { REACT_APP_PREFIX } from 'consts';
import { createEvent, createStore } from 'effector';
import { persist } from 'effector-storage/local';

export const setSearchHistory = createEvent();

export const searchHistory$ = createStore([]).on(
  setSearchHistory,
  (s, newItem) => {
    const newSearchHistory = s.filter(
      (el, index) => el.title !== newItem.title && index < 4,
    );
    newSearchHistory.unshift(newItem);
    return newSearchHistory;
  },
);

persist({ store: searchHistory$, key: `searchHistory_${REACT_APP_PREFIX}` });

export const setIsLastSearchResultsEmpty = createEvent();

export const isLastSearchResultsEmpty$ = createStore(null).on(
  setIsLastSearchResultsEmpty,
  (_, p) => p,
);

export const setMobileSearch = createEvent();

export const mobileSearch$ = createStore({
  currentRefinement: '',
  hits: [],
  refine: () => {},
}).on(setMobileSearch, (_, p) => p);
