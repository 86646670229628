export const customer = {
  titles: {
    quotes: 'Cotações',
    yourRecentQuotes: 'Suas cotações recentes e passadas',
    orders: 'Pedidos',
    yourRecentOrders: 'Seus pedidos recentes e anteriores',
  },

  tabs: {
    recent: 'Recente',
    past: 'Anterior',
  },

  items: {
    quote: 'Cotação',
    order: 'Ordenar',
    totalPrice: 'Preço total',
    hireDates: 'Datas de contratação',
    dateSubmited: 'Data Enviada',
    requestSummary: 'Resumo da Solicitação',
    hireDites: 'Datas de contratação',
    deliveryMethod: 'Delivery Method',
    deliveryAddress: 'Endereço de entrega',
    selfCollectionDepot: 'Depósito para coleta automática',
    products: 'produtos',
    estimateTotal: 'Total Estimado',
    completeCheckout: 'Complete checkout',
    viewInvoice: 'Ver índice',
    askQuestion: 'Faça uma pergunta',
    selfCollection: 'Coleta no local',
    delivery: 'Endereço de entrega e coleta',
  },

  account: {
    userType: 'Tipo de usuário',
    individual: 'Individual',
    company: 'Companhia',
    authentication: 'Autenticação',
    changeEmail: 'Alterar o e-mail',
    changePassword: 'Alterar senha',
  },

  notification: {
    quotes: 'Cotações',
    orders: 'Pedidos',
    supportChat: 'Chat de suporte',
    quoteIsReady: 'A cotação está pronta para revisão',
    emailNotificationWhen:
      'Notificação por e-mail quando sua solicitação de cotação for processada e estiver pronta para revisão',
    paymentFailed: 'Erro no pagamento',
    emailNotificatonIfOrder:
      'Notificar por email caso o pagamento do pedido tenha falhado.',
    newChatMessage: 'nova mensagem de chat',
    emailNotificatonIfThere:
      'Notificação por e-mail se houver uma nova mensagem de chat enquanto você estiver desconectado',
  },
};
