import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useHandleCategoryMenu } from 'hooks';
import { Lang } from 'lang';
import PopularCategories from 'layout/Header/PopularCategories';

import NestedCategoriesMenu from './NestedCategoriesMenu';

import { Row, T8y } from 'ui';

import { ReactComponent as ArrowBack } from 'assets/images/icon/arrowBack.svg';

import style from '../header.module.scss';

const SubMenuMobile = ({ id, openId, setOpenId, close }) => {
  const { header } = Lang();

  const handleGoToCategory = useCallback(() => {
    close();
    setOpenId(0);
  }, [close, setOpenId]);

  const {
    handleChooseMainCategory,
    handleReturnToParentCategory,
    handleChooseSubCategory,
    selectedCategory,
    goToCategory,
  } = useHandleCategoryMenu(handleGoToCategory);

  const isThisTabOpened = openId === id;

  const handleBackClick = useCallback(
    (e) => {
      if (!selectedCategory) {
        setOpenId(0);
      }
      handleReturnToParentCategory(e);
    },
    [handleReturnToParentCategory, selectedCategory, setOpenId],
  );

  return (
    <div className={style.category}>
      <Row
        justify="stretch"
        align="center"
        className={clsx(
          style.name,
          isThisTabOpened ? style.border : style.triangle,
          openId > 0 && !isThisTabOpened && style.none,
        )}
        onClick={() => {
          setOpenId(isThisTabOpened && !selectedCategory ? 0 : 1);
        }}
        data-testid="mobile-categories-disclosure"
      >
        {isThisTabOpened ? (
          <div className={style.backRowWrapper} onClick={handleBackClick}>
            <Row align="center">
              <ArrowBack className={style.backArrow} />{' '}
              <T8y variant="t1" color="light" className={style.menuTitle}>
                {header.bar.back}
              </T8y>
            </Row>
            <div className={style.prevCategoryTextWrapper}>
              <T8y variant="t3" color="lightGray" uppercase>
                {selectedCategory?.name || header.nav.hireByCategory}
              </T8y>
            </div>
          </div>
        ) : (
          <T8y variant="t1" color="light" className={style.menuTitle}>
            {header.nav.hireByCategory}
          </T8y>
        )}
      </Row>
      {isThisTabOpened && (
        <>
          {!selectedCategory && (
            <PopularCategories
              handleGoToCategory={handleGoToCategory}
              isMobile
            />
          )}
          <NestedCategoriesMenu
            goToCategory={goToCategory}
            handleChooseMainCategory={handleChooseMainCategory}
            handleChooseSubCategory={handleChooseSubCategory}
            selectedCategory={selectedCategory}
          />
        </>
      )}
    </div>
  );
};

export default SubMenuMobile;
