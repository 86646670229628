import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

import TrainingFallback from './TrainingFallback';

const Training = lazy(() => lazyRetry(() => import('./Training'), 'Training'));

export const trainingRoutes = [
  {
    path: PATHS.POST(':slug'),
    exact: true,
    component: () => WithSuspense(Training, TrainingFallback),
  },
];
