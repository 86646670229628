export const customer = {
  titles: {
    quotes: 'Preventivi',
    yourRecentQuotes: 'I tuoi preventivi più recenti e passati',
    orders: 'Ordini',
    yourRecentOrders: 'I tuoi ordini recenti e passati',
  },

  tabs: {
    recent: 'Recenti',
    past: 'Precedenti',
  },

  items: {
    quote: 'Preventivo',
    order: 'Ordine',
    totalPrice: 'Ordine',
    hireDates: 'Hire Dates',
    dateSubmited: 'Data di Invio',
    requestSummary: 'Riepilogo',
    hireDites: 'Date di noleggio',
    deliveryMethod: 'Modalità di consegna',
    deliveryAddress: 'Indirizzo di Consegna',
    selfCollectionDepot: 'La Sede per il Ritir',
    products: 'prodotti',
    estimateTotal: 'Totale Stimato',
    completeCheckout: 'Completa il pagamento',
    viewInvoice: 'Vedi Fattura',
    askQuestion: 'Fai una domanda',
    selfCollection: 'Ritiro',
    delivery: 'Indirizzo di consegna e ritiro',
  },

  account: {
    userType: 'Profilo Utente',
    individual: 'Privato',
    company: 'Azienda',
    authentication: 'Autentificazione',
    changeEmail: 'Cambia e-mail',
    changePassword: 'Cambia la password',
  },

  notification: {
    quotes: 'Preventivi',
    orders: 'Ordini',
    supportChat: 'Chat Assistenza',
    quoteIsReady: 'Il preventivo è pronto per la verifica',
    emailNotificationWhen:
      'Ricevi notifica via e-mail quando la tua richiesta di preventivo è stata elaborata ed è pronta per la verifica.',
    paymentFailed: 'Pagamento non riuscito',
    emailNotificatonIfOrder:
      "Ricevi notifica via e-mail in caso di mancato pagamento dell'ordine.",
    newChatMessage: 'Nuovo messaggio in chat',
    emailNotificatonIfThere:
      'Ricevi notifica via e-mail se arriva un nuovo messaggio in chat mentre sei disconnesso',
  },
};
