import { useCallback } from 'react';
import { LAPTOP_SCREEN } from 'consts';

import { useMatchMedia } from 'libs/useMatchMedia';

import scrollToEnquiryForm from 'utils/helpers/scrollToEnquiryForm';

export const useScrollToEnquiryForm = (enquiryRef) => {
  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);

  return useCallback(() => {
    scrollToEnquiryForm(enquiryRef, !isLaptop);
  }, [enquiryRef, isLaptop]);
};
