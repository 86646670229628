import { lazy } from 'react';
import { PATHS } from 'AppPaths';

import WithSuspense from 'features/common/WithSuspense';

const JobType = lazy(() => import('./JobType'));

export const jobTypeRoutes = [
  {
    path: PATHS.JOB_TYPE(':slug'),
    exact: true,
    component: () => WithSuspense(JobType),
  },
];
