import React, { useMemo } from 'react';
import { useBreadCrumbs } from 'hooks';
import FirstScreen from 'pages/training/FirstScreen';

export const TrainingFallback = () => {
  const breadCrumbs = useMemo(
    () => ({
      products: true,
      training: true,
    }),
    [],
  );

  useBreadCrumbs(breadCrumbs);

  return <FirstScreen />;
};

export default TrainingFallback;
