import { useLayoutEffect } from 'react';
import { COUNTRY_NAME, isETH } from 'consts';
import { getUrlSearchParams } from 'utils/formatUrl';

import { history } from 'libs/history';

export const useCheckAwinParams = () => {
  useLayoutEffect(() => {
    if (isETH && !window.location.search) {
      const UTMDataSession = JSON.parse(
        sessionStorage.getItem(`UTM_${COUNTRY_NAME}`),
      );

      if (UTMDataSession) {
        const params = getUrlSearchParams(UTMDataSession.utm_landing_page);

        if (params.awc) {
          history.replace(`?awc=${params.awc}&sn=${params.sn}`);
        }
      }
    }
  }, []);
};
