import Gold from 'assets/images/icon/discounts/gold.svg';
import Platinum from 'assets/images/icon/discounts/platinum.svg';
import Silver from 'assets/images/icon/discounts/silver.svg';
import Top from 'assets/images/icon/discounts/top.svg';

export const getOrderStatusClassName = (status) => {
  switch (status) {
    case 'new':
      return 'newOrder';
    case 'in_progress':
      return 'progressOrder';
    case 'completed':
      return 'completedOrder';
    case 'cancelled':
      return 'cancelledOrder';
    default:
      return 'newOrder';
  }
};

export const getUserStatus = (status) => {
  let pictureSrc;

  switch (status) {
    case 'gold':
      pictureSrc = Gold;
      break;
    case 'top':
      pictureSrc = Top;
      break;
    case 'silver':
      pictureSrc = Silver;
      break;
    case 'platinum':
      pictureSrc = Platinum;
      break;
    default:
      pictureSrc = Gold;
  }

  return pictureSrc;
};
