import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { MOBILE_SCREEN } from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import { Lang } from 'lang';
import { settingsLoaded$ } from 'models/settings';

import TwoCheckoutInfoModal from 'features/common/TwoCheckoutInfoModal';

import { useMatchMedia } from 'libs/useMatchMedia';

import { Row, T8y } from 'ui';

import { ReactComponent as Leaf } from 'assets/images/icon/leaf.svg';

import style from './banner.module.scss';

const TwoBanner = ({ isShowCarbonEmissionBar }) => {
  const { formatString, header } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);
  const settingsLoaded = useStore(settingsLoaded$);

  const [isTwoModalOpen, setIsTwoModalOpen] = useState(false);

  if (!settingsLoaded) {
    return null;
  }

  if (settings.twoIntegrationEnabled) {
    return (
      <Row
        justify="center"
        align="center"
        xsJustify="stretch"
        className={clsx(style.banner, style.bannerTwo)}
        noWrap
      >
        <T8y className={style.bannerText} variant="t2" xsVariant="t4" bold>
          Hire now, pay in 90 days - no interest, no fees. &nbsp;
          {isMobile ? '' : '🎉'}
        </T8y>
        <T8y
          variant="t2v1"
          xsVariant="t4"
          color="primary"
          onClick={() => {
            sendGAEvent('two_sticky_learn_more_click');
            setIsTwoModalOpen(true);
          }}
          noWrap
          bold
          asLink
        >
          {header.bar.learnMore}
        </T8y>
        {isTwoModalOpen && (
          <TwoCheckoutInfoModal setIsTwoModalOpen={setIsTwoModalOpen} />
        )}
      </Row>
    );
  }

  if (isShowCarbonEmissionBar) {
    return (
      <Row
        justify="center"
        align="center"
        className={clsx(style.banner, style.bannerCarbon)}
        noWrap
      >
        <Leaf />
        {formatString(
          header.bar.isCommitted,
          branding.companyName || '',
          <T8y
            variant="t2"
            color="primary"
            as={Link}
            to={PATHS.CARBON_EMISSION}
            target="_blank"
            inline
            ml
          >
            {header.bar.learnMore}
          </T8y>,
        )}
      </Row>
    );
  }

  return null;
};

export default TwoBanner;
