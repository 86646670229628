import { toast } from 'react-toastify';
import {
  createEffect,
  createEvent,
  createStore,
  guard,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import { getUserStatus } from 'utils/helpers/profileHelpers';

export const MembershipsGate = createGate('memberships');
export const getMembershipsFx = createEffect();

export const getUserInfo = createEvent();
export const setUserInfo = createEvent();
export const clearUserInfo = createEvent();
export const getUserAddressesInfo = createEvent();
export const getUserAddressInfo = createEvent();
export const logOutUser = createEvent();

export const getUserFx = createEffect();
export const getUserAddressesInfoFx = createEffect();
export const getUserAddressInfoFx = createEffect();
export const createUserAddressFx = createEffect();
export const updateUserAddressFx = createEffect();
export const deleteUserAddressFx = createEffect();
export const logOutUserFx = createEffect();

createUserAddressFx.fail.watch(({ error }) => {
  toast.error(error && error.response && error.response.statusText);
});

export const $userInfo = createStore({})
  .on(setUserInfo, (state, data) => {
    const newData = { ...state, ...data };

    return {
      ...newData,
      status: newData.tier?.slug,
      userDiscount: newData.tier?.discount || 0,
      statusPicture: getUserStatus(newData.tier?.slug),
    };
  })
  .on(clearUserInfo, (s, _) => {
    if (!s.id) {
      return;
    }

    return {};
  });

export const $userLoading = restore(getUserFx.pending.updates, false);

export const $isUserLoggedIn = createStore(false).on(
  $userInfo.updates,
  (_, user) => {
    return !!(user.id && user.verified);
  },
);

export const $isUserAdmin = createStore(false).on(
  $userInfo.updates,
  (_, user) => {
    return !!user.ownerId;
  },
);

guard({
  source: $userInfo.updates,
  filter: (user) => !!user.email && !user.verified,
  target: logOutUser,
});

export const memberships$ = restore(getMembershipsFx.doneData, []);
