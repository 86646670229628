export const error = {
  header: 'Pagina 404 non trovata',
  sorry:
    'Spiacenti, in questo momento non siamo in grado di trovare la pagina che stai cercando.\n' +
    'Prova ad utitlizzare la funzione di ricerca, uno dei link sottostanti o vai alla nostra {0}.',
  link: 'home page',
  callOrWrite: "Per qualsiasi domanda, chiamaci {0}  o {1}. E' gratis!",
  popularCategories: 'Categorie popolari',
  browseFullCatalogue: 'Sfoglia il catalogo completo',
  checkoutOurBlog: "Dai un'occhiata al nostro Blog",
  goToBlogHomepage: 'Vai alla home page del blog',

  critical: {
    title: 'Oops... Qualcosa è andato storto',
    subTitle: 'Stiamo lavorando per risolvere il problema',
    text: 'Per qualsiasi domanda {0}',
    sendEmail: `inviateci un'email`,
    refreshAndTryAgain: 'Ricarica la pagina e riprova',
    refreshThePage: 'Ricarica la pagina',
  },
};
