import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const NotFound = lazy(() => lazyRetry(() => import('./index'), 'NotFound'));

export const notFoundRoutes = [
  {
    path: PATHS.NOT_FOUND,
    exact: true,
    component: () => WithSuspense(NotFound),
  },
];
