import React, { useMemo } from 'react';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { jobTypes$ } from 'models/jobTypes';

import MultipleLinksMenuWrapper from '../MultipleLinksMenuWrapper';

const JobTypes = ({ setIsSubMenuOpen }) => {
  const jobTypes = useStore(jobTypes$);

  const linksList = useMemo(() => {
    return jobTypes.map(({ title, slug }) => ({
      path: PATHS.JOB_TYPE(slug),
      title,
      isCapitalize: true,
    }));
  }, [jobTypes]);

  return (
    <MultipleLinksMenuWrapper
      setIsSubMenuOpen={setIsSubMenuOpen}
      linksList={linksList}
    />
  );
};

export default JobTypes;
