import { useEffect } from 'react';

import { sendCopyToClipboard } from '../googleAnalytics';

export const useGlobalCopyListener = () => {
  useEffect(() => {
    document.addEventListener('copy', sendCopyToClipboard);

    return () => {
      document.removeEventListener('copy', sendCopyToClipboard);
    };
  }, []);
};
