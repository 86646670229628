export const success = {
  title: 'Seu pedido foi feito com sucesso',
  thankYouTitle: 'Obrigado pelo seu pedido!',
  thankYouTextGuest:
    'Estamos trabalhando em seu pedido e o manteremos atualizado. Você receberá por e-mail detalhes e um link para ativar sua conta comercial.',
  thankYouText:
    'Estamos trabalhando em seu pedido e o manteremos atualizado. Por favor, verifique seu e-mail e siga as instruções.',
  pleaseVerify: 'Verifique sua identificação e comprovante de endereço on-line',
  youCanAlso:
    'Você também pode fornecer seu documento de identidade e comprovante de endereço pessoalmente.',
  additionalInfo:
    'Após a ativação bem-sucedida da conta comercial, você poderá rastrear seu pedido, editar os detalhes do seu perfil e gerenciar os endereços de entrega diretamente em nosso site.',
  goHome: 'Vá para a página inicial',
  courseSectionTitle:
    'Não se esqueça que também oferecemos cursos de treinamento',
  blogSectionTitle: 'Confira {0} blog',
  insuranceWithCreditAccountWorking:
    'Estamos trabalhando arduamente para finalizar seu pedido e o manteremos informado sobre o andamento do mesmo.',
  insuranceWithCreditAccount:
    'Se você não tiver uma conta comercial {0}, verifique sua caixa de entrada em busca de um e-mail que detalhe seu pedido e contenha um formulário de crédito. Preencha e assine o formulário de crédito e envie-o por e-mail para a equipe {0}.',
};
