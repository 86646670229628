import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Lang } from 'lang';

import PopularProductsSlider from 'features/common/PopularProductsSlider';

import { T8y } from 'ui';

import style from './carousel.module.scss';

const Carousel = ({
  additionalContainerClassName,
  additionalCardsWrapperClassName,
}) => {
  const { main } = Lang();

  const title = useMemo(
    () => (
      <T8y
        variant="h1v1"
        smVariant="t1"
        className={style.sectionTitle}
        bold
        center
      >
        {main.titles.popularProducts}
      </T8y>
    ),
    [main.titles.popularProducts],
  );

  return (
    <PopularProductsSlider
      title={title}
      wrapperClassName={clsx(
        style.carouselWrapper,
        additionalContainerClassName,
      )}
      additionalCardsWrapperClassName={additionalCardsWrapperClassName}
    />
  );
};

export default Carousel;
