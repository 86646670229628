import React, { useCallback } from 'react';
import { SMALL_SCREEN } from 'consts';
import { Lang } from 'lang';
import MobileDropDown from 'layout/Footer/MobileDropDown';
import { setRegionForDisplaying } from 'models/regionForDisplay';

import { useMatchMedia } from 'libs/useMatchMedia';

import DesktopRegionsList from './DesktopRegionsList';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const Regions = ({ regionsForRender }) => {
  const { footer } = Lang();

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const handleRegionClick = useCallback((e, url, state) => {
    e.preventDefault();
    localStorage.setItem('region', url?.split('/')[3]);
    setRegionForDisplaying(state);
    window.location.replace(url);
  }, []);

  return isSmallScreen ? (
    <MobileDropDown title={footer.titles.supportedRegions}>
      {regionsForRender.map(({ name, url, state }, i) => (
        <T8y
          key={i}
          variant="t1v1"
          color="primary"
          as="a"
          target="_blank"
          href={url || '#'}
          rel="noopener noreferrer"
          onClick={(e) => handleRegionClick(e, url, state)}
          className={style.footerLink}
        >
          {name}
        </T8y>
      ))}
    </MobileDropDown>
  ) : (
    <DesktopRegionsList handleRegionClick={handleRegionClick} />
  );
};

export default Regions;
