import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import { getProductWithActualPrices } from 'utils/getProductWithActualPrices';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

import { branding$ } from '../branding';
import { currentLang$ } from '../language';
import { settings$ } from '../settings';
import { $userInfo } from '../user';

export const jobTypesGate = createGate('jobTypes');

export const getJobTypesFx = createEffect();
export const getJobTypeFx = createEffect();
export const getJobTypeProductsFx = createEffect();

export const clearJobType = createEvent();

export const isJobTypesRequestFinished$ = createStore(false).on(
  getJobTypesFx.done,
  (_) => true,
);

export const isJobTypeLoading$ = getJobTypeFx.pending.map((state) => !!state);
export const isJobTypeProductsLoading$ = getJobTypeProductsFx.pending.map(
  (state) => !!state,
);

export const isJobTypeRequestFinished$ = createStore(false).on(
  getJobTypeFx.done,
  (_) => true,
);

export const jobTypes$ = restore(getJobTypesFx, []);

export const jobType$ = restore(getJobTypeFx, null).on(
  clearJobType,
  (_) => null,
);

export const jobTypeWithActualProductsData$ = combine(
  jobType$,
  $userInfo,
  (jobType, user) => {
    const products = jobType?.products;

    if (!jobType) {
      return null;
    }

    const userDiscount = user.tier?.discount;

    if (!products?.length || !userDiscount) {
      return jobType;
    }

    return {
      ...jobType,
      products: products.map((product) =>
        getProductWithActualPrices(product, userDiscount),
      ),
    };
  },
);

export const jobTypeLocalized$ = combine(
  jobTypeWithActualProductsData$,
  currentLang$,
  settings$,
  branding$,
  (jobType, lang, { defaultLanguage }, { defaultImageCategory }) => {
    if (!jobType) {
      return null;
    }

    return {
      ...jobType,
      categories: jobType.categories.map((el) => ({
        ...el,
        name: el.names[lang] || el.names[defaultLanguage],
        image: el.image?.medium || defaultImageCategory,
      })),
      products: jobType.products.map((el) => ({
        ...el,
        name: getTranslatedFieldValue(el.names, lang, defaultLanguage),
      })),
    };
  },
);

export const jobTypeProducts$ = restore(getJobTypeProductsFx, []).on(
  clearJobType,
  () => [],
);

export const fetchGetCurrJobTypeFxError$ = restore(
  getJobTypeFx.failData,
  null,
).on(clearJobType, (_) => null);
