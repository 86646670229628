import { useEffect, useRef } from 'react';
import arePassiveEventsSupported from 'are-passive-events-supported';

const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';

const events = [MOUSEDOWN, TOUCHSTART];

const getOptions = (event) => {
  if (event !== TOUCHSTART) {
    return;
  }

  if (arePassiveEventsSupported()) {
    return { passive: true };
  }
};

export function useOnClickOutside(ref, handler, selectorExcludeEL) {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    if (!handler) {
      return;
    }

    const listener = (event) => {
      if (selectorExcludeEL && event.target.closest(selectorExcludeEL)) {
        return;
      }

      if (
        !ref.current ||
        !handlerRef.current ||
        ref.current.contains(event.target)
      ) {
        return;
      }

      handlerRef.current(event);
    };

    events.forEach((event) => {
      document.addEventListener(event, listener, getOptions(event));
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, listener);
      });
    };
  }, [handler, ref, selectorExcludeEL]);
}
