import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';

import { Row, T8y } from 'ui';

import style from '../../../header.module.scss';

const MenuItem = ({ category, onClick, additionalClassName }) => {
  return category.childs?.length ? (
    <Row
      justify="stretch"
      align="center"
      className={clsx(
        style.name,
        style.triangle,
        style.border,
        additionalClassName,
      )}
      onClick={onClick}
    >
      <T8y
        variant="t1"
        color="light"
        className={clsx(
          style.menuTitle,
          category.childs?.length && style.menuTitleWithArrow,
        )}
      >
        {category.name}
      </T8y>
    </Row>
  ) : (
    <Link
      to={{
        pathname: PATHS.CATEGORY(category.slug),
        presetType: 'category',
      }}
      className={clsx(
        style.name,
        style.border,
        style.menuTitle,
        style.menuLink,
        additionalClassName,
      )}
      onClick={onClick}
    >
      {category.name}
    </Link>
  );
};

export default MenuItem;
