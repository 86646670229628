import { lazy } from 'react';
import { PATHS } from 'AppPaths';

import WithSuspense from 'features/common/WithSuspense';

const Depots = lazy(() => import('./Depots'));

export const depotsRoutes = [
  {
    path: PATHS.DEPOTS,
    exact: true,
    component: () => WithSuspense(Depots),
  },
];
