import React, { useCallback, useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { BrandingContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel, sendGAEvent } from 'googleAnalytics';
import { useProductCardProps } from 'hooks';
import { Lang } from 'lang';
import { isPageWithNoSearchResultsOpened$ } from 'models/layoutData';
import * as MP from 'models/product';

import LazyLoadingComponent from 'features/common/LazyLoadingComponent';

import Labels from './Labels';
import MainProductPrice from './MainProductPrice';

import { Button, Rating, Row, T8y } from 'ui';

import style from './productCard.module.scss';

export function ProductCard({ product, testId, isFixedWidth }) {
  const { form } = Lang();

  const location = useLocation();
  const branding = useContext(BrandingContext);

  const flatCategoriesListLocalized = useStore(MP.flatCategoriesListLocalized$);
  const viewBtnRef = useRef(null);

  const isPageWithNoSearchResultsOpened = useStore(
    isPageWithNoSearchResultsOpened$,
  );

  const isPageWithProfileDashboardOpened = location.pathname.includes(
    PATHS.PROFILE_DASHBOARD,
  );

  const { mainPrice, showDiscount, defaultHiringPeriodProduct } =
    useProductCardProps(product);

  const handleLinkClick = useCallback(
    (e) => {
      if (product.category_id) {
        MP.setCurrentCategory(
          flatCategoriesListLocalized.find(
            ({ id }) => id === product.category_id,
          ) || null,
        );
      }

      if (isPageWithNoSearchResultsOpened) {
        sendEventWithEventLabel(
          'no_search_results_page_product_selected_from_popular',
          product.name,
        );
        return;
      }

      sendGAEvent(
        viewBtnRef.current?.contains(e.target)
          ? 'view_details_button_click'
          : 'product_card_click',
      );
    },
    [flatCategoriesListLocalized, isPageWithNoSearchResultsOpened, product],
  );

  return (
    <Link
      data-testid={testId}
      to={{
        pathname: PATHS.PRODUCT(product.slug),
        presetType: 'product',
      }}
      onClick={handleLinkClick}
      className={clsx(
        style.productCard,
        isFixedWidth && style.fixCardWidth,
        isPageWithProfileDashboardOpened && style.productCardProfile,
      )}
    >
      {!product.isOutOfStock && (
        <Labels
          price={mainPrice.price}
          label={product.label}
          showDiscount={showDiscount}
        />
      )}
      <div>
        <div className={style.productImageWrapper}>
          <LazyLoadingComponent className={style.lazyContainer} once>
            <picture>
              <source srcSet={product.image?.webp?.medium} type="image/webp" />
              <img
                className={style.productImage}
                src={product.image?.medium || branding.defaultImageProduct}
                alt="ProductParts img"
              />
            </picture>
          </LazyLoadingComponent>
        </div>
        <div className={style.mainProductPriceWrapper}>
          <MainProductPrice
            showDiscount={showDiscount}
            mainPrice={mainPrice}
            defaultHiringPeriodProduct={defaultHiringPeriodProduct}
          />
        </div>
        <T8y className={style.productNameWrapper} as="p" variant="t2v1">
          {product.name}
        </T8y>
        {Boolean(product.rating) && (
          <Row align="center" className={style.productCardSmallRatingWrapper}>
            <Rating rating={product.rating} className={style.productRating} />
            <T8y variant="t4" color="midGray">
              ({product.reviewsCount || 1})
            </T8y>
          </Row>
        )}
      </div>
      <div className={style.viewDetailsBtnWrapper}>
        <Button
          ref={viewBtnRef}
          inverse
          className={style.viewDetailsBtn}
          data-testid="viewDetailsBtn"
        >
          {form.buttons.viewDetails}
        </Button>
      </div>
    </Link>
  );
}
