import { ALGOLIA_CLIENT } from 'consts';

export function excludeSpaceSignFromUrl(str) {
  return str.replace(/%20/g, ' ');
}

export function getUrlSearchParams(search) {
  return search
    .slice(1)
    .split('&')
    .reduce((acc, s) => {
      const [k, v] = s.split('=');
      return Object.assign(acc, { [k]: v });
    }, {});
}

export function checkUrlSearchParams(params, keys) {
  const utmParams = Object.entries(params).reduce((acc, el) => {
    const key = el[0];
    const value = el[1];

    if (!keys.includes(key)) {
      return acc;
    }

    return { ...acc, [key]: excludeSpaceSignFromUrl(value) };
  }, {});

  if (Object.keys(utmParams).length) {
    return utmParams;
  }
  return null;
}

const NEEDED_PARAMS_KEYS = ['sort', 'order'];

export const getUrlSearchAlgoliaParams = () => {
  const params = getUrlSearchParams(window.location.search);
  return checkUrlSearchParams(params, NEEDED_PARAMS_KEYS);
};

export const getStandardSearchClient = (isSearchPage) => {
  return {
    search(requests) {
      if (
        !isSearchPage &&
        !requests.every(({ params }) => params.query && params.query.length > 2)
      ) {
        return;
      }

      if (
        requests.every(({ params }) => {
          return params.query && params.query.length < 3;
        })
      ) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        });
      }

      return ALGOLIA_CLIENT.search(requests);
    },
  };
};
