import React from 'react';
import { useStore } from 'effector-react';
import { sendUserClickedOnDefaultResult } from 'googleAnalytics';
import { Lang } from 'lang';
import {
  isPopularCategoriesFinished$,
  popularCategoriesLocalized$,
} from 'models/common';

import { Spinner, T8y } from 'ui';

import { ReactComponent as StarIcon } from 'assets/images/icon/search/folderSpecial.svg';

import style from '../searchResults.module.scss';

const PopularCategories = ({ handleHitClick }) => {
  const { search } = Lang();

  const popularCategoriesLocalized = useStore(popularCategoriesLocalized$);
  const isPopularCategoriesFinished = useStore(isPopularCategoriesFinished$);

  if (!isPopularCategoriesFinished) {
    return <Spinner dark size={1} />;
  }

  return (
    !!popularCategoriesLocalized.length && (
      <>
        <p className={style.searchDefaultTitle}>{search.popularCategories}</p>
        {popularCategoriesLocalized.map((el, index) => (
          <div
            className={style.searchDefaultItem}
            key={index}
            onClick={() => {
              sendUserClickedOnDefaultResult(
                'user_search_popular_categories',
                el.name,
              );
              handleHitClick(el, 'category');
            }}
          >
            <StarIcon />
            <T8y
              as="span"
              variant="t2v1"
              className={style.searchDefaultItemText}
            >
              {el.name}
            </T8y>
          </div>
        ))}
      </>
    )
  );
};

export default PopularCategories;
