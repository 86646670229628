import { useCheckAwinParams } from './useCheckAwinParams';
import { useDefineRegionLocalStore } from './useDefineRegionLocalStore';
import { useGetRequestByUrl } from './useGetRequestByUrl';
import { useGlobalCopyListener } from './useGlobalCopyListener';
import { useHandleAdminVerification } from './useHandleAdminVerification';
import { useRemoveOldCheckoutLocalStore } from './useRemoveOldCheckoutLocalStore';
import { useSetLoadedDOM } from './useSetLoadedDOM';
import { useSetUTMData } from './useSetUTMData';

export const useInit = () => {
  useGetRequestByUrl();
  useHandleAdminVerification();
  useSetLoadedDOM();
  useSetUTMData();
  useGlobalCopyListener();
  useRemoveOldCheckoutLocalStore();
  useDefineRegionLocalStore();
  useCheckAwinParams();
};
