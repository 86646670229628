import { forward, guard } from 'effector';
import { getUserStatus } from 'utils/helpers/profileHelpers';

import { Memberships } from 'api';
import { AUTH, removeToken, USER } from 'api/profileApi';

import * as DC from './';
import {
  clearUserInfo,
  createUserAddressFx,
  deleteUserAddressFx,
  getMembershipsFx,
  getUserAddressesInfo,
  MembershipsGate,
} from './';

DC.getUserFx.use(async () => {
  const { data } = await AUTH.me();
  return data.data;
});

DC.getUserAddressesInfoFx.use(async () => {
  const { data } = await USER.getAddresses();
  return data.data;
});

DC.getUserAddressInfoFx.use(async (id) => {
  const { data } = await USER.getAddress(id);
  return data.data;
});

DC.createUserAddressFx.use(async (id) => {
  const { data } = await USER.createAddress(id);
  return data.data;
});

DC.updateUserAddressFx.use(async ({ params, id }) => {
  const { data } = await USER.updateAddress(params, id);
  return data;
});

DC.deleteUserAddressFx.use(async (id) => {
  const { data } = await USER.deleteAddress(id);
  return data;
});

DC.logOutUserFx.use(async () => {
  removeToken();
  clearUserInfo();
});

forward({
  from: DC.logOutUser,
  to: DC.logOutUserFx,
});

forward({
  from: DC.getUserInfo,
  to: DC.getUserFx,
});

forward({
  from: DC.getUserAddressesInfo,
  to: DC.getUserAddressesInfoFx,
});

forward({
  from: DC.getUserAddressInfo,
  to: DC.getUserAddressInfoFx,
});

DC.$userInfo
  .on(DC.getUserFx.doneData, (state, data) => {
    return {
      ...state,
      ...data,
      status: data.tier?.slug,
      userDiscount: data.tier?.discount || 0,
      statusPicture: getUserStatus(data.tier?.slug),
    };
  })
  .on(DC.getUserAddressesInfoFx.doneData, (state, data) => {
    return { ...state, addresses: data };
  })
  .on(createUserAddressFx.doneData, (state, data) => {
    if (data) {
      getUserAddressesInfo();
    }
    return state;
  })
  .on(deleteUserAddressFx.doneData, (state, data) => {
    if (data) {
      getUserAddressesInfo();
    }
  });

getMembershipsFx.use(async () => {
  const { data } = await Memberships.getAllMemberships();
  return data;
});

const isIdle = getMembershipsFx.pending.map((state) => !state);

guard({
  source: MembershipsGate.open,
  filter: isIdle,
  target: getMembershipsFx,
});
