import React from 'react';
import clsx from 'clsx';
import { LAPTOP_SCREEN } from 'consts';

import SliderWithMobileOverflow from 'features/common/SliderWithMobileOverflow';

import { useMatchMedia } from 'libs/useMatchMedia';

import { ProductCard } from 'ui';

import style from './sliderWithMobileOverflowWrapper.module.scss';

const SliderWithMobileOverflowWrapper = ({
  handleProductClick,
  isStartPositioning,
  additionalCardsWrapperClassName,
  products,
  testId,
  noSliderMobileWrapperRef,
}) => {
  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);

  return (
    <div
      className={clsx(
        style.popularSliderWrapper,
        !isLaptop && products.length < 4 && style.noSlider,
      )}
    >
      <SliderWithMobileOverflow
        items={products}
        isStartPositioning={isStartPositioning}
        additionalCardsWrapperClassName={additionalCardsWrapperClassName}
        noSliderMobileWrapperRef={noSliderMobileWrapperRef}
      >
        {products.map((item, i) => (
          <div
            key={item.id}
            className={
              !isLaptop && products.length < 4
                ? style.popularCardNoSlider
                : style.popularCardSlider
            }
            onClick={() =>
              handleProductClick ? handleProductClick(item) : undefined
            }
          >
            <ProductCard
              product={item}
              testId={`${testId}-${i}`}
              isFixedWidth
            />
          </div>
        ))}
      </SliderWithMobileOverflow>
    </div>
  );
};

export default SliderWithMobileOverflowWrapper;
