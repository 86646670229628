export const account = {
  header: 'Il mio Account\n',
  subHeader:
    'Tutte le impostazioni e le preferenze del tuo account in una sola pagina',

  save: 'Salva',
  saveChanges: 'Salva Modifiche',
  cancel: 'Annulla',

  sections: {
    personalInformation: 'Dati Personali',
    userType: 'Privato',
    deliveryAddress: 'Indirizzo di Consegna',
    authentication: 'Autenticazione',
    emailNotifications: 'Notifiche via e-mail',
  },

  addresses: {
    addressBlockHeader: 'Indirizzo',
    makeDefault: 'Predefinito',
    edit: 'Modifica',
    delete: 'Elimina',
    defaultMark: 'Indirizzo Principale',
    addressLine: 'Indirizzo',
    chooseCorrectAddress: 'Por favor elige la dirección correcta de esta lista',
  },

  personal: {
    title: 'Valori del Brand',
    profileImage: 'Immagine del Profilo',
    uploadFile: 'Carica File...',
    deleteBtn: 'Cancella',
  },

  formFields: {
    nameFieldTitle: 'Nome e Cognome',
    nameFieldPlaceholder: 'Inserisci il tuo nome',
    emailFieldTitle: 'Indirizzo e-mail',
    emailFieldPlaceholder: 'Inserisci il tuo indirizzo e-mail',
    phoneFieldTitle: 'Numero di telefono',
    phoneFieldPlaceholder: 'Inserisci il tuo telefono',
  },

  youAreLoggedInAs: "Hai effettuato l'accesso come",
};
