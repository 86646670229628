export const getObject = (key) => {
  try {
    const value = localStorage.getItem(key);
    return value && JSON.parse(value);
  } catch (e) {
    console.warn(e);
  }
};

export const getValue = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.warn(e);
  }
};

export const setObject = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const setValue = (key, value) => {
  localStorage.setItem(key, value);
};
