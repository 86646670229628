import React, { useState } from 'react';
import { useGetStaticHeaderHeight } from 'hooks';

import SubCategory from './SubCategory';

import style from '../../../header.module.scss';

const SubCategoryLinks = ({
  selectedCategory,
  handleChooseCurrentCategory,
  isThirdLevelCategory,
  sendCategoryClicked,
}) => {
  const [categoryImage, setCategoryImage] = useState(null);

  const offsetFromTop = useGetStaticHeaderHeight();

  const handleMouseEnter = (item) => {
    if (!isThirdLevelCategory) {
      handleChooseCurrentCategory(item);
      !item.childs?.length && setCategoryImage(item.src);
    }
  };

  return (
    <div className={style.subMenuWrapper}>
      <ul
        style={{ maxHeight: `calc(100vh - ${offsetFromTop}px)` }}
        className={style.dropdownMenu}
      >
        <li className={style.listWrapper}>
          <div className={style.parentTitle}>
            <span className={style.categoryName}>{selectedCategory.name}</span>
          </div>
        </li>

        {selectedCategory.childs.map((item, i) => (
          <li
            onMouseEnter={() => handleMouseEnter(item)}
            onMouseLeave={() => setCategoryImage(null)}
            className={style.listWrapper}
            key={i}
          >
            <SubCategory
              item={item}
              sendCategoryClicked={() => sendCategoryClicked(item.name)}
            />
          </li>
        ))}
      </ul>
      {categoryImage && (
        <div className={style.selectedSubCategoryImgWrapper}>
          <img src={categoryImage} alt="Category" />
        </div>
      )}
    </div>
  );
};

export default SubCategoryLinks;
