import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { MOBILE_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { useOnClickOutside } from 'hooks';
import CartPopup from 'layout/Header/CartPopup';
import CartSing from 'layout/Header/CartSing';
import {
  cartAllItemsLength$,
  isCartPopupVisible$,
  setIsCartPopupVisible,
} from 'models/cart';

import { useMatchMedia } from 'libs/useMatchMedia';

import style from './cartSignWrapper.module.scss';

const CartSingWrapper = ({ close }) => {
  const cartPopoverRef = useRef();
  const isCartPopupVisible = useStore(isCartPopupVisible$);
  const cartAllItemsLength = useStore(cartAllItemsLength$);

  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);

  useOnClickOutside(
    cartPopoverRef,
    () => setIsCartPopupVisible(false),
    'div[class*="datePicker"], [data-testid="btn-addToReservation"], [data-testid="modal-productAttributes"]',
  );

  return (
    <div
      onClick={close}
      className={style.newCheckoutCartWrapper}
      onMouseEnter={
        !isMobile && cartAllItemsLength
          ? () => setIsCartPopupVisible(true)
          : undefined
      }
      onMouseLeave={(e) => {
        if (!e.target?.closest('div[class*=datePickerCalendar]')) {
          setIsCartPopupVisible(false);
        }
      }}
    >
      <Link
        to={PATHS.RESERVATION}
        onClick={close}
        className={style.cart}
        aria-label="cart"
      >
        <CartSing />
      </Link>
      <div
        ref={cartPopoverRef}
        className={clsx(
          style.popoverWrapper,
          isCartPopupVisible && style.cartPopupVisible,
        )}
        tabIndex={0}
      >
        <CartPopup />
      </div>
    </div>
  );
};

export default CartSingWrapper;
