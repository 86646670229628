import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const Checkout = lazy(() =>
  lazyRetry(() => import('./TrainingCheckout'), 'TrainingCheckout'),
);

export const trainingCheckoutRoutes = [
  {
    path: PATHS.TRAINING_CHECKOUT(':slug'),
    exact: true,
    component: () => WithSuspense(Checkout),
  },
];
