import { combine, createEffect, createStore, restore } from 'effector';
import { createGate } from 'effector-react';
import { currentLang$ } from 'models/language';
import { settings$ } from 'models/settings';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

export const aboutUsGate = createGate('aboutUs');

export const getAboutUsFx = createEffect();

export const aboutUs$ = restore(getAboutUsFx, {});

export const isAboutUsRequestFinished$ = createStore(false).on(
  getAboutUsFx.done,
  (_) => true,
);

export const aboutUsLocalized$ = combine(
  aboutUs$,
  currentLang$,
  settings$,
  (aboutUs, lang, { defaultLanguage }) => ({
    ...aboutUs,
    aboutMetaDescription: getTranslatedFieldValue(
      aboutUs.aboutMetaDescription,
      lang,
      defaultLanguage,
    ),
    aboutPageHistoryText: getTranslatedFieldValue(
      aboutUs.aboutPageHistoryText,
      lang,
      defaultLanguage,
    ),
    aboutPageVisionText: getTranslatedFieldValue(
      aboutUs.aboutPageVisionText,
      lang,
      defaultLanguage,
    ),
  }),
);
