import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { isEAR, isEN, isETH } from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel } from 'googleAnalytics';
import { Lang } from 'lang';
import { currentLang$ } from 'models/language';
import { isWarehouses$ } from 'models/warehouses';

import { Row, T8y } from 'ui';

import { ReactComponent as ArrowBack } from 'assets/images/icon/arrowBack.svg';

import style from '../header.module.scss';

const SubMenuCompany = ({ id, openId, setOpenId, close }) => {
  const {
    formatString,
    header: { nav },
    depots,
  } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const currentLangState = useStore(currentLang$);
  const isWarehouses = useStore(isWarehouses$);

  const isHire = openId === id;

  const handleItemClick = useCallback(
    (menuTitle) => {
      sendEventWithEventLabel('regional_page_header_about', menuTitle);
      close();
    },
    [close],
  );

  return (
    <div className={style.category}>
      <Row
        justify="stretch"
        align="center"
        className={clsx(
          style.name,
          isHire ? style.border : style.triangle,
          openId > 0 && !isHire && style.none,
        )}
        onClick={() => {
          setOpenId(isHire ? 0 : 3);
        }}
        data-testid="mobile-company-disclosure"
      >
        {isHire ? (
          <>
            <Row align="center">
              <ArrowBack className={style.backArrow} />{' '}
              <T8y variant="t1" color="light" className={style.menuTitle}>
                {nav.back}
              </T8y>
            </Row>
            <T8y variant="t3" color="lightGray" uppercase>
              {nav.aboutUs}
            </T8y>
          </>
        ) : (
          <T8y variant="t1" color="light" className={style.menuTitle}>
            {nav.aboutUs}
          </T8y>
        )}
      </Row>

      {isHire && (
        <>
          <T8y
            as={Link}
            to={PATHS.ABOUT}
            onClick={() =>
              handleItemClick(
                formatString(nav.findHowWeAre, branding.companyName || ''),
              )
            }
            color="light"
            variant="t1"
            className={style.categoryOnly}
            capitalize={!isETH && !isEAR}
          >
            {formatString(nav.findHowWeAre, branding.companyName || '')}
          </T8y>
          {isWarehouses && (
            <T8y
              as={Link}
              to={PATHS.DEPOTS}
              onClick={() => handleItemClick(depots.title)}
              color="light"
              variant="t1"
              className={style.categoryOnly}
              capitalize={!isEN}
            >
              {formatString(depots.title, '')}
            </T8y>
          )}
          {settings.showMaintenancePage && (
            <T8y
              as={Link}
              to={PATHS.MAINTENANCE}
              onClick={() => handleItemClick(nav.maintenance)}
              color="light"
              variant="t1"
              className={style.categoryOnly}
              capitalize
            >
              {nav.maintenance}
            </T8y>
          )}
          <T8y
            as="a"
            rel="noopener noreferrer"
            href={branding.faqLink?.[currentLangState] || '#'}
            onClick={() => handleItemClick(nav.faq)}
            color="light"
            variant="t1"
            className={style.categoryOnly}
            capitalize
          >
            {nav.faq}
          </T8y>
          <T8y
            as={Link}
            to={PATHS.CARBON_EMISSION}
            onClick={() => handleItemClick(nav.carbonOffsetting)}
            color="light"
            variant="t1"
            className={style.categoryOnly}
            capitalize
          >
            {nav.carbonOffsetting}
          </T8y>
        </>
      )}
    </div>
  );
};

export default SubMenuCompany;
