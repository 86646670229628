import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel } from 'googleAnalytics';
import { useGetStaticHeaderHeight, useHandleCategoryMenu } from 'hooks';
import PopularCategories from 'layout/Header/PopularCategories';
import { categoriesLocalized$ } from 'models/product';

import SubCategoryLinks from './SubCategoryLinks';

import { Row, T8y } from 'ui';

import { ReactComponent as Arrow } from 'assets/images/icon/arrowRight.svg';

import style from '../../header.module.scss';

const Catalog = ({ setIsSubMenuOpen }) => {
  const categoriesLocalized = useStore(categoriesLocalized$);

  const offsetFromTop = useGetStaticHeaderHeight();

  const {
    handleChooseMainCategory,
    setSelectedCategory,
    selectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
  } = useHandleCategoryMenu();

  const sendCategoryClicked = useCallback((categoryTitle) => {
    sendEventWithEventLabel('regional_page_header_hire', categoryTitle);
  }, []);

  if (!categoriesLocalized.length) {
    return null;
  }

  return (
    <div className={style.menuWrapper}>
      <div className={style.dropdownMenuWrapper}>
        <div
          style={{ maxHeight: `calc(100vh - ${offsetFromTop}px)` }}
          className={clsx(style.mainMenu, style.dropdownMenu)}
        >
          <PopularCategories
            setSelectedCategory={setSelectedCategory}
            setIsSubMenuOpen={setIsSubMenuOpen}
          />
          {categoriesLocalized.map((item, i) => (
            <Row
              key={i}
              as={NavLink}
              to={{
                pathname: PATHS.CATEGORY(item.slug),
                presetType: 'category',
              }}
              justify="stretch"
              align="center"
              noWrap
              pointer
              onMouseEnter={() => {
                handleChooseMainCategory(item);
                setSelectedSubCategory(null);
              }}
              onClick={() => {
                sendCategoryClicked(item.name);
                setIsSubMenuOpen(false);
              }}
              className={style.listItem}
            >
              <T8y variant="t1" color="light" className={style.listItemName}>
                {item.name}
              </T8y>
              <Arrow className={style.subArrow} />
            </Row>
          ))}
        </div>
        {selectedCategory?.childs && (
          <SubCategoryLinks
            selectedCategory={selectedCategory}
            handleChooseCurrentCategory={setSelectedSubCategory}
            sendCategoryClicked={sendCategoryClicked}
          />
        )}
        {selectedSubCategory?.childs?.length > 0 && (
          <SubCategoryLinks
            isThirdLevelCategory
            selectedCategory={selectedSubCategory}
            sendCategoryClicked={sendCategoryClicked}
          />
        )}
      </div>
    </div>
  );
};

export default Catalog;
