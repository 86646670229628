import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const Verification = lazy(() =>
  lazyRetry(() => import('./Verification'), 'Verification'),
);

const VerifyIframe = lazy(() =>
  lazyRetry(() => import('./VerifyIframe'), 'VerificationFrame'),
);

export const verificationRoutes = [
  {
    path: PATHS.VERIFICATION_FRAME,
    exact: true,
    component: () => WithSuspense(VerifyIframe),
  },
  {
    path: PATHS.VERIFICATION,
    exact: true,
    component: () => WithSuspense(Verification),
  },
];
