import React, { useEffect, useState } from 'react';
import { BrandingContext, EnquiryLeadContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { branding$, getBrandingFx } from 'models/branding';
import { getSettingsFx, settings$ } from 'models/settings';

const ContextProviders = ({ children }) => {
  const branding = useStore(branding$);
  const settings = useStore(settings$);

  const [handleScrollToEnquiryForm, setHandleScrollToEnquiryForm] =
    useState(null);

  useEffect(() => {
    getBrandingFx();
    getSettingsFx();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      <BrandingContext.Provider value={branding}>
        <EnquiryLeadContext.Provider
          value={{
            handleScrollToEnquiryForm,
            setHandleScrollToEnquiryForm: (clientCallback) => {
              setHandleScrollToEnquiryForm(() => clientCallback);
            },
          }}
        >
          {children}
        </EnquiryLeadContext.Provider>
      </BrandingContext.Provider>
    </SettingsContext.Provider>
  );
};

export default ContextProviders;
