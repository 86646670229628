import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { currentLang$ } from 'models/language';
import { getLocalizedMonthLabel } from 'utils/datesСalculation';

export function useGetLocalizedMonthLabels(moths) {
  const currentLangState = useStore(currentLang$);

  return useMemo(() => {
    if (!moths) {
      return [];
    }
    return moths.map((el) =>
      getLocalizedMonthLabel(currentLangState, el.month),
    );
  }, [currentLangState, moths]);
}
