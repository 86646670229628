import React, { useCallback, useEffect, useMemo } from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import {
  ALGOLIA_SEARCH_AUTO_SUGGESTIONS,
  ALGOLIA_SEARCH_CATEGORIES_INDEX,
  ALGOLIA_SEARCH_INDEX,
  ALGOLIA_SEARCH_TRAININGS_INDEX,
} from 'consts';
import { useGate, useStore } from 'effector-react';
import { sendEventWithCustomField } from 'googleAnalytics';
import { Lang } from 'lang';
import {
  PopularCategoriesGate,
  popularCategoriesLocalized$,
} from 'models/common';
import { changeSearchStr } from 'models/filter';
import { searchHistory$, setSearchHistory } from 'models/search';

import { history } from 'libs/history';

import FoundResults from './FoundResults';
import PopularCategories from './PopularCategories';
import SearchHistory from './SearchHistory';

import { Spinner, T8y } from 'ui';

import style from './searchResults.module.scss';

const SearchResults = ({
  currentRefinement,
  refine,
  searchListRef,
  hits,
  setIsListOpen,
  goToSearchPage,
  searchListClassName,
  searching,
  setIsEmptyResults,
}) => {
  const { search } = Lang();

  useGate(PopularCategoriesGate);
  const searchHistory = useStore(searchHistory$);
  const popularCategoriesLocalized = useStore(popularCategoriesLocalized$);

  const getRoute = useCallback((type, slug) => {
    switch (type) {
      case 'product':
        return PATHS.PRODUCT(slug);
      case 'category':
        return PATHS.CATEGORY(slug);
      case 'training':
        return PATHS.POST(slug);
      default:
        return '#';
    }
  }, []);

  const handleHitClick = useCallback(
    (hit, type, searchHistoryTitle) => {
      changeSearchStr('');
      refine(null);

      if (searchHistoryTitle) {
        setSearchHistory({
          title: searchHistoryTitle,
          type,
          slug: hit.slug,
        });
      }

      if (setIsListOpen) {
        setIsListOpen(false);
      }

      history.push({
        pathname: getRoute(type, hit.slug),
        presetType: type,
      });
    },
    [getRoute, refine, setIsListOpen],
  );

  const foundProducts = useMemo(() => {
    return hits.find((el) => el.index === ALGOLIA_SEARCH_INDEX)?.hits || [];
  }, [hits]);

  const foundCategories = useMemo(() => {
    return (
      hits.find((el) => el.index === ALGOLIA_SEARCH_CATEGORIES_INDEX)?.hits ||
      []
    );
  }, [hits]);

  const foundTrainings = useMemo(() => {
    return (
      hits.find((el) => el.index === ALGOLIA_SEARCH_TRAININGS_INDEX)?.hits || []
    );
  }, [hits]);

  const foundSuggestions = useMemo(() => {
    const suggestions =
      hits.find((el) => el.index === ALGOLIA_SEARCH_AUTO_SUGGESTIONS)?.hits ||
      [];

    return suggestions.slice(0, 5);
  }, [hits]);

  useEffect(() => {
    if (currentRefinement.length < 3) {
      return;
    }

    if (!foundProducts.length && !foundCategories.length) {
      sendEventWithCustomField(
        'user_search_no_result',
        'user_input',
        currentRefinement,
      );
    }
  }, [currentRefinement, foundCategories.length, foundProducts.length]);

  const isNoData =
    !currentRefinement.length &&
    !foundProducts.length &&
    !foundCategories.length &&
    !foundTrainings.length &&
    !foundSuggestions.length &&
    !popularCategoriesLocalized.length &&
    !searchHistory?.length;

  useEffect(() => {
    if (setIsEmptyResults) {
      setIsEmptyResults(isNoData);
    }
  }, [isNoData, setIsEmptyResults]);

  if (isNoData) {
    return null;
  }

  return (
    <div
      className={clsx(style.searchListScrollStyled, searchListClassName)}
      ref={searchListRef}
    >
      {currentRefinement.length > 2 &&
      (foundCategories.length ||
        foundProducts.length ||
        foundSuggestions.length ||
        foundTrainings.length) ? (
        <FoundResults
          foundCategories={foundCategories}
          foundProducts={foundProducts}
          foundSuggestions={foundSuggestions}
          foundTrainings={foundTrainings}
          handleHitClick={handleHitClick}
          goToSearchPage={goToSearchPage}
        />
      ) : (
        <div className={style.searchSuggestions}>
          {searching ? (
            <Spinner dark size={1} />
          ) : (
            currentRefinement.length > 2 && (
              <T8y
                as="span"
                variant="t2v1"
                className={style.noResultsTextWrapper}
              >
                {search.noResultsFound}
              </T8y>
            )
          )}
          {searching || currentRefinement.length < 3 ? (
            <>
              <SearchHistory
                handleHitClick={handleHitClick}
                goToSearchPage={goToSearchPage}
              />
              <PopularCategories handleHitClick={handleHitClick} />
            </>
          ) : (
            <>
              <PopularCategories handleHitClick={handleHitClick} />
              <SearchHistory
                handleHitClick={handleHitClick}
                goToSearchPage={goToSearchPage}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default connectStateResults(SearchResults);
