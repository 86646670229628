export const createObserverOnDOMElement = (obj, id, callback) => {
  try {
    const observer = new MutationObserver(function (mutations_list) {
      mutations_list.forEach(function (mutation) {
        mutation.addedNodes.forEach(function (added_node) {
          if (added_node.id === id) {
            callback();
            observer.disconnect();
          }
        });
      });
    });

    observer.observe(obj, { subtree: false, childList: true });
  } catch (e) {
    console.warn(e);
  }
};

export const createObserverOnDOMElementWithSubscribe = (obj, id, callback) => {
  try {
    const observer = new MutationObserver(function (mutations_list) {
      mutations_list.forEach(function (mutation) {
        mutation.addedNodes.forEach(function (added_node) {
          if (added_node.id === id) {
            callback();
          }
        });
      });
    });

    observer.observe(obj, { subtree: false, childList: true });
  } catch (e) {
    console.warn(e);
  }
};

export const createObserverOnDOMElementWithOptions = (sel, opt, cb) => {
  try {
    const Obs = new MutationObserver((m) => [...m].forEach(cb));
    document.querySelectorAll(sel).forEach((el) => Obs.observe(el, opt));
  } catch (e) {
    console.warn(e);
  }
};
