import { createEffect, createStore, restore } from 'effector';
import { createGate } from 'effector-react';

export const bannersGate = createGate('banners');

export const getBannersFx = createEffect();

export const isBannersRequestFinished$ = createStore(false).on(
  getBannersFx.done,
  (_) => true,
);

export const $banners = restore(getBannersFx, null);
