import React, { useRef, useState } from 'react';
import { useManageHitsListVisibility, useManageInputState } from 'hooks';
import { Lang } from 'lang';

import CheckoutInputField from 'features/common/CheckoutInputField';

import { useGetAddressesByPostcode } from './useGetAddressesByPostcode';

import { ReactComponent as OrangeArrow } from 'assets/images/icon/orangeArrow.svg';
import { ReactComponent as BackResults } from 'assets/images/icon/orangeBackResults.svg';

import style from './postcoderInput.module.scss';

export const PostcoderInput = ({
  onBlur,
  dataTestId,
  title,
  setEmptyAddress,
  changeDeliveryPostcodeAddress,
  addressByPostcode,
  completedAddress,
  findNewAddressClickCallback,
  addressClickCallback,
  handleSearchRequestError,
  autoScrollToList,
}) => {
  const { checkout, search } = Lang();

  const [searchRequestError, setSearchRequestError] = useState(null);

  const postcodeListBlockRef = useRef(null);
  const inputRef = useRef(null);

  const { makeListVisible, setIsListVisible, isListVisible } =
    useManageHitsListVisibility({
      inputRef,
      listRef: postcodeListBlockRef,
      excludeId: '#uk_postcode',
    });

  const {
    handleInputFocus,
    handleInputType,
    currValue,
    currValueError,
    setCurrValue,
  } = useManageInputState({
    initialValue: addressByPostcode?.postcode,
    setIsListVisible,
    setEmptyResult: setEmptyAddress,
    searchRequestError,
    makeListVisible,
    autoScrollToList,
    handleSearchRequestError,
  });

  const {
    handleFindNewAddressClick,
    handleBackToResultsClick,
    handleAddressClick,
    hits,
    isLoading,
    filterPaths,
  } = useGetAddressesByPostcode({
    changeDeliveryPostcodeAddress,
    currPostCodeStr: currValue,
    setIsPostcodesListVisible: setIsListVisible,
    postCodeError: currValueError,
    setEmptyAddress,
    setCurrPostCodeStr: setCurrValue,
    setSearchRequestError,
    findNewAddressClickCallback,
    addressClickCallback,
    makePostcodeListVisible: makeListVisible,
    autoScrollToList,
  });

  return addressByPostcode.name ? (
    completedAddress(handleFindNewAddressClick)
  ) : (
    <CheckoutInputField
      placeholder={`${checkout.placeholders.findPostcode} ...`}
      title={title}
      onChange={handleInputType}
      value={currValue}
      errorText={currValueError || searchRequestError}
      additionalClass={style.postcodeInput}
      isLoading={isLoading}
      innerRef={inputRef}
      onBlur={onBlur}
      id="uk_postcode"
      onFocus={handleInputFocus}
      autoComplete="off"
      data-testid={dataTestId}
    >
      {isListVisible && (
        <div className={style.postcodeListBlock} ref={postcodeListBlockRef}>
          <div className={style.postcodeList}>
            {!!filterPaths.length && (
              <div
                className={style.backResults}
                onClick={isLoading ? undefined : handleBackToResultsClick}
              >
                <BackResults /> {checkout.backToResults}
              </div>
            )}
            {currValue.length >= 3 && !hits.length && !isLoading && (
              <span className={style.postcodeItem}>{search.noItems}</span>
            )}
            {hits.map((el, i) => (
              <div
                key={el.id}
                className={style.postcodeItem}
                onClick={() => (isLoading ? undefined : handleAddressClick(el))}
                data-testid={`postcode-item-${i}`}
              >
                <div className={style.summaryText}>
                  <span>{el.summaryline}</span>
                  <span className={style.locationText}>
                    {el.locationsummary}
                    {el.count > 1 && ` - ${el.count} Addresses`}
                  </span>
                </div>
                {el.count > 1 && <OrangeArrow />}
              </div>
            ))}
          </div>
        </div>
      )}
    </CheckoutInputField>
  );
};
