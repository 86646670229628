export const sidebar = {
  yourReservation: 'La tua Prenotazione',
  yourQuote: 'Il tuo preventivo',
  costSummary: 'Anteprima dei Costi',
  proceed: 'Procedi',
  estimatePerWeek: 'Anteprima dei Costi',
  damageWaiver: 'Assicurazione Danni Lievi',
  subtotal: 'Costo Noleggio',
  transport: 'Logistica',
  delivery: 'Consegna',
  collection: 'Ritiro',
  selfCollection: 'Ritiro e riconsegna in Sede',
  vat: 'IVA',
  total: 'Subtotale',
  totalForCourse: 'Totale',
  totalExclVat: 'Totale (IVA esclusa)',
  deposit: 'Deposito',
  gasTankDeposit: 'Gas Tank Deposit',
  iAgree: 'Accetto i',
  free: 'Da Definire',
  insurance: 'Assicurazione',
  tbd: 'Da Definire',
  gratis: 'Gratis',
  safetyFee: 'Quota messa sicurezza',
  sanitationFee: 'Quota sanificazione',
  adminFee: 'Admin Fee',
  slightDamageWaiverFee: 'Rinuncia al danno',
  grandTotal: 'Totale',
  grandTotalProductCheckout: 'Totale',
  invoice: 'Fattura',
  logisticAdditionalText:
    'Ritiro in sede: gratuito. \n' +
    'Consegna a domicilio: costo da concordare in base alla distanza dalla sede',
  logisticAdditionalTextOnlySelfCollection:
    'Ritiro in sede: gratuito.\n ' +
    'Consegna: la consegna non è disponibile per questo prodotto.',
  logisticAdditionalTextOnlyDelivery:
    'Ritiro in sede: il ritiro in sede non è disponibile per questo prodotto.\n ' +
    'Consegna: costo da concordare in base all’indirizzo della consegna.',
  logisticAdditionalTextMixedDeliveries:
    'Ritiro in sede: gratuito.\n ' +
    'Consegna: costo da concordare in base all’indirizzo della consegna.',
  depositAdditionalText:
    'La cauzione verrà rimborsata sul metodo di pagamento non appena accertate le condizioni del materiale noleggiato',
  promoCodeAdditionalText:
    'Congratulazioni! Il tuo sconto è del {0}% \n Codice promozionale: {1}',
  maxDiscount: 'Sconto massimo',
  page: 'Pagina',
  expand: 'Espandi',
  collapse: 'Comprimi',
  products: 'Prodotti',
  showMore: 'Mostra di più',
  showLess: 'Mostra meno',
  hide: 'Nascondi',
  discount: 'Sconto',
  courseCost: 'Costo del corso',
  refundableDepositWarning:
    'Un deposito rimborsabile verrà bloccato per questo prodotto.',
  depositMayBeApplied:
    'Potrebbe essere applicato un deposito rimborsabile in base al totale complessivo del tuo ordine.',
  clickAddToReservation:
    "Clicca su ‘Aggiungi alla prenotazione' e procedi con il checkout per visualizzare il riepilogo completo dell’ordine.",
  itemsToHire: 'Prodotti a noleggio',
  tradeAccountDiscount: 'Sconto per account commerciale',
};
