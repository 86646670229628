import React from 'react';
import clsx from 'clsx';

import SliderWithMobileOverflowWrapper from 'features/common/SliderWithMobileOverflowWrapper';

import { T8y } from 'ui';

import style from './productsSlider.module.scss';

export function ProductsSlider({
  title,
  testId,
  className,
  titleClassName,
  products = [],
  anchor,
}) {
  return (
    <div className={clsx(style.container, className)} id={anchor}>
      <T8y
        variant="h1"
        xsVariant="t1"
        as="h3"
        color="primary"
        bold
        className={titleClassName}
      >
        {title}
      </T8y>
      <SliderWithMobileOverflowWrapper testId={testId} products={products} />
    </div>
  );
}
