import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const About = lazy(() => lazyRetry(() => import('./About'), 'About'));

export const aboutRoutes = [
  {
    path: PATHS.ABOUT,
    exact: true,
    component: () => WithSuspense(About),
  },
];
