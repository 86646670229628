import React, { useMemo } from 'react';
import { useBreadCrumbs } from 'hooks';
import SearchSpinner from 'pages/search/SearchSpinner';

const SearchFallback = () => {
  const breadCrumbs = useMemo(
    () => ({
      products: true,
      search: true,
    }),
    [],
  );

  useBreadCrumbs(breadCrumbs);

  return <SearchSpinner />;
};

export default SearchFallback;
