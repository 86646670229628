export const account = {
  header: 'My Account',
  subHeader: 'All your account settings and preferences on one page',

  save: 'Save',
  saveChanges: 'Save Changes',
  cancel: 'Cancel',

  sections: {
    personalInformation: 'Personal information',
    userType: 'User Type',
    deliveryAddress: 'Delivery Address',
    authentication: 'Authentication',
    emailNotifications: 'Email Notifications',
  },

  addresses: {
    addressBlockHeader: 'Address',
    makeDefault: 'Make Default',
    edit: 'Edit',
    delete: 'Delete',
    defaultMark: 'Default address',
    addressLine: 'Address Line',
    chooseCorrectAddress: 'Please choose the correct address from the list',
  },

  personal: {
    title: 'Brand Values',
    profileImage: 'Profile Image',
    uploadFile: 'Upload File',
    deleteBtn: 'Delete',
  },

  formFields: {
    nameFieldTitle: 'Full name',
    nameFieldPlaceholder: 'Enter your name',
    emailFieldTitle: 'Email Address',
    emailFieldPlaceholder: 'Enter your email address',
    phoneFieldTitle: 'Phone Number',
    phoneFieldPlaceholder: 'Enter your phone',
  },

  youAreLoggedInAs: 'You are logged in as',
};
