import React from 'react';
import { isETH } from 'consts';
import { Lang } from 'lang';
import ProductPricesTable from 'pages/product/MainBlock/ProductPricesTable';

import ProductCardPriceTableBody from 'features/common/ProductCardPriceTableBody';

import { T8y } from 'ui';

import style from './prices.module.scss';

const Prices = ({ product, showDiscount, sortedPrices }) => {
  const { products } = Lang();

  if (isETH) {
    return (
      <ProductPricesTable
        product={product}
        showDiscount={showDiscount}
        isCart
      />
    );
  }

  if (sortedPrices.length === 1) {
    return null;
  }

  return (
    <>
      <T8y variant="t4" color="midGray" className={style.pricesTableText}>
        {products.orderOnline}
      </T8y>
      <ProductCardPriceTableBody
        sortedPrices={sortedPrices}
        isShowOldPrice={showDiscount}
        additionalPrices={product.additional_prices}
      />
    </>
  );
};

export default Prices;
