import { useEffect } from 'react';
import { Lang } from 'lang';
import {
  removeBreadCrumbs,
  setBreadCrumbs,
  setBreadCrumbsIsLoading,
} from 'models/breadCrumbs';

export const useBreadCrumbs = (breadCrumbs, isLoading = false) => {
  const { header } = Lang();

  useEffect(() => {
    if (!isLoading) {
      setBreadCrumbs({ header, ...breadCrumbs });
    }
    setBreadCrumbsIsLoading(isLoading);

    return removeBreadCrumbs;
  }, [breadCrumbs, header, isLoading]);
};
