export const carbonEmission = {
  pageTitle: 'Alquiler Carbono Neutral',

  ourCommitment: {
    title: 'Nuestro compromiso',
    text1:
      '{0} ha decidido emprender el camino para convertirse en una empresa de cero emisiones, contribuyendo en proyectos y soluciones para el cambio climático y ofreciendo a sus socios y clientes la posibilidad de participar en los mismos.',
    text2:
      'Por cada alquiler compensamos las emisiones de CO2 derivadas del uso de la maquinaria alquilada, invirtiendo en proyectos sostenibles. Somos conscientes de que nuestro compromiso con el medio ambiente no debe acabar allí, pero este es nuestro primer paso para reducir las emisiones que involucran directamente a nuestra actividad.',
  },

  howDoesItWork: {
    title: 'Cómo funciona',
    text1:
      'Estamos comprometidos en obtener el mejor resultado en la compensación de nuestras emisiones de CO2. Para lograr esto, trabajamos junto con First Climate, líder en soluciones de compensación de carbono y energías renovables. La compensación de emisiones se basa en una contribución financiera que pagamos para apoyar proyectos de sostenibilidad de alto impacto.',
    text2:
      '{0} se hace cargo de los costes de la compensación. Trabajamos continuamente para mejorar la eficacia de los cálculos de emisiones de CO2',
    carbonEmission: 'Compensaremos la emisión de CO2 de cada alquiler',
    team: 'Nos haremos cargo de la totalidad de la compensación',
    environment:
      'Participamos en proyectos ambientales sostenibles para reducir nuestro impacto en el medio ambiente',
    analyticsData:
      'Seguiremos actualizando y mejorando nuestros datos sobre emisiones promedio y uso por producto/categoría',
  },

  supportWithFirstClimate: {
    title: 'El proyecto que apoyamos junto a',

    backgroundTitle: 'Antecedentes',
    backgroundText1:
      'Desde 1990, Indonesia ha perdido casi una cuarta parte de su cubierta forestal. Las selvas tropicales de Borneo, como en muchas áreas tropicales, están amenazadas por la tala de árboles, la extracción de minerales y la producción de aceite de palma, pulpa y caucho.',
    backgroundText2:
      'El problema continúa empeorando a medida que las áreas despejadas brindan un fácil acceso a zonas del bosque que antes eran remotas. El acceso más fácil también ha resultado en un aumento del comercio ilegal de vida silvestre, lo que representa una amenaza adicional para la vida silvestre en el área. Se ha estimado que cada minuto se deforesta un área equivalente a un campo de fútbol.',
    backgroundText3:
      'Sólo los bosques de turberas de Borneo contienen hasta 70 veces más carbono que las cantidades emitidas anualmente por la quema de combustibles fósiles en todo el mundo. Por lo tanto, la degradación de los bosques puede resultar en una drástica pérdida de reservas de carbono. Además, el área es extraordinariamente rica en biodiversidad y alberga cientos de especies en peligro de extinción que están amenazadas por la sobreexplotación.',

    projectTitle: 'El proyecto',
    projectText1:
      'La Reserva Rimba Raya se encuentra en el sureste de Borneo y se extiende casi 100 kilómetros de norte a sur a lo largo de los límites del Parque Nacional Tanjung Puting. La reserva cubre alrededor de 64.000 hectáreas y está dedicada a la protección y preservación de muchas especies en peligro de extinción, en particular, el orangután de Borneo cuya población ha disminuido más del 95% en el último siglo. Para proteger las áreas del bosque, se deben lograr los derechos de uso de la tierra para la tierra que limita con las áreas protegidas. Hasta ahora, el financiamiento del proyecto apoya 13 iniciativas comunitarias que van desde la filtración de agua hasta el apoyo a una cooperativa de acuicultura de camarón.',
    projectText2:
      'Mientras nos enfocamos en reducir las emisiones de gases de efecto invernadero, todos nuestros proyectos también generan múltiples beneficios colaterales. Estos apoyan los Objetivos de Desarrollo Sostenible de las Naciones Unidas.',

    technologyBriefTitle: 'Resumen tecnológico - cómo funciona',
    technologyBriefText1:
      'El carbono circula dentro de un ciclo, que consiste en la atmósfera, la planta, la hojarasca de las plantas y el suelo. El dióxido de carbono extraído de la atmósfera circundante es el principal insumo de los procesos de fotosíntesis de cualquier planta. Los resultados son agua, oxígeno y carbohidratos. Estos últimos están integrados en la fibra de la planta, fijando así el carbono en la biomasa de la planta. En última instancia, el carbono vuelve a entrar en la atmósfera a partir de la basura de biomasa en descomposición o la respiración del suelo.',
    technologyBriefText2:
      'La deforestación rompe este ciclo con múltiples efectos negativos. Primero, quemar biomasa directamente aumenta la cantidad de dióxido de carbono en la atmósfera. En segundo lugar, reduce la capacidad absoluta de la biosfera para fijar carbono. En tercer lugar, la eliminación de la cubierta vegetal acelera la velocidad a la que el carbono fijado en los suelos se respira en la atmósfera. Por último, la erosión de los suelos impide la recuperación a largo plazo de la vegetación en áreas degradadas. Este es un tema particularmente desafiante en climas tropicales donde los suelos son en su mayoría pobres en nutrientes',

    projectStandardsTitle: 'Estándares del proyecto',
    projectStandardsVCSboldText: 'El Verified Carbon Standard (VCS)',
    projectStandardsVCSText:
      'es un estándar global para la validación y verificación de reducciones voluntarias de emisiones de carbono. Las reducciones de emisiones de los proyectos de VCS deben ser reales, medibles, permanentes, adicionales, únicas, transparentes y verificadas por terceros. Evaluado en el contexto del volumen total de reducciones de emisiones, el VCS es el estándar líder a nivel mundial para las compensaciones voluntarias de carbono.',
    projectStandardsCCBSboldText:
      'Los Estándares de Clima, Comunidad y Biodiversidad (CCB)',
    projectStandardsCCBSText:
      'se lanzaron en 2005 para fomentar el desarrollo y la inversión en proyectos basados en sitios que brinden beneficios creíbles y significativos para el clima, la comunidad y la biodiversidad de una manera integrada y sustentable.',

    additionalText:
      '* Los datos de cálculo de emisiones se basan en estimaciones de categorías de productos promedio. Cada día trabajamos para mejorarlos y actualizarlos.',
  },
};
