import React from 'react';

import { Row, Spinner } from 'ui';

import style from './searchSpinner.module.scss';

const SearchSpinner = () => {
  return (
    <Row mt3>
      <div className={style.searchSpinnerWrapper}>
        <Spinner dark size={5} />
      </div>
    </Row>
  );
};

export default SearchSpinner;
