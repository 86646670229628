import { useCallback, useContext, useMemo } from 'react';
import {
  DEFAULT_DELIVERY_METHODS,
  isETH,
  NOT_AVAILABLE_DELIVERY,
} from 'consts';
import { SettingsContext } from 'contexts';
import { parse } from 'date-fns';
import { useStore } from 'effector-react';
import { productCalendarSettings$ } from 'models/common';
import { $userInfo } from 'models/user';
import { diffInDays } from 'utils/datesСalculation';
import { getDefaultDatesForProduct } from 'utils/getDefaultDatesForProduct';
import { getMainPriceForCard } from 'utils/helpers/formatPrices';

import { useGetProductDatesSettings } from './useGetProductDatesSettings';

export const useProductCardProps = (product) => {
  const settings = useContext(SettingsContext);

  const user = useStore($userInfo);
  const { currLocaleDate, isCurrentDayHirePossible } = useStore(
    productCalendarSettings$,
  );

  const { deliveryWeekends, defaultHiringPeriod, finalBlockedDates } =
    useGetProductDatesSettings();

  const defaultHiringPeriodProduct = useMemo(() => {
    if (isETH) {
      return product?.displayedRange === 'week' ? 7 : 1;
    }

    return defaultHiringPeriod;
  }, [defaultHiringPeriod, product]);

  const getCurrDefaultDatesForProduct = useCallback(
    () =>
      getDefaultDatesForProduct(
        {
          daysMarginBeforeRental: product?.daysMarginBeforeRental,
          min_period_days: product?.min_period_days,
        },
        deliveryWeekends,
        defaultHiringPeriodProduct,
        finalBlockedDates,
        isCurrentDayHirePossible,
        currLocaleDate,
      ),
    [
      product,
      deliveryWeekends,
      finalBlockedDates,
      defaultHiringPeriodProduct,
      isCurrentDayHirePossible,
      currLocaleDate,
    ],
  );

  const getHiringPeriod = useCallback(() => {
    const dates = getCurrDefaultDatesForProduct();

    return dates.start && dates.end
      ? diffInDays(
          parse(dates.end, 'dd/MM/yyyy', new Date()),
          parse(dates.start, 'dd/MM/yyyy', new Date()),
        )
      : 0;
  }, [getCurrDefaultDatesForProduct]);

  const isNotAvailable = useMemo(() => {
    const notAvailableIndex = DEFAULT_DELIVERY_METHODS.findIndex(
      (el) => el === NOT_AVAILABLE_DELIVERY,
    );
    return product?.delivery?.type === notAvailableIndex;
  }, [product]);

  const isHiringPossible = useMemo(() => {
    return (
      product?.prices &&
      !!product.prices?.length &&
      !isNotAvailable &&
      settings.deliveryWeekends?.length < 7
    );
  }, [isNotAvailable, product, settings]);

  //set price is 0 do display "not available" text
  //since in card we don't care if it's because of prices or delivery or settings
  const mainPrice = useMemo(() => {
    if (!isHiringPossible) {
      return 0;
    }

    return getMainPriceForCard({ product, hiringPeriod: getHiringPeriod() });
  }, [getHiringPeriod, isHiringPossible, product]);

  const showDiscount = Boolean(user.userDiscount);

  return useMemo(
    () => ({
      showDiscount,
      mainPrice,
      defaultHiringPeriodProduct,
    }),
    [mainPrice, showDiscount, defaultHiringPeriodProduct],
  );
};
