import axios from 'axios';

import { getBrowser, getIsMobile, isBot } from './helpers/getUserBrowserInfo';

export const sendMsgToSlack = async (error, isAttachments, email) => {
  if (process.env.NODE_ENV === 'development' || isBot) {
    return;
  }

  const webhookUrl =
    'https://hooks.slack.com/services/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';

  const errorText = error ? error.toString() : '';

  const url = window.location.href;

  const browser = getBrowser();
  const isMobile = getIsMobile();

  const data = {
    text: `Url: ${url} \n ${errorText} \n Browser: ${browser.name} ${browser.version} \n Mobile: ${isMobile} \n User agent: ${navigator.userAgent}`,
    ...(isAttachments && {
      attachments: [
        {
          text: 'PAYMENT ERROR!',
          color: '#c61616',

          fields: [
            {
              title: 'User email',
              value: email,
              short: false,
            },
          ],
        },
      ],
    }),
  };

  await axios.post(webhookUrl, JSON.stringify(data), {
    withCredentials: false,
    transformRequest: [
      (data, headers) => {
        delete headers.post?.['Content-Type'];
        return data;
      },
    ],
  });
};
