export const dashboard = {
  header: 'Bem-vindo',
  subHeader: 'Este é o Painel de Controle do Cliente',
  getQuote: 'Obter cotação',
  viewOrder: 'Ver pedidos',
  support: 'Entre em contato com o suporte',
  allProducts: 'Todos os produtos',
  titles: {
    quickStart: 'Começo rápido',
    recentQuotes: 'Cotações Recentes',
    recentOrders: 'Pedidos Recentes',
    onHireOrders: 'Em Aluguer',
    popularCategories: 'Categorias Populares',
    noQuotes: 'Não há cotações para exibir. Por favor, navegue em nosso {0}',
    noOrders: 'Não há pedidos para exibir. Por favor, navegue em nosso {0}',
    catalog: 'Catálogo',
    popularProducts: 'Produtos mais populares',
    benefitsCustomer: '{0} Benefícios da conta',
  },

  table: {
    estimateCellHeader: 'Total estimado',
    deliveryCellHeader: 'Data de entrega',
    collectionCellHeader: 'Data de coleta',
  },

  quotesTable: {
    quoteCellHeader: 'Cotação #',
    estimateCellHeader: 'Total estimado',
    deliveryCellHeader: 'Data de entrega',
    collectionCellHeader: 'Data de coleta',
    btnText: 'Ver Cotação',
  },

  ordersTable: {
    orderCellHeader: 'Pedido #',
    priceCellHeader: 'Preço Total',
    deliveryCellHeader: 'Data de Entrega',
    collectionCellHeader: 'Data de coleta',
    statusCellHeader: 'Status',
    btnText: 'Ver Índice',
    dateCreated: 'Data de criação do pedido',
    grandTotal: 'Total Geral',
    partnership: 'Desenvolvido Em Parceria Com',
  },
  discountText: 'Seu desconto em todo o inventário',
  discountTypes: {
    platinum: 'Platina',
    gold: 'Ouro',
    silver: 'Prata',
    top: 'Voltar ao topo',
  },

  discountPortalTypes: {
    platinum: 'Desconto Platina',
    gold: 'Desconto Ouro',
    silver: 'Desconto Prata',
    top: 'Topo Desconto',
  },

  dashboardButtons: {
    newOrder: 'Iniciar um Novo Pedido',
    copyPrevOrder: 'Copiar Pedido Anterior',
    newUser: 'Adicionar Novo Usuário',
    supportChat: 'Chat de Suporte',
    reorder: 'Reordenar',
    cancelOrder: 'Cancelar pedido',
    cancelRequestSent: 'Solicitação de Cancelamento Enviada',
    changeEndDate: 'Alterar Data de Término',
  },
};
