import { languagesOverridesMapLocales } from 'consts';
import {
  add,
  closestIndexTo,
  differenceInDays,
  differenceInWeeks,
  eachDayOfInterval,
  format,
  getDay,
  isAfter,
  isPast,
  isSameDay,
  isWithinInterval,
  lightFormat,
  max,
  min,
  parse,
  parseISO,
} from 'date-fns';
import { enGB } from 'date-fns/locale';

import { capitalizeFirstLetter } from './formatStrings';

const DAYS_OF_THE_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getDayOfWeek = (date) => {
  return DAYS_OF_THE_WEEK[getDay(date)];
};

export const isInInterval = (date, interval) => {
  return isWithinInterval(date, interval);
};

export const diffInWeeks = (date) => {
  return differenceInWeeks(new Date(), parseISO(date));
};

export const diffInDays = (dateLeft, dateRight) => {
  return differenceInDays(dateLeft, dateRight) || 1;
};

export const getTimestampsDifference = (timestamp1, timestamp2) => {
  const d1 = new Date(timestamp1);
  const d2 = new Date(timestamp2);

  const diff = d2 - d1;
  //minutes
  return Math.floor(diff / 60e3);
};

export const formatDate = (date) => {
  if (!date) return null;
  return date?.toLocaleString('en-GB').slice(0).split(',')[0];
};

export const getDate = (date) => {
  if (!date) return null;
  return isPast(date) ? null : new Date(date);
};

export const getDateFromString = (date, format = 'dd/MM/yyyy') => {
  if (!date) return null;
  return parse(date, format, new Date());
};

export const getDateInFormat = (date, template = 'dd/MM/yyyy HH:mm') => {
  if (!date) return null;
  return format(date, template);
};

export const incrementDate = (date, increment) => {
  const dateFormatToTime = new Date(date);
  return new Date(dateFormatToTime.getTime() + increment * 86400000);
};

const calculateSkipDays = (
  nextDay,
  deliveryWeekends,
  blockedDatesList,
  skipDays = 1,
) => {
  if (
    !deliveryWeekends.includes(getDayOfWeek(nextDay)) &&
    !blockedDatesList.includes(getDateInFormat(nextDay, 'yyyy-MM-dd'))
  ) {
    return skipDays;
  }

  return calculateSkipDays(
    incrementDate(nextDay, 1),
    deliveryWeekends,
    blockedDatesList,
    skipDays + 1,
  );
};

export const nextDayFromCurrDayDate = add(new Date(), { days: 1 });

export const getBlockedDatesByDaysWeek = (
  blockedWeekDays = [],
  regionTimezone,
) => {
  if (!blockedWeekDays.length || !regionTimezone) {
    return [];
  }

  const dateWithTimezoneFromSettings = new Date(
    new Date().toLocaleDateString('en-US', { timeZone: regionTimezone }),
  );

  const nowDayOfWeek = getDayOfWeek(dateWithTimezoneFromSettings);
  const daysOffset = blockedWeekDays.find(
    (el) => el.weekDay.toLowerCase() === nowDayOfWeek.toLowerCase(),
  )?.offset;

  if (daysOffset) {
    return eachDayOfInterval({
      start: dateWithTimezoneFromSettings,
      end: incrementDate(dateWithTimezoneFromSettings, daysOffset),
    }).slice(1);
  }

  return [];
};

export const getStartDateWithDaysMargin = (
  deliveryWeekends,
  finalBlockedDates,
  daysMarginBeforeRental,
  start,
) => {
  const blockedDatesWithMarginDays = [
    ...finalBlockedDates,
    getDateInFormat(start, 'yyyy-MM-dd'),
  ];
  let startDate = start;
  let skipDaysForStart = 1;
  let marginOfDaysLeft = daysMarginBeforeRental;

  do {
    marginOfDaysLeft = marginOfDaysLeft - 1;
    skipDaysForStart = calculateSkipDays(
      startDate,
      deliveryWeekends,
      blockedDatesWithMarginDays,
      0,
    );

    startDate = incrementDate(startDate, skipDaysForStart);

    if (marginOfDaysLeft > 0) {
      blockedDatesWithMarginDays.push(getDateInFormat(startDate, 'yyyy-MM-dd'));
    }
  } while (marginOfDaysLeft > 0);

  return { startDate, blockedDatesWithMarginDays };
};

export const getDatesDefault = ({
  product,
  deliveryWeekends = [],
  type = 'start',
  defaultHiringPeriod = 7,
  finalBlockedDates = [],
  isCurrentDayHirePossible,
  currLocaleDate,
}) => {
  let skipDaysForStart =
    product?.daysMarginBeforeRental || isCurrentDayHirePossible ? 0 : 1;
  const startDaysCount = isCurrentDayHirePossible
    ? currLocaleDate
    : nextDayFromCurrDayDate;

  let skipDaysForEnd = defaultHiringPeriod;
  let finalBlockedDatesWithMarginDays = [...finalBlockedDates];

  let start = incrementDate(new Date(), skipDaysForStart);
  let end;

  if (
    deliveryWeekends.length ||
    finalBlockedDates.length ||
    product?.daysMarginBeforeRental
  ) {
    //change from what day we will be skip days
    skipDaysForStart = calculateSkipDays(
      startDaysCount,
      deliveryWeekends,
      finalBlockedDates,
      isCurrentDayHirePossible ? 0 : 1,
    );

    start = incrementDate(new Date(), skipDaysForStart);

    //count product's daysMarginBeforeRental
    if (product?.daysMarginBeforeRental) {
      const { startDate, blockedDatesWithMarginDays } =
        getStartDateWithDaysMargin(
          deliveryWeekends,
          finalBlockedDates,
          product.daysMarginBeforeRental,
          start,
        );

      start = startDate;
      finalBlockedDatesWithMarginDays = [...blockedDatesWithMarginDays];
    }
  }

  let minPeriod = defaultHiringPeriod;

  if (
    product &&
    product.min_period_days &&
    product.min_period_days > defaultHiringPeriod
  ) {
    minPeriod = product.min_period_days;
  }

  if (
    deliveryWeekends.length ||
    finalBlockedDates.length ||
    minPeriod !== defaultHiringPeriod
  ) {
    const nextDay = add(start, { days: minPeriod - 1 });
    skipDaysForEnd = calculateSkipDays(
      nextDay,
      deliveryWeekends,
      finalBlockedDates,
      minPeriod,
    );
  }

  end = incrementDate(start, skipDaysForEnd - 1);

  if (type === 'listOfBlockedDates') {
    return finalBlockedDatesWithMarginDays;
  }

  return lightFormat(type === 'end' ? end : start, 'dd/MM/yyyy');
};

export const getFormattedStringDate = (date, template = 'dd/MM/yyyy HH:mm') => {
  return format(parseISO(date), template);
};

export const getLocalizedMonthLabel = (currLang, month) => {
  return languagesOverridesMapLocales[currLang]
    ? languagesOverridesMapLocales[currLang].localize.month(month, {
        width: 'wide',
      })
    : enGB.localize.month(month, { width: 'wide' });
};

export const getFormattedDayString = (date = '2020-01-01', lang = 'en-gb') => {
  const actualDate = new Date(date);
  const monthName = new Intl.DateTimeFormat(lang, { month: 'short' }).format(
    actualDate,
  );
  const day = actualDate.getDate();
  const year = actualDate.getFullYear();

  return `${day} ${monthName}, ${year}`;
};

const nowDate = new Date().toISOString();

export const getNearestDateIndex = (datesArray) => {
  const parsedDates = datesArray.map((el) => parseISO(el.date));
  return closestIndexTo(parseISO(nowDate), parsedDates);
};

export const getTrainingFormatDate = (lang = 'en-gb', start, end) => {
  const startDate = new Date(start).getDate();
  const startYear = new Date(start).getFullYear();
  const monthName = new Intl.DateTimeFormat(lang, { month: 'long' }).format;
  const firstMonthName = capitalizeFirstLetter(monthName(new Date(start)));

  if (!end || start === end) {
    return `${startDate} ${firstMonthName} ${startYear}`;
  }

  const endDate = new Date(end).getDate();
  const endYear = new Date(end).getFullYear();
  const secondMonthName = capitalizeFirstLetter(monthName(new Date(end)));

  const isOneMonth = firstMonthName === secondMonthName;
  const isOneYear = startYear === endYear;

  if (isOneMonth && isOneYear) {
    return `${startDate}\u2013${endDate} ${secondMonthName} ${endYear}`;
  }

  return `${startDate} ${isOneMonth ? '' : firstMonthName} ${
    isOneYear ? '' : startYear
  } \u2013 ${endDate} ${secondMonthName} ${endYear}`;
};

export const getFormattedHireDates = (
  lang = 'en-gb',
  start = '01/01/2020',
  end = '01/01/2020',
) => {
  const startDate = getDateFromString(start);
  const endDate = getDateFromString(end);

  const monthName = new Intl.DateTimeFormat(lang, { month: 'short' }).format;
  const dayOfWeekName = new Intl.DateTimeFormat(lang, { weekday: 'short' })
    .format;

  const startDateDay = new Date(startDate).getDate();
  const startDateDayOfWeek = capitalizeFirstLetter(dayOfWeekName(startDate));
  const startDateYear = new Date(startDate).getFullYear();
  const startDateMonth = capitalizeFirstLetter(monthName(new Date(startDate)));

  const endDateDay = new Date(endDate).getDate();
  const endDateDayOfWeek = capitalizeFirstLetter(dayOfWeekName(endDate));
  const endDateYear = new Date(endDate).getFullYear();
  const endDateMonth = capitalizeFirstLetter(monthName(new Date(endDate)));

  return {
    startDate: `${startDateDayOfWeek}, ${startDateMonth} ${startDateDay}, ${startDateYear}`,
    endDate: `${endDateDayOfWeek}, ${endDateMonth} ${endDateDay}, ${endDateYear}`,
  };
};

export const getUTCDate = () => {
  const date = new Date();
  const utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );

  return new Date(utcDate);
};

export const getOrderDates = (products) => {
  if (!products.length) {
    return {
      start_date: '',
      end_date: '',
    };
  }

  if (products.length === 1) {
    return {
      start_date: products[0].start_date,
      end_date: products[0].end_date,
    };
  }
  const { startDates, endDates } = products.reduce(
    (acc, el) => {
      acc.startDates.push(getDateFromString(el.start_date));
      acc.endDates.push(getDateFromString(el.end_date));
      return acc;
    },
    {
      startDates: [],
      endDates: [],
    },
  );

  return {
    start_date: getDateInFormat(min(startDates), 'dd/MM/yyyy'),
    end_date: getDateInFormat(max(endDates), 'dd/MM/yyyy'),
  };
};

export const getEndDateByStartDate = ({
  minBookingDays,
  startDate,
  deliveryWeekends = [],
  finalBlockedDates = [],
}) => {
  let skipDaysForEnd = minBookingDays;

  let end;

  if (deliveryWeekends.length || finalBlockedDates.length) {
    const nextDay = add(startDate, { days: minBookingDays - 1 });
    skipDaysForEnd = calculateSkipDays(
      nextDay,
      deliveryWeekends,
      finalBlockedDates,
      minBookingDays,
    );
  }

  end = incrementDate(startDate, skipDaysForEnd - 1);

  return lightFormat(end, 'dd/MM/yyyy');
};

export const getHiringDaysCount = (selectingDetails) => {
  if (selectingDetails && selectingDetails.dates) {
    return diffInDays(
      parse(selectingDetails.dates.end, 'dd/MM/yyyy', new Date()),
      parse(selectingDetails.dates.start, 'dd/MM/yyyy', new Date()),
    );
  }
  return 0;
};

export const checkDatesRange = ({
  product,
  deliveryWeekends = [],
  defaultHiringPeriod = 7,
  finalBlockedDates = [],
  isCurrentDayHirePossible,
  currLocaleDate,
  initialStartDate,
  initialEndDate,
}) => {
  let initialBlockedDatesWithMarginDays = [...finalBlockedDates];
  let skipDaysForStart =
    product?.daysMarginBeforeRental || isCurrentDayHirePossible ? 0 : 1;
  const startDaysCount = isCurrentDayHirePossible
    ? currLocaleDate
    : nextDayFromCurrDayDate;

  let skipDaysForEnd = defaultHiringPeriod;
  let start = incrementDate(new Date(), skipDaysForStart);
  let end;

  if (
    deliveryWeekends.length ||
    finalBlockedDates.length ||
    product?.daysMarginBeforeRental
  ) {
    //change from what day we will be skip days
    skipDaysForStart = calculateSkipDays(
      startDaysCount,
      deliveryWeekends,
      finalBlockedDates,
      isCurrentDayHirePossible ? 0 : 1,
    );

    start = incrementDate(new Date(), skipDaysForStart);

    //count product's daysMarginBeforeRental
    if (product?.daysMarginBeforeRental) {
      const { startDate, blockedDatesWithMarginDays } =
        getStartDateWithDaysMargin(
          deliveryWeekends,
          finalBlockedDates,
          product.daysMarginBeforeRental,
          start,
        );

      start = startDate;
      initialBlockedDatesWithMarginDays = [...blockedDatesWithMarginDays];
    }
  }

  let minPeriod = defaultHiringPeriod;

  if (
    product &&
    product.min_period_days &&
    product.min_period_days > defaultHiringPeriod
  ) {
    minPeriod = product.min_period_days;
  }

  if (
    deliveryWeekends.length ||
    finalBlockedDates.length ||
    minPeriod !== defaultHiringPeriod
  ) {
    const nextDay = add(start, { days: minPeriod - 1 });
    skipDaysForEnd = calculateSkipDays(
      nextDay,
      deliveryWeekends,
      finalBlockedDates,
      minPeriod,
    );
  }

  end = incrementDate(start, skipDaysForEnd - 1);

  // check if start date is same or more then min possible start date
  // check if that date is available
  const isStartValid =
    (isSameDay(initialStartDate, start) || isAfter(initialStartDate, start)) &&
    !deliveryWeekends.includes(getDayOfWeek(initialStartDate)) &&
    !initialBlockedDatesWithMarginDays.includes(
      getDateInFormat(initialStartDate, 'yyyy-MM-dd'),
    );

  // check if end date is same or more then min possible end date
  // check if that date is available
  const isEndValid =
    (isSameDay(initialEndDate, end) || isAfter(initialEndDate, end)) &&
    !deliveryWeekends.includes(getDayOfWeek(initialEndDate)) &&
    !initialBlockedDatesWithMarginDays.includes(
      getDateInFormat(initialEndDate, 'yyyy-MM-dd'),
    );

  return isStartValid && isEndValid;
};
