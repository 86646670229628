import React, { useCallback, useEffect, useRef } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import {
  sendEventWithCustomField,
  sendEventWithEventLabel,
} from 'googleAnalytics';
import { Lang } from 'lang';
import { changeSearchStrFromUrl } from 'models/filter';
import {
  isLastSearchResultsEmpty$,
  setMobileSearch,
  setSearchHistory,
} from 'models/search';

import { Row } from 'ui';

import { ReactComponent as SearchIcon } from 'assets/images/icon/search/searchNew.svg';

import style from './searchInput.module.scss';

const SearchInput = ({ currentRefinement, hits, refine }) => {
  const { form } = Lang();

  const history = useHistory();

  const inputRef = useRef();

  const isLastSearchResultsEmpty = useStore(isLastSearchResultsEmpty$);

  useEffect(() => {
    setMobileSearch({ currentRefinement, hits, refine });
  }, [currentRefinement, hits, refine]);

  const sendAnalytics = useCallback(
    (valueForSearch) => {
      if (
        isLastSearchResultsEmpty &&
        history.location.state?.from?.includes('/search')
      ) {
        sendEventWithEventLabel(
          'no_search_results_page_start_search',
          valueForSearch,
        );
      } else {
        sendEventWithCustomField(
          'user_start_search',
          'user input',
          valueForSearch,
        );
      }
    },
    [history.location.state, isLastSearchResultsEmpty],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!currentRefinement) {
        return;
      }

      sendAnalytics(currentRefinement);
      setSearchHistory({
        title: currentRefinement,
        type: 'product',
      });
      history.push(`${PATHS.SEARCH(1)}?q=${currentRefinement}`);
    },
    [currentRefinement, history, sendAnalytics],
  );

  return (
    <Row as="form" onSubmit={onSubmit} autoComplete="off">
      <div className={style.searchFieldWrapper}>
        <div
          className={style.searchField}
          onClick={() => {
            inputRef.current.focus();
          }}
        >
          <Row align="center" className={style.searchBtn}>
            <SearchIcon />
          </Row>
          <input
            type="search"
            name="search"
            placeholder={form.fields.search}
            value={currentRefinement}
            ref={inputRef}
            onChange={(event) => {
              changeSearchStrFromUrl(null);
              refine(event.currentTarget.value);
            }}
            autoFocus
          />
          {!!currentRefinement.length && (
            <div
              className={style.clearText}
              onClick={() => {
                refine('');
                inputRef.current.focus();
              }}
            >
              {form.fields.clear}
            </div>
          )}
        </div>
      </div>
    </Row>
  );
};

export const Search = connectAutoComplete(SearchInput);
