export const training = {
  seoTitle: 'Corsi di Formazione Sicurezza sul lavoro Emilia Romagna',

  checkout: {
    mainTitle: 'Iscrizione al Corso',
    numberOfSeatsTitle: 'Numero di posti',
    datesTitle: 'Data iscrizione',
    contactTitle: 'Contatti',
    paymentTitle: 'Metodo di pagamento',
    enterDetails: 'Inserisci i dati del tuo contatto',
    maxNumberOfSeatsMessage:
      'Stai prenotando per 5 o più persone? O sei un azienda che vuole formare il suo personale? Contattaci per scoprire la migliore offerta a te riservata!',
  },

  titles: {
    signUpFor: 'Iscriviti ai nostri corsi di formazione',
    closestDate: 'Prossima data',
    gallery: 'Galleria',
    schedule: 'Programma corsi',
    learning: 'Cosa imparerai',
    advantages: 'Perché scegliere noi',
    about: 'Specifiche Certificato',
    reviews: 'Recensioni del corso',
    questions: 'Domande Frequenti',
    other: 'Altri Corsi',
    description: 'Descrizione corso',
    haveAnyQuestions: 'Hai domande? Saremo felici di aiutarti!',
    fillInTheForm: 'Compila il form per ricevere ulteriori informazioni:',
    partners: 'Il corso è disponibile in partnership con',
    exploreOurCourses: 'Esplora i nostri corsi di formazione',
    recommendedProducts: 'Prodotti consigliati',
  },

  emptyDates: 'Date da definire',
  emptyDate: 'Data da definire',
  emptyLocation: 'Organizzatore  e luogo del corso da definire',
  emptyPrice: 'Prezzo da definire',
  emptyTheory: 'Ore di teoria e pratica da definire',
  noSchedule:
    'Il programma è disponibile su richiesta. Solitamente offriamo dai quattro ai cinque al mese. Completa la scheda sopra per prenotare e ti contatteremo il prima possibile.',
  perSeat: 'per persona',
};
