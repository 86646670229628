import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel } from 'googleAnalytics';
import { Lang } from 'lang';
import { trainingsLocalized$ } from 'models/trainings';

import { Row, T8y } from 'ui';

import { ReactComponent as ArrowBack } from 'assets/images/icon/arrowBack.svg';

import style from '../header.module.scss';

const SubMenuTraining = ({ id, openId, setOpenId, close }) => {
  const {
    header: { nav },
  } = Lang();

  const trainingsLocalized = useStore(trainingsLocalized$);

  const isHire = openId === id;

  const handleTrainingLinkClicked = useCallback(
    (trainingTitle) => {
      sendEventWithEventLabel('regional_page_header_training', trainingTitle);
      close();
    },
    [close],
  );

  if (!trainingsLocalized.length) {
    return (
      <div className={style.category}>
        <Row justify="stretch" align="center" className={style.name}>
          <Link
            to={PATHS.TRAINING}
            onClick={() => {
              handleTrainingLinkClicked('index training');
            }}
            className={style.menuTitle}
          >
            {nav.training}
          </Link>
        </Row>
      </div>
    );
  }

  return (
    <div className={style.category}>
      <Row
        justify="stretch"
        align="center"
        className={clsx(
          style.name,
          isHire ? style.border : style.triangle,
          openId > 0 && !isHire && style.none,
        )}
        onClick={() => {
          setOpenId(isHire ? 0 : 2);
        }}
        data-testid="mobile-trainings-disclosure"
      >
        {isHire ? (
          <>
            <Row align="center">
              <ArrowBack className={style.backArrow} />{' '}
              <T8y variant="t1" color="light" className={style.menuTitle}>
                {nav.back}
              </T8y>
            </Row>
            <T8y variant="t3" color="lightGray" uppercase>
              {nav.training}
            </T8y>
          </>
        ) : (
          <T8y variant="t1" color="light" className={style.menuTitle}>
            {nav.training}
          </T8y>
        )}
      </Row>

      {isHire && (
        <>
          {trainingsLocalized.map(({ title, slug }, i) => (
            <T8y
              key={i}
              as={Link}
              to={PATHS.POST(slug)}
              onClick={() => {
                handleTrainingLinkClicked(title);
              }}
              color="light"
              variant="t1"
              className={style.categoryOnly}
            >
              {title}
            </T8y>
          ))}
        </>
      )}
    </div>
  );
};

export default SubMenuTraining;
