import { about } from './es/about';
import { account } from './es/account';
import { carbonEmission } from './es/carbonEmission';
import { checkout } from './es/checkout';
import { common } from './es/common';
import { customer } from './es/customer';
import { dashboard } from './es/dashboard';
import { depots } from './es/depots';
import { error } from './es/error';
import { footer } from './es/footer';
import { form } from './es/form';
import { header } from './es/header';
import { invoice } from './es/invoice';
import { jobTypes } from './es/jobTypes';
import { main } from './es/main';
import { orders } from './es/orders';
import { products } from './es/products';
import { profile } from './es/profile';
import { search } from './es/search';
import { sidebar } from './es/sidebar';
import { success } from './es/success';
import { training } from './es/training';
import { verification } from './es/verification';

export const es = {
  about,
  account,
  training,
  main,
  footer,
  sidebar,
  checkout,
  products,
  header,
  form,
  profile,
  dashboard,
  customer,
  search,
  invoice,
  error,
  orders,
  carbonEmission,
  common,
  success,
  jobTypes,
  depots,
  verification,
};
