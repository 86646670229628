import React from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { useSectionAutoCollapsing } from 'layout/Footer/useSectionAutoCollapsing';

import { Row, T8y } from 'ui';

import { ReactComponent as BottomArrow } from 'assets/images/icon/bottomOrangeArrow.svg';

import style from './mobileDropDown.module.scss';

const MobileDropDown = ({ title, children, titleAsLink, path }) => {
  const disclosure = useDisclosureState();

  useSectionAutoCollapsing(disclosure);

  return (
    <div className={style.wrapper}>
      <Disclosure
        as={Row}
        {...disclosure}
        className={style.dropDownTitle}
        align="center"
        justify="stretch"
      >
        <T8y
          as={titleAsLink ? Link : undefined}
          to={titleAsLink ? path : undefined}
          variant="t1v1"
          bold
          className={style.title}
        >
          {title}
        </T8y>
        <BottomArrow className={disclosure.visible ? style.open : undefined} />
      </Disclosure>
      <DisclosureContent {...disclosure} className={style.content}>
        {children}
      </DisclosureContent>
    </div>
  );
};

export default MobileDropDown;
