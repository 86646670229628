import React, { useContext } from 'react';
import Iubenda from 'react-iubenda-policy';
import { NavLink } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { isETH } from 'consts';
import { BrandingContext, EnquiryLeadContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendClickOnPhoneIcon } from 'googleAnalytics';
import { Lang } from 'lang';
import { currentLang$ } from 'models/language';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const InformationItemsList = () => {
  const { footer, products, about, header } = Lang();

  const branding = useContext(BrandingContext);
  const { handleScrollToEnquiryForm } = useContext(EnquiryLeadContext);

  const currentLangState = useStore(currentLang$);

  return (
    <>
      <T8y
        as={NavLink}
        variant="t2v1"
        smVariant="t1v1"
        color="primary"
        to={PATHS.ABOUT}
        className={style.footerLink}
      >
        {footer.aboutUs}
      </T8y>
      <T8y
        as={NavLink}
        variant="t2v1"
        smVariant="t1v1"
        color="primary"
        to={PATHS.CARBON_EMISSION}
        className={style.footerLink}
      >
        {header.nav.carbonOffsetting}
      </T8y>
      <T8y
        as="a"
        variant="t2v1"
        smVariant="t1v1"
        color="primary"
        href={branding.blogLink || '#'}
        className={style.footerLink}
      >
        {footer.blog}
      </T8y>
      <T8y
        as="a"
        variant="t2v1"
        smVariant="t1v1"
        color="primary"
        href={branding.faqLink?.[currentLangState] || '#'}
        className={style.footerLink}
      >
        {footer.faq}
      </T8y>
      {!isETH && branding.privacyPolicy && (
        <T8y
          as="a"
          variant="t2v1"
          smVariant="t1v1"
          color="primary"
          href={PATHS.POLICY}
          target="_blank"
          className={style.footerLink}
        >
          {footer.privacyPolicy}
        </T8y>
      )}
      {isETH && (
        <Iubenda id="53062915" type="privacy" styling="nostyle">
          {footer.privacyPolicy}
        </Iubenda>
      )}
      {!isETH && branding.termsConditions && (
        <T8y
          as="a"
          variant="t2v1"
          smVariant="t1v1"
          color="primary"
          href={PATHS.TERMS}
          target="_blank"
          className={style.footerLink}
        >
          {footer.termsConditions}
        </T8y>
      )}
      {isETH && (
        <Iubenda id="53062915" type="terms-and-conditions" styling="nostyle">
          {footer.termsConditions}
        </Iubenda>
      )}
      {!isETH && branding.cookiePolicy && (
        <T8y
          as="a"
          variant="t2v1"
          smVariant="t1v1"
          color="primary"
          href={PATHS.COOKIE_POLICY}
          target="_blank"
          className={style.footerLink}
        >
          {footer.cookiePolicy}
        </T8y>
      )}
      {isETH && (
        <Iubenda id="53062915" type="cookie" styling="nostyle">
          {footer.cookiePolicy}
        </Iubenda>
      )}
      {branding.generalTerms && (
        <T8y
          as="a"
          variant="t2v1"
          smVariant="t1v1"
          color="primary"
          href={PATHS.GENERAL_TERMS}
          target="_blank"
          className={style.footerLink}
        >
          {footer.generalTerms}
        </T8y>
      )}
      {handleScrollToEnquiryForm ? (
        <T8y
          variant="t2v1"
          smVariant="t1v1"
          color="primary"
          pointer
          className={style.footerLink}
          onClick={handleScrollToEnquiryForm}
        >
          {about.contactUsTitle}
        </T8y>
      ) : (
        <T8y
          as="a"
          variant="t2v1"
          smVariant="t1v1"
          color="primary"
          href={`mailto:${branding.companyEmail}`}
          className={style.footerLink}
        >
          {branding.companyEmail}
        </T8y>
      )}
      <T8y
        as="a"
        variant="t2v1"
        smVariant="t1v1"
        color="primary"
        href={`tel:${branding.companyPhoneNumberCall}`}
        className={style.footerLink}
        onClick={() => {
          sendClickOnPhoneIcon('footer 1');
        }}
      >
        {products.call} {branding.companyPhoneNumber}
      </T8y>
    </>
  );
};

export default InformationItemsList;
