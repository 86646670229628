import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { BrandingContext } from 'contexts';
import { Lang } from 'lang';

import LazyLoadingComponent from 'features/common/LazyLoadingComponent';

import { Button, Rating, Row, T8y } from 'ui/index';

import style from './bigTrainingCard.module.scss';

export const BigTrainingCard = ({ training, testId }) => {
  const { form, products, header, training: trainingLocalized } = Lang();

  const branding = useContext(BrandingContext);

  //attribute offset="140" for lazy was tested on iPhone 14 Pro

  return (
    <Link
      data-testid={testId}
      to={PATHS.POST(training.slug)}
      className={style.trainingCard}
    >
      <LazyLoadingComponent className={style.lazyContainer} once offset={140}>
        <picture>
          <source srcSet={training.image?.webp?.medium} type="image/webp" />
          <img
            src={training.image?.medium || branding.defaultImageProduct}
            alt={training.name}
          />
        </picture>
      </LazyLoadingComponent>
      <Row
        align="start"
        justify="stretch"
        direction="column"
        className={style.infoWrapper}
      >
        <Row direction="column">
          <T8y variant="t1" xsVariant="t1v1" mb2 className={style.title}>
            {header.nav.training}: {training.name}
          </T8y>
          {Boolean(training.reviewsCont) && (
            <Row align="center" className={style.rating}>
              <Rating rating={training.rating} width={17} height={16} />
              <T8y variant="t1v1" xsVariant="t4" color="midGray" ml>
                ({training.reviewsCont})
              </T8y>
            </Row>
          )}
          {training.minPrice ? (
            <T8y variant="h2" className={style.price}>
              {`${branding.currencySymbol}${training.minPrice.toFixed(2)}`}
              <T8y as="span" variant="t2v1" ml>
                {trainingLocalized.perSeat}
              </T8y>
            </T8y>
          ) : (
            <T8y className={style.noPrice} bold>
              {products.noPrices.title}
            </T8y>
          )}
          <T8y
            variant="t2v1"
            color="midGray"
            className={style.shortDescription}
            dangerouslySetInnerHTML={{
              __html: training.shortDescription,
            }}
          />
        </Row>
        <Button
          className={style.viewDetailsBtn}
          inverse
          data-testid="viewDetailsBtn"
        >
          {form.buttons.viewDetails}
        </Button>
      </Row>
    </Link>
  );
};
