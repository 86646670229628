import { REACT_APP_PUBLIC_URL } from 'consts';
import { getDateInFormat } from 'utils/datesСalculation';

export const clearUpDataForAnalytics = (data) => {
  const { lead_source, ...rest } = data;

  if (lead_source) {
    rest.cf_lead_source = lead_source;
    rest.cf_preffered_comunication_method = lead_source;
  }

  return rest;
};

export const sendFMLeadToCRM = (data) => {
  if (window.freshsales) {
    const new_lead = {
      ...data,
      cf_subscribed_date: getDateInFormat(new Date()),
      company: {
        Name: 'Rentuu',
        Website: REACT_APP_PUBLIC_URL,
      },
    };

    const identifier = data.email;
    window.freshsales.identify(identifier, new_lead);
  }
};

export const sendFRLeadToCRM = ({ data, action = '' }) => {
  if (window.fwcrm) {
    window.fwcrm.trackCustomEvent(action, {
      ...data,
      cf_subscribed_date: getDateInFormat(new Date()),
    });
  }
};
