import React from 'react';
import clsx from 'clsx';

import style from './switcher.module.scss';

export const Switcher = ({ value, toggle, disabled }) => {
  const handleToggle = () => {
    if (toggle) {
      toggle(value === 0 ? 1 : 0);
    }
  };

  return (
    <div
      className={clsx(style.switcher, disabled && style.disabled)}
      onClick={disabled ? undefined : handleToggle}
    >
      <div className={clsx(style.circle, value === 1 && style.right)} />
    </div>
  );
};
