export const training = {
  seoTitle: 'Corsi di Formazione Sicurezza sul lavoro Emilia Romagna',

  checkout: {
    mainTitle: 'Training Course Reservation',
    numberOfSeatsTitle: 'Number of Seats',
    datesTitle: 'Reservation Date',
    contactTitle: 'Contact Details',
    paymentTitle: 'Payment Method',
    enterDetails: 'Enter contact details',
    maxNumberOfSeatsMessage:
      'Do you want to book for 5 or more people? Are you a company that wants to train its staff? Contact us to find out the best offer for you!',
  },

  titles: {
    signUpFor: 'Get certified through easy training academy',
    closestDate: 'Closest Date',
    gallery: 'Course Gallery',
    schedule: 'Scheduled Courses',
    learning: 'What you will learn',
    advantages: 'Why Choose Us',
    about: 'About Certificate',
    reviews: 'Course Reviews',
    questions: 'Frequently Asked Questions',
    other: 'Other Courses',
    description: 'Course description',
    haveAnyQuestions: 'Have any questions? We’ll be happy to help!',
    fillInTheForm:
      "Fill in the form and we'll get back to you with more details",
    partners: 'Course is made available in partnership with',
    exploreOurCourses: 'Explore our training courses',
    recommendedProducts: 'Recommended Products',
  },

  emptyDates: 'Dates TBC',
  emptyDate: 'Date TBC',
  emptyLocation: 'Course Organizer and Location(s) TBC',
  emptyPrice: 'Price TBC',
  emptyTheory: 'Theory and Practice Hours TBC',
  noSchedule:
    'Schedule is available upon request. Usually we have 4–5 courses per month. Please use the form above to make a reservation and we’ll contact you shortly.',
  perSeat: 'per seat',
};
