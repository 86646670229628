import { DEFAULT_PHONE_COUNTRY } from 'consts';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import { persist } from 'effector-storage/local';
import { branding$ } from 'models/branding';
import { currentLang$ } from 'models/language';
import { settings$ } from 'models/settings';
import { $isUserLoggedIn } from 'models/user';
import { sortByAlphabet } from 'utils/formatStrings';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

export const PopularCategoriesGate = createGate('popularCategories');

export const getPopularCategoriesFx = createEffect();

export const popularCategories$ = restore(getPopularCategoriesFx, []);

export const popularCategoriesLocalized$ = combine(
  popularCategories$,
  currentLang$,
  settings$,
  branding$,
  (popularCategories, lang, { defaultLanguage }, { defaultImageCategory }) => {
    return sortByAlphabet(
      popularCategories.map((el) => ({
        ...el,
        name: getTranslatedFieldValue(el.names, lang, defaultLanguage),
        webpImage: el.image?.webp?.medium,
        generalImage: el.image?.medium || defaultImageCategory,
      })),
    );
  },
);

export const isPopularCategoriesFinished$ = createStore(false)
  .on(getPopularCategoriesFx.done, (_) => true)
  .on(getPopularCategoriesFx.fail, (_) => true);

export const $isCheckoutNotAvailable = combine(
  $isUserLoggedIn,
  settings$,
  (isUserLoggedIn, settings) => {
    return (
      settings.productCTAAction?.type === 'make enquiry focus' &&
      !isUserLoggedIn
    );
  },
);

export const setPhoneMaskCountry = createEvent();

export const phoneMaskCountry$ = createStore(DEFAULT_PHONE_COUNTRY || null).on(
  setPhoneMaskCountry,
  (_, p) => p,
);

persist({ store: phoneMaskCountry$, key: 'phoneMaskCountry' });

export const productCalendarSettings$ = createStore({
  isCurrentDayHirePossible: false,
  currLocaleDate: null,
}).on(settings$.updates, (_, p) => {
  let isCurrentDayHirePossible = false;

  const dateWithTimezoneFromSettings = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: p.regionTimezone,
    }),
  );

  if (p.isSameDayHire) {
    const currHours = dateWithTimezoneFromSettings.getHours();
    const currMinutes = dateWithTimezoneFromSettings.getMinutes();

    const maxTime = p.sameDayMaxHireTime.split(':');
    const maxHours = Number(maxTime[0]);
    const maxMinutes = Number(maxTime[1]);

    if (currHours < maxHours) {
      isCurrentDayHirePossible = true;
    } else if (currHours === maxHours) {
      isCurrentDayHirePossible = currMinutes <= maxMinutes;
    }
  }

  return {
    isCurrentDayHirePossible,
    currLocaleDate: dateWithTimezoneFromSettings,
  };
});

export const territoryIDGate = createGate('territoryId');

export const getTerritoryIDFx = createEffect();

export const territoryID$ = restore(getTerritoryIDFx, null);
