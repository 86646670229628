export const form = {
  titles: {
    signUp: 'Inscreva-se no curso',
    thankYouFor: 'Obrigado por inscrever-se!',
    youHaveSuccessfully: 'Você conseguiu',
    makeAnEnquiry: 'Faça uma Pergunta',
    makeAnEnquiryButton: 'Contate-nos',
    checkBoxIAgree:
      'Eu concordo em compartilhar minhas informações com {0}. Nunca compartilharemos suas informações com terceiros sem sua permissão.',
    checkBoxLink: 'Consulte a Política de Privacidade aqui',
    checkBoxIWish:
      'Quero ser o primeiro a saber sobre as últimas ofertas, lançamentos de produtos, blogs e novidades da {0}.',
    makeAnEnquiryDescription:
      'Sinta-se à vontade para entrar em contato conosco se tiver alguma dúvida. Ficaremos felizes em ajudar!',
    howWouldYouLike: 'Como você gostaria de ser contactado?',
    pleaseEnterDetails: 'Por favor, insira seus detalhes de contato',
  },
  optionalField: 'opcional',
  cancel: 'Cancelar',
  saveChanges: 'Salvar alterações',
  fields: {
    search: 'Buscar',
    sortBy: 'Ordenar por',
    fullName: 'Nome completo',
    emailAddress: 'Endereço de e-mail',
    PhoneNumber: 'Número de telefone',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    dateOfBirth: 'Data de nascimento',
    birthplace: 'Local de nascimento',
    address: 'Endereço',
    companyName: 'Nome da Empresa',
    country: 'País',
    vatNumber: 'Número de IVA',
    sortCode: 'Código de Ordenação',
    userType: 'Tipo de usuário',
    accountNumber: 'Número de conta',
    taxNumber: 'Número de identificação fiscal',
    city: 'Cidade',
    newEmail: 'Novo Email',
    oldPassword: 'Senha Antiga',
    newPassword: 'Nova Senha',
    confirmNewPassword: 'Confirme a nova senha',
    deliveryAddress: 'Endereço de entrega',
    message: 'Mensagem',
    yourCommentsOrQuestions: 'Seus comentários ou perguntas',
    privacyLink: 'Política de Privacidade',
    postcode: 'Postal code',
    resendLink: 'Reenviar um link',
    forgotPassword: 'Esqueceu a senha',
    currentEmailAddress: 'Endereço de e-mail atual',
    newEmailAddress: 'Novo Endereço de Email',
    enterNewEmailAddress: 'Digite seu novo endereço de e-mail',
    password: 'Senha',
    enterPassword: 'Digite sua senha',
    currentPassword: 'Senha atual',
    repeatNewPassword: 'Repita a nova senha',
    passwordConfirmation: 'Confirmação de senha',
    enterNewPassword: 'Digite sua nova senha',
    repeatYourNewPassword: 'Repita sua nova senha',
    enterCurrentPassword: 'Digite sua senha atual',
    clear: 'Limpar',
    purchaseOrderNumber: 'Número do Pedido de Compra (Opcional)',
  },

  radio: {
    phone: 'Telefone',
    email: 'e-mail',
    chat: 'Chat / WhatsApp',
  },

  placeholders: {
    superConstruction: 'Super Construction Co.',
    italy: 'Portugal',
    enterNewEmail: 'Digite novo e-mail',
    enterOldPassword: 'Enter old password',
    enterNewPassword: 'Insira a nova senha',
    reEnterNewPassword: 'Re-introduza a nova senha',
    startTyping: 'Começe a digitar...',
    startTypingDeliveryAddress: 'Comece a digitar seu endereço de entrega...',
    enterPostalCode: 'Digite o código postal',
    yourMessage: 'Sua mensagem',
    startTypingToFilter: 'Comece a digitar para filtrar',
    ordersAvailable: 'Pedidos disponíveis',
    orderAvailable: 'Pedido disponível',
    quotesAvailable: 'orçamentos disponíveis',
    quoteAvailable: 'orçamento disponível',
    dateRange: 'Intervalo de datas',
    message: 'Detalhe sua consulta ou qualquer dúvida aqui',
    yourComment: 'Seu comentário',
    purchasePlaceholder: 'Insira o número de referência do cliente',
  },

  select: {
    select: 'Selecione',
    popular: 'Popularidade',
    priceLow: 'Preço (baixo para alto)',
    priceHigh: 'Preço (alto para baixo)',
    date: 'Novas chegadas',
    depotToLocation: 'Do depósito ao local',
    locationToDepot: 'Do local ao depósito',
    bothWaysDelivery: 'Entrega e Coleta',
  },

  refineListing: {
    title: 'Refinar a lista',
    reset: 'Redefinir',
    power: 'Potência de saída, kW',
    weight: 'Capacidade de peso, kg',
  },

  buttons: {
    signUp: 'Inscrever-se',
    confirm: 'Confirmar e fazer pedido',
    nextStep: 'Próxima Etapa',
    back: 'Voltar',
    submitIndex: 'Enviar Índice',
    submitEnquiry: 'Enviar consulta',
    browseSimilar: 'Procurar Opções Semelhantes',
    all: 'Todo',
    signUpNow: 'Inscreva-se agora',
    makeReservation: 'Faça uma Pergunta',
    proceedToCheckout: 'Fazer o Check-out',
    viewUserGuidelines: 'Veja as diretrizes do usuário',
    continueHiring: 'Continuar Contratando',
    goToCheckout: 'Ir para Check-out',
    submitRequest: 'Enviar Pedido',
    viewDetails: 'Ver Detalhes',
  },

  calendar: {
    selectHirePeriod: 'Selecione o Período de Contratação',
    previous: 'Previous',
    reset: 'Restaurar ao padrão',
    next: 'Próximo',
    dayPlaceholder: 'DD',
    monthPlaceholder: 'MM',
    yearPlaceholder: 'AAAA',
  },

  errors: {
    cardNumber: 'O número do cartão é um campo obrigatório',
    cardExpiry: 'A expiração é um campo obrigatório',
    cardCvc: 'CVC é um campo obrigatório',
    regionEmail: 'Erro',
    invalidData: 'Dados inválidos',
    noTerms:
      'Você deve ler e concordar com os Termos e Condições Gerais para prosseguir',
    wentWrong: 'Algo deu errado',
    wentWrongTryLater: 'Algo deu errado, tente novamente mais tarde',
    productNotAvailable: 'Este produto não está disponível para pedido',
    deliveryFromWarehouseNotAvailable:
      'A sessão de informações de entrega expirou, atualize a página',
  },

  validation: {
    required: 'Campo obrigatório*',
    requiredInOneWord: 'Obrigatório*',
    emailPattern: 'E-mail incorreto',
    passwordLength: 'A senha deve ter pelo menos 6 caracteres',
    passwordDifferent: 'As senhas são diferentes',
    invalidCreds: 'E-mail ou senha inválidos',
    userImageUploadError: 'A imagem não pode ser salva',
    emailVerification: 'Link de verificação do endereço de e-mail enviado',
    minLength: 'Mín. {0} caracteres',
    phonePatter: 'Número de telefone incorreto',
  },

  success: {
    regionEmail: 'Seu pedido foi enviado com sucesso!',
    thankYou: 'Obrigado por sua pergunta!',
    youHave:
      'Estamos trabalhando em sua consulta e entraremos em contato com você em breve por e-mail ou telefone.',
    futureCheckout:
      'Você pode reutilizar as informações inseridas no formulário de consulta e prosseguir para o checkout.',
    checkoutDiscount: 'Para todos os cursos pagos online oferecemos ',
    discountPercent: '{0}% de desconto',
    okProceed: 'Ok, prossiga para o checkout',
    thankYouForEnquiry: 'Obrigado por enviar sua pergunta!',
    weWillContact: 'Entraremos em contato com você em breve.',
  },

  successGetQuote: {
    title: 'Tua solicitação de orçamento foi recebida com sucesso',
    thankYouTitle: 'Obrigado pelo seu pedido de orçamento!',
    msg: 'Enviamos um e-mail com a cotação solicitada para o seu endereço de e-mail. Entraremos em contato em breve!',
    newCheckoutMsg:
      'Enviamos um e-mail com a cotação solicitada para o seu endereço de e-mail. Entraremos em contato em breve!',
  },

  purchaseTooltip:
    'Adicione isto se o seu cliente desejar uma referência de pedido de compra associada ao pedido. Isso aparecerá na fatura que seu cliente receber.',
};
