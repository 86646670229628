import React, { useCallback, useMemo } from 'react';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel } from 'googleAnalytics';
import MultipleLinksMenuWrapper from 'layout/Header/MultipleLinksMenuWrapper';
import { trainingsLocalized$ } from 'models/trainings';

const Training = ({ setIsSubMenuOpen }) => {
  const trainingsLocalized = useStore(trainingsLocalized$);

  const linksList = useMemo(() => {
    return trainingsLocalized.map(({ title, slug }) => ({
      path: PATHS.POST(slug),
      title,
      isCapitalize: true,
    }));
  }, [trainingsLocalized]);

  const sendAnalytics = useCallback((itemTitle) => {
    sendEventWithEventLabel('regional_page_header_training', itemTitle);
  }, []);

  return (
    <MultipleLinksMenuWrapper
      setIsSubMenuOpen={setIsSubMenuOpen}
      linksList={linksList}
      onClickCallback={sendAnalytics}
    />
  );
};

export default Training;
