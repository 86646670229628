import { useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import {
  getPopularProducts,
  isPopularProductsLoading$,
  popularProductsLocalized$,
} from 'models/product';

export function usePopularProductData(selectedPackage) {
  const popularProductsLocalized = useStore(popularProductsLocalized$);
  const isPopularProductsLoading = useStore(isPopularProductsLoading$);

  useEffect(() => {
    getPopularProducts(selectedPackage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      products: popularProductsLocalized,
      loading: isPopularProductsLoading,
    }),
    [isPopularProductsLoading, popularProductsLocalized],
  );
}
