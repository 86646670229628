import { ALGOLIA_CLIENT, ALGOLIA_SEARCH_CATEGORIES_INDEX } from 'consts';
import { combine, createEvent, forward, guard } from 'effector';

import { CATREGORIES, PRODUCTS } from 'api';

import * as DC from './';

DC.getAllCategoriesFx.use(async () => {
  const { data } = await CATREGORIES.getAll();
  return data.data;
});

const categoryAlgoliaIndex = ALGOLIA_CLIENT.initIndex(
  ALGOLIA_SEARCH_CATEGORIES_INDEX,
);

DC.getAllCategoriesFromAlgoliaFx.use(async () => {
  let hits = [];
  await categoryAlgoliaIndex.browseObjects({
    batch: (batch) => {
      hits = hits.concat(batch);
    },
  });
  return hits;
});

DC.getPopularProductsFx.use(async (selectedPackage) => {
  const { data } = await PRODUCTS.getPopular(selectedPackage);
  return data.data;
});

export const getProductsByCategory = createEvent();

const isIdle = DC.getAllCategoriesFx.pending.map((state) => !state);
const isAlgoliaIdle = DC.getAllCategoriesFx.pending.map((state) => !state);

DC.getProductVariationsFx.use(async (slug) => {
  const { data } = await PRODUCTS.getProductVariations(slug);
  return data.data;
});

DC.getProductHiredTogetherFx.use(async (slug) => {
  const { data } = await PRODUCTS.getHiredTogether(slug);
  return data.data;
});

DC.getCurrProductFx.use(async (id) => {
  const { data } = await PRODUCTS.getProduct(id);
  return data.data;
});

DC.getRelatedJobTypesFx.use(async (slug) => {
  const { data } = await PRODUCTS.getProductJobTypes(slug);
  return data;
});

guard({
  source: DC.categoriesGate.open,
  filter: combine(
    isIdle,
    DC.categories$,
    (isIdle, categories) => isIdle && !categories.length,
  ),
  target: DC.getAllCategoriesFx,
});

guard({
  source: DC.algoliaCategoriesGate.open,
  filter: combine(
    isIdle,
    DC.categories$,
    (isIdle, categories) => isAlgoliaIdle && !categories.length,
  ),
  target: DC.getAllCategoriesFromAlgoliaFx,
});

forward({
  from: DC.getPopularProducts,
  to: DC.getPopularProductsFx,
});

forward({
  from: DC.getProductVariations,
  to: DC.getProductVariationsFx,
});

forward({
  from: DC.getCurrProduct,
  to: DC.getCurrProductFx,
});

forward({
  from: DC.getProductHiredTogether,
  to: DC.getProductHiredTogetherFx,
});
