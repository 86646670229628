import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const Success = lazy(() =>
  lazyRetry(() => import('./TrainingCheckoutSuccess'), 'TrainingSuccess'),
);

export const trainingCheckoutSuccessRoutes = [
  {
    path: PATHS.TRAINING_CHECKOUT_SUCCESS,
    exact: true,
    component: () => WithSuspense(Success),
  },
];
