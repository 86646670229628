import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

import MainPageTemplate from './MainPageTemplate';

const Main = lazy(() => lazyRetry(() => import('./Main'), 'Main'));

export const mainRoutes = [
  {
    path: PATHS.ROOT,
    exact: true,
    component: () => WithSuspense(Main, MainPageTemplate),
  },
];
