import { useEffect } from 'react';

export const useSectionAutoCollapsing = (state) => {
  useEffect(
    () => {
      state.hide();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.pathname],
  );
};
