import React, { useCallback } from 'react';
import { useStore } from 'effector-react';
import { sendUserClickedOnDefaultResult } from 'googleAnalytics';
import { Lang } from 'lang';
import { searchHistory$ } from 'models/search';

import { T8y } from 'ui';

import { ReactComponent as SearchIcon } from 'assets/images/icon/search/history.svg';

import style from '../searchResults.module.scss';

const SearchHistory = ({ handleHitClick, goToSearchPage }) => {
  const { search } = Lang();

  const searchHistory = useStore(searchHistory$);

  const handleClickOnSearchHistoryItem = useCallback(
    (el) => {
      sendUserClickedOnDefaultResult('user_search_history_selected', el.title);

      if (el.type === 'category') {
        handleHitClick({ slug: el.slug || el.title }, 'category', el.title);
        return;
      }

      goToSearchPage(el.title);
    },
    [goToSearchPage, handleHitClick],
  );

  return (
    !!searchHistory?.length && (
      <>
        <p className={style.searchDefaultTitle}>{search.searchHistory}</p>
        {searchHistory.map((el, index) => (
          <div
            className={style.searchDefaultItem}
            onClick={() => handleClickOnSearchHistoryItem(el)}
            key={index}
          >
            <SearchIcon />
            <T8y
              as="span"
              variant="t2v1"
              className={style.searchDefaultItemText}
            >
              {el.title}
            </T8y>
          </div>
        ))}
      </>
    )
  );
};

export default SearchHistory;
