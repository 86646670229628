export const profile = {
  noData: 'No se encontraron datos para los criterios especificados.',
  and: 'y',

  logIn: {
    header: 'Acceder a {0}',
    subHeader: 'Aprovecha las ofertas y servicios de {0}',
    nameFieldTitle: 'Nombre completo',
    nameFieldPlaceholder: 'Introduce tu nombre',
    emailFieldTitle: 'Dirección de correo electrónico',
    emailFieldPlaceholder: 'Introduce tu dirección de correo electrónico',
    passwordFieldTitle: 'Contraseña',
    passwordFieldPlaceholder: 'Introduce tu contraseña',
    remember: 'Recuérdame',
    forgotPassword: '¿Has olvidado tu contraseña?',
    submit: 'Inicia sesión',
    sendMeAnActivationEmail: 'Enviadme un email para activar mi cuenta',
  },

  singUp: {
    header: 'Regístrate',
    subHeader: 'Crea una cuenta para disfrutar de nuestros servicios',
    nameFieldTitle: 'Nombre completo',
    nameFieldPlaceholder: 'Introduce tu nombre',
    emailFieldTitle: 'Dirección de correo electrónico',
    emailFieldPlaceholder: 'Introduce tu dirección de correo electrónico',
    passwordFieldTitle: 'Contraseña',
    passwordFieldPlaceholder: 'Introduce tu contraseña',
    repeatPasswordFieldTitle: 'Repite tu contraseña',
    repeatPasswordFieldPlaceholder: 'Reintroduce tu contraseña',
    goToLogin: 'Ya tengo una cuenta, ir a Inicio de Sesión',
    submit: 'Regístrate',
    emailSentTitleThanks: '¡Gracias por registrarte!',
    emailSentTitleVerify: 'Por favor verifica tu correo y activa tu cuenta',
    emailSentText:
      'Hemos enviado un enlace de activación a tu dirección de correo electrónico. Por favor revisa tu bandeja de entrada y sigue las instrucciones para verificar tu correo electrónico y activar tu cuenta',
    emailSentLinkDidNotReceive: '¿No has recibido el correo electrónico?',
    emailSentLink: 'Reenviar correo de activación.',
    emailSentResendError:
      'El reenvío es posible una vez por minuto, por favor intenta nuevamente más tarde',
    tokenExpired: 'El token ha caducado',
  },

  forgotPassword: {
    header: '¿Has olvidado tu contraseña?',
    customerHeader: 'He olvidado la contraseña del Portal de Cliente',
    subHeader:
      'Introduce la dirección de correo electrónico que has usado para registrarte y te enviaremos un enlace para restablecer la contraseña',
    emailFieldTitle: 'Dirección de correo electrónico',
    emailFieldPlaceholder: 'Introduce tu dirección de correo electrónico',
    goToLogin: 'Ir a Inicio de Sesión, he recordado mi contraseña',
    submit: 'Enviar',
    sent: 'El mensaje ha sido enviado, por favor revisa tu bandeja de entrada',
    cancel: 'Cancelar',
  },

  resetPassword: {
    header: 'Restablecer la contraseña',
    customerHeader: 'Restablecer la contraseña del Portal de Cliente',
    subHeader: 'Por favor introduce tu nueva contraseña aquí debajo',
    emailAddressTitle: 'Dirección de correo electrónico',
    passwordFieldTitle: 'Nueva contraseña',
    passwordFieldPlaceholder: 'Introduce tu contraseña',
    repeatPasswordFieldTitle: 'Repite tu contraseña',
    repeatPasswordFieldPlaceholder: 'Re-introduce tu contraseña',
    submit: 'Enviar',
    saveChanges: 'Guardar cambios',
  },

  activationAccount: {
    customerHeader: 'Activa tu Cuenta de Confianza',
    subHeader: 'Por favor ingresa tu contraseña',
    saveChanges: 'Activar cuenta',
    passwordFieldTitle: 'Contraseña',
    repeatPasswordFieldTitle: 'Repite tu contraseña',
  },

  validation: {
    required: 'Campo requerido*',
    emailPattern: 'Dirección de correo electrónico incorrecta',
    passwordLength: 'La constraseña ha de tener al menos 6 caracteres',
    passwordNotIncludeSpaces: 'La contraseña no debe incluir espacios',
    passwordDifferent: 'Las contraseñas son diferentes',
    invalidCreds:
      'La dirección de correo electrónico o la contraseña son inválidos',
    userImageUploadError: `La imagen no puede ser guardada`,
    invalidEmail: 'Dirección de correo no válida',
  },

  menu: {
    dashboard: 'Panel de control',
    notifications: 'Notificaciones',
    catalogue: 'Catálogo',
    invoices: 'Facturas',
    settings: 'Configuración',
    management: 'Gestión',
    quotes: 'Presupuestos',
    orders: 'Pedidos',
    support: 'Asistencia',
    myAccount: 'Mi cuenta',
    logOut: 'Salir',
    profile: 'Perfil',
    usersPermissions: 'Usuarios y permisos',
  },

  dropzone: {
    dropText: 'Suelta los archivos aquí',
    dragText: 'Arrastra el archivo aquí o',
    dragClickableText: 'Elige desde tu ordenador',
  },

  toastMessages: {
    updateSuccess: 'La información ha sido actualizada con éxito',
    updateError: `La información no puede ser actualizada`,
  },

  toastErrorMessages: {
    wrongOldPassword: 'La contraseña anterior es incorrecta',
  },

  benefits: {
    title:
      'Descubre las ventajas de las cuentas de confianza - Regístrate ahora!',
    subTitle:
      'El portal del cliente se desarrolló para ayudar a nuestros socios a alquilar equipos periódicamente de forma rápida y sencilla. Ingresa tu información personal o de empresa, ahorra en tus pedidos y ¡listo!',
    discountMemberships:
      'Accede a ventajosos descuentos según tu volumen de alquiler',
    noDeposit: 'Posibilidad de no pagar depósito en algunos de tus pedidos',
    paymentMethods: 'Posibilidad de elegir entre múltiples métodos de pago',
    payLater: 'Posibilidad de posponer el pago',
    orders:
      'Conserva tus pedidos (recientes y pasados) y tus pagos en un único lugar ',
    catalogue: 'Catálogo siempre actualizado con las novedades más recientes',
    percent:
      'Acceso instantáneo a nuestras ofertas especiales, descuentos y promociones',
    chat: 'Chat de ayuda y rápida asistencia, reservas en tan solo 5-10 minutos',
  },

  customerLogin: {
    header: 'Acceso a las Cuentas de Confianza',
    emailFieldTitle: 'Dirección de correo electrónico',
    passwordFieldTitle: 'Contraseña',
    forgotPassword: '¿Has olvidado la contraseña?',
    register: 'Regístrate',
    submit: 'Inicia sesión',
  },

  customRegister: {
    header: 'Registro en las Cuentas de Confianza',
    subHeader: 'Por favor selecciona el segmento al que perteneces',

    privateTab: 'Privado',
    companyTab: 'Compañía',

    fiscalCodeFieldTitle: 'Código Fiscal',
    officeAddressFieldTitle: 'Dirección legal',
    postalCodeFieldTitle: 'Código postal',
    provinceFieldTitle: 'Provincia',
    sectorOfActivity: 'Sector de actividad?',
    uniqueCodeFieldTitle: 'Código único',
    PECFieldTitle: 'PEC',
    howDidYouKnowAboutUs: 'Cómo supiste de nosotros?',

    generalInformation: 'Información general',
    contactInformation: 'Información de contacto',
    contactPhoneNumber: 'Número de teléfono de contacto',
    contactEmailAddress: 'Correo electrónico de contacto',
    backToLogin: 'Vuelta a Inicio de sesión',
    businessName: 'Razón Social',

    thankYouTitle: '¡Gracias por registrarte!',
    thankYouSubTitle:
      'Has completado con éxito el registro para el Portal del Cliente. Por favor, revisa tu bandeja de entrada y sigue las instrucciones que hemos enviado a tu correo.',
    goToLogin: 'Ir a Inicio de sesión',
    privacyCheckbox: 'He leído las',
    consentCheckbox:
      'Doy mi consentimiento para el envío de comunicaciones comerciales, incluso a través de métodos automatizados, de productos y servicios ofrecidos por {0} y/o sus asociados.',
  },

  sectorOfActivity: {
    business: 'Actividad comercial',
    constructions: 'Construcciones',
    public_authority: 'Autoridad pública',
    training_courses: 'Cursos de formación',
    gardening: 'Jardinería',
    industry: 'Industria',
    services: 'Servicios',
    transport: 'Transporte',
    tourism: 'Turismo',
    other: 'Otro',
  },

  aboutUs: {
    search_on_the_internet: 'Buscar en la red',
    television: 'Televisión',
    word_of_mouth: 'Boca a boca',
    headquarter_visibility: 'Visibilidad de la sede',
    construction_warehouse: 'Almacén de construcción',
    gardening_dealer: 'Social Media',
    magazines_and_newspapers: 'Revistas y periódicos',
    other: 'Otro',
  },

  emptySection: {
    noInfo:
      ' Todavía no tienes {0}. Para realizar un pedido, comienza navegando por nuestro {1}.',
    callUs: 'No dudes en llamarnos al {0} si tienes alguna pregunta',
  },

  noDiscount: {
    title: 'Descuentos especiales a nuestros miembros',
    description:
      'Para inspirar a nuestros clientes tenemos cuatro tipos de membresía: {0}, {1}, {2} y {3}. La membresía provee un descuento en todos los productos de nuestro {4}.',
  },

  loginErrors: {
    incorrectCredentials: `Usuario o contraseña incorrectos`,
    accountIsNotActivated: 'La cuenta no ha sido activada aún',
  },

  paymentMethods: 'Payment Methods',
  pleaseFillAllFields:
    'A tu perfil le falta información. Por favor completa todos los campos en blanco a continuación.',
  membershipDiscount: 'Descuento por Membresía',
  selectSegment: 'A que segmento perteneces?',
  continue: 'Continuar',
  continueWithGoogle: 'Continuar con Google',
  or: 'o',
  registerWithEmail: 'Registrarse con correo electrónico',
  signedInWithGoogle: 'Iniciar sesión con la cuenta de Google',
  signedInWithRegisteredEmail: `Hemos enviado el mensaje a {0}.`,
  signedInWithRegisteredEmailCheck:
    'Por favor revisa tu correo electrónico y sigue las instrucciones.',
  membershipTooltip: `{0} es tu plan actual con {1}. Contáctanos para saber más sobre el plan {2}. Contáctanos para saber más sobre el plan {3}.`,
  basic: 'Básico',
  standard: 'Estándar',
  premium: 'Premium',
};
