import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import CategoryProductTemplate from 'features/common/CategoryProductTemplate';
import WithSuspense from 'features/common/WithSuspense';

const CategoryProductWrapper = lazy(() =>
  lazyRetry(
    () => import('features/common/CategoryProductWrapper'),
    'CategoryProduct',
  ),
);

export const productRoutes = [
  {
    path: PATHS.PRODUCT(':slug'),
    exact: true,
    component: () =>
      WithSuspense(CategoryProductWrapper, CategoryProductTemplate),
  },
];
