export const header = {
  region: {
    region: 'Região',
    areYouIn: 'Você está localizado em',
    yes: 'Sim',
    no: 'Não, use',
    checkOther: 'Confira outras regiões em que estamos disponíveis',
    serviceAvailability: 'Serviço disponível',
    weWillStart:
      'Em breve começaremos a oferecer nossos serviços na sua região!',
    meanwhileFeel:
      'Enquanto isso, fique à vontade para navegar em nosso catálogo em:',
    orLeaveYour:
      'Ou deixe seus contatos e avisaremos assim que lançarmos em sua região',
    weDetected: 'Detectamos que você está localizado em',
    wouldYouLike: 'Você gostaria que mudássemos a região de acordo',
    isNotAvailable: 'ainda não está disponível na sua região.',
    okUse: 'ok, use',
    submit: 'Enviar',
  },
  getAQuote: 'Obter uma cotação',
  allProducts: 'Todos os produtos',
  byUsingThis: 'Ao usar este site você concorda com o uso de cookies.',
  cookieBtn: 'Entendi',
  backTo: 'De volta a',
  nav: {
    back: 'Voltar',
    backToPreviousPage: 'Voltar para a pagina anterior',
    home: 'Home',
    catalog: 'Catálogo',
    hireByCategory: 'Alugar',
    company: 'Companhia',
    training: 'Treinamento',
    aboutUs: 'Sobre nós',
    blog: 'Blog',
    logIn: 'Log in',
    logOut: 'Log Out',
    customerPortal: 'Portal do cliente',
    reservation: 'Sua reserva',
    faq: 'Perguntas frequentes',
    carbonOffsetting: 'Compensação de carbono',
    menuLanguage: 'Português',
    signAs: 'Signed in como',
    maintenance: 'Manutenção',
    otherServices: 'Outros serviços',
    jobTypes: 'Tipos de trabalho',
    findHowWeAre: 'Sobre {0}',
    searchResults: 'Procurar Resultados',
    breadcrumbRegion: 'Região',
  },

  bar: {
    customize: 'Personalizar inventário',
    depots: 'Depósitos',
    allDepots: 'Todos os depósitos',
    seeAllDepots: 'Ver todos os depósitos',
    allDepotsTitle: 'Nossos endereços de depósitos em {0}',
    choose: 'Escolha os depósitos que funcionam para você',
    yourChoice:
      'Sua escolha filtrará nosso inventário. Pelo menos um depósito deve ser selecionado. Para navegar por todo o catálogo, selecione todos os depósitos.',
    regionDepots: 'região e depósitos',
    yourRegion: 'Sua região',
    selectedRegion: 'Região selecionada',
    region: 'Região',
    selectRegion: 'Selecione o índice',
    selectDepots: 'Selecionar Depósitos',
    back: 'Voltar',
    isCommitted:
      'A {0} está comprometida com o aluguer de equipamentos sustentáveis {1}',
    learnMore: 'Saber mais',
  },

  callModal: {
    title: 'Melhor preço on-line!',
    text: 'Para obter os preços mais baixos, crie a sua cotação online ou faça a seu pedido online.',
    closeBtnText: 'Ok, vou fazer online',
    subText:
      'Se você ainda quiser nos ligar, aqui está o número. Mas lembre-se, ligando vai custar mais caro!',
  },
};
