export const products = {
  allProducts: 'Todos os produtos',
  forYourDates: 'Para suas datas',
  pricePerDay: 'Preço por dia',
  pricePerWeek: 'por semana',
  exclVat: 'Preço excluindo IVA',
  hiringPeriod: 'Período de contratação',
  minHiringPeriod: 'Período mínimo',
  discountText:
    'Sua adesão {0} fornece um desconto para todos os produtos em nosso {1}.',
  addToQuote: 'Adicionar à cotação',
  addedToQuote: 'Adicionado à cotação',
  yourReservation: 'Sua reserva',
  addedToReservation: 'Adicionar à reserva',
  maxQuantityReached: 'A quantidade máxima foi atingida',
  hireTime: 'Período de aluguer',
  price: 'Preço',
  pricePer: 'por {0} dias',
  pricePerOneDay: 'por 1 dia',
  variations: 'Variações do produto',
  userGuides: 'Guia do usuário',
  dimensionsOfTheUnit: 'Dimensões da unidade',
  CallUs: 'Ligue-nos se tiver alguma dúvida',
  call: 'Ligar',
  requestCallback: 'Solicitar um retorno de chamada',
  shareYourContactDetails:
    'Por favor, compartilhe seus detalhes de contato e nós ligaremos de volta para responder a todas as suas perguntas.',
  comment: 'Comente',
  tableOfContents: 'Índice',
  frequentlyHiredTogether: 'Freqüentemente contratados juntos',
  goToGeneral: 'Ir para as perguntas frequentes gerais',
  day: 'dia',
  days: 'dias',
  additional: '7 dias adicionais, cada',
  noDescription: 'Sem descrição',
  learnMore: 'Saber mais...',
  yetToBeReviewed: 'Ainda a ser revisado',
  averageScore: 'Pontuação média: {0}',
  finishAddToReservationModalText:
    'O produto foi adicionado com sucesso à sua reserva',
  carbonOffsettingText:
    'Este aluguer é Carbono Neutro. Compensamos a emissão de CO2 para este produto.',
  ourDailyPrices: 'Nossos preços diários para aluguer de longa duração',
  save: 'Economize {0} {1}',
  onAverage: 'na média',
  promisePriceText:
    'Economize {0} no aluguel de ferramentas e equipamentos. Encomende online e poupe dinheiro no preço que vai pagar. As opções de Entrega e Clique e colete e estão disponíveis.',
  clickRecommendationIcon: 'Clique no ícone para ler sua descrição',
  quantity: 'Quantidade',
  relatedJobTypes: 'Tipos de trabalho relacionados',
  shareSpecs: 'Compartilhar especificações',
  viewThisAt: 'Veja isso em',

  titles: {
    fullDescription: 'Descrição completa',
    fullReview: 'Avaliação completa',
    faq: 'Perguntas frequentes',
    perWeek: 'per week',
    reviews: 'Avaliações',
    backToCategory: 'Voltar à categoria',
    features: 'Características',
    feature: 'Característica',
    specifications: 'Especificações',
    thisProduct: 'Este produto não possui especificações',
    otherCharges: 'Outras taxas',
    toBeDefined: 'A ser definida',
    safetyRecommendations: 'Recomendações de segurança',
    downloads: 'Downloads',
    shareThisPage: 'Compartilhe esta página',
    video: 'Vídeo',
    similarProducts: 'Produtos similares',
  },

  consumables: {
    title: 'Consumíveis necessários para este produto',
    subTitle: 'Indique a quantidade de consumíveis necessários',
    showDetails: 'Mostrar detalhess',
    perItem: 'por item',
  },

  extra: {
    extras: 'Extras',
    modalTitle: 'Extras do produto',
    subTitle: 'Marque os extras de sua preferência e indique a quantidade',
    showDetalis: 'Mostrar detalhes',
    showMoreExtras: 'Mostrar mais extras',
    sale: 'Liquidação',
    select: 'Selecione',
    selected: 'Selecionado',
    skipThisStep: 'Saltar este passo',
    skip: 'Saltar',
    apply: 'Aplicar',
    addToCart: 'Adicionar ao carrinho',
    needSelectReqExtra:
      'Para solicitar {0}, você precisa selecionar os extras necessários na lista abaixo. Você receberá 1 item extra para cada {0} contratado. Não estamos fornecendo {0} separadamente.',
  },

  other: {
    thisProduct:
      'Este produto está disponível apenas por hora junto com o operador',
    pleaseShare:
      'Por favor, compartilhe seus dados de contato no formulário abaixo e entraremos em contato com você em breve!',
    makeAnEnquiry: 'Faça uma consulta',
    perDay: 'por dia',
    promisePriceSave: 'Economize {0}%',
    file: 'Arquivo',
    changeDates: 'Alterar datas',
    hireStart: 'Início do Aluguer',
    hireEnd: 'Fim do Aluguer',
    readMore: 'Consulte Mais informação',
  },

  depots: {
    title: 'Nossos depósitos',
    description:
      'O estoque está sujeito à disponibilidade. Atualizamos nosso sistema a cada 48 horas.',
    available: 'Disponível',
    unavailable: 'Não disponível',
    toBeChecked: 'A ser checado',
    qty: 'quant.',
  },

  noPrices: {
    title: 'Preço em pedido',
    subTitle:
      'Os preços e detalhes do contrato estão disponíveis apenas mediante solicitação.',
    text: 'Para contratar este produto ou saber mais sobre ele, envie-nos uma consulta preenchendo o formulário abaixo. É easy',
  },

  feelFreeToContact:
    'Sinta-se à vontade para entrar em contato conosco se tiver alguma dúvida. Ficaremos felizes em ajudar!',
  allCategoryProducts: 'Todos os {0} produtos ({1})',
  benefits: {
    title: 'Benefícios de contratar online com {0}',
    benefit1: 'Avaliado por usuários e empresas',
    benefit2: 'Pagamento on-line fácil',
    benefit3: 'Conta pessoal para gerir pedidos',
    benefit4: 'Serviço de entrega e de coleta',
  },

  getQuote: {
    modalTitle: 'Your Reservation Request',
    howToProceed: 'Como você gostaria de proceder?',
    getQuote: 'Obter cotação',
    getQuoteDescription:
      'Preencha alguns campos e receba um orçamento com preços completos em seu e-mail.',
    checkoutDescription:
      'Insira todos os detalhes necessários para a contratação, veja o preço total e efetue o pagamento.',
    checkoutAvailability:
      'Verifique a disponibilidade e prossiga para o checkout',
    checkoutAvailabilityDescription:
      'Insira seus dados de contato para verificar a disponibilidade do produto e fazer seu pedido online.',
    availabilityCheckTitle: 'Verificação de Disponibilidade do Produto',
    notAvailable:
      'O produto não está disponível, entraremos em contato em breve!',
    notAvailableDescription:
      'Processamos sua solicitação, mas infelizmente o produto não está em estoque nas datas selecionadas. Um dos membros de nossa equipe entrará em contato com você em breve para encontrar uma solução diferente para seu pedido. Obrigada!',
    available: 'O produto está disponível',
  },

  availableLabels: {
    availableInStock: 'Disponível em estoque',
    itemsAvailable: 'Existem apenas {0} itens em estoque',
    oneItemAvailable: 'Há apenas 1 item em estoque',
    unavailable: 'Não disponível',
    outOfStock: 'Fora de estoque',
  },
  referencesText: '{0} faz parte da família de marcas {1}',
  easy: 'easy',
  easyJet: 'easyJet',
  showMoreVariations: 'Mostrar mais variações',
  notAvailableForCheckout:
    'Para contratar este produto ou saber mais sobre ele, envie-nos uma consulta preenchendo o formulário abaixo. É easy!',
  productIsOutOfStock: 'O produto está esgotado',
  outOfStockText:
    'Este produto está fora de estoque. Estamos trabalhando para tê-lo de volta em estoque o mais rápido possível. Se você tiver alguma dúvida ou estiver interessado em contratar este produto ou similar, sinta-se à vontade para fazer uma consulta.',
  freeOfCharge: 'Grátis',
  wasDiscount: 'Era',
  orderOnline: 'Encomende online e poupe dinheiro no preço final!',
  notAvailableForCheckoutProductCard:
    'Os preços e detalhes do contrato para este produto estão disponíveis somente mediante solicitação. Por favor, envie-nos um inquérito para mais informações.',
  currentlyNoProducts: 'Atualmente não há produtos nesta categoria.',
  pingForAnyRequest:
    'Para qualquer solicitação, não hesite em nos ligar {0} ou {1} usando o formulário abaixo.',
  extraDay: 'Extra Day',
  startingFromPrice: 'Preço a partir de (Sem IVA)',
  clearDates: 'Limpar Datas',
  notAvailableTitle: 'Este produto não está disponível no momento',
  notAvailableText: `Estamos trabalhando para disponibilizá-lo novamente o mais rápido possível. Se você tiver alguma dúvida ou estiver interessado em alugar este ou um produto similar, não hesite em fazer uma consulta`,
  onRequestOnlyTitle:
    'Preços e detalhes do contrato estão disponíveis apenas mediante solicitação',
  onRequestOnlyText: `Se você tem interesse em alugar este produto e deseja saber os preços e demais detalhes, utilize o formulário abaixo para nos enviar uma solicitação. É fácil!`,
  subtotalPerHiringPeriod: 'Subtotal por período de contratação',
  subtotalPerDays: 'Subtotal por {0}',
  viewSimilarProducts: 'Ver Produtos Semelhantes',
  sendEnquiry: 'Enviar Inquérito',
  duration: 'Duração',
  tbdDeliveryTooltip:
    'O custo de entrega será calculado na página de checkout com base na sua localização e seleção do equipamento',
  minimumPeriodIs: 'O período mínimo é de {0} dias',
};
