import { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const Maintenance = lazy(() =>
  lazyRetry(() => import('./Maintenance'), 'Maintenance'),
);

export const maintenanceRoutes = [
  {
    path: PATHS.MAINTENANCE,
    exact: true,
    component: () => WithSuspense(Maintenance),
  },
];
