export const main = {
  h1: 'Easy and efficient tool and machinery rental in Italy',
  h2: 'Our mission is to make tool and plant rental easy and accessible for all. Find what you are after for a landscaping project to a renovation job and more',

  howItWorks: {
    title: 'How it Works',
    sections: [
      {
        title: '1. Choose Products',
        description: 'Browse and shop our inventory',
      },
      {
        title: '2. Review your reservation',
        description: 'Refine your list, check full pricing',
      },
      {
        title: '3. Checkout in 4 easy steps',
        description: 'Specify delivery and payment details',
      },
    ],
  },

  subscribeToNewsletter: {
    stayUpdated: 'Stay Updated',
    enterYourEmail:
      'Enter your email address to sign up to our mailing list for special offers, discounts, and industry news.',
    agreementText:
      'By submitting your details you are agreeing to be contacted by {0} in accordance with our {1}',
    linkText: 'Privacy Policy',
    thankYouForSubscribing: 'Thank you for subscribing!',
    signUp: 'Sign Up',
  },

  titles: {
    eventTypes: 'Event Types',
    certifiedPartner: '{0} is a Certified Partner',
    proudToWork: 'Proud to work with',
    familyOfBrands: 'Part of the easy family of brands',
    popularProducts: 'Popular products',
    frequentlyHired: 'Frequently hired together',
    easyAndBudget: 'The easy and efficient tool hire service in {0}',
    ourMission:
      'Our mission is to make the hiring process of tools smooth, "easy" and accessible to everyone.\n' +
      'Find and hire what you need for any kind of project - from renovating to maintenance, to gardening, to much more.',
    topCategories: 'Hire Popular Categories',
    hireByCategory: 'Hire by Category',
    recentlyViewedProducts: 'Recently Viewed Products',
  },

  notices: {
    noPopularProduct: 'No popular products for this package',
    newArrivals: 'New arrivals',
  },
};
