import { useCallback, useMemo } from 'react';
import { sendGAEvent } from 'googleAnalytics';

export const useManageModal = (
  actualConsumables,
  actualReqExtras,
  type,
  count,
  actualOptionalExtras,
  isMaxQuantityReached,
  setIsOpen,
  handleChangeExtras,
) => {
  const isNoReqAttributes = useMemo(
    () => !actualConsumables.length && !actualReqExtras.length,
    [actualConsumables.length, actualReqExtras.length],
  );

  const isDisableApplyButton = useMemo(
    () =>
      (isNoReqAttributes &&
        !actualOptionalExtras.some((el) => el.quantity) &&
        !type) ||
      isMaxQuantityReached,
    [actualOptionalExtras, isMaxQuantityReached, isNoReqAttributes, type],
  );

  const isDisableSkipButton = useMemo(
    () => !type && isNoReqAttributes && isMaxQuantityReached,
    [isMaxQuantityReached, isNoReqAttributes, type],
  );

  const handleCancelClick = useCallback(() => {
    sendGAEvent('extras-cancel-button');
    isNoReqAttributes ? handleChangeExtras('skip') : setIsOpen(false);
  }, [handleChangeExtras, isNoReqAttributes, setIsOpen]);

  return useMemo(
    () => ({
      isNoReqAttributes,
      isDisableApplyButton,
      isDisableSkipButton,
      handleCancelClick,
    }),
    [
      isNoReqAttributes,
      handleCancelClick,
      isDisableApplyButton,
      isDisableSkipButton,
    ],
  );
};
