import React from 'react';
import { useGate, useStore } from 'effector-react';
import { Lang } from 'lang';
import { isWarehouses$, WarehousesGate } from 'models/warehouses';

import { Depots } from './Depots/Depots';
import Region from './Region';

import { Row } from 'ui';

import style from './bar.module.scss';

export const Bar = () => {
  const {
    header: { bar },
  } = Lang();

  useGate(WarehousesGate);

  const isWarehouses = useStore(isWarehouses$);

  return (
    <Row align="center" className={style.wrapper} justify="center">
      <Row className={style.innerWrapper} align="center" justify="center">
        <span>{bar.customize}</span>
        <Region />
        {isWarehouses && <Depots />}
      </Row>
    </Row>
  );
};
