export const sidebar = {
  yourReservation: 'Tu reserva',
  yourQuote: 'Tu presupuesto',
  costSummary: 'Resumen del costo',
  proceed: 'Proceder',
  estimatePerWeek: 'Resumen del costo',
  damageWaiver: 'Exención de responsabilidad por daños',
  subtotal: 'Subtotal',
  transport: 'Transporte',
  delivery: 'Entrega',
  collection: 'Retiro',
  selfCollection: 'Recogida en Sede',
  vat: 'IVA',
  total: 'Total',
  totalForCourse: 'Total',
  totalExclVat: 'Total (excl. IVA)',
  deposit: 'Fianza',
  gasTankDeposit: 'Depósito de combustible',
  iAgree: 'Entiendo y acepto los',
  free: 'Gratuito',
  insurance: 'Seguro',
  tbd: 'A definir',
  gratis: 'Recogida',
  safetyFee: 'Tarifa de seguridad',
  sanitationFee: 'Tarifa de saneamiento',
  adminFee: 'Gestión de residuos',
  slightDamageWaiverFee: 'Exención de responsabilidad por daños leves',
  grandTotal: 'Total Final',
  grandTotalProductCheckout: 'Total Final',
  invoice: 'Factura',
  logisticAdditionalText:
    'Retiro en sede: gratuito. Entrega a domicilio: costo a acordar en base a la distancia desde la sede',
  logisticAdditionalTextOnlySelfCollection:
    'Retiro en sede: gratuito.\n ' +
    'Entrega: la entrega no está disponible para este producto.',
  logisticAdditionalTextOnlyDelivery:
    'Retiro en sede: el retiro en sede no está disponible para este producto.\n ' +
    'Entrega: costo a definir en base a la dirección de entrega.',
  logisticAdditionalTextMixedDeliveries:
    'Retiro en sede: gratuito.\n ' +
    'Entrega: costo a definir en base a la dirección de entrega.',
  depositAdditionalText:
    'El depósito por pérdida y daños se reembolsará a tu tarjeta una vez que el producto sea devuelto en buenas condiciones y con el depósito de combustible lleno (cuando aplique).',
  promoCodeAdditionalText:
    '¡Felicidades! Su descuento es del {0}% \n Código de promoción: {1}',
  maxDiscount: 'Descuento máximo',
  page: 'Página',
  expand: 'Expandir',
  collapse: 'Colapsar',
  products: 'Productos',
  showMore: 'Mostrar más',
  showLess: 'Mostrar menos',
  hide: 'Esconder',
  discount: 'Descuento',
  courseCost: 'Costo del curso',
  refundableDepositWarning:
    'Se bloqueará un depósito reembolsable para este producto.',
  depositMayBeApplied:
    'Un depósito reembolsable basado en el total del pedido puede aplicarse a tu reserva.',
  clickAddToReservation:
    'Haga clic en Agregar a la reserva y proceda al Checkout para ver un resumen de costos completo.',
  itemsToHire: 'Alquiler Maquinaria',
  tradeAccountDiscount: 'Descuento de cuenta comercial',
};
