export const invoice = {
  titles: {
    name: 'Name',
    tel: 'Tel',
    email: 'Email',

    address: 'Indirizzo',

    item: 'Articolo',
    sku: 'SKU',
    price: 'Prezzo',
    days: 'giorni',

    subtotal: 'Subtotale',
    insurance: 'Assicurazione',
    safetyFee: 'Oneri Sicureza',
    sanitationFee: 'Oneri Igenizzazione',
    tax: 'tax',
    transport: 'Logistica',
    deposit: 'Deposito',

    total: 'Totale',
    grandTotal: 'Grand Totale',
  },
};
