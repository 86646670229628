export const checkout = {
  table: {
    visual: 'Imagen',
    productTitle: 'Nombre del producto',
    hiringPeriod: 'Duración del alquiler',
    hirePerItem: 'Coste por artículo',
    qty: 'Cant',
    hirePrice: 'Precio del alquiler',
  },

  changeExtrasModal: {
    requiredExtrasTitle: 'Elige implementos requeridos',
    optionalExtrasTitle: 'Elige implementos opcionales',
    subTitle: 'Selecciona los implementos e indica la cantidad',
  },

  getQuote: 'Obtén un presupuesto',
  checkout: 'Haz el pedido',
  checkoutReservation: 'Haz el pedido',
  emailMe: 'Enviadme el presupuesto por correo electrónico',
  transportationRoute: 'Ruta de transporte',

  quoteHasExpiredTitle: 'Su presupuesto ha caducado',
  quoteHasExpiredSubTitle:
    'Lamentablemente, el presupuesto que ha recibido ha caducado.',
  quoteHasExpiredText:
    'Nuestros presupuestos tienen una validez de 7 días, plazo que ya ha transcurrido. Para crear un nuevo presupuesto, navega por nuestro {0} y sigue los pasos para solicitar un presupuesto.',
  viewCatalogue: 'Ver catálogo',

  press: 'Presiona',
  change: 'Modifica',
  optionalExtra: 'Implementos opcionales',
  requiredExtra: 'Implementos requeridos',
  requiredConsumable: 'Consumible Requerido',
  changeExtra: 'Editar extra',
  for: 'para',
  free: 'Gratis',

  addMoreProducts: 'Agregar más productos',
  payNow: 'Pagar ahora',

  deliveryCollection: 'Dirección de entrega y recogida',
  selfCollectionText:
    'Recoge y devuelve los productos pedidos en una de nuestras sucursales, pero asegúrate de tener suficiente espacio para su transporte.',
  deliveryText:
    'Los productos serán entregados y posteriormente recogidos en la dirección indicada por usted. El costo del transporte se cobrará según su ubicación.',
  comingSoon: 'Disponible próximamente',

  emptyData: 'Vacío',
  openingTimes: 'Horario de apertura',
  select: 'Selecciona',
  selected: 'Seleccionado',
  chooseAnotherDepot: 'Elige otro almacén',
  chooseDepot: 'Elige el almacén',
  impossibleDelivery:
    'Para solicitar la entrega de los productos en tu ubicación, puedes comunicarte con nosotros al {0} o al {1}.',
  impossibleSelfCollection:
    'La opción de retiro en sede no está disponible para este producto. Continúa con la opción de entrega o contáctanos si tienes alguna pregunta.',

  italy: 'España',

  fields: {
    hireStartDate: 'Fecha de inicio del alquiler',
    hireEndDate: 'Fecha de finalización del alquiler',
    chooseStartDate: 'Fecha de inicio',
    chooseEndDate: 'Fecha final',
    deliveryTimeSlot: 'Intervalo de tiempo de entrega ',
    collectionTimeSlot: 'Intervalo de tiempo de recogida',
    addressLine: 'Dirección',
    city: 'Ciudad',
    deliveryPostcode: 'Código Postal',
    country: 'País',
    vatNumber: 'NIF o CIF ',
    taxNumber: 'Código Fiscal',
    fullName: 'Nombre y apellidos',
    phoneNumber: 'Número de teléfono',
    emailAddress: 'Dirección de correo electrónico',
    deliveryAddress: 'Dirección de entrega',
  },

  placeholders: {
    startDate: 'DD / MM / AAAA ',
    endDate: 'DD / MM / AAAA ',
    deliveryTime: 'Tiempo de entrega',
    collectionTime: 'Tiempo de recogida',
    address: 'Dirección',
    ofDelivery: 'de entrega',
    postcode: 'Código postal',
    findPostcode: 'Escribe tu código postal',
  },

  titles: {
    yourQuote: 'Tu presupuesto',
    chooseHowYou: 'Elige cómo proceder',
    pleaseEnter: 'Introduce la siguiente información',
    payWithCard: 'Paga con tarjeta',
    hiringPeriod: 'Período de alquiler',
    estimatePerWeek: 'Coste',
    deliveryMethod: 'Logística',
    invalidCard: 'Número de tarjeta inválido',
    invalidCardTryAgain: 'Algo ha salido mal, vuelve a intentarlo',
    contactInformation: 'Datos de contacto',
    selfCollection: 'Retiro',
    delivery: 'Entrega',
    selectYour: 'Usuario',
    enterYour: 'Introduce tus datos de contacto',
    individual: 'Privado',
    company: 'Empresa',
    accountType: '{0} account',
    selectWhich: 'Selecciona la delegación para la recogida',
    yourReservation: 'Tu reserva',
    selfCollectionDepot: 'Recogida en la sede',
    deliveryAddress: 'Entrega a',
    yourDetalis: 'Tus datos',
    paymentMethod: 'Condiciones de pago',
    loggedInAs: 'Has accedido como',
    makeAddress: 'Establecer esta dirección como predeterminada',
    accountSection: '¿Tienes una cuenta {0}?',
    depositType: 'Deposit Type',
    depositProceed: 'Default deposit',
    creditAccountProceed: 'Deposit based on your credit records',
    findDeliveryByPostcode: 'Busca la dirección de entrega por código postal',
    findBillingAddress: 'Find billing address by Post Code',
  },

  radio: {
    payWithCredit: 'Paga ahora con tarjeta de crédito',
    payByBank: 'Paga mediante transferencia bancaria',
    payByBankText: 'Te enviaremos un correo electrónico con todos los detalles',
    payAtSelf: 'Paga en el punto de recogida',
    payLater: 'Pay in 90 days - no interest, no fees.',
    payLaterText:
      'Two lets your business pay later for the items you hire online, You will receive an invoice from Two when your order has been processed.',
    payAtTrainingLocation:
      'Realiza el pago más adelante en la delegación donde se lleva a cabo el curso',
    otherMethods: 'Otro método de pago',
    otherMethodsText:
      'Nuestro representante se comunicará contigo para elegir entre los métodos de pago disponibles',
  },

  details: {
    createAccount: 'Perfil/Cuenta en {0}',
    no: 'No',
    yes: 'Sí',
  },

  guest: 'Continúa como invitado',
  guestText: 'Se creará automáticamente una cuenta de confianza.',
  oldUser: 'Tengo una cuenta {0} ',
  newUser:
    'Crear una cuenta de {0} (ayudará a realizar el pago más rápido la próxima vez)',
  go: 'Continúa',
  logOut: 'Salir',
  dontHaveAccount: '¿Aún no tienes una cuenta?',
  singUpSuccess: 'Gracias por registrarte! Inicia sesión para continuar.',

  stripeNote:
    'We use Stripe to process all your payments. Stripe is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry.',

  descriptions: {
    fillFewFields:
      'Proporciona alguna información adicional para verificar el precio final y recibir un prespuesto directamente en el correo electrónico',
    shareAllThe:
      'Proporciona la información solicitada para el alquiler, visualiza el coste total y procede con el pago',
    paymentSecured: 'Pago seguro SSL',
    leaveTheseFields:
      'Deja estos campos en blanco si aún no conoces las fechas de tu proyecto. Te enviaremos un correo electrónico con el coste semanal estimado',
    theLossDamage:
      'El depósito por pérdida y daños se devolverá a tu tarjeta una vez que hayas devuelto los productos cumpliendo las condiciones estipuladas',
    yourQuoteIsEmpty:
      'Tu {0} carrito está vacío. Visita nuestro {1} para encontrar lo que buscas.',
    callUs: 'Llámanos al {0} para cualquier pregunta.',
    startTyping: 'Intoduce tu dirección de entrega',
    enterAddress: 'Escribe aquí una dirección de entrega',
    depositProceed:
      'The deposit in your cost summary will be calculated using our default settings and will reflect the full replacement value of the item',
    creditAccountProceed:
      'The deposit will be defined and confirmed with you using your credit records. We will email you an electronic Credit Application form with further details. Use this option if you would like to decrease your deposit',
    addressChosen: 'Dirección de entrega seleccionada',
    billingAddress: 'Billing Address',
    enterBillingAddress: 'Enter billing address',
    changeBillingAddress: 'Change billing address',
    sameAsDeliveryAddress: 'Same as Delivery Address',
  },

  success: {
    yourEnquiry: 'Hemos recibido tu petición',
    youWill:
      'Pronto recibirás un correo electrónico con el presupuesto. Podrás utilizar el presupuesto recibido para seguir adelante con el pedido. Gracias',
    youShould:
      'Pronto recibirás un correo electrónico de confirmación con los detalles de tu pedido',
    goTo: 'Ve a tu perfil',
    availableAddress: 'Entrega: ',
  },

  errors: {
    outOfRegion:
      'Lo sentimos, has ingresado una dirección fuera de la región {region}. Considera la recolección gratuita en una de nuestras delegaciones, llámanos al {phone} o verifica si el producto está disponible en tu región visitando nuestra {homepage}.',
    outOfDistance:
      'Lo sentimos, no entregamos a su dirección. Utiliza ‘Retiro en sede’ y recoge el producto en una de nuestras delegaciones, ¡siempre es gratis!',
    postalCodeDefined:
      'El precio del transporte en tu área está aún sin definir, puedes proceder con el retiro en sede o contactarnos',
    differentTypes: `Para algunos productos en tu carrito, no está disponible la entrega. Selecciona una filial donde te resulte conveniente recoger el producto. Si no, quítalo del carrito de compra`,
    noWarehouses:
      'Los productos no están disponibles en nuestros depósitos de auto recogida',
    sameDay:
      'No podemos ofrecer entrega el mismo día o durante el fin de semana para estos productos. Cambias el período de contratación o seleccionas "Recogida"',
  },

  steps: {
    title: 'Pago',
    payConfirmation: 'Tu reserva',
    delivery: 'Recogida en sede o Entrega',
    details: 'Tus datos',
    confirmAndPay: 'Confirma y paga',
  },

  toastMessages: {
    requiredWarehouse: 'Tienes que seleccionar una filial',
    requiredAddress: 'La dirección de entrega debe ingresarse correctamente,',
  },

  promo: {
    title: '¿Tienes un código de descuento?',
    placeholder: 'Introduce el código aquí',
    apply: 'Recalcula',
    remove: 'Borrar',
    success: 'Tu código promocional se aplica al resumen del costo!',
    notValid: 'Vaya, tu código promocional no es válido',
    promoAlreadyRedeemed: 'Código promocional ya canjeado',
    promoForAuthorizedUsersOnly:
      'Esta promoción está disponible solo para usuarios autorizados',
    promoDoesntExist: 'Este código promocional no existe',
    codeHasExpired: 'Este código promocional ha caducado',
    promoNotValidForProducts:
      'Este código promocional no es válido para tus productos',
    doYouHavePromotional: '¿Tienes un cupón de descuento?',
    promotionalCode: 'Cupón de descuento',
    promoCodeApplied: 'Descuento aplicado',
    promoCodeNote:
      'El cupón de descuento solo se puede aplicar durante el checkout',
    promoForNonAuthorizedUsersOnly:
      'Esta promoción está disponible solo para usuarios no autorizados',
  },

  generateUrl: 'Generar URL para esta lista de reservas',
  getEmailQuote: 'Obtener presupuesto por correo electrónico',
  urlIsCopied: 'La URL se copia en su portapapeles',
  enterComment: 'Ingrese tu mensaje (opcional)',
  confirmedAvailability: 'availability is confirmed',
  futureDelivery: 'El costo de la entrega será definido más adelante.',
  deliveryNotAvailable: 'No disponible',
  findNewAddress: 'Busca una nueva dirección',
  backToResults: 'Regresa a los resultados',
  continueShopping: 'Continuar alquilando',
  proceedViewFullPayment: 'Ver desglose',
  backToReservation: 'Volver a la reserva',
  selectDeliveryViewCostSummary:
    'Selecciona el método de entrega para ver el desglose',
  selectPayment: 'Selecciona el método de pago para confirmar el pedido',
  continueToDelivery: 'Continuar con la entrega',
  continuePayment: 'Continuar con el pago',
  placeOrder: 'Hacer reserva',
  alreadyHaveAnAcc: '¿Ya tienes una cuenta?',
  dontHaveAnAcc: '¿No tienes una cuenta?',
  imCompany: 'Soy una empresa',
  imNotCompany: 'I am not a company',
  imNotCompanyNote: `Select this checkbox if you're not a registered company. This will exclude the 'Pay in 90 days' option.`,
  card: 'Tarjeta de crédito',
  date: 'Fecha caducidad',

  stripeErrors: {
    authentication_required:
      'No podemos autentificar su método de pago. Elija otro método de pago y vuelva a intentarlo.',
    approve_with_id: 'El pago no ha sido autorizado. Inténtalo de nuevo.',
    card_not_supported:
      'La tarjeta no admite este tipo de compra. Comunícate con el emisor de la tarjeta.',
    card_velocity_exceeded:
      'Has excedido el saldo o límite de crédito disponible en la tarjeta.',
    currency_not_supported: 'La tarjeta no admite la moneda especificada.',
    expired_card: 'Tu tarjeta ha caducado. Intentalo con otra tarjeta.',
    incorrect_cvc:
      'El número CVC es incorrecto. Vuelve a intentarlo con el número CVC correcto.',
    invalid_cvc:
      'El número CVC es incorrecto. Vuelve a intentarlo con el número CVC correcto.',
    invalid_expiry_month:
      'El mes de vencimiento no es válido. Vuelve a intentarlo utilizando la fecha de caducidad correcta.',
    invalid_expiry_year:
      'El año de caducidad no es válido. Vuelve a intentarlo con la fecha de caducidad correcta.',
    issuer_not_available:
      'No se pudo contactar al emisor de la tarjeta. Intenta realizar el pago de nuevo.',
    pin_try_exceeded:
      'Se superó el número permitido de intentos de PIN. Utiliza otra tarjeta o método de pago.',
  },

  deliveryOptions: 'Opciones de Entrega',
  paymentOptions: 'Opciones de Pago',
  debitCreditCards: 'Tarjetas de débito/crédito',
  payLater: 'Paga después',
  delivery: 'Entrega',
  charge: 'Pago',
  freeProduct: 'Gratis',
};
