import React, { useRef } from 'react';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { useOnClickOutside } from 'hooks';
import { Lang } from 'lang';

import WarehousesList from './WarehousesList';

import { Row, T8y } from 'ui';

import { ReactComponent as RegionIcon } from 'assets/images/icon/depot.svg';

import style from './depots.module.scss';

export const Depots = () => {
  const {
    header: { bar },
  } = Lang();

  const disclosure = useDisclosureState();

  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, disclosure.hide);

  return (
    <Row className={style.wrapper}>
      <Disclosure as={Row} align="center" noWrap ml2 pointer {...disclosure}>
        <RegionIcon />
        <T8y variant="t2" className={style.dashed} ml2>
          {bar.seeAllDepots}
        </T8y>
      </Disclosure>

      <DisclosureContent {...disclosure} ref={wrapperRef}>
        <WarehousesList closeWarehousesList={disclosure.hide} />
      </DisclosureContent>
    </Row>
  );
};
