import React, { useCallback, useContext } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendUserClickedOnDefaultResult } from 'googleAnalytics';
import { Lang } from 'lang';
import { currentLang$ } from 'models/language';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

import { Row, T8y } from 'ui';

import { ReactComponent as SearchIcon } from 'assets/images/icon/search/folder.svg';
import { ReactComponent as SearchGlass } from 'assets/images/icon/search/searchNew.svg';

import style from './foundResults.module.scss';

const FoundResults = ({
  foundCategories,
  foundProducts,
  foundTrainings,
  foundSuggestions,
  handleHitClick,
  goToSearchPage,
}) => {
  const { header } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const currentLangState = useStore(currentLang$);

  const handleClickOnProduct = useCallback(
    (hit) => {
      const title = getTranslatedFieldValue(
        hit.names,
        currentLangState,
        settings.defaultLanguage,
      );

      sendUserClickedOnDefaultResult(
        'user_search_suggested_product_selected',
        title,
      );
      handleHitClick(hit, 'product', title);
    },
    [currentLangState, handleHitClick, settings.defaultLanguage],
  );

  const handleClickOnCategory = useCallback(
    (hit) => {
      const title = getTranslatedFieldValue(
        hit.names,
        currentLangState,
        settings.defaultLanguage,
      );

      sendUserClickedOnDefaultResult(
        'user_search_suggested_category_selected',
        title,
      );
      handleHitClick(hit, 'category', title);
    },
    [currentLangState, handleHitClick, settings.defaultLanguage],
  );

  const handleClickOnTraining = useCallback(
    (hit) => {
      const title = getTranslatedFieldValue(
        hit.title,
        currentLangState,
        settings.defaultLanguage,
      );

      sendUserClickedOnDefaultResult(
        'user_search_suggested_training_selected',
        title,
      );
      handleHitClick(hit, 'training', title);
    },
    [currentLangState, handleHitClick, settings.defaultLanguage],
  );

  return (
    <>
      {foundSuggestions.map((hit, index) => (
        <div
          className={style.categorySuggestionItem}
          key={index}
          onClick={() => {
            sendUserClickedOnDefaultResult(
              'user_search_auto_suggestion_selected',
              hit.query,
            );
            goToSearchPage(hit.query);
          }}
        >
          <SearchGlass />
          <T8y as="span" variant="t2v1" className={style.searchDefaultItemText}>
            <Highlight attribute="query" hit={hit} />
          </T8y>
        </div>
      ))}
      {foundCategories.map((hit, index) => (
        <div
          className={style.categorySuggestionItem}
          key={index}
          onClick={() => handleClickOnCategory(hit)}
        >
          <SearchIcon />
          <T8y as="span" variant="t2v1" className={style.searchDefaultItemText}>
            <Highlight
              attribute={
                hit.names[currentLangState]
                  ? `names.${[currentLangState]}`
                  : `names.${[settings.defaultLanguage]}`
              }
              hit={hit}
            />
          </T8y>
        </div>
      ))}
      {foundProducts.map((hit) => (
        <Row
          key={hit.objectID}
          as={T8y}
          color="primary"
          justify="start"
          align="center"
          className={style.searchListItem}
          onClick={() => handleClickOnProduct(hit)}
          noWrap
        >
          <Row justify="center" className={style.searchListItemImageWrapper}>
            <img
              alt="img"
              src={hit.image?.thumb || branding.defaultImageProduct}
            />
          </Row>
          <T8y as="span" variant="t2v1" className={style.searchListItemText}>
            <Highlight
              attribute={
                hit.names[currentLangState]
                  ? `names.${[currentLangState]}`
                  : `names.${[settings.defaultLanguage]}`
              }
              hit={hit}
            />
          </T8y>
        </Row>
      ))}
      {foundTrainings.map((hit) => (
        <Row
          key={hit.objectID}
          as={T8y}
          color="primary"
          justify="start"
          align="center"
          className={style.searchListItem}
          onClick={() => handleClickOnTraining(hit)}
          noWrap
        >
          <Row justify="center" className={style.searchListItemImageWrapper}>
            <img
              alt="img"
              src={hit.mainImage?.data?.thumb || branding.defaultImageProduct}
            />
          </Row>
          <div>
            <T8y color="midGray" className={style.trainingHeader}>
              {header.nav.training}
            </T8y>
            <T8y as="span" variant="t2v1" className={style.searchListItemText}>
              <Highlight
                attribute={
                  hit.title[currentLangState]
                    ? `title.${[currentLangState]}`
                    : `title.${[settings.defaultLanguage]}`
                }
                hit={hit}
              />
            </T8y>
          </div>
        </Row>
      ))}
    </>
  );
};

export default FoundResults;
