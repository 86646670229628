import { about } from './pt/about';
import { account } from './pt/account';
import { carbonEmission } from './pt/carbonEmission';
import { checkout } from './pt/checkout';
import { common } from './pt/common';
import { customer } from './pt/customer';
import { dashboard } from './pt/dashboard';
import { depots } from './pt/depots';
import { error } from './pt/error';
import { footer } from './pt/footer';
import { form } from './pt/form';
import { header } from './pt/header';
import { invoice } from './pt/invoice';
import { jobTypes } from './pt/jobTypes';
import { main } from './pt/main';
import { orders } from './pt/orders';
import { products } from './pt/products';
import { profile } from './pt/profile';
import { search } from './pt/search';
import { sidebar } from './pt/sidebar';
import { success } from './pt/success';
import { training } from './pt/training';
import { verification } from './pt/verification';

export const pt = {
  about,
  account,
  training,
  main,
  footer,
  sidebar,
  checkout,
  products,
  header,
  form,
  profile,
  dashboard,
  customer,
  search,
  invoice,
  error,
  orders,
  carbonEmission,
  common,
  success,
  jobTypes,
  depots,
  verification,
};
