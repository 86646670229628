import React, { useContext } from 'react';
import { BrandingContext } from 'contexts';
import { Lang } from 'lang';

import { Row, T8y } from 'ui';

import { ReactComponent as Facebook } from 'assets/images/icon/footerSocialMedia/facebook.svg';
import { ReactComponent as Instagram } from 'assets/images/icon/footerSocialMedia/instagram.svg';
import { ReactComponent as LinkedIn } from 'assets/images/icon/footerSocialMedia/linkedin.svg';
import { ReactComponent as Youtube } from 'assets/images/icon/footerSocialMedia/youtube.svg';
import { ReactComponent as Twitter } from 'assets/images/icon/twitter.svg';

import style from './attachmentTop.module.scss';

const AttachmentTop = () => {
  const { footer } = Lang();

  const branding = useContext(BrandingContext);

  const showFollowUs =
    branding.companyLinkedIn ||
    branding.companyFacebook ||
    branding.companyInstagram ||
    branding.companyTwitter ||
    branding.companyYoutube;

  return (
    <Row
      align="center"
      justify="center"
      className={style.footerAttachmentTopWrapper}
    >
      <Row
        align="center"
        justify="stretch"
        smDirection="column"
        smAlign="center"
        noWrap
        className={style.footerAttachmentContainer}
      >
        <a href={branding.indexPageLink || '#'} className={style.footerLogo}>
          <img src={branding.logo} alt={branding.companyName} />
        </a>
        {showFollowUs && (
          <Row align="center" xsDirection="column" noWrap>
            <T8y
              variant="h2"
              xsVariant="t1v1"
              color="light"
              bold
              className={style.socialMediaTitle}
            >
              {footer.followUs}
            </T8y>
            <Row noWrap>
              {branding.companyLinkedIn && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={branding.companyLinkedIn}
                  className={style.footerSocialMediaItem}
                  aria-label="LinkedIn"
                >
                  <LinkedIn />
                </a>
              )}

              {branding.companyFacebook && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={branding.companyFacebook}
                  className={style.footerSocialMediaItem}
                  aria-label="Facebook"
                >
                  <Facebook />
                </a>
              )}

              {branding.companyInstagram && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={branding.companyInstagram}
                  className={style.footerSocialMediaItem}
                  aria-label="Instagram"
                >
                  <Instagram />
                </a>
              )}
              {branding.companyTwitter && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={branding.companyTwitter}
                  className={style.footerSocialMediaItem}
                  aria-label="Twitterm"
                >
                  <Twitter />
                </a>
              )}
              {branding.companyYoutube && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={branding.companyYoutube}
                  className={style.footerSocialMediaItem}
                  aria-label="Youtube"
                >
                  <Youtube />
                </a>
              )}
            </Row>
          </Row>
        )}
      </Row>
    </Row>
  );
};

export default AttachmentTop;
