import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { useSectionAutoCollapsing } from 'layout/Footer/useSectionAutoCollapsing';
import { trainingsLocalized$ } from 'models/trainings';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const DesktopTrainingsList = () => {
  const { footer, sidebar } = Lang();

  const trainings = useStore(trainingsLocalized$);

  const disclosure = useDisclosureState();

  useSectionAutoCollapsing(disclosure);

  const { trainingsForFirstRender, restTrainings } = useMemo(
    () => ({
      trainingsForFirstRender: trainings.slice(0, 8),
      restTrainings: trainings.slice(8),
    }),
    [trainings],
  );

  return (
    <>
      <T8y
        as={Link}
        to={PATHS.TRAINING}
        variant="t1v1"
        mb2
        bold
        className={style.title}
      >
        {footer.training}
      </T8y>
      <div>
        {trainingsForFirstRender.map(({ title, slug }, i) => (
          <T8y
            key={i}
            as={Link}
            to={PATHS.POST(slug)}
            variant="t2v1"
            color="primary"
            className={style.footerLink}
          >
            {title}
          </T8y>
        ))}
      </div>
      {!!restTrainings.length && (
        <>
          <DisclosureContent {...disclosure} className={style.restTrainings}>
            {restTrainings.map(({ title, slug }, i) => (
              <T8y
                key={i}
                as={Link}
                to={PATHS.POST(slug)}
                variant="t2v1"
                color="primary"
                className={style.footerLink}
              >
                {title}
              </T8y>
            ))}
          </DisclosureContent>
          <Disclosure {...disclosure}>
            <T8y
              variant="t2v1"
              color="primary"
              mt2
              pointer
              className={style.footerLink}
            >
              {disclosure.visible ? sidebar.showLess : sidebar.showMore}...
            </T8y>
          </Disclosure>
        </>
      )}
    </>
  );
};

export default DesktopTrainingsList;
