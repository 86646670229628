import { editAttributes, editItemInCart } from 'models/cart';

export function changeCountItemInCart(
  count,
  cartItem,
  type,
  indexItem,
  productInfo,
) {
  if (!type) {
    if (count === cartItem.count) {
      return;
    }
    editItemInCart({
      ...cartItem,
      count: count,
      reqExtra: cartItem.reqExtra?.map((el) => ({
        ...el,
        quantity: el.quantity < count ? count : el.quantity,
      })),
      returnDirty: productInfo.is_return_dirty,
    });
  } else {
    if (count === cartItem[type][indexItem].quantity) {
      return;
    }
    const newExtra = [...cartItem[type]];
    newExtra[indexItem].quantity = count;
    editAttributes({ id: cartItem.id, attributes: newExtra, type });
  }
}
