import React from 'react';
import { useHideChats } from 'hooks';
import { Lang } from 'lang';

import { CustomButton } from 'features/common/CustomButton';

import AttributesModalItem from './AttributesModalItem';
import ItemsContainer from './ItemsContainer';
import { useGetExtrasActions } from './useGetExtrasActions';
import { useManageModal } from './useManageModal';

import { Modal, Row } from 'ui';

import style from './attributesModal.module.scss';

function AttributesModal({
  optionalExtras = [],
  reqExtras = [],
  consumables = [],
  type,
  count,
  setExtras,
  setIsOpen,
  isMaxQuantityReached,
  isAddToReservation,
}) {
  const { form, checkout, products } = Lang();

  useHideChats({ isHideOnMobile: true });

  const {
    actualConsumables,
    actualOptionalExtras,
    actualReqExtras,
    changeAttributes,
    handleChangeExtras,
  } = useGetExtrasActions(
    optionalExtras,
    reqExtras,
    consumables,
    setExtras,
    setIsOpen,
  );

  const {
    isNoReqAttributes,
    isDisableApplyButton,
    isDisableSkipButton,
    handleCancelClick,
  } = useManageModal(
    actualConsumables,
    actualReqExtras,
    type,
    count,
    actualOptionalExtras,
    isMaxQuantityReached,
    setIsOpen,
    handleChangeExtras,
  );

  return (
    <Modal
      header={products.extra.modalTitle}
      classNameModal={style.customModal}
      classNameContent={style.changeExtrasModal}
      classNameHeader={style.customModalHeader}
      classNameClose={style.classNameClose}
      classNameOverlay={style.classNameOverlay}
      dataTestId="modal-productAttributes"
      setIsOpen={setIsOpen}
      onCloseIconClickCallback={
        isAddToReservation ? handleCancelClick : undefined
      }
      secondCloseIcon
    >
      <Row direction="column" className={style.itemsWrapper} noWrap>
        <ItemsContainer title={checkout.requiredConsumable}>
          {actualConsumables.map((el, i) => (
            <AttributesModalItem
              key={i}
              changeCount={(value) =>
                changeAttributes(actualConsumables, 'consumables', value, i)
              }
              item={el}
              index={i}
              minQuantity={1}
              testId={`consumable-${i}`}
            />
          ))}
        </ItemsContainer>
        <ItemsContainer title={checkout.requiredExtra}>
          {actualReqExtras.map((el, i) => (
            <AttributesModalItem
              key={i}
              changeCount={(value) =>
                changeAttributes(actualReqExtras, 'reqExtra', value, i)
              }
              item={el}
              index={i}
              count={count}
              minQuantity={count}
              testId={`required-extra-${i}`}
            />
          ))}
        </ItemsContainer>
        <ItemsContainer title={checkout.optionalExtra}>
          {actualOptionalExtras.map((el, i) => (
            <AttributesModalItem
              key={i}
              changeCount={(value) =>
                changeAttributes(
                  actualOptionalExtras,
                  'optionalExtra',
                  value,
                  i,
                )
              }
              item={el}
              index={i}
              count={count}
              testId={`optional-extra-${i}`}
            />
          ))}
        </ItemsContainer>
      </Row>
      <div className={style.buttonPanel}>
        <CustomButton
          disableClassName={style.disableBtn}
          onClick={handleCancelClick}
          isLoading={isDisableSkipButton}
          innerText={
            isNoReqAttributes && !type ? products.extra.skip : form.cancel
          }
        />
        <CustomButton
          disableClassName={style.disableBtn}
          isActive
          isLoading={isDisableApplyButton}
          onClick={handleChangeExtras}
          innerText={products.extra.apply}
          data-testid="apply-changes-btn"
        />
      </div>
    </Modal>
  );
}

export default AttributesModal;
