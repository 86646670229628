import React, { useContext } from 'react';
import clsx from 'clsx';
import { DESKTOP_SCREEN, LAPTOP_SCREEN } from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import { Lang } from 'lang';
import { getPriceRangeTextInTable } from 'utils/helpers/formatPrices';

import { useMatchMedia } from 'libs/useMatchMedia';

import { T8y } from 'ui';

import style from './productPricesTableItem.module.scss';

const ProductPricesTableItem = ({
  el,
  showDiscount,
  isNotAvailable,
  allPrices,
}) => {
  const { products } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const isSmallestDesktop = useMatchMedia(`(max-width: ${DESKTOP_SCREEN}px)`);
  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);

  return (
    <div
      className={clsx(
        style.priceTableItem,
        isSmallestDesktop &&
          !isLaptop &&
          allPrices.length > 4 &&
          style.priceTableItemSmallPaddings,
      )}
    >
      <T8y variant="t3" bold noWrap>
        {getPriceRangeTextInTable(el, products, settings.fixedRangesPrice)}
      </T8y>
      {showDiscount && (
        <T8y variant="t5" color="newGray" className={style.oldPrice}>
          {branding.currencySymbol}
          {el.oldPrice ? parseFloat(el.oldPrice).toFixed(2) : 0}
        </T8y>
      )}
      <T8y
        variant="t2"
        color={isNotAvailable ? 'newGray' : 'primary'}
        className={style.price}
      >
        {branding.currencySymbol}
        {el.price ? parseFloat(el.price).toFixed(2) : 0}
      </T8y>
    </div>
  );
};

export default ProductPricesTableItem;
