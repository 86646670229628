export const checkout = {
  table: {
    visual: 'Visual',
    productTitle: 'Product Title',
    hiringPeriod: 'Hiring period',
    hirePerItem: 'Hire per Item',
    qty: 'Qty',
    hirePrice: 'Hire Price',
  },

  changeExtrasModal: {
    requiredExtrasTitle: 'Choose required extras',
    optionalExtrasTitle: 'Choose optional extras',
    subTitle: 'Select extras and indicate their quantity',
  },

  getQuote: 'Get Quote',
  checkout: 'Checkout',
  checkoutReservation: 'Checkout',
  emailMe: 'Email me this Quote',
  transportationRoute: 'Transportation Route',

  quoteHasExpiredTitle: 'Your Quote Has Expired',
  quoteHasExpiredSubTitle: 'Unfortunately, the quote you received has expired.',
  quoteHasExpiredText:
    'Our quotes are valid for 7 days, and this time frame has now passed. To create a new quote, please browse our {0} and follow the steps to request a quote.',
  viewCatalogue: 'View Catalogue',

  press: 'Press',
  change: 'Change',
  optionalExtra: 'Optional Extras',
  requiredExtra: 'Required Extras',
  requiredConsumable: 'Required Consumables',
  changeExtra: 'Change Extra',
  for: 'for',
  free: 'Free',

  addMoreProducts: 'Add more products',
  payNow: 'Pay now',

  deliveryCollection: 'Delivery & Collection',
  selfCollectionText:
    'Collect the tools/products you have hired from the depot you have chosen/specified. Please ensure you have sufficient space for transportation and that your vehicle is suitable to transport the tools/products.',
  deliveryText:
    'The products you have hired will be delivered to the address you have specified. A fee will be charged. The fee will be based on the address you have provided.',

  comingSoon: 'Coming soon',

  emptyData: 'empty data',
  openingTimes: 'Opening Times',
  select: 'Select',
  selected: 'Selected',
  chooseAnotherDepot: 'Choose another depot',
  chooseDepot: 'Choose depot',
  impossibleDelivery:
    'To request the products to be delivered at your location, you can contact us at {0} or at {1}.',
  impossibleSelfCollection:
    'Self-collection option is not available for this product. Please proceed with delivery option or contact us at {0} for any questions.',

  italy: 'Italy',

  fields: {
    hireStartDate: 'Hire start date',
    hireEndDate: 'Hire end date',
    chooseStartDate: 'Choose start date',
    chooseEndDate: 'Choose end date',
    deliveryTimeSlot: 'Delivery Time Slot',
    collectionTimeSlot: 'Collection Time Slot',
    addressLine: 'Address line',
    city: 'City',
    deliveryPostcode: 'Delivery postcode',
    country: 'Country',
    vatNumber: 'VAT Number',
    taxNumber: 'Tax Number',
    fullName: 'Full name',
    phoneNumber: 'Phone number',
    emailAddress: 'Email address',
    deliveryAddress: 'Delivery Address',
  },

  placeholders: {
    startDate: 'DD/MM/YYYY',
    endDate: 'DD/MM/YYYY',
    deliveryTime: 'Delivery Time',
    collectionTime: 'Collection Time',
    address: 'Address',
    ofDelivery: 'Of delivery',
    postcode: 'Postcode',
    findPostcode: 'Start typing your Post Code',
  },

  titles: {
    yourQuote: 'Your quote',
    chooseHowYou: 'Choose how you would like to proceed',
    pleaseEnter: 'Please enter the details below',
    payWithCard: 'CREDIT CARD',
    hiringPeriod: 'Hiring Period',
    estimatePerWeek: 'Estimate per Week',
    deliveryMethod: 'Delivery Method',
    invalidCard: 'Invalid card number',
    invalidCardTryAgain: 'Something went wrong, please try one more time',
    contactInformation: 'Contact Information',
    selfCollection: 'Self-collection',
    delivery: 'Delivery',
    selectYour: 'Select your user type',
    enterYour: 'Enter your contact details',
    individual: 'Individual',
    company: 'Company',
    accountType: '{0} account',
    selectWhich: 'Select which depot to collect from',
    yourReservation: 'Your Reservation',
    selfCollectionDepot: 'Self-Collection Depot',
    deliveryAddress: 'Delivery at',
    yourDetalis: 'Your Details',
    paymentMethod: 'Payment Method',
    loggedInAs: 'logged in as',
    makeAddress: 'Make Address as default',
    accountSection: 'Do you have {0} account?',
    depositType: 'Deposit Type',
    depositProceed: 'Default deposit',
    creditAccountProceed: 'Deposit based on your credit records',
    findDeliveryByPostcode: 'Find delivery address by Post Code',
    findBillingAddress: 'Find billing address by Post Code',
  },

  radio: {
    payWithCredit: 'Pay with Credit Card',
    payByBank: 'Pay by Bank Transfer',
    payByBankText: "We'll send you an email with all the details",
    payAtSelf: 'Pay at self-collection depot',
    payLater: 'Pay in 90 days - no interest, no fees.',
    payLaterText:
      'Two lets your business pay later for the items you hire online, You will receive an invoice from Two when your order has been processed.',
    payAtTrainingLocation: 'Pay later at the course location',
    otherMethods: 'Other Payment Method',
    otherMethodsText:
      'You will be contacted by our representative to choose from the payment methods available for you',
  },

  details: {
    createAccount: 'Create {0} account',
    no: 'No',
    yes: 'Yes',
  },

  guest: 'Check out as a guest',
  guestText: 'A trade account will be automatically created.',
  oldUser: 'I have {0} account',
  newUser: 'Create {0} account (will help to checkout faster next time)',
  go: 'Go',
  logOut: 'Log Out',
  dontHaveAccount: 'Don’t have an account yet?',
  singUpSuccess: 'Thank you for signing up! Log in to continue.',

  stripeNote:
    'We use Stripe to process all your payments. Stripe is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry.',

  descriptions: {
    fillFewFields:
      'Fill in a few fields to check full pricing and receive a quote to your email.',
    shareAllThe:
      'Share all the details required for hiring, view full pricing and make a payment.',
    paymentSecured: 'Payment is secured with SSL certificate',
    leaveTheseFields:
      'Leave these fields blank if you don’t know event dates yet. We will email you estimate per week.',
    theLossDamage:
      'The loss & damage deposit will be returned to your card upon safe return of the inventory',
    yourQuoteIsEmpty:
      'Your {0} cart is empty. Browse our {1} and create your reservation list.',
    callUs: 'Call us {0} if any questions.',
    startTyping: 'Start typing your delivery address',
    enterAddress: 'Enter delivery address',
    depositProceed:
      'The deposit in your cost summary will be calculated using our default settings and will reflect the full replacement value of the item',
    creditAccountProceed:
      'The deposit will be defined and confirmed with you using your credit records. We will email you an electronic Credit Application form with further details. Use this option if you would like to decrease your deposit',
    addressChosen: 'Selected Delivery Address',
    billingAddress: 'Billing Address',
    enterBillingAddress: 'Enter billing address',
    changeBillingAddress: 'Change billing address',
    sameAsDeliveryAddress: 'Same as Delivery Address',
  },

  success: {
    yourEnquiry: 'Your enquiry has been placed successfully',
    youWill:
      'You will shortly receive an email with your hire quote. You can use that quote to place the orders when you`re ready.',
    youShould:
      'You should receive an email confirmation detailing your orders shortly.',
    goTo: 'Go to manage',
    availableAddress: 'Delivery: ',
  },

  errors: {
    outOfRegion:
      'We’re sorry, you entered an address outside {region} region. Choose self-collection (if available) in one of our depots or check if the product is available in your region by visiting our {homepage}.',
    outOfDistance:
      'Sorry, we don’t support delivery to your address.\n' +
      'Use Self-Collection and pick products from one of our depots, it’s always free.',
    postalCodeDefined:
      'The price in your area is yet to be defined. Proceed to self-collection or contact us',
    differentTypes: `For some products in your cart delivery option is not available. Please, select a depo or delete following products from the cart`,
    noWarehouses: 'Products are not available at our Self-Collection depots',
    sameDay:
      "We can't honor same-day delivery or over the weekend for these products. Please change the hiring period or continue with Self-Collect",
  },

  steps: {
    title: 'Checkout',
    payConfirmation: 'Your reservation',
    delivery: 'Self-collection or Delivery',
    details: 'Your details',
    confirmAndPay: 'Confirm and pay',
  },

  toastMessages: {
    requiredWarehouse: 'Warehouse need to be selected',
    requiredAddress: 'Delivery address need to be filled correctly',
  },

  promo: {
    title: 'Do you have a promo code?',
    placeholder: 'Enter your promo code',
    apply: 'Apply',
    remove: 'Remove',
    success: 'Your promo code is applied to the cost summary!',
    notValid: 'Oops, your promo code is not valid',
    promoAlreadyRedeemed: 'Promo code already redeemed',
    promoForAuthorizedUsersOnly:
      'This promo is available for authorized users only',
    promoDoesntExist: "The promo code doesn't exist",
    codeHasExpired: 'This promo code has expired',
    promoNotValidForProducts: 'This promo code is not valid for your products',
    doYouHavePromotional: 'Do you have a Promotional Code?',
    promotionalCode: 'Promotional Code',
    promoCodeApplied: 'Promo code applied',
    promoCodeNote:
      'Promotional Code can be applied only once during online checkout',
    promoForNonAuthorizedUsersOnly:
      "Oops! This Promo Code can't be applied to Trade Account Holders",
  },

  generateUrl: 'Generate URL for this reservation list',
  getEmailQuote: 'Get your email quote today',
  urlIsCopied: 'URL is copied to your clipboard',
  enterComment: 'Any additional comments (optional)',
  confirmedAvailability: 'availability is confirmed',
  futureDelivery:
    'The costs of delivery to your address will be defined later.',
  deliveryNotAvailable: 'Currently not available',
  findNewAddress: 'Find new address',
  backToResults: 'Back to results',
  continueShopping: 'Continue shopping',
  proceedViewFullPayment: 'Proceed to view full pricing breakdown',
  backToReservation: 'Back to Reservation',
  selectDeliveryViewCostSummary:
    'Select your Delivery Method to view full Cost Summary',
  selectPayment: 'Select Payment Method to confirm Your Order',
  continueToDelivery: 'Continue to Delivery',
  continuePayment: 'Continue with Payment',
  placeOrder: 'Place Order',
  alreadyHaveAnAcc: 'Already have an Account?',
  dontHaveAnAcc: 'Don’t have an Account ?',
  imCompany: 'I am a company',
  imNotCompany: 'I am not a company',
  imNotCompanyNote: `Select this checkbox if you're not a registered company. This will exclude the 'Pay in 90 days' option.`,
  card: 'Card',
  date: 'Date',

  stripeErrors: {
    authentication_required:
      'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
    approve_with_id: 'The payment can’t be authorized. Please try again.',
    card_not_supported:
      'The card does not support this type of purchase. Please contact card issuer.',
    card_velocity_exceeded:
      'You’ve exceeded the balance or credit limit available on the card.',
    currency_not_supported: 'The card does not support the specified currency.',
    expired_card: 'Your card has expired. Please try another card.',
    incorrect_cvc:
      'The CVC number is incorrect. Please try again using the correct CVC number.',
    invalid_cvc:
      'The CVC number is incorrect. Please try again using the correct CVC number.',
    invalid_expiry_month:
      'The expiration month is invalid. Please try again using correct expiration date.',
    invalid_expiry_year:
      'The expiration year is invalid. Please try again using the correct expiration date.',
    issuer_not_available:
      'The card issuer couldn’t be reached. Please attempt the payment again.',
    pin_try_exceeded:
      'The allowable number of PIN tries was exceeded. Please use another card or method of payment.',
  },

  deliveryOptions: 'Delivery Options',
  paymentOptions: 'Payment Options',
  debitCreditCards: 'Debit/credit cards',
  payLater: 'Pay later',
  delivery: 'Delivery',
  charge: 'Charge',
  freeProduct: 'Free',
};
