import React, { useCallback, useRef, useState } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import {
  sendEventWithCustomField,
  sendEventWithEventLabel,
  sendGAEvent,
} from 'googleAnalytics';
import { useOnClickOutside } from 'hooks';
import { Lang } from 'lang';
import { changeSearchStr, changeSearchStrFromUrl } from 'models/filter';
import { isPageWithNoSearchResultsOpened$ } from 'models/layoutData';
import { setSearchHistory } from 'models/search';

import SearchResults from '../SearchResults';

import { Row } from 'ui';

import { ReactComponent as ClearIcon } from 'assets/images/icon/closeModal.svg';
import { ReactComponent as SearchIcon } from 'assets/images/icon/search/searchNew.svg';

import style from './search.module.scss';

const SearchInput = ({ currentRefinement, hits, refine }) => {
  const { form } = Lang();

  const inputRef = useRef();
  const searchListRef = useRef();

  const isPageWithNoSearchResultsOpened = useStore(
    isPageWithNoSearchResultsOpened$,
  );

  const [isListOpen, setIsListOpen] = useState(false);
  const [isEmptyResults, setIsEmptyResults] = useState(true);

  const closeSearch = useCallback(() => {
    setIsListOpen(false);
    changeSearchStrFromUrl(null);
    refine(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(searchListRef, closeSearch, '[data-testid="headerSearch"]');

  const goToSearchPage = useCallback(
    (valueForSearch) => {
      if (!valueForSearch) {
        return;
      }

      refine(null);
      setIsListOpen(false);
      changeSearchStrFromUrl(null);
      changeSearchStr(valueForSearch);
      setSearchHistory({ title: valueForSearch, type: 'product' });

      if (isPageWithNoSearchResultsOpened) {
        sendEventWithEventLabel(
          'no_search_results_page_start_search',
          valueForSearch,
        );
      } else {
        sendEventWithCustomField(
          'user_start_search',
          'user input',
          valueForSearch,
        );
      }
    },
    [isPageWithNoSearchResultsOpened, refine],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      inputRef.current.blur();
      goToSearchPage(currentRefinement);
    },
    [currentRefinement, goToSearchPage],
  );

  const setInputFocus = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [inputRef],
  );

  return (
    <Row
      as="form"
      data-testid="headerSearch"
      onSubmit={onSubmit}
      align="center"
      className={clsx(
        style.search,
        isListOpen && !isEmptyResults && style.searchListOpen,
      )}
      autoComplete="off"
    >
      <Row align="center" className={style.searchBtn} onClick={setInputFocus}>
        <SearchIcon />
      </Row>
      <Row direction="column" className={style.searchContainer}>
        <Row className={style.searchInputField} align="center">
          <input
            type="search"
            name="search"
            placeholder={form.fields.search}
            value={currentRefinement}
            ref={inputRef}
            onChange={(event) => {
              changeSearchStrFromUrl(null);
              refine(event.currentTarget.value);
            }}
            onFocus={() => {
              sendGAEvent('user_click_search_field');
              setIsListOpen(true);
            }}
          />
        </Row>
        {isListOpen && (
          <SearchResults
            currentRefinement={currentRefinement}
            refine={refine}
            searchListRef={searchListRef}
            hits={hits}
            setIsListOpen={setIsListOpen}
            goToSearchPage={goToSearchPage}
            searchListClassName={style.searchListDesktop}
            setIsEmptyResults={setIsEmptyResults}
          />
        )}
      </Row>
      {isListOpen && !!currentRefinement.length && (
        <Row
          align="center"
          className={style.clearIcon}
          onClick={(e) => {
            refine(null);
            setInputFocus(e);
          }}
          data-testid="clear-search-string-btn"
        >
          <ClearIcon />
        </Row>
      )}
    </Row>
  );
};

export const Search = connectAutoComplete(SearchInput);
