import React from 'react';

import { T8y } from 'ui';

const ItemsContainer = ({ title, children }) => {
  if (!children.length) {
    return null;
  }

  return (
    <>
      <T8y variant="t2v1" fullWidth mt3 mb2 bold>
        {title}
      </T8y>
      {children}
    </>
  );
};

export default ItemsContainer;
