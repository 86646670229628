import { combine, guard } from 'effector';

import { CATREGORIES, THIRD_PARTY_INTEGRATIONS } from 'api';

import * as DC from './';

DC.getPopularCategoriesFx.use(async () => {
  const { data } = await CATREGORIES.getPopularCategories();

  return data.data;
});

const isPopularCategoriesLoading$ = DC.getPopularCategoriesFx.pending.map(
  (state) => !!state,
);

guard({
  source: DC.PopularCategoriesGate.open,
  filter: combine(
    isPopularCategoriesLoading$,
    DC.popularCategories$,
    (isPopularCategoriesLoading, popularCategories) =>
      !isPopularCategoriesLoading && !popularCategories.length,
  ),
  target: DC.getPopularCategoriesFx,
});

DC.getTerritoryIDFx.use(async () => {
  const { data } = await THIRD_PARTY_INTEGRATIONS.getTerritoryId();

  return data.territory_id || null;
});

const isLoadingTerritory = DC.getTerritoryIDFx.pending.map((state) => !!state);

guard({
  source: DC.territoryIDGate.open,
  filter: combine(
    isLoadingTerritory,
    DC.territoryID$,
    (isLoadingTerritory, territoryID) => !isLoadingTerritory && !territoryID,
  ),
  target: DC.getTerritoryIDFx,
});
