export const footer = {
  titles: {
    supportedRegions: 'Supported Regions',
  },

  copyright: 'Copyright © {0} {1}. All rights reserved.',
  termsConditions: 'Terms & Conditions',
  cookiePolicy: 'Cookie Policy',
  generalTerms: 'General Terms',
  privacyPolicy: 'Privacy Policy',

  instantQuote: 'Instant quote',
  training: 'Training',
  information: 'Information',
  aboutUs: 'About Us',
  blog: 'Blog',
  faq: 'FAQ',
  securePayments: 'Secure Payments',

  thisWebsite: 'This website is Powered by',
  rentuuOperates:
    'easyHire Technologies Ltd operates a global, fast-growing, cloud-based technology platform and franchise network for the multi-billion pound sterling equipment hire industry.',
  learnMoreEasyFamilyOfBrands:
    'Visit {0} and learn more about the easy family of brands.',
  learnMoreEasyHistory: 'Visit {0} and learn about the brand history.',

  followUs: 'Follow Us',
};
