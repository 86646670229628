import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { Button } from 'ui';

import style from './button.module.scss';

export const CustomButton = forwardRef(
  (
    {
      innerText,
      isActive,
      additionalClass,
      disableClassName,
      type = 'button',
      isLoading,
      defaultBorder,
      icon,
      disabled,
      ...rest
    },
    ref,
  ) => {
    return (
      <Button
        type={type}
        className={clsx(
          isActive && style.active,
          isLoading && (disableClassName || style.loading),
          style.profileButton,
          style.mainBtn,
          additionalClass,
          defaultBorder && style.defaultBorder,
        )}
        disabled={disabled || isLoading}
        ref={ref}
        {...rest}
      >
        {icon}
        {innerText}
      </Button>
    );
  },
);
