import React, { useContext, useMemo } from 'react';
import { SMALL_SCREEN } from 'consts';
import { SettingsContext } from 'contexts';
import { getFormattedPricesForTable } from 'utils/helpers/formatPrices';

import { useMatchMedia } from 'libs/useMatchMedia';

import DesktopItem from './DesktopItem';
import MobileItem from './MobileItem';

import { Row } from 'ui';

const ProductCardPriceTableBody = ({
  sortedPrices,
  isShowOldPrice,
  additionalPrices,
}) => {
  const settings = useContext(SettingsContext);

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const allPrices = useMemo(() => {
    return getFormattedPricesForTable({
      sortedPrices,
      additionalPrices,
      showInfinitySignInPrices: settings.showInfinitySignInPrices,
    });
  }, [additionalPrices, settings.showInfinitySignInPrices, sortedPrices]);

  return (
    <Row align="stretch" smDirection="column">
      {allPrices.map((el, index) => {
        return isSmallScreen ? (
          <MobileItem key={index} el={el} isShowOldPrice={isShowOldPrice} />
        ) : (
          <DesktopItem key={index} el={el} isShowOldPrice={isShowOldPrice} />
        );
      })}
    </Row>
  );
};

export default ProductCardPriceTableBody;
