import React, { useContext, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { isETH, SMALL_SCREEN } from 'consts';
import { BrandingContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import { useProductCardProps } from 'hooks';
import { Lang } from 'lang';
import * as MP from 'models/product';

import LazyLoadingComponent from 'features/common/LazyLoadingComponent';

import { useMatchMedia } from 'libs/useMatchMedia';

import Labels from '../ProductCard/Labels';
import MainProductPrice from '../ProductCard/MainProductPrice';

import { Button, Rating, Row, T8y } from 'ui';
import Prices from 'ui/BigProductCard/Prices';

import style from './bigProductCard.module.scss';

export const BigProductCard = ({ product, testId }) => {
  const { form, products } = Lang();

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const branding = useContext(BrandingContext);
  const flatCategoriesListLocalized = useStore(MP.flatCategoriesListLocalized$);
  const viewBtnRef = useRef(null);

  const { mainPrice, showDiscount, defaultHiringPeriodProduct } =
    useProductCardProps(product);

  const prices = product?.prices;

  const sortedPrices = useMemo(() => {
    if (!prices) {
      return [];
    }

    return [...prices].sort((a, b) => (a.period_from > b.period_from ? 1 : -1));
  }, [prices]);

  //attribute offset="140" for lazy was tested on iPhone 14 Pro
  return (
    <Link
      data-testid={testId}
      to={{
        pathname: PATHS.PRODUCT(product.slug),
        presetType: 'product',
      }}
      onClick={(e) => {
        if (product.category_id) {
          MP.setCurrentCategory(
            flatCategoriesListLocalized.find(
              ({ id }) => id === product?.category_id,
            ) || null,
          );
        }

        sendGAEvent(
          viewBtnRef.current?.contains(e.target)
            ? 'view_details_button_click'
            : 'product_card_click',
        );
      }}
      className={style.productCard}
    >
      {!product.isOutOfStock && (
        <Labels
          price={mainPrice.price}
          label={product.label}
          showDiscount={showDiscount}
          isBigCard
        />
      )}
      <div className={style.productImageWrapper}>
        <LazyLoadingComponent className={style.lazyContainer} once offset={140}>
          <picture>
            <source srcSet={product.image?.webp?.medium} type="image/webp" />
            <img
              className={style.productImage}
              src={product.image?.medium || branding.defaultImageProduct}
              alt="ProductParts img"
            />
          </picture>
        </LazyLoadingComponent>
      </div>
      <div className={style.productInfoWrapper}>
        <div>
          {isSmallScreen && !isETH && (
            <div className={style.mainPriceWrapper}>
              <MainProductPrice
                price={product.price}
                showDiscount={showDiscount}
                mainPrice={mainPrice}
                defaultHiringPeriodProduct={defaultHiringPeriodProduct}
                isBigCard
              />
            </div>
          )}
          <T8y
            className={clsx(
              style.productNameWrapper,
              isETH && style.productETHName,
            )}
            as="p"
            variant="t1"
            mdVariant={isETH ? 't1' : 't1v1'}
            bold={isETH}
          >
            {product.name}
          </T8y>
          {Boolean(product.rating) && (
            <Row align="center" className={style.productRatingWrapper}>
              <Rating
                rating={product.rating}
                className={style.productRating}
                width={17}
                height={16}
              />
              <T8y variant="t1v1" xsVariant="t4" color="midGray">
                ({product.reviewsCount || 1})
              </T8y>
            </Row>
          )}
          {!isSmallScreen && !isETH && (
            <div className={style.mainPriceWrapper}>
              <MainProductPrice
                price={product.price}
                showDiscount={showDiscount}
                mainPrice={mainPrice}
                defaultHiringPeriodProduct={defaultHiringPeriodProduct}
                isBigCard
              />
            </div>
          )}
          {!!mainPrice.price ? (
            <Prices
              sortedPrices={sortedPrices}
              product={product}
              showDiscount={showDiscount}
            />
          ) : (
            <T8y
              variant="t2v1"
              color="midGray"
              className={style.contentWrapper}
            >
              {products.notAvailableForCheckoutProductCard}
            </T8y>
          )}
        </div>
        <div className={style.viewDetailsBtnWrapper}>
          <Button
            ref={viewBtnRef}
            className={style.viewDetailsBtn}
            inverse
            data-testid="viewDetailsBtn"
          >
            {form.buttons.viewDetails}
          </Button>
        </div>
      </div>
    </Link>
  );
};
