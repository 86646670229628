export function getFlatArray(array, mutable) {
  const toString = Object.prototype.toString;
  const arrayTypeStr = '[object Array]';

  const result = [];
  const nodes = (mutable && array) || array.slice();
  let node = null;

  if (!array.length) {
    return result;
  }

  node = nodes.pop();

  do {
    if (toString.call(node) === arrayTypeStr) {
      nodes.push.apply(nodes, node);
    } else {
      result.push(node);
    }
  } while (nodes.length && (node = nodes.pop()) !== undefined);

  result.reverse();
  return result;
}

export function getFlatCategoriesList(
  categoriesList = [],
  key,
  level = 1,
  parent = null,
  mainAcc = [],
  isRecursiveCall = false,
) {
  categoriesList.forEach((item) => {
    mainAcc.push({
      ...item,
      level,
      parent,
    });
    getFlatCategoriesList(item[key], key, level + 1, item.id, mainAcc, true);
  });

  if (isRecursiveCall) {
    return;
  }

  return mainAcc;
}
