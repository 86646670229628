import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { aboutRoutes } from 'pages/about/aboutRoutes';
import { carbonEmissionRoutes } from 'pages/carbonEmission/carbonEmissionRoutes';
import { categoryRoutes } from 'pages/category/categoryRoutes';
import { checkoutSuccessRoutes } from 'pages/checkoutSuccess/checkoutSuccessRoutes';
import { depotsRoutes } from 'pages/depots/depotsRoutes';
import {
  CookiePolicyRoutes,
  generalTermsRoutes,
  policyRoutes,
  termsRoutes,
} from 'pages/footerLinks/footerRoutes';
import { jobTypeRoutes } from 'pages/jobType/jobTypeRoutes';
import { mainRoutes } from 'pages/main/mainRoutes';
import { maintenanceRoutes } from 'pages/maintenance/maintenanceRoutes';
import { mobileSearchRoutes } from 'pages/mobileSearch/mobileSearchRoutes';
import { notFoundRoutes } from 'pages/notFound/notFoundRoutes';
import { orderRoutes } from 'pages/order/orderRoutes';
import { productRoutes } from 'pages/product/productRoutes';
import { profileRoutes } from 'pages/profile/profileRoutes';
import { searchRoutes } from 'pages/search/searchRoutes';
import { trainingRoutes } from 'pages/training/trainingRoutes';
import { trainingCheckoutRoutes } from 'pages/trainingCheckout/trainingCheckoutRoutes';
import { trainingCheckoutSuccessRoutes } from 'pages/trainingCheckoutSuccess/trainingCheckoutSuccessRoutes';
import { trainingIndexRoutes } from 'pages/trainingIndex/trainingIndexRoutes';
import { verificationRoutes } from 'pages/verification/verificationRoutes';
import { verificationSuccessRoutes } from 'pages/verificationSuccess/verificationSuccessRoutes';

import { PATHS } from './AppPaths';

const routesList = [
  ...verificationSuccessRoutes,
  ...verificationRoutes,
  ...mobileSearchRoutes,
  ...mainRoutes,
  ...depotsRoutes,
  ...policyRoutes,
  ...termsRoutes,
  ...CookiePolicyRoutes,
  ...generalTermsRoutes,
  ...profileRoutes,
  ...orderRoutes,
  ...checkoutSuccessRoutes,
  ...carbonEmissionRoutes,
  ...maintenanceRoutes,
  ...searchRoutes,
  ...aboutRoutes,
  ...trainingIndexRoutes,
  ...trainingRoutes,
  ...trainingCheckoutSuccessRoutes,
  ...trainingCheckoutRoutes,
  ...notFoundRoutes,
  ...jobTypeRoutes,
  ...categoryRoutes,
  ...productRoutes,
];

export const Routes = () => {
  return (
    <Switch>
      {routesList.map((route, index) => (
        <Route key={index} {...route} />
      ))}
      <Redirect to={PATHS.NOT_FOUND} />
    </Switch>
  );
};
