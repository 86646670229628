class TokenEventObserver {
  constructor() {
    this.tokenEventObservers = [];
  }

  subscribe(fn) {
    this.tokenEventObservers.push(fn);
  }

  unsubscribe(fn) {
    this.tokenEventObservers = this.tokenEventObservers.filter(
      (subscriber) => subscriber !== fn,
    );
  }

  broadcast(data) {
    this.tokenEventObservers.forEach((subscriber) => subscriber(data));
  }
}

const tokenEventObserver = new TokenEventObserver();

export default tokenEventObserver;
