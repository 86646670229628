import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { categoriesLocalized$ } from 'models/product';

import MenuItem from './MenuItem';

import style from '../../header.module.scss';

const NestedCategoriesMenu = ({
  goToCategory,
  handleChooseMainCategory,
  handleChooseSubCategory,
  selectedCategory,
}) => {
  const categoriesLocalized = useStore(categoriesLocalized$);

  const handleClickOnMainCategory = useCallback(
    (category) => {
      if (!category.childs?.length) {
        goToCategory(category);
        return;
      }

      handleChooseMainCategory(category);
    },
    [goToCategory, handleChooseMainCategory],
  );

  return (
    <>
      {selectedCategory && (
        <Link
          to={{
            pathname: PATHS.CATEGORY(selectedCategory.slug),
            presetType: 'category',
          }}
          className={clsx(
            style.name,
            style.border,
            style.menuTitle,
            style.menuLink,
          )}
          onClick={() => goToCategory(selectedCategory)}
        >
          {selectedCategory.name}
        </Link>
      )}
      {!selectedCategory &&
        categoriesLocalized.map((category) => (
          <MenuItem
            category={category}
            onClick={() => handleClickOnMainCategory(category)}
            key={category.id}
          />
        ))}
      {selectedCategory?.childs?.map((category) => (
        <MenuItem
          category={category}
          onClick={(e) => handleChooseSubCategory(e, category)}
          additionalClassName={clsx(style.toggle, style.open)}
          key={category.id}
        />
      ))}
    </>
  );
};

export default NestedCategoriesMenu;
