export const getProductWithActualPrices = (product, discount) => {
  const weekendRatePrice = {
    price: product.weekendRatePrice || product.weekend_rate_price,
  };

  return {
    ...product,
    prices: product.prices?.map((range) => ({
      ...range,
      oldPrice: range.price,
      price: range.price - range.price * (discount / 100),
    })),
    additional_prices: product.additional_prices && {
      ...product.additional_prices,
      old_price_per_day: product.additional_prices.price_per_day,
      price_per_day:
        product.additional_prices.price_per_day -
        product.additional_prices.price_per_day * (discount / 100),
    },
    weekendRatePrice: weekendRatePrice.price && {
      price: weekendRatePrice.price - weekendRatePrice.price * (discount / 100),
      oldPrice: weekendRatePrice.price,
    },
  };
};
