import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from 'contexts';
import { useList, useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  cart$,
  cartLength$,
  isCartPopupVisible$,
  latestTotalPriceInfo$,
} from 'models/cart';
import { currentLang$ } from 'models/language';
import { getSimilarExtraInTotalPrices } from 'utils/formatProducts';

import ProductListItem from '../ProductListItem';

const ProductList = () => {
  const { checkout } = Lang();

  const settings = useContext(SettingsContext);

  const cartLength = useStore(cartLength$);
  const { latestTotalPrice, isLoadingLatestTotalPrice } = useStore(
    latestTotalPriceInfo$,
  );
  const currentLangState = useStore(currentLang$);
  const isCartPopupVisible = useStore(isCartPopupVisible$);

  const [datePickerOpenId, setDatePickerOpenId] = useState(null);

  useEffect(() => {
    if (!isCartPopupVisible) {
      setDatePickerOpenId(null);
    }
  }, [isCartPopupVisible]);

  return (
    <>
      {useList(cart$, {
        keys: [
          latestTotalPrice,
          currentLangState,
          settings,
          cartLength,
          datePickerOpenId,
        ],
        fn: (item, index) => {
          const { products } = latestTotalPrice;

          const similarProductInTotalPrices = products?.find(
            (el) => el.id === item.id,
          );

          const isLastItem = index === cartLength - 1;

          return (
            <>
              <ProductListItem
                key={item.id}
                itemInCart={item}
                image={item.product.image?.thumb}
                slug={item.product.slug}
                price={similarProductInTotalPrices?.unit_price}
                totalPrice={similarProductInTotalPrices?.product_total}
                quantity={similarProductInTotalPrices?.quantity}
                isLoading={isLoadingLatestTotalPrice}
                isLastItem={isLastItem}
                isProductDisabled={isLoadingLatestTotalPrice}
                setDatePickerOpenId={setDatePickerOpenId}
                datePickerOpenId={datePickerOpenId}
              />
              {item.reqExtra?.map((el, index) => {
                const similarExtraInTotalPrices = getSimilarExtraInTotalPrices(
                  similarProductInTotalPrices,
                  el,
                );
                return (
                  <ProductListItem
                    key={index}
                    itemInCart={item}
                    slug={el.slug}
                    image={el.src}
                    extraTitle={el.value}
                    quantity={el?.quantity}
                    price={similarExtraInTotalPrices.unit_price}
                    totalPrice={similarExtraInTotalPrices.attribute_total}
                    type="reqExtra"
                    typeText={checkout.requiredExtra}
                    indexItem={index}
                    isLoading={isLoadingLatestTotalPrice}
                    isProductDisabled={isLoadingLatestTotalPrice}
                    isLastItem={isLastItem}
                  />
                );
              })}
              {item.optionalExtra?.map((el, index) => {
                const similarExtraInTotalPrices = getSimilarExtraInTotalPrices(
                  similarProductInTotalPrices,
                  el,
                );
                return (
                  <ProductListItem
                    key={index}
                    itemInCart={item}
                    slug={el.slug}
                    image={el.src}
                    extraTitle={el.value}
                    quantity={el.quantity}
                    price={similarExtraInTotalPrices.unit_price}
                    totalPrice={similarExtraInTotalPrices.attribute_total}
                    type="optionalExtra"
                    typeText={checkout.optionalExtra}
                    indexItem={index}
                    isLoading={isLoadingLatestTotalPrice}
                    isProductDisabled={isLoadingLatestTotalPrice}
                    isLastItem={isLastItem}
                  />
                );
              })}
              {item.optionalSaleExtra?.map((el, index) => {
                const similarExtraInTotalPrices = getSimilarExtraInTotalPrices(
                  similarProductInTotalPrices,
                  el,
                );
                return (
                  <ProductListItem
                    key={index}
                    itemInCart={item}
                    slug={el.slug}
                    image={el.src}
                    extraTitle={el.value}
                    quantity={el.quantity}
                    price={similarExtraInTotalPrices.unit_price}
                    totalPrice={similarExtraInTotalPrices.attribute_total}
                    type="optionalSaleExtra"
                    typeText={checkout.optionalExtra}
                    indexItem={index}
                    isLoading={isLoadingLatestTotalPrice}
                    isProductDisabled={isLoadingLatestTotalPrice}
                    isLastItem={isLastItem}
                  />
                );
              })}
              {item.consumables?.map((el, index) => {
                const similarExtraInTotalPrices = getSimilarExtraInTotalPrices(
                  similarProductInTotalPrices,
                  el,
                );
                return (
                  <ProductListItem
                    key={index}
                    itemInCart={item}
                    extraTitle={el.value}
                    type="consumables"
                    slug={el.slug}
                    image={el.src}
                    quantity={el.quantity}
                    price={similarExtraInTotalPrices.unit_price}
                    totalPrice={similarExtraInTotalPrices.attribute_total}
                    typeText={checkout.requiredConsumable}
                    indexItem={index}
                    isLoading={isLoadingLatestTotalPrice}
                    isProductDisabled={isLoadingLatestTotalPrice}
                    isLastItem={isLastItem}
                  />
                );
              })}
            </>
          );
        },
      })}
    </>
  );
};

export default ProductList;
