import { combine, createEvent, restore } from 'effector';
import { isRegionsRequestFinished$ } from 'models/regions';

import { isAboutUsRequestFinished$ } from '../aboutUs';
import { isBannersRequestFinished$ } from '../banners';
import { isBlogPostsRequestFinished$ } from '../blog';
import { isBrandingRequestFinished$ } from '../branding';
import { isPopularCategoriesFinished$ } from '../common';
import {
  isJobTypeRequestFinished$,
  isJobTypesRequestFinished$,
} from '../jobTypes';
import { isMaintenanceRequestFinished$ } from '../maintenance';
import {
  isCategoriesRequestFinished$,
  isCurrProductRequestFinished$,
  isPopularProductsRequestFinished$,
  isProductHiredTogetherRequestFinished$,
  isProductsPerCategoryFinished$,
  isProductVariationsRequestFinished$,
} from '../product';
import { isSettingsRequestFinished$, settings$ } from '../settings';
import {
  isCurrentTrainingRequestFinished$,
  isTrainingIndexRequestFinished$,
  isTrainingsRequestFinished$,
} from '../trainings';
import { isWarehousesRequestFinished$ } from '../warehouses';

export const setIsFooterLinkRequestFinished = createEvent();

export const isFooterLinkRequestFinished$ = restore(
  setIsFooterLinkRequestFinished,
  false,
);

const commonStores = combine(
  {
    isBrandingRequestFinished: isBrandingRequestFinished$,
    isSettingsRequestFinished: isSettingsRequestFinished$,
    isCategoriesRequestFinished$: isCategoriesRequestFinished$,
    isRegionsRequestFinished: isRegionsRequestFinished$,
    isWarehousesRequestFinished: isWarehousesRequestFinished$,
    isJobTypesRequestFinished: isJobTypesRequestFinished$,
    settings: settings$,
  },
  (p) => {
    const { settings, ...keyStores } = p;

    if (!settings.showCustomizeInventory) {
      return Object.keys(keyStores).every((key) => {
        return key === 'isWarehousesRequestFinished' || !!keyStores[key];
      });
    }

    return Object.keys(keyStores).every((key) => !!keyStores[key]);
  },
);

export const requestsPerPageStore$ = combine(
  commonStores,
  isPopularProductsRequestFinished$,
  isBannersRequestFinished$,
  isTrainingIndexRequestFinished$,
  isTrainingsRequestFinished$,
  isCurrentTrainingRequestFinished$,
  isBlogPostsRequestFinished$,
  isAboutUsRequestFinished$,
  isCategoriesRequestFinished$,
  isProductsPerCategoryFinished$,
  isCurrProductRequestFinished$,
  isProductVariationsRequestFinished$,
  isProductHiredTogetherRequestFinished$,
  isMaintenanceRequestFinished$,
  isFooterLinkRequestFinished$,
  isPopularCategoriesFinished$,
  isJobTypeRequestFinished$,
  (
    common,
    popular,
    banners,
    trainingIndex,
    trainings,
    currTraining,
    blogPosts,
    aboutUs,
    allCategories,
    productsCategory,
    currProduct,
    variations,
    hiredTogether,
    maintenanceReq,
    footerReq,
    popularCategories,
    jobType,
  ) => ({
    main: [common, popular, banners, popularCategories],
    trainingIndex: [common, trainingIndex, trainings],
    training: [common, trainings, currTraining],
    notFound: [common, trainings, blogPosts, popular],
    aboutUs: [common, aboutUs],
    category: [common, popular, allCategories, productsCategory],
    product: [common, popular, currProduct, variations, hiredTogether],
    profile: [common],
    checkout: [common, popular],
    success: [common, trainings, blogPosts, popular],
    footerLink: [common, footerReq],
    search: [common, popular],
    carbon: [common, popular],
    maintenance: [common, popular, maintenanceReq],
    jobType: [common, jobType],
  }),
);
