import React, { useCallback, useContext, useState } from 'react';
import { BrandingContext } from 'contexts';
import { sendClickOnPhoneIcon, sendGAEvent } from 'googleAnalytics';
import { Lang } from 'lang';

import { Button, Modal, Row, T8y } from 'ui';

import { ReactComponent as Discount } from 'assets/images/icon/discountHeader.svg';
import { ReactComponent as CallIcon } from 'assets/images/icon/phoneWhite.svg';

import style from './modalWithCallSign.module.scss';

const ModalWithCallSign = ({ isProduct }) => {
  const { header } = Lang();

  const branding = useContext(BrandingContext);

  const [isOpen, setIsOpen] = useState(false);

  const clickOnOkBtn = useCallback(() => {
    setIsOpen(false);

    sendGAEvent(
      isProduct
        ? 'precall-ok-online-button-product-page'
        : 'precall-ok-online-button-header',
    );
  }, [isProduct]);

  const onCloseIconClickCallback = useCallback(() => {
    sendGAEvent(
      isProduct ? 'precall-close-product-page' : 'precall-close-header',
    );
  }, [isProduct]);

  return (
    <>
      <CallIcon
        className={style.phoneIcon}
        onClick={() => {
          setIsOpen(true);
          sendGAEvent('click-to-call-header');
        }}
        data-testid="call-header-btn"
      />

      {isOpen && (
        <Modal
          setIsOpen={setIsOpen}
          header={
            <Row align="center" justify="center">
              <Discount />
              <T8y color="light" variant="h2" ml2>
                {header.callModal.title}
              </T8y>
            </Row>
          }
          classNameModal={style.container}
          classNameHeader={style.header}
          onCloseIconClickCallback={onCloseIconClickCallback}
        >
          <div>
            <Row
              direction="column"
              align="center"
              className={style.innerContainer}
            >
              <T8y className={style.discountText} center>
                {header.callModal.text}
              </T8y>
              <Button onClick={clickOnOkBtn}>
                <T8y variant="t1v1" color="light" center>
                  {header.callModal.closeBtnText}
                </T8y>
              </Button>
              <T8y className={style.subDiscountText} center>
                {header.callModal.subText}
              </T8y>

              <Row
                noWrap
                align="center"
                mb2
                ml2
                className={style.discountPhoneNumberWrapper}
              >
                <CallIcon className={style.callIcon} width={19} />
                <T8y
                  variant="h2"
                  as="a"
                  href={`tel:${branding.companyPhoneNumberCall}`}
                  onClick={() => {
                    sendClickOnPhoneIcon(
                      isProduct ? 'productpage-icon' : 'header',
                    );
                  }}
                  color="primary"
                  ml2
                >
                  {branding.companyPhoneNumber}
                </T8y>
              </Row>
            </Row>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalWithCallSign;
