import React from 'react';
import { SMALL_SCREEN } from 'consts';
import { Lang } from 'lang';
import MobileDropDown from 'layout/Footer/MobileDropDown';

import { useMatchMedia } from 'libs/useMatchMedia';

import InformationItemsList from './InformationItemsList';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const Information = () => {
  const { footer } = Lang();

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  return isSmallScreen ? (
    <MobileDropDown title={footer.information}>
      <InformationItemsList />
    </MobileDropDown>
  ) : (
    <>
      <T8y variant="t1v1" mb2 bold className={style.title}>
        {footer.information}
      </T8y>
      <InformationItemsList />
    </>
  );
};

export default Information;
