import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel } from 'googleAnalytics';
import { Lang } from 'lang';
import { popularCategoriesLocalized$ } from 'models/common';

import { Row, T8y } from 'ui';

import { ReactComponent as StarIcon } from 'assets/images/icon/search/folderSpecial.svg';

import style from './popularCategories.module.scss';

const PopularCategories = ({
  isMobile,
  setSelectedCategory,
  setIsSubMenuOpen,
  handleGoToCategory,
}) => {
  const { search } = Lang();

  const popularCategoriesLocalized = useStore(popularCategoriesLocalized$);

  const handlePopularCategoryClick = useCallback(
    (categoryName) => {
      sendEventWithEventLabel(
        'header_hire_popular_category_selected',
        categoryName,
      );

      if (setIsSubMenuOpen) {
        setIsSubMenuOpen(false);
      }
      if (handleGoToCategory) {
        handleGoToCategory();
      }
    },
    [handleGoToCategory, setIsSubMenuOpen],
  );

  if (!popularCategoriesLocalized.length) {
    return null;
  }

  return (
    <div
      className={clsx(
        style.popularCategories,
        isMobile && style.mobilePopularCategories,
      )}
    >
      <T8y
        color="midGray"
        variant={isMobile ? 't2v1' : 't4'}
        uppercase={!isMobile}
        boldLight={isMobile}
        className={style.title}
      >
        {search.popularCategories}
      </T8y>
      <Row direction="column" className={style.categoriesList}>
        {popularCategoriesLocalized.slice(0, 6).map((item, index) => (
          <Row
            key={index}
            as={NavLink}
            to={{
              pathname: PATHS.CATEGORY(item.slug),
              presetType: 'category',
            }}
            align="start"
            pointer
            fullWidth
            noWrap
            onMouseEnter={() => {
              if (setSelectedCategory) {
                setSelectedCategory(null);
              }
            }}
            onClick={() => handlePopularCategoryClick(item.name)}
            className={style.category}
          >
            {!isMobile && <StarIcon />}
            <T8y
              variant="t1"
              color="light"
              boldLight={isMobile}
              className={style.categoryTitle}
            >
              {item.name}
            </T8y>
          </Row>
        ))}
      </Row>
    </div>
  );
};

export default PopularCategories;
