import { REACT_APP_PUBLIC_URL } from 'consts';

export const useSearchStructureData = () => {
  const data = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: `${REACT_APP_PUBLIC_URL}`,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${REACT_APP_PUBLIC_URL}/search.html?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };

  return JSON.stringify(data);
};
