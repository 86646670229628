import { getDatesDefault } from 'utils/datesСalculation';

export const getDefaultDatesForProduct = (
  product,
  deliveryWeekends,
  defaultHiringPeriod,
  finalBlockedDates,
  isCurrentDayHirePossible,
  currLocaleDate,
) => {
  if (deliveryWeekends?.length > 6) {
    return {
      start: '',
      end: '',
    };
  }

  return {
    start: getDatesDefault({
      product,
      deliveryWeekends,
      type: 'start',
      defaultHiringPeriod,
      finalBlockedDates,
      isCurrentDayHirePossible,
      currLocaleDate,
    }),
    end: getDatesDefault({
      product,
      deliveryWeekends,
      type: 'end',
      defaultHiringPeriod,
      finalBlockedDates,
      isCurrentDayHirePossible,
      currLocaleDate,
    }),
  };
};
