import React, { useCallback, useContext, useState } from 'react';
import { Group } from 'reakit';
import { PATHS } from 'AppPaths';
import { isETH } from 'consts';
import { SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel } from 'googleAnalytics';
import { Lang } from 'lang';
import { jobTypes$ } from 'models/jobTypes';
import { trainingsLocalized$ } from 'models/trainings';

import JobTypes from '../JobTypes';
import Catalog from '../Menu/Catalog';
import Company from '../Menu/Company';
import MenuItem from '../Menu/MenuItem';
import Training from '../Menu/Training';

import { Row } from 'ui';

import style from './nav.module.scss';

const Nav = () => {
  const { header } = Lang();

  const settings = useContext(SettingsContext);

  const trainingsLocalized = useStore(trainingsLocalized$);
  const jobTypes = useStore(jobTypes$);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const isTrainingsAvailable = !!trainingsLocalized.length;

  const handleTrainingLinkClicked = useCallback(() => {
    sendEventWithEventLabel('regional_page_header_training', 'index training');
  }, []);

  return (
    <Group as={Row} align="center" noWrap mdHide ml3>
      <MenuItem
        setIsSubMenuOpen={setIsSubMenuOpen}
        isSubMenuOpen={isSubMenuOpen}
        additionalClassName={style.categoriesLink}
        title={header.nav.hireByCategory}
        isNoCapitalize
      >
        {isSubMenuOpen && <Catalog setIsSubMenuOpen={setIsSubMenuOpen} />}
      </MenuItem>

      {settings.showTrainingsInMenu && (
        <MenuItem
          href={PATHS.TRAINING}
          isSubMenuOpen={isSubMenuOpen}
          setIsSubMenuOpen={setIsSubMenuOpen}
          title={header.nav.training}
          onClickCallback={handleTrainingLinkClicked}
          isSingleLink={!isTrainingsAvailable}
        >
          {isTrainingsAvailable && isSubMenuOpen && (
            <Training setIsSubMenuOpen={setIsSubMenuOpen} />
          )}
        </MenuItem>
      )}

      {!!jobTypes.length && (
        <MenuItem
          isSubMenuOpen={isSubMenuOpen}
          setIsSubMenuOpen={setIsSubMenuOpen}
          title={isETH ? 'Partners' : header.nav.jobTypes}
        >
          {isSubMenuOpen && <JobTypes setIsSubMenuOpen={setIsSubMenuOpen} />}
        </MenuItem>
      )}

      <MenuItem
        isSubMenuOpen={isSubMenuOpen}
        setIsSubMenuOpen={setIsSubMenuOpen}
        title={header.nav.aboutUs}
      >
        {isSubMenuOpen && <Company setIsSubMenuOpen={setIsSubMenuOpen} />}
      </MenuItem>
    </Group>
  );
};

export default Nav;
