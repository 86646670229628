import React, { useCallback, useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import { SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { useOnClickOutside } from 'hooks';
import { currentLang$, selectLang } from 'models/language';
import { setLanguageFlag } from 'utils/setLanguageFlag';

import style from './selectLanguage.module.scss';

export const SelectLanguage = () => {
  const settings = useContext(SettingsContext);

  const currentLangState = useStore(currentLang$);

  const wrapperRef = useRef(null);

  const [openMenu, setOpenMenu] = useState(false);

  useOnClickOutside(wrapperRef, () => setOpenMenu(false));

  const handleChangeLanguage = useCallback(
    (flag) => {
      selectLang(flag);
      setOpenMenu(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLangState],
  );

  return (
    <div ref={wrapperRef} className={style.selectLanguageBtn}>
      <div
        className={style.activeLanguage}
        onClick={() => setOpenMenu(!openMenu)}
        data-testid="language-selector"
      >
        {setLanguageFlag(currentLangState)}
      </div>
      {settings.supportedLanguages && openMenu && (
        <div className={clsx(style.country, openMenu && style.open)}>
          {settings.supportedLanguages
            .filter((el) => el !== currentLangState)
            .map((el) => (
              <div
                key={el}
                className={style.flag}
                title={el}
                onClick={() => handleChangeLanguage(el)}
                data-testid={`${el}-language`}
              >
                {setLanguageFlag(el)}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
