export const customer = {
  titles: {
    quotes: 'Quotes',
    yourRecentQuotes: 'Your recent and past quotes',
    orders: 'Orders',
    yourRecentOrders: 'Your recent and past orders',
  },

  tabs: {
    recent: 'Recent',
    past: 'Past',
  },

  items: {
    quote: 'Quote',
    order: 'Ordine',
    totalPrice: 'Total Price',
    hireDates: 'Hire Dates',
    dateSubmited: 'Date Submited',
    requestSummary: 'Request Summary',
    hireDites: 'Hire dates',
    deliveryMethod: 'Delivery Method',
    deliveryAddress: 'Delivery Address',
    selfCollectionDepot: 'Self-Collection Depot',
    products: 'products',
    estimateTotal: 'Estimate Total',
    completeCheckout: 'Complete checkout',
    viewInvoice: 'View Index',
    askQuestion: 'Ask Question',
    selfCollection: 'Self-collection',
    delivery: 'Delivery & Collection Address',
  },

  account: {
    userType: 'User Type',
    individual: 'Individual',
    company: 'Company',
    authentication: 'Authentication',
    changeEmail: 'Change Email',
    changePassword: 'Change Password',
  },

  notification: {
    quotes: 'Quotes',
    orders: 'Orders',
    supportChat: 'Support Chat',
    quoteIsReady: 'Quote is ready for review',
    emailNotificationWhen:
      'Email notification when your quote request is processed and ready for review',
    paymentFailed: 'Payment failed',
    emailNotificatonIfOrder: 'Email notificaton if orders payment is failed.',
    newChatMessage: 'New chat message',
    emailNotificatonIfThere:
      'Email notificaton if there is a new chat message while you are logged out',
  },
};
