export const footer = {
  titles: {
    supportedRegions: 'Regiones operativas',
  },

  copyright: 'Copyright © {0} {1}. Todos los derechos reservados.',
  termsConditions: 'Términos y condiciones',
  cookiePolicy: 'Política de cookies',
  generalTerms: 'Condiciones generales',
  privacyPolicy: 'Política',

  instantQuote: 'Presupuesto instantáneo',
  training: 'Formaciones',
  information: 'Información',
  aboutUs: 'Sobre Nosotros',
  blog: 'Blog',
  faq: 'Preguntas Frecuentes',
  securePayments: 'Pago seguro',

  thisWebsite: 'This website is Powered by',
  rentuuOperates:
    'easyHire Technologies Ltd operates a global, fast-growing, cloud-based technology platform and franchise network for the multi-billion pound sterling equipment hire industry.',
  learnMoreEasyFamilyOfBrands:
    'Visita {0} y descubre más sobre la familia de marcas easy.',
  learnMoreEasyHistory: 'Visita {0} y descubre la historia de la marca.',

  followUs: 'Síguenos',
};
