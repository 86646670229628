export const success = {
  title: 'Your order has been placed successfully',
  thankYouTitle: 'Thank you for your order!',
  thankYouTextGuest:
    'We are working on your order and will keep you updated. You will receive by email your order details and a link to activate your trade account.',
  thankYouText:
    'We are working on your order and will keep you updated. Please, check your email and follow further instructions.',
  pleaseVerify: 'Please Verify Your Identification and Proof of Address Online',
  youCanAlso: 'You can also provide your ID and proof of address in-person.',
  additionalInfo:
    'Upon successful trade account activation, you’ll be able to track your order, edit your profile details and manage delivery addresses directly from our website.',
  goHome: 'Go to Homepage',
  courseSectionTitle: "Don't forget we also offer training courses",
  blogSectionTitle: 'Check out {0} blog',
  insuranceWithCreditAccountWorking:
    'We are working hard to finalise your order and will keep you updated on its progress.',
  insuranceWithCreditAccount:
    'If you do not have an {0} trade account, please check your inbox for an email that details your order and contains a credit form. Please sign the credit form and email the completed form back to the {0} team.',
};
