export const verification = {
  title: 'It’s Almost Finished!',
  subTitle: 'We Need You to Verify Identity - It’s Easy and Safe!',
  orderCompletedText:
    'Your order {0} is almost completed. Please continue with ID verification.',
  warning1:
    'Until you receive a confirmation of hire via SMS/email {0} of your equipment',
  warning2: 'please do not travel to our depot or expect delivery',
  firstItemTitle:
    'Choose your valid photo Identification document and take a picture. You will only need one of the following:',
  secondItemTitle: 'Take a selfie',
  thirdItemTitle:
    'Select and submit your proof of address document. You will only need one of the following:',

  laterScreen: {
    warning:
      'ID must be verified online before delivery or collection to confirm your hire. Failing to do so will result in your hire being cancelled and a cancellation fee being applied',
    title: 'Ok, We Have Sent You Verification Link',
    text: 'We have sent you verification link on your email {0}. You can get verified at your convenience',
  },

  doneScreen: {
    title: 'You’ve been verified',
    text: 'We’ve already completed your ID check. No need for us to reverify your identity. Your order will be processed as normal.',
  },

  verify: 'Verify Now',
  later: 'Later',
  backToHome: 'Back To Home',
};
