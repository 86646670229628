import React, { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { lazyRetry } from 'utils/chankLoadingHandler';

import WithSuspense from 'features/common/WithSuspense';

const ResetFormEmailWrapper = lazy(() =>
  lazyRetry(
    () => import('features/common/ResetFormEmailWrapper'),
    'ResetFormEmailWrapper',
  ),
);
const SingUpWithEmailSent = lazy(() =>
  lazyRetry(
    () => import('features/common/SingUpWithEmailSent'),
    'SingUpWithEmailSent',
  ),
);
const EmailVerification = lazy(() =>
  lazyRetry(() => import('./EmailVerification'), 'EmailVerification'),
);
const LoginWrapper = lazy(() =>
  lazyRetry(() => import('./LoginWrapper'), 'LoginWrapper'),
);
const Profile = lazy(() => lazyRetry(() => import('./Profile'), 'Profile'));
const ResetFormPassword = lazy(() =>
  lazyRetry(() => import('./ResetFormPasswordWrapper'), 'ResetFormPassword'),
);
const AlreadyRegistered = lazy(() =>
  lazyRetry(() => import('./AlreadyRegistered'), 'AlreadyRegistered'),
);
const ForgotForm = (props) => (
  <ResetFormEmailWrapper {...props} isLinkOnLoginPage />
);

export const profileRoutes = [
  {
    path: PATHS.PROFILE_FORGOT,
    exact: true,
    component: () => WithSuspense(ForgotForm),
  },
  {
    path: PATHS.PROFILE_LOGIN,
    exact: true,
    component: () => WithSuspense(LoginWrapper),
  },
  {
    path: PATHS.PROFILE_REGISTRATION,
    exact: false,
    component: () => WithSuspense(SingUpWithEmailSent),
  },
  {
    path: [
      PATHS.PROFILE,
      PATHS.PROFILE_ORDERS,
      PATHS.PROFILE_ORDER(':number'),
      PATHS.PROFILE_INVOICES,
    ],
    component: () => WithSuspense(Profile),
  },
  {
    path: PATHS.PROFILE_RESET,
    exact: true,
    component: () => WithSuspense(ResetFormPassword),
  },
  {
    path: PATHS.ACCOUNT_ACTIVATION,
    exact: true,
    component: () => WithSuspense(ResetFormPassword),
  },
  {
    path: PATHS.EMAIL_VERIFICATION,
    exact: true,
    component: () => WithSuspense(EmailVerification),
  },
  {
    path: PATHS.EMAIL_REGISTERED,
    exact: true,
    component: () => WithSuspense(AlreadyRegistered),
  },
];
