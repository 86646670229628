export const error = {
  header: '404 Página Não Encontrada',
  sorry:
    'Lamentamos, mas não conseguimos encontrar a página que procura. Você ainda pode encontrá-lo usando a pesquisa, seguindo os links abaixo ou visitando nosso {0}.',
  link: 'Pagina inicial',
  callOrWrite: 'Não hesite em nos ligar {0} ou {1} – é grátis!',
  popularCategories: 'Categorias populares',
  browseFullCatalogue: 'Navegar no Catálogo Completo',
  checkoutOurBlog: 'Confira nosso blog',
  goToBlogHomepage: 'Ir para a página inicial do blog',
  critical: {
    title: 'Ops... Algo deu errado',
    subTitle: 'Estamos trabalhando para resolver o problema!',
    text: '{0} em caso de dúvidas.',
    sendEmail: 'Envie-nos um e-mail',
    refreshAndTryAgain: 'Recarregue a página e tente novamente',
    refreshThePage: 'Recarregar página',
  },
};
