import { REACT_APP_PREFIX } from 'consts';
import { combine, createEvent, createStore, restore } from 'effector';
import { persist } from 'effector-storage/local';
import { currProductLocalized$ } from 'models/product';
import { getProductWithActualPrices } from 'utils/getProductWithActualPrices';

import { $userInfo } from '../user';

export const setFixedReservationBannerVisible = createEvent();

export const isFixedReservationBannerVisible$ = createStore(false).on(
  setFixedReservationBannerVisible,
  (_, p) => p,
);

export const setIsFreshChatWasLoaded = createEvent();

export const isFreshChatWasLoaded$ = createStore(false).on(
  setIsFreshChatWasLoaded,
  (_, p) => p,
);

export const setShowFreshChatBtn = createEvent();

export const showFreshChatBtn$ = restore(setShowFreshChatBtn, true);

export const setIsPageWithNoSearchResultsOpened = createEvent();

export const isPageWithNoSearchResultsOpened$ = createStore(null).on(
  setIsPageWithNoSearchResultsOpened,
  (_, p) => p,
);

export const recentlyViewedProducts$ = createStore([]).on(
  currProductLocalized$.updates,
  (s, p) => {
    if (p?.id) {
      const recentlyViewedProducts = s.filter((el) => el.id !== p.id);

      recentlyViewedProducts.unshift(p);

      return recentlyViewedProducts.slice(0, 15);
    }
  },
);

persist({
  store: recentlyViewedProducts$,
  key: `recentlyViewedProducts_${REACT_APP_PREFIX}`,
});

export const actualRecentlyViewedProducts$ = combine(
  recentlyViewedProducts$,
  $userInfo,
  (products, user) => {
    if (!products?.length) {
      return [];
    }

    const userDiscount = user.tier?.discount;

    if (!userDiscount) {
      return products;
    }

    return products.map((product) =>
      getProductWithActualPrices(product, userDiscount),
    );
  },
);
