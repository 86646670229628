import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { sendFRLeadToCRM } from 'analytics';
import clsx from 'clsx';
import { BrandingContext, TrainingContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel, sendGAEvent } from 'googleAnalytics';
import { Lang } from 'lang';
import { territoryID$ } from 'models/common';
import { currentTraining$, currentTrainingLocalized$ } from 'models/trainings';
import { $UTMData } from 'models/UTMData';
import { createLead } from 'utils/createLead';
import { scrollToTop } from 'utils/scrollToTop';
import { validateEmailReg } from 'utils/validationEmail';

import PhoneInputWithCountry from 'features/common/PhoneInputWithCountryInForm';
import { AgreementFormButtons } from 'features/trainings/AgreementFormButtons';

import { TRAININGS } from 'api';

import SuccessEnquiryMessage from './SuccessEnquiryMessage';
import { setTrainingEnquiryFormData } from 'models/trainingEnquiryFormData';
import { Button, Field, inputStyle, T8y } from 'ui';

import { ReactComponent as IconName } from 'assets/images/icon/icons8-name.svg';
import { ReactComponent as IconPhone } from 'assets/images/icon/icons8-phone.svg';
import { ReactComponent as IconMail } from 'assets/images/icon/icons8-secured_letter.svg';

import style from './requestTrainingForm.module.scss';

const RequestTrainingForm = () => {
  const { form, training, sidebar, footer, formatString } = Lang();

  const branding = useContext(BrandingContext);
  const { formRef } = useContext(TrainingContext);

  const currentTraining = useStore(currentTraining$);
  const currentTrainingLocalized = useStore(currentTrainingLocalized$);
  const UTMData = useStore($UTMData);
  const territoryID = useStore(territoryID$);

  const [isCheckBoxInfo, setIsCheckBoxInfo] = useState(false);
  const [isEnquirySent, setIsEnquirySent] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const { register, handleSubmit, errors, watch, setValue, control } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    register({ name: 'subscription' });
    setValue('subscription', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (isEnquirySent) {
      setIsEnquirySent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTraining]);

  const values = watch();

  const isValidDates = useMemo(() => {
    return (
      !!currentTrainingLocalized?.schedules.length &&
      currentTrainingLocalized.schedules.some((el) =>
        el.variations.some((el) => !el.isOld),
      )
    );
  }, [currentTrainingLocalized]);

  const handleFinishSubscription = useCallback(
    (data) => {
      setIsEnquirySent(true);
      scrollToTop(formRef?.current?.offsetTop - 110);

      if (isValidDates) {
        setTrainingEnquiryFormData({
          ...data,
          ...currentTrainingLocalized,
        });
      }
    },
    [currentTrainingLocalized, formRef, isValidDates],
  );

  const sendAnalytics = useCallback((finalData) => {
    try {
      sendFRLeadToCRM({
        data: finalData,
        action: 'Send request form on trainings',
      });
    } catch (e) {
      console.warn(e);
    }
  }, []);

  const subscription = watch('subscription');

  const sendLead = useCallback(
    (data) => {
      const leadData = {
        [form.fields.fullName]: data.name,
        [form.fields.emailAddress]: data.email,
        [form.fields.PhoneNumber]: data.phone,
        [`checkbox_${sidebar.iAgree} ${footer.termsConditions}`]:
          isCheckBoxInfo,
        [`checkbox_${formatString(
          form.titles.checkBoxIWish,
          branding.companyName,
        )}`]: subscription,
      };

      createLead(
        {
          ...data,
          course_name: currentTrainingLocalized.title,
          franchisee_id: currentTrainingLocalized.franchiseeId,
          trainingId: currentTrainingLocalized.id,
          ...(UTMData && { utm: { ...UTMData } }),
        },
        leadData,
        'trainingEnquiry',
        null,
        (data) => {
          sendGAEvent('training_finish_enquiry', {
            territory_id: territoryID,
            cold_lead_id: data.lead_id,
          });
        },
      );
    },
    [
      territoryID,
      UTMData,
      branding.companyName,
      currentTrainingLocalized,
      footer.termsConditions,
      form,
      formatString,
      isCheckBoxInfo,
      sidebar.iAgree,
      subscription,
    ],
  );

  const onSubmit = useCallback(
    async (data) => {
      setIsSendingRequest(true);
      sendLead(data);

      try {
        const finalData = {
          ...data,
          course_name: currentTrainingLocalized.title,
        };

        await TRAININGS.signUpTraining(finalData);

        sendAnalytics(finalData);
        setIsCheckBoxInfo(false);
        toast.success(form.success.regionEmail);
        handleFinishSubscription(data);
      } catch (e) {
        console.warn(e);
        toast.error(form.errors.regionEmail);
        sendGAEvent('error-something-went-wrong-form');
      } finally {
        setIsSendingRequest(false);
      }
    },
    [
      sendLead,
      currentTrainingLocalized,
      sendAnalytics,
      form,
      handleFinishSubscription,
    ],
  );

  return (
    <div className={style.wrapper} id="requestTrainingForm">
      {isEnquirySent ? (
        <SuccessEnquiryMessage isValidDates={isValidDates} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
          <T8y className={style.formTitle} variant="t1" center bold mb3>
            {isValidDates
              ? training.titles.fillInTheForm
              : training.titles.haveAnyQuestions}
          </T8y>
          <Field
            legend={form.fields.fullName}
            icon={IconName}
            className={style.formField}
          >
            <input
              name="name"
              ref={register({
                validate: (value) => !!value.trim() || form.validation.required,
              })}
              placeholder={form.fields.fullName}
              className={inputStyle}
              autoComplete="shipping name"
              onBlur={() => {
                sendEventWithEventLabel(
                  'training_form_name',
                  values.name || '',
                );
              }}
            />
          </Field>
          <T8y variant="t4" color="danger" as="p" className={style.error}>
            {errors.name && errors.name.message}
          </T8y>

          <Field
            legend={form.fields.emailAddress}
            icon={IconMail}
            className={style.formField}
          >
            <input
              name="email"
              type="email"
              ref={register({
                required: form.validation.required,
                pattern: {
                  value: validateEmailReg,
                  message: form.validation.emailPattern,
                },
              })}
              onBlur={() => {
                sendEventWithEventLabel(
                  'training_form_email',
                  values.email || '',
                );
              }}
              placeholder={form.fields.emailAddress}
              className={inputStyle}
              autoComplete="email"
            />
          </Field>
          <T8y variant="t4" color="danger" as="p" className={style.error}>
            {errors.email && errors.email.message}
          </T8y>

          <Field
            legend={form.fields.PhoneNumber}
            icon={IconPhone}
            className={style.formField}
          >
            <PhoneInputWithCountry
              name="phone"
              className={clsx(inputStyle, style.requestTrainingPhoneWrapper)}
              onBlur={() => {
                sendEventWithEventLabel(
                  'training_form_number',
                  values.phone || '',
                );
              }}
              placeholder={form.fields.PhoneNumber}
              control={control}
            />
          </Field>
          <T8y variant="t4" color="danger" as="p" className={style.error}>
            {errors.phone && errors.phone.message}
          </T8y>

          <Field
            legend={form.fields.message + ' (' + form.optionalField + ')'}
            className={style.formField}
          >
            <textarea
              ref={register}
              name="message"
              rows="2"
              placeholder={form.fields.yourCommentsOrQuestions}
              className={style.message}
              onBlur={() => {
                sendEventWithEventLabel(
                  'training_form_message',
                  values.message || '',
                );
              }}
            />
          </Field>

          <AgreementFormButtons
            isCheckBoxInfo={isCheckBoxInfo}
            setIsCheckBoxInfo={setIsCheckBoxInfo}
            isSubscription={values?.subscription}
            setIsSubscription={(val) => setValue('subscription', val)}
          />

          <Button
            className={style.trainingFormSubmitButton}
            type="submit"
            onClick={isSendingRequest ? undefined : handleSubmit}
            loading={isSendingRequest}
            disabled={!isCheckBoxInfo || isSendingRequest}
            data-testid="course-btn-singUp"
            isNoStretch
          >
            {form.buttons.makeReservation}
          </Button>
        </form>
      )}
    </div>
  );
};

export default RequestTrainingForm;
