export const success = {
  title: 'Tu solicitud se ha realizado con éxito',
  thankYouTitle: '¡Gracias por tu pedido!',
  thankYouTextGuest:
    'Hemos recibido tu pedido y te contactaremos a la brevedad. Recibirás por correo electrónico los detalles de tu pedido y un enlace para activar tu cuenta.',
  thankYouText:
    'Estamos trabajando en tu solicitud, te mantendremos informado del seguimiento. Por favor, revisa tu correo electrónico y sigue las instrucciones.',
  pleaseVerify:
    'Por favor verifica tu identidad y prueba de domicilio en línea',
  youCanAlso:
    'También puedes verificar tu identificación y prueba de domicilio en persona',
  additionalInfo:
    'Tras la activación exitosa de tu cuenta, podrás realizar un seguimiento de tus pedidos, editar los detalles de tu perfil y administrar las direcciones de entrega directamente desde nuestro sitio web.',
  goHome: 'Ve a la página de inicio',
  courseSectionTitle: 'Recuerda que también ofrecemos cursos de formación',
  blogSectionTitle: 'Visita el blog de {0}',
  insuranceWithCreditAccountWorking:
    'We are working hard to finalise your order and will keep you updated on its progress.',
  insuranceWithCreditAccount:
    'If you do not have an {0} trade account, please check your inbox for an email that details your order and contains a credit form. Please sign the credit form and email the completed form back to the {0} team.',
};
