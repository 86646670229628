export const dashboard = {
  header: 'Benvenuto',
  subHeader: 'Questo è il tuo pannello di controllo',

  getQuote: 'Richiedi un preventivo',
  viewOrder: 'I tuoi ordini',
  support: "Contatta l'Assistenza",
  allProducts: 'Tutti i prodotti',

  titles: {
    quickStart: 'Avvio veloce',
    recentQuotes: 'Preventivi recenti',
    recentOrders: 'Ordini recenti',
    onHireOrders: 'Ordini in Corso',
    popularCategories: 'Categorie più richieste',
    noQuotes:
      'Nessun preventivo da visualizzare. Sfoglia il nostro {0} per trovare quello che ti serve.',
    noOrders:
      'Nessun ordine da visualizzare. Sfoglia il nostro {0} per trovare quello che ti serve.',
    catalog: 'Catalogo',
    popularProducts: 'Prodotti Popolari',
    benefitsCustomer: 'Vantaggi del Trade Account',
  },

  table: {
    estimateCellHeader: 'Totale stimato',
    deliveryCellHeader: 'Data di consegna',
    collectionCellHeader: 'Data di ritiro',
  },

  quotesTable: {
    quoteCellHeader: 'Preventivo #',
    estimateCellHeader: 'Totale stimato',
    deliveryCellHeader: 'Data di consegna',
    collectionCellHeader: 'Data di ritiro',
    btnText: 'Vedi preventivo',
  },

  ordersTable: {
    orderCellHeader: 'Ordine #',
    priceCellHeader: 'Prezzo Totale:',
    deliveryCellHeader: 'Delivery date',
    collectionCellHeader: 'Collection date',
    statusCellHeader: 'Stato',
    btnText: 'Vedi Fattura',
    dateCreated: 'Data di creazione',
    grandTotal: 'Grand Totale',
    partnership: 'Sviluppato il collaborazione con',
  },

  discountText: 'Il tuo sconto è valido su tutto il catalogo',

  discountTypes: {
    platinum: 'Platinum',
    gold: 'Gold',
    silver: 'Silver',
    top: 'Top',
  },

  discountPortalTypes: {
    platinum: 'Sconto Platinum',
    gold: 'Sconto Gold',
    silver: 'Sconto Silver',
    top: 'Sconto Top',
  },

  dashboardButtons: {
    newOrder: 'Nuovo Ordine',
    copyPrevOrder: 'Copia ordine precedente',
    newUser: 'Aggiungere nuovo utente',
    supportChat: 'Chatta Con Noi',
    reorder: 'Riordina',
    cancelOrder: 'Cancella ordine',
    cancelRequestSent: 'Richiesta cancellazione inviata',
    changeEndDate: 'Modifica data finale',
  },
};
