import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import {
  cartFormattedData$,
  deliveryMethod$,
  details$,
  focusedUserTypeInputStatus$,
} from 'models/cart';
import { $isUserLoggedIn } from 'models/user';
import { $UTMData } from 'models/UTMData';
import { createLead } from 'utils/createLead';

export const useSendStartedCheckoutCRM = (orderLink, isInfoValid) => {
  const { form } = Lang();

  const UTMData = useStore($UTMData);
  const details = useStore(details$);
  const cartFormattedData = useStore(cartFormattedData$);
  const focusedUserTypeInputStatus = useStore(focusedUserTypeInputStatus$);
  const isUserLoggedIn = useStore($isUserLoggedIn);
  const { method } = useStore(deliveryMethod$);

  const [lastSentEmailToCRM, setLastSentEmailToCRM] = useState(null);

  const isSomeInputFocused = useMemo(() => {
    return Boolean(
      Object.values(focusedUserTypeInputStatus).find((el) => !!el),
    );
  }, [focusedUserTypeInputStatus]);

  const productIds = useMemo(() => {
    return cartFormattedData.map((el) => el.id);
  }, [cartFormattedData]);

  const sendDataToCRM = useCallback(() => {
    const leadData = {
      [form.fields.fullName]: details.name,
      [form.fields.emailAddress]: details.email,
      [form.fields.PhoneNumber]: details.phone,
    };

    createLead(
      {
        name: details.name,
        email: details.email,
        phone: details.phone,
        tax_number:
          details.type !== 'company' && details.fiscalCode
            ? details.fiscalCode
            : undefined,
        vat_number:
          details.type === 'individual'
            ? undefined
            : details.vat_number || undefined,
        cart_url: orderLink || undefined,
        type: details.type,
        customerHasAccount: isUserLoggedIn,
        productIds,
        ...(UTMData && { utm: { ...UTMData } }),
      },
      leadData,
      'checkoutStarted',
    );
    setLastSentEmailToCRM(details.email);
  }, [details, orderLink, UTMData, form, productIds, isUserLoggedIn]);

  const sendCrmEvent = useCallback(() => {
    if (!isSomeInputFocused && lastSentEmailToCRM !== details.email) {
      sendDataToCRM();
    }
  }, [isSomeInputFocused, lastSentEmailToCRM, details, sendDataToCRM]);

  useEffect(() => {
    if (isInfoValid && orderLink && details.email && method) {
      sendCrmEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.email, isInfoValid, isSomeInputFocused, orderLink, method]);
};
