export const training = {
  seoTitle: 'Cursos de treinamento',

  checkout: {
    mainTitle: 'Reserva de Curso de Treinamento',
    numberOfSeatsTitle: 'Número de assentos',
    datesTitle: 'Data da Reserva',
    contactTitle: 'Detalhes de contato',
    paymentTitle: 'Forma de pagamento',
    enterDetails: 'Insira detalhes de contato',
    maxNumberOfSeatsMessage:
      'Quer reservar para 5 ou mais pessoas? É uma empresa que pretende formar o seu pessoal? Contacte-nos para saber a melhor oferta para você!',
  },
  titles: {
    signUpFor: 'Obtenha a certificação através da academia de treinamento easy',
    closestDate: 'Data mais próxima',
    gallery: 'Galeria de Cursos',
    schedule: 'Cursos agendados',
    learning: 'O que você vai aprender',
    advantages: 'Porque escolher-nos',
    about: 'Sobre o Certificado',
    reviews: 'Avaliações do curso',
    questions: 'Perguntas Frequentes',
    other: 'Outros cursos',
    description: 'Descrição do Curso',
    haveAnyQuestions: 'Tem alguma pergunta? Ficaremos felizes em ajudar!',
    fillInTheForm: 'Preencha o formulário e retornaremos com mais detalhess',
    partners: 'O curso está disponível em colaboração com',
    exploreOurCourses: 'Explore nossos cursos de treinamento',
    recommendedProducts: 'Produtos Recomendados',
  },
  emptyDates: 'Datas a confirmar',
  emptyDate: 'Data a confirmar',
  emptyLocation: 'Organizador do Curso e Local(is) a confirmar',
  emptyPrice: 'Preço a confirmar',
  emptyTheory: 'Horas de Teoria e Prática a confirmar',
  noSchedule:
    'A programação está disponível mediante solicitação. Normalmente temos 4-5 cursos por mês. Por favor, use o formulário acima para fazer uma reserva e entraremos em contato com você em breve.',
  perSeat: 'por pessoa',
};
