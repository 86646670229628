export const about = {
  brands: {
    title: 'Valori del Brand',
    sections: {
      greatValue: 'Great Value',
      bigBoys: 'Taking on the big boys',
      forTheMany: 'For the many not the few',
      relentlessInnovation: 'Relentless innovation',
      keepItSimple: 'Keep it simple',
      entrepreneurial: 'Entrepreneurial',
      makingTheDifferencePeopleLives: 'Making the difference in people’s lives',
      caringAndFun: 'Honest, Open, Caring and Fun',
    },
  },

  depots: {
    title: 'Abbiamo diverse filiali in Regione',
    description:
      'Scegli “Ritiro” al momento del pagamento e vieni in una delle nostre sedi - il servizio di ritiro è gratuito!', // in main page
    showMoreDepots: 'Mostra più filiali',
    showLessDepots: 'Mostra meno filiali',
  },

  easyLinkText:
    '{0}® fa parte della famiglia di marchi {1}®. Per ulteriori informazioni, visita {2} e scopri di più sulla famiglia di marchi easy.',
  easyHistoryLinkText:
    '{0}® fa parte della famiglia di marchi {1}®. Per ulteriori informazioni, visita {2} e scopri la storia del marchio.',

  vision: 'La Missione di {0}',
  representativeIn: '{0} - {1} rappresentante nella regione {2}',
  about: '{0}',
  contactUsTitle: 'Contattaci',
  history: 'Storia della easy family of brands',
  representativeInRegion: '{0} rappresentanti nella regione {1}',
};
