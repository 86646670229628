export const header = {
  region: {
    region: 'Regioni',
    areYouIn: 'Ti trovi in',
    yes: 'Si',
    no: 'No, scegli',
    checkOther: 'Queste sono le regioni in cui siamo operativi',

    serviceAvailability: 'Disponibilità del servizio',
    weWillStart: 'Offriremo il servizio nella tua regione molto presto!',
    meanwhileFeel: 'Nel frattempo, puoi guardare il nostro catalogo in:',
    orLeaveYour:
      'Oppure puoi lasciarci il tuo contatto, e ti informeremo quando il servizio sarà attivo nella tua regione',
    weDetected: 'Abbiamo notato che ti trovi in',
    wouldYouLike: 'Vuoi modificare la regione?',
    isNotAvailable: 'non è ancora disponibile nella tua regione.',
    okUse: 'ok, usa',
    submit: 'Richiedi',
  },

  getAQuote: 'Ricevi Preventivo',
  allProducts: 'Catalogo',

  byUsingThis:
    "Proseguendo la navigazione acconsenti all'utilizzo dei cookies.",
  cookieBtn: 'Accetta',

  backTo: 'Indietro',

  nav: {
    back: 'Indietro',
    backToPreviousPage: 'Vai alla pagina precedente',
    home: 'Home',
    catalog: 'Catalogo',
    hireByCategory: 'Noleggio',
    company: 'Gruppo',
    training: 'Formazione',
    aboutUs: 'Chi siamo',
    blog: 'Blog',
    logIn: 'Accesso',
    logOut: 'Esci',
    customerPortal: 'Customer Portal',
    reservation: 'La Tua Prenotazione',
    faq: 'Tutte le FAQ',
    carbonOffsetting: 'Carbon offsetting',
    menuLanguage: 'Italiano',
    signAs: 'Accesso effettuato come',
    maintenance: 'Manutenzione',
    otherServices: 'Altri servizi',
    jobTypes: 'Progetti',
    findHowWeAre: 'Su di noi',
    searchResults: 'Risultati di ricerca',
    breadcrumbRegion: 'Regione',
  },

  bar: {
    customize: 'Personalizzazione',
    depots: 'Filiali',
    allDepots: 'Tutte le filiali',
    seeAllDepots: 'Vedere tutte le filiali',
    allDepotsTitle: 'Gli indirizzi delle nostre filiali nella {0}',
    choose: 'Scegli la filiale a te più conveniente',
    yourChoice:
      "La tua scelta applicherà un filtro al nostro inventario. Per navigare l'intero catalogo, sezleziona tutte le filiali",
    regionDepots: 'Regioni e Filiali',
    yourRegion: 'La tua Regione',
    selectedRegion: 'Regione selezionata',
    region: 'Regioni',
    selectRegion: 'Scegli la regione',
    selectDepots: 'Scegli la filiale',
    back: 'Indietro',
    isCommitted: '{0} si impegna per il noleggio sostenibile {1}',
    learnMore: 'Scopri di più',
  },

  callModal: {
    title: 'Miglior Prezzo Online!',
    text: 'Per un prezzo migliore, crea il tuo preventivo online o effettua il tuo ordine online.',
    closeBtnText: 'Ok, lo farò online',
    subText:
      'Se vuoi ancora chiamarci, ecco il numero. Ma ricorda, con il processo online puoi risparmiare!',
  },
};
