import { getDateFromString, getDateInFormat } from './datesСalculation';

export const getProductsForGoogleAnalytics = (products) => {
  if (!products || !products.length) {
    return [];
  }

  return products.map((el) => ({
    id: el.product.id,
    name: el.product.name,
    category: el.product.category && el.product.category.name,
    price:
      el.product.prices && el.product.prices.length
        ? Math.min(...el.product.prices.map((el) => Number(el.price)))
        : 0,
  }));
};

export const getFormattedProductsForTotalPriceRequest = ({
  id,
  count: quantity,
  reqExtra,
  optionalExtra,
  optionalSaleExtra,
  returnDirty,
  consumables,
  dates,
  extraConsumablesEnabled,
}) => {
  let attributes = [reqExtra?.id, optionalExtra?.id, optionalSaleExtra?.id]
    .filter(Boolean)
    .map((e) => ({ id: e, quantity }));

  if (reqExtra?.length) {
    attributes = attributes.concat(
      reqExtra.map((el) => ({ id: el.id, quantity: el.quantity })),
    );
  }

  if (optionalExtra?.length) {
    attributes = attributes.concat(
      optionalExtra.map((el) => ({ id: el.id, quantity: el.quantity })),
    );
  }

  if (optionalSaleExtra?.length) {
    attributes = attributes.concat(
      optionalSaleExtra.map((el) => ({
        id: el.id,
        quantity: el.quantity,
      })),
    );
  }

  if (extraConsumablesEnabled && consumables?.length) {
    attributes = attributes.concat(
      consumables.map((el) => ({ id: el.id, quantity: el.quantity })),
    );
  }

  const template = 'yyyy-MM-dd';

  return {
    id,
    quantity,
    start_date:
      dates && getDateInFormat(getDateFromString(dates.start), template),
    end_date: dates && getDateInFormat(getDateFromString(dates.end), template),
    is_return_dirty: returnDirty,
    attributes,
  };
};

export const getSimilarExtraInTotalPrices = (
  similarProductInTotalPrices,
  extra,
) => {
  return (
    similarProductInTotalPrices?.attributes.find(
      (attr) => attr.id === extra.id,
    ) || {}
  );
};
