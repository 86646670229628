import React from 'react';
import { NavLink } from 'react-router-dom';
import { PATHS } from 'AppPaths';

import { Row, T8y } from 'ui';

import { ReactComponent as Arrow } from 'assets/images/icon/arrowRight.svg';

import style from 'layout/Header/header.module.scss';

const SubCategory = ({ item, sendCategoryClicked }) => {
  return (
    <Row
      as={NavLink}
      to={{
        pathname: PATHS.CATEGORY(item.slug),
        presetType: 'category',
      }}
      onClick={sendCategoryClicked}
      justify="stretch"
      align="center"
      noWrap
      fullWidth
      className={style.listItem}
    >
      <T8y variant="t1v1" className={style.listItemSubCatName}>
        {item.name}
      </T8y>
      {!!item.childs?.length && <Arrow className={style.subArrow} />}
    </Row>
  );
};

export default SubCategory;
