import React, { useContext } from 'react';
import clsx from 'clsx';
import { SMALL_SCREEN } from 'consts';
import { BrandingContext } from 'contexts';
import { useStore } from 'effector-react';
import { cartAllItemsLength$ } from 'models/cart';

import { useMatchMedia } from 'libs/useMatchMedia';

import { ReactComponent as CartIcon } from 'assets/images/icon/basket.svg';

import style from './cartSign.module.scss';

const CartSing = () => {
  const branding = useContext(BrandingContext);
  const cartAllItemsLength = useStore(cartAllItemsLength$);

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  return (
    <>
      {!!cartAllItemsLength && (
        <div
          className={clsx(
            style.circle,
            branding.showPhoneIconInHeader && isSmallScreen
              ? style.circleWithPhone
              : style.circleWithNoPhone,
          )}
        >
          {cartAllItemsLength}
        </div>
      )}
      <CartIcon
        className={
          branding.showPhoneIconInHeader && isSmallScreen
            ? style.cartIconWithPhone
            : style.cartIcon
        }
      />
    </>
  );
};

export default CartSing;
