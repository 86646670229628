export const main = {
  h1: 'Il servizio di noleggio in Italia easy ed efficiente',
  h2: 'La nostra missione è di rendere facile, “easy” ed accessibile a tutti il processo del noleggio. Trova e noleggia quello di cui hai bisogno per il tuo progetto di rinnovamento, manutenzione, giardinaggio e tanto altro',

  howItWorks: {
    title: 'Come Funziona',
    sections: [
      {
        title: '1. Scegli i prodotti',
        description: 'Guarda il catalogo e scegli',
      },
      {
        title: '2. Verifica i dettagli',
        description: 'Riguarda il tuo ordine. controlla i prezzi',
      },
      {
        title: `3. Finalizza l'ordine in 4 passaggi`,
        description: 'Scegli modalità di consegna e pagamento',
      },
    ],
  },

  subscribeToNewsletter: {
    stayUpdated: 'Iscriviti alla nostra Newsletter',
    enterYourEmail:
      'Iscriviti alla newsletter di easyNoleggio per le ultime novità ed offerte del settore.',
    agreementText:
      'Inviando i tuoi dati accetti di essere contattato da {0} in conformità con la nostra Informativa sulla {1}',
    linkText: 'privacy',
    thankYouForSubscribing: 'Grazie per esserti iscritto!',
    signUp: 'Iscriviti',
  },

  titles: {
    eventTypes: 'Projects',
    certifiedPartner: '{0} è un partner certificato',
    proudToWork: 'Alcuni dei nostri clienti',
    familyOfBrands: 'Facciamo parte della famiglia easy insieme a',
    popularProducts: 'Prodotti popolari',
    frequentlyHired: 'Sono spesso noleggiati insieme',
    easyAndBudget: 'Il servizio di noleggio in Italia easy ed efficiente',
    ourMission:
      'La nostra missione è di rendere facile, "easy" ed accessibile a tutti il processo del noleggio. Trova e noleggia quello di cui hai bisogno per il tuo progetto di rinnovamento, manutenzione, giardinaggio e tanto altro',
    topCategories: 'Noleggio Categorie Principali',
    hireByCategory: 'Noleggio per settori',
    recentlyViewedProducts: 'Prodotti visti di recente',
  },

  notices: {
    noPopularProduct: 'Nessun prodotto popolare',
    newArrivals: 'Nuovi arrivi',
  },
};
