import React, { useContext } from 'react';
import { BrandingContext, SettingsContext } from 'contexts';
import { Lang } from 'lang';
import { getPriceRangeTextInTable } from 'utils/helpers/formatPrices';

import { T8y } from 'ui';

import style from '../priceTableBody.module.scss';

const MobileItem = ({ el, isShowOldPrice }) => {
  const { products } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  return (
    <div className={style.priceItem}>
      <T8y variant="t2v1" bold className={style.period}>
        {getPriceRangeTextInTable(el, products, settings.fixedRangesPrice)}
      </T8y>
      <span className={style.dots}>
        ..........................................................................................................................................
      </span>
      <div className={style.prices}>
        {isShowOldPrice && (
          <T8y variant="t4" color="midGray" className={style.oldPrice}>
            {branding.currencySymbol}
            {el.oldPrice ? parseFloat(el.oldPrice).toFixed(2) : 0}
          </T8y>
        )}
        <T8y variant="t2v1">
          {branding.currencySymbol}
          {el.price ? parseFloat(el.price).toFixed(2) : 0}
        </T8y>
      </div>
    </div>
  );
};

export default MobileItem;
