import { useContext, useMemo } from 'react';
import { SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { currentLang$ } from 'models/language';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

export const useGetLocalizedExtraFields = (extraFields = []) => {
  const settings = useContext(SettingsContext);
  const currentLangState = useStore(currentLang$);

  return useMemo(
    () =>
      extraFields.map((el) => ({
        ...el,
        title: getTranslatedFieldValue(
          el.titles,
          currentLangState,
          settings.defaultLanguage,
        ),
        placeholder: el.placeholders
          ? getTranslatedFieldValue(
              el.placeholders,
              currentLangState,
              settings.defaultLanguage,
            )
          : null,
      })),
    [settings, currentLangState, extraFields],
  );
};
