export const orders = {
  order: 'Pedido',
  orders: 'Pedidos',
  viewOrder: 'Ver pedido',
  viewInvoice: 'Ver fatura',
  viewQuote: 'Ver cotação',
  viewQuotes: 'View quotes',
  productList: 'Lista de produtos',
  youWantToCancelThisOrder: 'Tem certeza de que deseja cancelar este pedido?',
  provideCancellationReason: 'Se sim, informe o motivo do cancelamento',
  enterNewEndHireDateAndReason:
    'Por favor, informe-nos sobre a nova data de término da contratação e o motivo dessa mudança. Entraremos em contato em breve!',
  endHireDate: 'Nova data de término da contratação',
  provideReasonForThisChange: 'Informe o motivo dessa alteração',
  item: {
    orderStatus: 'Status do pedido',
    date: 'Data de criação do pedido',
    transport: 'Transporte',
    paymentType: 'Tipo de pagamento',
    productList: 'Lista de produtos',
    documents: 'Documentos',
  },

  table: {
    orderId: 'ID do pedido',
    orderNumber: 'Número do pedido',
    quoteNumber: 'Número de cotação',
    invoiceDate: 'Data da fatura',
    status: 'Status',
    payment: 'Pagamento',
    startDate: 'Início do aluguer',
    endDate: 'Fim do aluguer',
    purchaseOrderNumber: 'Número do Pedido de Compra',
  },

  paymentType: {
    card: 'Cartão de crédito',
    cash: 'Dinheiro',
    bank: 'Transferência bancária',
    other: 'Outro',
  },

  statusType: {
    error: 'Erro',
    paid: 'Pago',
    not_paid: 'Não pago',
    new: 'Novo',
    in_progress: 'Em andamento',
    completed: 'Concluído',
    cancelled: 'Cancelado',
    all: 'Todos os pedidos',
  },
  backTo: 'Voltar à lista de pedidos',
  backToQuotes: 'Voltar à listagem de cotações',
};
