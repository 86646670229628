import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { MOBILE_SCREEN } from 'consts';
import { BrandingContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import { useItemInCart } from 'hooks';
import { Lang } from 'lang';
import { isNewProductAdded$, setIsCartPopupVisible } from 'models/cart';
import AttributesModal from 'pages/product/AttributesModal';

import MobileCalendarModal from 'features/checkout/ProductListItem/DatesPanel/MobileCalendarModal';
import ProductQuantityChanger from 'features/common/ProductQuantityChanger';

import { useMatchMedia } from 'libs/useMatchMedia';

import DatePicker from '../DatePicker';

import { Row, Spinner, T8y } from 'ui';

import { ReactComponent as RemoveIcon } from 'assets/images/icon/cart/remove.svg';

import style from './productListItem.module.scss';

const ProductListItem = ({
  itemInCart,
  type,
  slug,
  image,
  typeText,
  quantity,
  isLoading,
  indexItem,
  price,
  totalPrice,
  isProductDisabled,
  isLastItem,
  extraTitle,
  datePickerOpenId,
  setDatePickerOpenId,
}) => {
  const { products, checkout } = Lang();

  const branding = useContext(BrandingContext);

  const currProductRef = useRef();
  const isNewProductAdded = useStore(isNewProductAdded$);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);

  const {
    productTitle,
    itemPriceText,
    productInfo,
    reqExtraList,
    optionalExtraList,
    changeExtraModalTitle,
    onDateChangeCallback,
    productInCartCount,
    minQuantity,
    handleChangeCount,
    isLimit,
    changeExtra,
    handleDelete,
  } = useItemInCart({
    itemInCart,
    type,
    quantity,
    isLoading,
    indexItem,
    isPopup: true,
  });

  const isFixedPriceItem =
    type && (type === 'consumables' || type === 'optionalSaleExtra');

  //scroll to the last item in cart
  useEffect(() => {
    if (isNewProductAdded && isLastItem && !type && currProductRef.current) {
      currProductRef.current.parentNode.scrollTop =
        currProductRef.current.offsetTop;
    }
  }, [isLastItem, type, isNewProductAdded]);

  const showDatePicker =
    datePickerOpenId && datePickerOpenId === itemInCart.product?.id;

  const handleChangeDates = useCallback(
    (dates) => {
      onDateChangeCallback(dates);
      setDatePickerOpenId(null);
    },
    [onDateChangeCallback, setDatePickerOpenId],
  );

  const daysText = useMemo(() => {
    const product = itemInCart.product;

    if (!product.min_period_days) {
      return '';
    }

    return `(${products.minHiringPeriod}: ${product.min_period_days} ${
      product.min_period_days === 1 ? products.day : products.days
    })`;
  }, [itemInCart.product, products]);

  return (
    <Row
      className={clsx(
        style.productListItemWrapper,
        isLastItem && isNewProductAdded && style.isNewProductAdded,
      )}
      ref={currProductRef}
      noWrap
    >
      <div className={style.imageWrapper}>
        <img
          src={
            image ||
            (type ? branding.defaultImageExtra : branding.defaultImageProduct)
          }
          alt={type ? extraTitle : productTitle}
        />
      </div>
      <Row smDirection="column" className={style.productListItemInfoWrapper}>
        <Row direction="column" fullWidth>
          <Row
            align="start"
            direction="column"
            fullWidth
            className={style.centralInfoWrapper}
          >
            {!type ? (
              <div className={style.productTitleRow}>
                <T8y
                  as={Link}
                  to={{
                    pathname: PATHS.PRODUCT(slug),
                    presetType: 'product',
                  }}
                  onClick={() => {
                    setIsCartPopupVisible(false);
                    sendGAEvent('reservation_popup_product_click');
                  }}
                  variant="t3"
                  color="primary"
                  linkOpposite
                  className={style.itemTitle}
                >
                  {productTitle}
                </T8y>
                <div
                  onClick={isProductDisabled ? undefined : handleDelete}
                  className={style.removeIconWrapper}
                  role="button"
                >
                  <RemoveIcon />
                </div>
              </div>
            ) : (
              <T8y variant="t3" color="primary" className={style.itemTitle}>
                {extraTitle}
              </T8y>
            )}

            <T8y variant="t3" className={style.priceWrapper}>
              {`${branding.currencySymbol}${parseFloat(price || 0).toFixed(2)}`}{' '}
              {isFixedPriceItem ? products.consumables.perItem : itemPriceText}
            </T8y>
          </Row>
          {type && (
            <Row className={style.typeTextWrapper}>
              <T8y as="span" variant="t3">
                <T8y as="span" variant="t3" bold>
                  {typeText}&nbsp;
                </T8y>
                <T8y as="span" variant="t3">
                  {checkout.for} {productTitle}&nbsp;
                </T8y>
                {type !== 'consumables' && (
                  <T8y
                    as="span"
                    variant="t3"
                    color={isProductDisabled ? 'gray' : 'primary'}
                    linkOpposite
                    onClick={() =>
                      isProductDisabled ? undefined : setIsModalOpen(true)
                    }
                    data-testid="change extras btn"
                  >
                    {checkout.change}...
                  </T8y>
                )}
              </T8y>
              {isModalOpen && (
                <AttributesModal
                  reqExtras={reqExtraList}
                  optionalExtras={optionalExtraList}
                  count={itemInCart.count || 0}
                  setExtras={changeExtra}
                  title={changeExtraModalTitle}
                  setIsOpen={setIsModalOpen}
                />
              )}
            </Row>
          )}
          {!isFixedPriceItem && (
            <>
              <T8y color="midGray" className={style.hiringPeriodText}>
                {products.hiringPeriod}
              </T8y>

              <Row align="center" className={style.datesWrapper}>
                <T8y variant="t3">{itemInCart.dates?.start}</T8y> -{' '}
                <T8y variant="t3">{itemInCart.dates?.end}</T8y>
                &nbsp;
                {!type && (
                  <>
                    <T8y
                      variant="t3"
                      color={isProductDisabled ? 'gray' : 'primary'}
                      linkOpposite
                      onClick={() => {
                        setDatePickerOpenId(itemInCart.product.id);
                      }}
                    >
                      {checkout.change}...
                    </T8y>

                    {showDatePicker && isMobile && (
                      <MobileCalendarModal
                        cartItem={itemInCart}
                        setIsCalendarModalOpen={() => setDatePickerOpenId(null)}
                        handleDatesChange={handleChangeDates}
                        daysText={daysText}
                      />
                    )}
                    {showDatePicker && !isMobile && (
                      <DatePicker
                        cartItem={itemInCart}
                        onChangeDates={handleChangeDates}
                        onCancel={() => setDatePickerOpenId(null)}
                      />
                    )}
                  </>
                )}
              </Row>
            </>
          )}
        </Row>

        <Row justify="stretch" align="center" fullWidth>
          <ProductQuantityChanger
            startCount={!type ? productInCartCount : quantity}
            maxQuantity={!type && productInfo.max_quantity}
            minQuantity={minQuantity}
            changeCount={handleChangeCount}
            isLoading={isLoading}
            limit={!type && isLimit}
            isProductDisabled={isProductDisabled}
            size={36}
          />
          <T8y variant="t3" bold className={style.priceWrapper}>
            {isLoading ? (
              <Spinner dark size={1} />
            ) : (
              `${branding.currencySymbol}${parseFloat(totalPrice || 0).toFixed(
                2,
              )}`
            )}
          </T8y>
        </Row>
      </Row>
    </Row>
  );
};

export default ProductListItem;
