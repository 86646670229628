export const main = {
  h1: 'O aluguer de ferramentas e máquinas mais fácil e eficiente em Portugal',
  h2: 'Nossa missão é tornar o aluguer de ferramentas e máquinas fácil e acessível a todos. Encontre o que procura para realizar tudo: desde um projeto de paisagismo até uma reforma, e muito mais',
  howItWorks: {
    title: 'Como funciona',
    sections: [
      {
        title: '1. Escolha os produtos',
        description: 'Navegue e escolha em nosso estoque',
      },
      {
        title: '2. Revise sua reserva',
        description: 'Refine sua lista e verifique o preço total',
      },
      {
        title: '3. Checkout em 4 passos fáceis',
        description: 'Especifique os detalhes de entrega e pagamento',
      },
    ],
  },

  subscribeToNewsletter: {
    stayUpdated: 'Fique atualizado',
    enterYourEmail:
      'Digite seu endereço de e-mail para se inscrever e receber ofertas especiais, descontos e novidades do setor',
    agreementText:
      'Ao enviar seus dados, você concorda em ser contatado pela {0} de acordo com nossa {1}',
    linkText: 'Política de Privacidade',
    thankYouForSubscribing: 'Grato pela assinatura!',
    signUp: 'Inscreva-se',
  },

  titles: {
    eventTypes: 'Tipos de evento',
    certifiedPartner: '{0} é um Parceiro certificado',
    proudToWork: 'Orgulho de trabalhar com',
    familyOfBrands: 'Parte da família de marcas easy',
    popularProducts: 'Produtos populares',
    frequentlyHired: 'Freqüentemente alugados juntos',
    easyAndBudget: 'O serviço de aluguer de máquinas fácil e eficiente em {0}',
    ourMission:
      'A nossa missão é tornar o processo de aluguer de máquinas simples, "fácil" e acessível a todos.\nEncontre e alugue o que você precisa para qualquer tipo de projeto - desde a renovação à manutenção, à jardinagem e muito mais.',
    topCategories: 'Alugue nas Categorias Principais',
    hireByCategory: 'Alugue por categoria',
    recentlyViewedProducts: 'Visualizado recentemente',
  },

  notices: {
    noPopularProduct: 'Nenhum produto popular para este pacote',
    newArrivals: 'Recém chegados',
  },
};
