import { useCallback, useEffect, useMemo, useState } from 'react';
import { Lang } from 'lang';

export const useManageInputState = ({
  initialValue,
  setIsListVisible,
  setEmptyResult,
  searchRequestError,
  makeListVisible,
  autoScrollToList,
  handleSearchRequestError,
}) => {
  const { form, formatString } = Lang();

  const [currValueError, setCurrValueError] = useState(null);
  const [currValue, setCurrValue] = useState(initialValue || '');

  useEffect(
    () => {
      if (searchRequestError) {
        setIsListVisible(false);

        if (handleSearchRequestError) {
          handleSearchRequestError(searchRequestError, currValue);
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchRequestError],
  );

  const handleInputFocus = useCallback(() => {
    if (
      currValue.length >= 3 &&
      !searchRequestError &&
      currValue !== initialValue
    ) {
      if (autoScrollToList) {
        makeListVisible();
      } else {
        setIsListVisible(true);
      }
    }
  }, [
    currValue,
    searchRequestError,
    initialValue,
    autoScrollToList,
    makeListVisible,
    setIsListVisible,
  ]);

  const handleInputType = useCallback(
    (e) => {
      const currPostcode = e.target.value;
      setCurrValue(currPostcode);

      if (initialValue) {
        setEmptyResult();
      }

      if (!currPostcode || currPostcode.trim().length < 3) {
        setCurrValueError(
          !currPostcode
            ? form.validation.required
            : formatString(form.validation.minLength, 3),
        );
      } else {
        setCurrValueError(null);
      }
    },
    [initialValue, form, formatString, setEmptyResult],
  );

  return useMemo(
    () => ({
      handleInputFocus,
      handleInputType,
      currValue,
      currValueError,
      setCurrValue,
    }),
    [
      currValue,
      handleInputFocus,
      handleInputType,
      setCurrValue,
      currValueError,
    ],
  );
};
