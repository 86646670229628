import { about } from './en-gb/about';
import { account } from './en-gb/account';
import { carbonEmission } from './en-gb/carbonEmission';
import { checkout } from './en-gb/checkout';
import { common } from './en-gb/common';
import { customer } from './en-gb/customer';
import { dashboard } from './en-gb/dashboard';
import { depots } from './en-gb/depots';
import { error } from './en-gb/error';
import { footer } from './en-gb/footer';
import { form } from './en-gb/form';
import { header } from './en-gb/header';
import { invoice } from './en-gb/invoice';
import { jobTypes } from './en-gb/jobTypes';
import { main } from './en-gb/main';
import { orders } from './en-gb/orders';
import { products } from './en-gb/products';
import { profile } from './en-gb/profile';
import { search } from './en-gb/search';
import { sidebar } from './en-gb/sidebar';
import { success } from './en-gb/success';
import { training } from './en-gb/training';
import { verification } from './en-gb/verification';

export const enGb = {
  about,
  account,
  training,
  main,
  footer,
  sidebar,
  checkout,
  products,
  header,
  form,
  profile,
  dashboard,
  customer,
  search,
  invoice,
  error,
  orders,
  carbonEmission,
  common,
  success,
  jobTypes,
  depots,
  verification,
};
