import React, { useCallback, useContext } from 'react';
import { TrainingContext } from 'contexts';
import { sendEventWithEventLabel } from 'googleAnalytics';
import { Lang } from 'lang';
import { scrollToTop } from 'utils/scrollToTop';

import { Button } from 'ui';

import style from './scrollBtn.module.scss';

const TrainingBtn = ({ text, elementLocation }) => {
  const { form } = Lang();

  const { formRef, isFormOpenedState } = useContext(TrainingContext);

  const [isFormOpened, setIsFormOpened] = isFormOpenedState;

  const handleClick = useCallback(() => {
    if (!isFormOpened) {
      setIsFormOpened(true);
    }

    scrollToTop(formRef?.current?.offsetTop - 110);

    if (elementLocation) {
      sendEventWithEventLabel('training_page_make_enquiry', elementLocation);
    }
  }, [elementLocation, formRef, isFormOpened, setIsFormOpened]);

  return (
    <Button
      className={style.trainingSingUpBtn}
      onClick={handleClick}
      isNoStretch
    >
      {text || form.buttons.makeReservation}
    </Button>
  );
};

export default TrainingBtn;
