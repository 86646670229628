import { createEffect, createStore, restore } from 'effector';
import { createGate } from 'effector-react';

export const RegionsGate = createGate('warehouses');

export const getRegionsFx = createEffect();

export const isRegionsRequestFinished$ = createStore(false).on(
  getRegionsFx.done,
  (_) => true,
);

export const $regions = restore(getRegionsFx, []);
